import { LoadingButton } from '@mui/lab';
import { Box, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { updateExpenseType } from 'app/contexts/reducers/expenseType/expenseTypeSlice';
export default function UpdateExpenseType({
  open,
  onClose,
  currentExpenseType,
  expenseTypeId,
  handleApiCall,
  handleClickSuccess,
  setError,
  handleClickError
}) {
  const dispatch = useDispatch();
  const [expenseEntryDetails, setExpenseEntryDetails] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setExpenseEntryDetails(currentExpenseType);
  }, [currentExpenseType]);
  async function handleUpdate() {
    try {
      setLoading(true);
      const response = await dispatch(updateExpenseType({ expenseTypeId, expenseEntryDetails }));
      if (!response.hasOwnProperty('error')) {
        onClose();
        handleApiCall();
        handleClickSuccess('Expense-type Update successfully');

        setLoading(false);
      } else {
        throw response;
      }
    } catch (e) {
      handleClickError();
      setError(e);
      setLoading(false);
    }
  }
  return (
    <Box>
      <Dialog open={open} onClose={onClose} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>Update Expense-Type</DialogTitle>

        <DialogContent>
          <DialogContentText>Please update the name Expense-Type</DialogContentText>

          <TextField
            fullWidth
            autoFocus
            id='expenseType'
            type='text'
            margin='dense'
            label='Expense Type'
            value={expenseEntryDetails}
            sx={{ my: 2 }}
            onChange={(e) => {
              setExpenseEntryDetails(e.target.value);
            }}
          />
        </DialogContent>

        <DialogActions>
          <Button variant='outlined' color='secondary' onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton
            onClick={handleUpdate}
            color='primary'
            loading={loading}
            disabled={expenseEntryDetails && expenseEntryDetails.length > 0 ? false : true}
            variant='contained'
            sx={{ my: 2 }}
          >
            Update Expense-Type
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
