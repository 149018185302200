import { LoadingButton } from '@mui/lab';
import { Box, TextField, styled } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUpdateSingleItem } from '../helper/updateHelper';
import { H5 } from 'app/components/Typography';
import { updateProducts } from 'app/contexts/reducers/product/productSlice';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});
const FlexBox = styled(Box)(() => ({ display: 'flex', alignItems: 'center' }));
const JustifyBox = styled(FlexBox)(() => ({ justifyContent: 'center' }));

export default function UpdateDialog({
  open,
  onClose,
  updateItemId,
  setApiCall,
  handleClickSuccess,
  setError,
  handleClickError
}) {
  const dispatch = useDispatch();
  const { productData } = useSelector((state) => state.product);
  const { isProductSuccess } = useSelector((state) => state.product);
  const [updateValue, setUpdateValue] = useState('');
  const [itemName, setItemName] = useState('');
  const [productImage, setProductImage] = useState('');
  const [updateProductImage, setHandleProductImage] = useState('');
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (productData) {
      getUpdateSingleItem(productData.data.data, updateItemId).then((data) => {
        setUpdateValue(data.productname);
        setItemName(data.itemname);
        setProductImage(data.productimage);
      });
    }
  }, [updateItemId, isProductSuccess, productData]);
  async function handleImageUpdate(event) {
    setHandleProductImage(event.target.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      setProductImage(reader.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  }
  async function handleUpdate() {
    try {
      setLoading(true);
      const currentItem = await getUpdateSingleItem(productData.data.data, updateItemId);
      if (updateValue !== currentItem.productname) {
        const data = new FormData();
        data.append('productName', updateValue);
        data.append('productImage', updateProductImage);
        const response = await dispatch(updateProducts({ updateItemId, data }));
        if (!response.hasOwnProperty('error')) {
          onClose();
          setApiCall((prev) => !prev);
          handleClickSuccess('Product Update successfully');
          setLoading(false);
        } else {
          throw response;
        }
      } else {
        if (productImage) {
          const data = new FormData();
          data.append('productImage', updateProductImage);
          const response = await dispatch(updateProducts({ updateItemId, data }));
          if (!response.hasOwnProperty('error')) {
            onClose();
            setApiCall((prev) => !prev);
            handleClickSuccess('Product Update successfully');
            setLoading(false);
          } else {
            throw response;
          }
        } else {
          onClose();
          setLoading(false);
        }
      }
    } catch (e) {
      handleClickError();
      setError(e);
      setLoading(false);
    }
  }
  return (
    <Box>
      <Dialog open={open} onClose={onClose} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>Update Item</DialogTitle>

        <DialogContent>
          <DialogContentText>Please update the name of the watch brand</DialogContentText>

          <TextField
            fullWidth
            id='itemName'
            type='text'
            margin='dense'
            label={itemName}
            sx={{ my: 2 }}
            disabled
          />
          <TextField
            fullWidth
            autoFocus
            id='itemName'
            type='text'
            margin='dense'
            label='Watch Model Name'
            value={updateValue}
            sx={{ my: 2 }}
            onChange={(e) => {
              setUpdateValue(e.target.value);
            }}
          />
          <Button
            component='label'
            variant='contained'
            onChange={handleImageUpdate}
            sx={{ my: 2 }}
            startIcon={<CloudUploadIcon />}
          >
            Upload Model Image
            <VisuallyHiddenInput type='file' />
          </Button>
          {/* <TextField
            fullWidth
            autoFocus
            id='modalImage'
            type='file'
            margin='dense'
            label='Upload Model Image'
            onChange={handleImageUpdate}
          /> */}

          <Box sx={{ mt: 2 }}>
            <H5>Product Image</H5>
            <JustifyBox p={4} height='100%' sx={{ maxWidth: 320, m: 'auto' }}>
              <img src={productImage} width='100%' alt={updateValue} />
            </JustifyBox>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant='outlined' color='secondary' onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleUpdate}
            color='primary'
            loading={loading}
            disabled={updateValue.length > 0 ? false : true}
            variant='contained'
            sx={{ my: 2 }}
          >
            Update Item
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
