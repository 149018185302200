import { Box, Card, Grid, styled } from '@mui/material';
import { Fragment } from 'react';

import OrderDetailCards from './shared/OrderDetailCards';
// import UserOrder from './shared/UserOrder';
import BasicDetails from './shared/BasicDetails';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { getOrderEmplyoeeData, getSingleOrder } from 'app/contexts/reducers/transaction/oderSlice';
import AddDiamondInwardDetails from './shared/Inward/AddInwardDetails';

import DiamondInwardTable from './shared/Inward/DiamondInwardTable';
import AddCustomerInwardDetails from './shared/Inward/AddCustomerInward';
import DiamondOutWard from './shared/Outward/DiamondOutWard';
import AddBulkInwardDetails from './shared/Inward/AddBulkInwardDetails';
import UpdateOrderStatus from './shared/UpdateOrderStatus';
import { getVendorAccountData } from 'app/contexts/reducers/account/accountSlice';
import Loading from 'app/components/MatxLoading';
const ContentBox = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' }
}));
const FlexBox = styled(Box)(() => ({ display: 'flex', alignItems: 'center' }));

const JustifyBox = styled(FlexBox)(() => ({ justifyContent: 'center' }));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  marginRight: '.5rem',
  textTransform: 'capitalize'
}));

const SingleOrder = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const { particularOrder, isParticularOrderSuccess, isOrderEmployees, orderEmployeeData } =
    useSelector((state) => state.order);
  const [singleOrder, setSingleOrder] = useState();
  const [connectedEmployee, setConnectedEmployee] = useState([]);

  const [isloading, setIsLoading] = useState(true);
  const [apiCall, setApiCall] = useState(false);
  function handleApiCall() {
    setApiCall((prev) => !prev);
  }
  useEffect(() => {
    dispatch(getSingleOrder({ orderId }));
    dispatch(getVendorAccountData());
    dispatch(getOrderEmplyoeeData({ orderId }));
  }, [dispatch, orderId, apiCall]);
  useEffect(() => {
    if (isParticularOrderSuccess) {
      setSingleOrder(particularOrder.data.data[0]);
      // setConnectedEmployee(particularOrder.data.data[0].connected_employee.split(','));
      setIsLoading(false);
    }
  }, [particularOrder, isParticularOrderSuccess]);
  useEffect(() => {
    if (isOrderEmployees) {
      if (Number(orderEmployeeData.data.code) === 200) {
        setConnectedEmployee(orderEmployeeData.data.data.split(','));
      } else {
        setConnectedEmployee([]);
      }
    } else {
      setConnectedEmployee([]);
    }
  }, [isOrderEmployees, orderEmployeeData]);

  return (
    <Fragment>
      <ContentBox className='analytics'>
        <Grid container spacing={3}>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            {!isloading ? (
              <OrderDetailCards singleOrder={singleOrder} handleApiCall={handleApiCall} />
            ) : (
              <Loading />
            )}

            {!isloading ? (
              singleOrder.order_inward_type.replace(/\s/g, '').toLocaleLowerCase() === 'self' &&
              (Number(singleOrder.status) === 1 || Number(singleOrder.status) === 0) && (
                <AddDiamondInwardDetails
                  apiCall={apiCall}
                  handleApiCall={handleApiCall}
                  inwardType={singleOrder.order_inward_type}
                  orderId={orderId}
                />
              )
            ) : (
              <Loading />
            )}

            {!isloading ? (
              singleOrder.order_inward_type.replace(/\s/g, '').toLocaleLowerCase() ===
                'customer_self' &&
              (Number(singleOrder.status) === 1 || Number(singleOrder.status) === 0) && (
                <AddCustomerInwardDetails
                  apiCall={apiCall}
                  handleApiCall={handleApiCall}
                  customerId={singleOrder.account_id}
                  inwardType={singleOrder.order_inward_type}
                  orderId={orderId}
                />
              )
            ) : (
              <Loading />
            )}

            {!isloading ? (
              singleOrder.order_inward_type.replace(/\s/g, '').toLocaleLowerCase() ===
                'bulk_self' &&
              (Number(singleOrder.status) === 1 || Number(singleOrder.status) === 0) && (
                <AddBulkInwardDetails
                  apiCall={apiCall}
                  handleApiCall={handleApiCall}
                  customerId={singleOrder.account_id}
                  customerName={singleOrder.account_name}
                  inwardType={singleOrder.order_inward_type}
                  orderId={orderId}
                />
              )
            ) : (
              <Loading />
            )}

            {!isloading ? (
              <DiamondInwardTable
                apiCall={apiCall}
                handleApiCall={handleApiCall}
                orderId={orderId}
                orderStatus={singleOrder.status}
              />
            ) : (
              <Loading />
            )}
            {!isloading ? (
              <DiamondOutWard
                orderStatus={singleOrder.status}
                apiCall={apiCall}
                orderId={orderId}
                customerId={singleOrder.account_id}
              />
            ) : (
              <Loading />
            )}
            {/* {!isloading ? (
              <UserOrder CustomerId={singleOrder.account_id} currentOrder={orderId} />
            ) : (
              <Loading />
            )} */}
          </Grid>

          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Card sx={{ px: 3, py: 2, mb: 3 }}>
              <Title>Ordered Item</Title>

              {!isloading ? (
                <JustifyBox px={4} pb={4} pt={2} sx={{ minWidth: 320 }}>
                  <img src={singleOrder.productimage} width='100%' alt={singleOrder.product_name} />
                </JustifyBox>
              ) : (
                <Loading />
              )}
            </Card>

            {!isloading ? (
              <BasicDetails singleOrder={singleOrder} connectedEmployee={connectedEmployee} />
            ) : (
              <Loading />
            )}
            {!isloading ? (
              <UpdateOrderStatus singleOrder={singleOrder} handleApiCall={handleApiCall} />
            ) : (
              <Loading />
            )}
          </Grid>
        </Grid>
      </ContentBox>
    </Fragment>
  );
};

export default SingleOrder;
