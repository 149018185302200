import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
  styled
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import DialogTitle from '@mui/material/DialogTitle';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSpecificInvoice, updateInvoice } from 'app/contexts/reducers/transaction/invoiceSlice';
const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' }
}));
const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main }
}));

export default function UpdateInvoiceDialog({
  open,
  onClose,
  updateInvoiceId,
  handleApiCall,
  apiCall,
  handleClickSuccess,
  setError,
  handleClickError
}) {
  const dispatch = useDispatch();

  const { specificInvoiceData, isSpecificInvoiceSuccess } = useSelector((state) => state.invoice);

  const [updateValue, setUpdateValue] = useState({
    invoiceId: updateInvoiceId,
    selfWeight: '',
    usedPcs: '',
    labourChargePerPcs: '',
    labourCharge: '',
    diamondCaratPrice: '',
    diamondPrice: '',
    productPrice: '',
    extraCost: 0,
    remark: '',
    purity: '',
    subTotal: '',
    total: ''
  });

  const [loading, setLoading] = useState(false);
  const [specificInvoiceDetails, setSpecificInvoiceDetails] = useState();
  useEffect(() => {
    dispatch(getSpecificInvoice({ invoiceId: updateInvoiceId }));
  }, [dispatch, updateInvoiceId, apiCall]);
  useEffect(() => {
    if (isSpecificInvoiceSuccess) {
      setSpecificInvoiceDetails(specificInvoiceData.data.data[0]);
    }
  }, [isSpecificInvoiceSuccess, specificInvoiceData, updateInvoiceId]);
  useEffect(() => {
    if (isSpecificInvoiceSuccess) {
      specificInvoiceDetails &&
        setUpdateValue({
          selfWeight: specificInvoiceDetails.self_weight,
          usedPcs: specificInvoiceDetails.studd_pieces,
          labourChargePerPcs: specificInvoiceDetails.labour_charge_per_pcs,
          labourCharge: specificInvoiceDetails.labour_charge,
          diamondCaratPrice: specificInvoiceDetails.diamond_carat_price,
          diamondPrice: specificInvoiceDetails.diamond_price,
          productPrice: specificInvoiceDetails.product_price,
          extraCost: specificInvoiceDetails.extra_cost,
          remark: specificInvoiceDetails.remark,
          purity: specificInvoiceDetails.purity,
          subTotal: specificInvoiceDetails.subTotal,
          total: specificInvoiceDetails.total
        });
    }
    // eslint-disable-next-line
  }, [
    updateInvoiceId,
    isSpecificInvoiceSuccess,
    specificInvoiceDetails,
    specificInvoiceData,
    open
  ]);
  useEffect(() => {
    // Ensure both values are integers before performing multiplication
    const labourChargePerPcs = parseInt(updateValue.labourChargePerPcs);
    const usedPcs = parseInt(updateValue.usedPcs);

    // Check if both values are valid integers
    if (!isNaN(labourChargePerPcs) && !isNaN(usedPcs)) {
      // Perform the multiplication and update the state
      const result = labourChargePerPcs * usedPcs;
      setUpdateValue((prev) => ({ ...prev, labourCharge: result }));
    }
  }, [updateValue.labourChargePerPcs, updateValue.usedPcs]);
  useEffect(() => {
    // Ensure both values are integers before performing multiplication
    const diamondCaratPrice = parseInt(updateValue.diamondCaratPrice);
    const selfWeight = parseFloat(parseFloat(updateValue.selfWeight).toFixed(3));

    // Check if both values are valid integers
    if (!isNaN(diamondCaratPrice) && !isNaN(selfWeight)) {
      // Perform the multiplication and update the state
      const result = diamondCaratPrice * selfWeight;
      setUpdateValue((prev) => ({ ...prev, diamondPrice: result }));
    }
  }, [updateValue.diamondCaratPrice, updateValue.selfWeight]);
  useEffect(() => {
    // Ensure all values are numbers before performing addition
    const labourCharge = parseInt(updateValue.labourCharge);
    const diamondPrice = parseInt(updateValue.diamondPrice);
    const productPrice = parseInt(updateValue.productPrice);
    const extraCost = parseInt(updateValue.extraCost);

    // Check if all values are valid numbers
    if (!isNaN(labourCharge) && !isNaN(diamondPrice) && !isNaN(productPrice) && !isNaN(extraCost)) {
      // Perform the addition and update the state
      const result = labourCharge + diamondPrice + productPrice + extraCost;
      setUpdateValue((prev) => ({ ...prev, total: result, subTotal: result }));
    }
  }, [
    updateValue.labourCharge,
    updateValue.diamondPrice,
    updateValue.productPrice,
    updateValue.extraCost,
    setUpdateValue
  ]);

  async function handleUpdate() {
    try {
      if (
        updateValue.labourChargePerPcs &&
        updateValue.labourChargePerPcs > 0 &&
        updateValue.total &&
        updateValue.total > 0 &&
        updateValue.subTotal &&
        updateValue.subTotal > 0
      ) {
        const updateInvoiceDetails = { ...updateValue };
        const propertiesToConvert = [
          'selfWeight',
          'usedPcs',
          'labourChargePerPcs',
          'diamondCaratPrice',
          'diamondPrice',
          'labourCharge',
          'productPrice',
          'extraCost',
          'subTotal',
          'total'
        ];
        propertiesToConvert.forEach((property) => {
          if (updateInvoiceDetails.hasOwnProperty(property)) {
            if (property === 'usedWeight' || property === 'selfWeight') {
              updateInvoiceDetails[property] = parseFloat(
                parseFloat(updateInvoiceDetails[property]).toFixed(3)
              );
            } else {
              updateInvoiceDetails[property] = parseInt(updateInvoiceDetails[property]);
            }
          }
        });
        Object.keys(updateInvoiceDetails).forEach((key) => {
          if (updateInvoiceDetails[key] === '') {
            delete updateInvoiceDetails[key];
          }
          if (key === 'employeeName') {
            updateInvoiceDetails[key] = updateInvoiceDetails[key].join(', ');
          }
        });
        const responseValue = await dispatch(
          updateInvoice({ invoiceId: updateInvoiceId, updateInvoiceDetails })
        );
        if (!responseValue.hasOwnProperty('error')) {
          onClose();
          handleClickSuccess('Invoice Updated successfully!!');
          handleApiCall();
          setLoading(false);
        } else {
          throw responseValue;
        }
      } else {
        handleClickError();
        setError({ payload: 'Please Fill Mandatory Data!' });
        setLoading(false);
      }
    } catch (e) {
      handleClickError();
      setError(e);
      setLoading(false);
    }
  }

  return (
    <Box>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth='md'
        fullWidth
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>Update Invoice</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: '10px' }}>
            You can Update Invoice Details From Here
          </DialogContentText>

          <Grid container spacing={3} sx={{ mb: '24px' }}>
            <Grid item xs={12} md={12}>
              <StyledCard elevation={6}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ContentBox>
                      <TextField
                        id='usedPcs'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Used Piece'
                        required
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '80%' }}
                        variant='standard'
                        value={updateValue.usedPcs}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='labourChargePerPcs'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Labour/Piece Charge'
                        required
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '80%' }}
                        variant='standard'
                        value={updateValue.labourChargePerPcs}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, labourChargePerPcs: e.target.value });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='diamondCaretPrice'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Diamond Caret Charge'
                        required
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '80%' }}
                        variant='standard'
                        value={updateValue.diamondCaratPrice}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, diamondCaratPrice: e.target.value });
                        }}
                      />
                    </ContentBox>

                    <ContentBox>
                      <TextField
                        id='productPrice'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Product Price'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '80%' }}
                        variant='standard'
                        value={updateValue.productPrice}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, productPrice: e.target.value });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='purity'
                        type='text'
                        margin='dense'
                        size='small'
                        label='Purity'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '80%' }}
                        variant='standard'
                        value={updateValue.purity}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, purity: e.target.value });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='total'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Total'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '80%' }}
                        variant='standard'
                        value={updateValue.total}
                      />
                    </ContentBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ContentBox>
                      <TextField
                        id='selfWeight'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Self Weight'
                        required
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '80%' }}
                        variant='standard'
                        value={updateValue.selfWeight}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='labourCharge'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Labour Charge'
                        required
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '80%' }}
                        variant='standard'
                        value={updateValue.labourCharge}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='diamondPrice'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Diamond Price'
                        required
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '80%' }}
                        variant='standard'
                        value={updateValue.diamondPrice}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='extraCost'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Extra Cost'
                        required
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '80%' }}
                        variant='standard'
                        value={updateValue.extraCost}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, extraCost: e.target.value });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        multiline
                        maxRows={4}
                        id='remark'
                        type='text'
                        margin='dense'
                        size='small'
                        label='Remark'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '80%' }}
                        variant='standard'
                        value={updateValue.remark}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, remark: e.target.value });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='subTotal'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Sub-Total'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '80%' }}
                        variant='standard'
                        value={updateValue.subTotal}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, subTotal: e.target.value });
                        }}
                      />
                    </ContentBox>
                  </Grid>
                </Grid>
              </StyledCard>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='standard' color='secondary' onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleUpdate}
            color='primary'
            loading={loading}
            variant='contained'
            sx={{ my: 2 }}
          >
            Update Invoice
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
