import { MenuItem, Select } from '@mui/material';

import { useSelector } from 'react-redux';
import { useState } from 'react';
import { selectEntryMonth } from '../../helper/filterStock';

const FilterSpecificStock = ({ setVendorStockData }) => {
  const { singleStockEntryData } = useSelector((state) => state.stock);
  const [defaultSelect, setDefaultSelect] = useState('all');
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const handleSelect = async (event) => {
    setDefaultSelect(event.target.value);
    const seletctedData = await selectEntryMonth(
      event.target.value,
      singleStockEntryData.data.data
    );
    setVendorStockData(seletctedData);
  };
  return (
    <Select size='small' value={defaultSelect} onChange={handleSelect}>
      <MenuItem value='all' id='all'>
        Select Month
      </MenuItem>

      <MenuItem value={currentMonth} id={currentMonth}>
        This Month
      </MenuItem>

      <MenuItem
        value={currentMonth === 1 ? 12 : currentMonth - 1}
        id={currentMonth === 1 ? 12 : currentMonth - 1}
      >
        Last Month
      </MenuItem>
    </Select>
  );
};

export default FilterSpecificStock;
