import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Fab,
  Icon,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Snackbar,
  Alert,
  IconButton,
  Avatar,
  Grid
} from '@mui/material';

import { H5, H6, Paragraph } from 'app/components/Typography';
import { addItem, deleteItem, getItems } from 'app/contexts/reducers/item/itemSlice';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteDialog from './DeleteDialog';
import UpdateDialog from './UpdateDialog';
import SearchItem from './searchItem';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});
const CardHeader = styled(Box)(() => ({
  display: 'flex',
  paddingLeft: '24px',
  paddingRight: '24px',
  marginBottom: '12px',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  textTransform: 'capitalize'
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)'
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' }
}));
const FlexBox = styled(Box)(() => ({ display: 'flex', alignItems: 'center' }));
const JustifyBox = styled(FlexBox)(() => ({ justifyContent: 'center' }));
const ItemTable = () => {
  const dispatch = useDispatch();
  const { itemData } = useSelector((state) => state.item);
  const { isItemSuccess } = useSelector((state) => state.item);

  const [open, setOpen] = useState(false);
  const [itemName, setItemName] = useState();
  const [itemImage, setItemImage] = useState();
  const [previweImage, setPreviweImage] = useState();
  const [loading, setLoading] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [error, setError] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [itemList, setItemList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiCall, setApiCall] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [updateItemId, setUpdateItemId] = useState(null);
  const handleClickOpen = () => setOpen(true);

  useEffect(() => {
    dispatch(getItems());
  }, [dispatch, apiCall]);
  useEffect(() => {
    if (isItemSuccess) {
      setIsLoading(true);
      setItemList(itemData.data.data);
      setIsLoading(false);
    }
  }, [isItemSuccess, itemData]);

  function handleDeleteClose() {
    setDeleteOpen(false);
  }
  function handleUpdateClose() {
    setUpdateOpen(false);
  }
  function handleClickSuccess(message) {
    setErrorOpen(false);
    setSuccessMessage(message);
    setSuccessOpen(true);
  }
  function handleClickError() {
    setSuccessOpen(false);
    setErrorOpen(true);
  }
  function handleSnakeBarClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
    setErrorOpen(false);
  }
  async function handleImageUpdate(event) {
    setItemImage(event.target.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      setPreviweImage(reader.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  }
  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (itemName && itemImage) {
        const data = new FormData();
        data.append('itemName', itemName);
        data.append('itemImage', itemImage);
        const responseValue = await dispatch(addItem(data));
        if (!responseValue.hasOwnProperty('error')) {
          setApiCall((prev) => !prev);
          setOpen(false);
          handleClickSuccess('Item added successfully');
          setLoading(false);
          setPreviweImage('');
          setItemName('');
          setItemImage('');
        } else {
          throw responseValue;
        }
      } else {
        handleClickError();
        setError({ payload: 'Please Add Item-Image and Item-Name' });
        setLoading(false);
      }
    } catch (e) {
      handleClickError();
      setError(e);
      setLoading(false);
    }
  };
  const handleDelete = async () => {
    try {
      const code = window.prompt('Please enter the code to delete the stock:');
      if (code === '3535') {
        const response = await dispatch(deleteItem(deleteItemId));
        if (!response.hasOwnProperty('error')) {
          handleDeleteClose();
          setApiCall((prev) => !prev);
          setOpen(false);
          handleClickSuccess('Item delete successfully');
          setLoading(false);
        } else {
          throw response;
        }
      } else {
        window.alert('Incorrect code entered. Stock deletion canceled.');
      }
    } catch (e) {
      handleClickError();
      setError(e);
      setLoading(false);
    }
  };
  const handleClose = () => setOpen(false);

  return (
    <React.Fragment>
      <Snackbar open={successOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='error'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {error?.payload}
        </Alert>
      </Snackbar>
      <Card elevation={3} sx={{ pt: '20px', mb: 3 }}>
        <CardHeader>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <Title>Item</Title>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <SearchItem setItemList={setItemList} />
                </Grid>
                <Grid item>
                  <Fab
                    variant='extended'
                    color='primary'
                    aria-label='AddItem'
                    className='button'
                    onClick={handleClickOpen}
                    sx={{ ml: 3 }}
                  >
                    <Icon>add</Icon>
                    Add Item
                  </Fab>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardHeader>

        <Box overflow='auto'>
          <ProductTable>
            <TableHead>
              <TableRow>
                <TableCell sx={{ px: 3 }} colSpan={2}>
                  Index
                </TableCell>
                <TableCell sx={{ px: 'auto' }} colSpan={4}>
                  Name
                </TableCell>
                <TableCell sx={{ px: 0 }} colSpan={2}>
                  Edit
                </TableCell>
                <TableCell sx={{ px: 0 }} colSpan={2}>
                  Delete
                </TableCell>
              </TableRow>
            </TableHead>

            {isLoading ? (
              'Loading ...'
            ) : (
              <TableBody>
                {itemList && itemList.length > 0 ? (
                  itemList.map((item, index) => (
                    <TableRow key={index} hover>
                      <TableCell
                        align='left'
                        colSpan={2}
                        sx={{ px: 3, textTransform: 'capitalize' }}
                      >
                        <H6 sx={{ px: 3 }}>{index + 1}</H6>
                      </TableCell>
                      <TableCell
                        colSpan={4}
                        align='left'
                        sx={{ px: 0, textTransform: 'capitalize' }}
                      >
                        <Box display='flex' alignItems='center'>
                          <Avatar src={item.itemimage} />
                          <Paragraph sx={{ m: 0, ml: 4 }}>{item.itemname}</Paragraph>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ px: 0 }} colSpan={2}>
                        <IconButton
                          onClick={() => {
                            setUpdateOpen(true);
                            setUpdateItemId(item.id);
                          }}
                        >
                          <Icon color='primary'>edit</Icon>
                        </IconButton>
                      </TableCell>
                      <TableCell sx={{ px: 0 }} colSpan={2}>
                        <IconButton
                          onClick={() => {
                            setDeleteOpen(true);
                            setDeleteItemId(item.id);
                          }}
                        >
                          <Icon color='error'>delete</Icon>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={8}
                      sx={{ textAlign: 'center', color: 'rgb(34,41,67)', fontWeight: 'bold' }}
                    >
                      No Stock Data Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </ProductTable>
        </Box>
      </Card>
      {/* Start-Add-Item */}
      <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>Add Item</DialogTitle>

        <DialogContent>
          <DialogContentText>Please enter the name of the watch brand</DialogContentText>

          <TextField
            fullWidth
            autoFocus
            id='itemName'
            type='text'
            margin='dense'
            label='Watch Brand Name'
            sx={{ my: 2 }}
            onChange={(e) => {
              setItemName(e.target.value);
            }}
          />
          <Button
            component='label'
            variant='contained'
            onChange={handleImageUpdate}
            sx={{ my: 2 }}
            startIcon={<CloudUploadIcon />}
          >
            Upload Brand Image
            <VisuallyHiddenInput type='file' />
          </Button>
          <Box sx={{ mt: 2 }}>
            <H5>Item Image</H5>
            {previweImage && (
              <JustifyBox p={4} height='100%' sx={{ maxWidth: 320, m: 'auto' }}>
                <img src={previweImage} width='100%' alt={itemName ? itemName : 'Item-Image'} />
              </JustifyBox>
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant='outlined' color='secondary' onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleSubmit}
            color='primary'
            loading={loading}
            variant='contained'
            sx={{ my: 2 }}
          >
            Add Item
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {/* End-Add-Item */}

      {/* Start-Delete-Item */}
      <DeleteDialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        deleteItemId={deleteItemId}
        handleDelete={handleDelete}
        loading={loading}
      />
      {/* End-Delete-Item */}
      <UpdateDialog
        open={updateOpen}
        onClose={handleUpdateClose}
        updateItemId={updateItemId}
        setApiCall={setApiCall}
        handleClickSuccess={handleClickSuccess}
        setError={setError}
        handleClickError={handleClickError}
      />
    </React.Fragment>
  );
};

export default ItemTable;
