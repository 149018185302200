import {
  Alert,
  Box,
  Card,
  Grid,
  Icon,
  IconButton,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { H5 } from 'app/components/Typography';
import { getExpenseTypeData } from 'app/contexts/reducers/expenseType/expenseTypeSlice';
import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UpdateExpenseType from './UpdateExpenseType';
import DeleteExpenseTypeDialog from './DeleteExpenseType';

const CardHeader = styled(Box)(() => ({
  display: 'flex',
  paddingLeft: '24px',
  paddingRight: '24px',
  marginBottom: '12px',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  textTransform: 'capitalize'
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)'
  },
  '& td': { borderBottom: '1px solid lightgray' },
  '& td:first-of-type': { paddingLeft: '16px !important' }
}));

const ExpenseTypeTable = ({ handleApiCall, apiCall }) => {
  const dispatch = useDispatch();
  const { isExpenseTypeSuccess, expenseTypeData } = useSelector((state) => state.expenseType);

  const [expenseTypesData, setExpenseTypesData] = useState([]);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const [updateExpenseTypeId, setUpdateExpenseTypeId] = useState();
  const [deleteExpenseTypeId, setDeleteExpenseTypeId] = useState(null);
  const [currentExepnseType, setCurrentExpenseType] = useState();
  const [error, setError] = useState();
  const [errorOpen, setErrorOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [successOpen, setSuccessOpen] = useState(false);

  useEffect(() => {
    dispatch(getExpenseTypeData());
  }, [dispatch, apiCall]);
  useEffect(() => {
    if (isExpenseTypeSuccess) {
      if (Number(expenseTypeData.data.code) === 200) {
        setExpenseTypesData(expenseTypeData.data.data);
      } else {
        setExpenseTypesData([]);
      }
    }
  }, [isExpenseTypeSuccess, expenseTypeData]);
  function handleUpdateClose() {
    setUpdateOpen(false);
  }
  function handleDeleteClose() {
    setDeleteOpen(false);
  }
  function handleClickSuccess(message) {
    setErrorOpen(false);
    setSuccessMessage(message);
    setSuccessOpen(true);
  }
  function handleClickError() {
    setSuccessOpen(false);
    setErrorOpen(true);
  }
  function handleSnakeBarClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
    setErrorOpen(false);
  }
  return (
    <React.Fragment>
      {/* Alert */}
      <Snackbar open={successOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='error'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {error?.payload}
        </Alert>
      </Snackbar>
      <Card elevation={3} sx={{ pt: '20px', mb: 3 }}>
        <CardHeader>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <Title>Expense Type</Title>
            </Grid>
            <Grid item>
              <Grid container spacing={1}></Grid>
            </Grid>
          </Grid>
        </CardHeader>

        <Box overflow='auto'>
          <ProductTable>
            <TableHead sx={{ backgroundColor: 'rgb(34,41,67)' }}>
              <TableRow>
                <TableCell colSpan={2} sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Index
                </TableCell>
                <TableCell colSpan={5} sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Expense Type
                </TableCell>
                <TableCell colSpan={5} sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {expenseTypesData && expenseTypesData.length > 0 ? (
                expenseTypesData.map((data, index) => (
                  <TableRow key={index} hover>
                    <TableCell colSpan={2} align='left' sx={{ px: 3, textTransform: 'capitalize' }}>
                      <H5 sx={{ px: 3 }}>{index + 1}</H5>
                    </TableCell>
                    <TableCell colSpan={5} align='left' sx={{ px: 0, textTransform: 'capitalize' }}>
                      {data.expense_type}
                    </TableCell>
                    <TableCell colSpan={5} align='left' sx={{ px: 0, textTransform: 'capitalize' }}>
                      <IconButton
                        sx={{ ml: 1 }}
                        onClick={() => {
                          setUpdateExpenseTypeId(data.id);
                          setCurrentExpenseType(data.expense_type);
                          setUpdateOpen(true);
                        }}
                      >
                        <Icon color='primary'>edit</Icon>
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setDeleteExpenseTypeId(data.id);
                          setDeleteOpen(true);
                        }}
                      >
                        <Icon color='error'>close</Icon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={12} sx={{ textAlign: 'center' }}>
                    No data found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </ProductTable>
        </Box>
      </Card>
      <UpdateExpenseType
        open={updateOpen}
        onClose={handleUpdateClose}
        expenseTypeId={updateExpenseTypeId}
        currentExpenseType={currentExepnseType}
        handleApiCall={handleApiCall}
        handleClickSuccess={handleClickSuccess}
        setError={setError}
        handleClickError={handleClickError}
      />
      <DeleteExpenseTypeDialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        expenseTypeId={deleteExpenseTypeId}
        handleApiCall={handleApiCall}
        handleClickSuccess={handleClickSuccess}
        handleClickError={handleClickError}
        setError={setError}
      />
    </React.Fragment>
  );
};

export default ExpenseTypeTable;
