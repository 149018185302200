import {
  Box,
  Card,
  Fab,
  Icon,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Snackbar,
  Alert,
  IconButton,
  Avatar,
  Grid
} from '@mui/material';

import { H6, Paragraph } from 'app/components/Typography';
import { getItems } from 'app/contexts/reducers/item/itemSlice';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteDialog from './DeleteDialog';
import UpdateDialog from './UpdateDialog';
import AddDialog from './AddDialog';
import { getProducts } from 'app/contexts/reducers/product/productSlice';
import SearchProduct from './SearchProduct';

const CardHeader = styled(Box)(() => ({
  display: 'flex',
  paddingLeft: '24px',
  paddingRight: '24px',
  marginBottom: '12px',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  textTransform: 'capitalize'
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)'
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' }
}));

const ProductTableHolder = () => {
  const dispatch = useDispatch();
  const { productData } = useSelector((state) => state.product);
  const { isProductSuccess } = useSelector((state) => state.product);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [error, setError] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [productList, setProductList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiCall, setApiCall] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [updateItemId, setUpdateItemId] = useState(null);
  const handleClickOpen = () => setOpen(true);

  useEffect(() => {
    dispatch(getItems());
    dispatch(getProducts());
  }, [dispatch, apiCall]);
  useEffect(() => {
    if (isProductSuccess) {
      setIsLoading(true);
      setProductList(productData.data.data);
      setIsLoading(false);
    }
  }, [isProductSuccess, productData]);

  function handleDeleteClose() {
    setDeleteOpen(false);
  }
  function handleUpdateClose() {
    setUpdateOpen(false);
  }
  function handleClickSuccess(message) {
    setErrorOpen(false);
    setSuccessMessage(message);
    setSuccessOpen(true);
  }
  function handleClickError() {
    setSuccessOpen(false);
    setErrorOpen(true);
  }
  function handleSnakeBarClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
    setErrorOpen(false);
  }
  // const handleDelete = async () => {
  //   try {
  //     const response = await dispatch(deleteItem(deleteItemId));
  //     if (!response.hasOwnProperty('error')) {
  //       handleDeleteClose();
  //       setApiCall((prev) => !prev);
  //       setOpen(false);
  //       handleClickSuccess('Prodcut delete successfully');
  //       setLoading(false);
  //     } else {
  //       throw response;
  //     }
  //   } catch (e) {
  //     handleClickError();
  //     setError(e);
  //     setLoading(false);
  //   }
  // };
  const handleClose = () => setOpen(false);

  return (
    <React.Fragment>
      <Snackbar open={successOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='error'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {error?.payload}
        </Alert>
      </Snackbar>
      <Card elevation={3} sx={{ pt: '20px', mb: 3 }}>
        <CardHeader>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <Title>Prodcut</Title>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <SearchProduct setProductList={setProductList} />
                </Grid>
                <Grid item>
                  <Fab
                    variant='extended'
                    color='primary'
                    aria-label='AddItem'
                    className='button'
                    onClick={handleClickOpen}
                  >
                    <Icon sx={{ mr: 1 }}>add</Icon>
                    Add Product
                  </Fab>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardHeader>

        <Box overflow='auto'>
          <ProductTable>
            <TableHead>
              <TableRow>
                <TableCell sx={{ px: 3 }} colSpan={2}>
                  Index
                </TableCell>
                <TableCell sx={{ px: 3 }} colSpan={2}>
                  Item Name
                </TableCell>
                <TableCell sx={{ px: 'auto' }} colSpan={4}>
                  Product Name
                </TableCell>
                <TableCell sx={{ px: 0 }} colSpan={2}>
                  Edit
                </TableCell>
                <TableCell sx={{ px: 0 }} colSpan={2}>
                  Delete
                </TableCell>
              </TableRow>
            </TableHead>

            {isLoading ? (
              'Loading ...'
            ) : (
              <TableBody>
                {productList && productList.length > 0 ? (
                  productList.map((product, index) => (
                    <TableRow key={index} hover>
                      <TableCell
                        align='left'
                        colSpan={2}
                        sx={{ px: 3, textTransform: 'capitalize' }}
                      >
                        <H6 sx={{ px: 3 }}>{index + 1}</H6>
                      </TableCell>
                      <TableCell
                        align='left'
                        colSpan={2}
                        sx={{ px: 3, textTransform: 'capitalize' }}
                      >
                        <Paragraph sx={{ px: 0 }}>{product.itemname}</Paragraph>
                      </TableCell>
                      <TableCell
                        colSpan={4}
                        align='left'
                        sx={{ px: 0, textTransform: 'capitalize' }}
                      >
                        <Box display='flex' alignItems='center'>
                          <Avatar src={product.productimage} />
                          <Paragraph sx={{ m: 0, ml: 4 }}>{product.productname}</Paragraph>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ px: 0 }} colSpan={2}>
                        <IconButton
                          onClick={() => {
                            setUpdateOpen(true);
                            setUpdateItemId(product.id);
                          }}
                        >
                          <Icon color='primary'>edit</Icon>
                        </IconButton>
                      </TableCell>
                      <TableCell sx={{ px: 0 }} colSpan={2}>
                        <IconButton
                          onClick={() => {
                            setDeleteOpen(true);
                            setDeleteItemId(product.id);
                          }}
                        >
                          <Icon color='error'>delete</Icon>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={8}
                      sx={{ textAlign: 'center', color: 'rgb(34,41,67)', fontWeight: 'bold' }}
                    >
                      No Stock Data Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </ProductTable>
        </Box>
      </Card>
      {/* Start-Add-Item */}
      <AddDialog
        open={open}
        setOpen={setOpen}
        setApiCall={setApiCall}
        handleClickSuccess={handleClickSuccess}
        setError={setError}
        handleClickError={handleClickError}
        onClose={handleClose}
      />
      {/* End-Add-Item */}

      {/* Start-Delete-Item */}
      <DeleteDialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        deleteItemId={deleteItemId}
        loading={loading}
        setLoading={setLoading}
        setApiCall={setApiCall}
        handleClickSuccess={handleClickSuccess}
        handleClickError={handleClickError}
        setError={setError}
        setOpen={setOpen}
      />
      {/* End-Delete-Item */}
      <UpdateDialog
        open={updateOpen}
        onClose={handleUpdateClose}
        updateItemId={updateItemId}
        setApiCall={setApiCall}
        handleClickSuccess={handleClickSuccess}
        setError={setError}
        handleClickError={handleClickError}
      />
    </React.Fragment>
  );
};

export default ProductTableHolder;
