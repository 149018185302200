import {
  Alert,
  Box,
  Card,
  Fab,
  Icon,
  IconButton,
  Radio,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { H6 } from 'app/components/Typography';
import { useState } from 'react';
import AddBookMasterDialog from './addBookMasterDialog';
import React from 'react';
import { getBookMaster, updateDefaultYear } from 'app/contexts/reducers/bookMaster/bookMasterSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import DeleteBookMasterDialog from './DeleteBookMasterDialog';

const CardHeader = styled(Box)(() => ({
  display: 'flex',
  paddingLeft: '24px',
  paddingRight: '24px',
  marginBottom: '12px',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  textTransform: 'capitalize'
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)'
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' }
}));

const BookMasterTable = () => {
  const dispatch = useDispatch();

  const { bookMasterData, isBookMasterSuccess, bookYear } = useSelector(
    (state) => state.bookMaster
  );
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteYearId, setDeleteYearId] = useState();
  const [deleteYearName, setDeleteYearName] = useState();
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [errorOpen, setErrorOpen] = useState(false);
  const [error, setError] = useState();
  const [apiCall, setApiCall] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [yearData, setYearData] = useState([]);
  const [defaultYear, setDefaultYear] = useState(bookYear);
  useEffect(() => {
    dispatch(getBookMaster());
  }, [dispatch, apiCall]);
  useEffect(() => {
    if (isBookMasterSuccess) {
      setIsLoading(true);
      setYearData(bookMasterData.data.data);
      setIsLoading(false);
    }
  }, [isBookMasterSuccess, bookMasterData]);

  function handleSnakeBarClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
    setErrorOpen(false);
  }
  function handleDeleteClose() {
    setDeleteOpen(false);
  }
  function handleClickSuccess(message) {
    setErrorOpen(false);
    setSuccessMessage(message);
    setSuccessOpen(true);
  }
  function handleClickError() {
    setSuccessOpen(false);
    setErrorOpen(true);
  }
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Card elevation={3} sx={{ pt: '20px', mb: 3 }}>
        <CardHeader>
          <Title>Book Master</Title>
          <Fab
            variant='extended'
            color='primary'
            aria-label='AddItem'
            className='button'
            onClick={handleClickOpen}
            sx={{ ml: 3 }}
          >
            <Icon>add</Icon>
            Add Year
          </Fab>
        </CardHeader>

        <Box overflow='auto'>
          <ProductTable>
            <TableHead>
              <TableRow>
                <TableCell sx={{ px: 3 }}>Index</TableCell>
                <TableCell sx={{ px: 0 }}>Year</TableCell>
                <TableCell sx={{ px: 0 }}>Select Default</TableCell>
                <TableCell sx={{ px: 0 }}>Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {!isLoading ? (
                yearData.map((year, index) => (
                  <TableRow key={index} hover>
                    <TableCell align='left' sx={{ px: 0, textTransform: 'capitalize' }}>
                      <H6 sx={{ px: 3 }}>{index + 1}</H6>
                    </TableCell>
                    <TableCell align='left' sx={{ px: 0, textTransform: 'capitalize' }}>
                      {year.name}
                    </TableCell>
                    <TableCell>
                      <Radio
                        checked={defaultYear === year.name}
                        onChange={() => {
                          setDefaultYear(year.name);
                          dispatch(updateDefaultYear(year.name));
                        }}
                        value={year.name}
                        name='radio-buttons'
                        inputProps={{ 'aria-label': 'A' }}
                      />
                    </TableCell>

                    <TableCell sx={{ px: 0 }}>
                      <IconButton
                        onClick={() => {
                          setDeleteOpen(true);
                          setDeleteYearId(year.id);
                          setDeleteYearName(year.name);
                        }}
                      >
                        <Icon color='error'>delete</Icon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} sx={{ textAlign: 'center' }}>
                    No data found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </ProductTable>
        </Box>
      </Card>
      <AddBookMasterDialog
        open={open}
        setOpen={setOpen}
        setApiCall={setApiCall}
        handleClickSuccess={handleClickSuccess}
        setError={setError}
        handleClickError={handleClickError}
        onClose={handleClose}
      />
      <DeleteBookMasterDialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        deleteYearId={deleteYearId}
        setApiCall={setApiCall}
        handleClickSuccess={handleClickSuccess}
        handleClickError={handleClickError}
        setError={setError}
        deleteYearName={deleteYearName}
      />

      <Snackbar open={successOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='error'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {error?.payload}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default BookMasterTable;
