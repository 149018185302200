import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Card,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  styled,
  TextField
} from '@mui/material';
import {
  addEmployeeAccountData,
  updateAccountType
} from 'app/contexts/reducers/account/accountSlice';
import { getaccountgroup } from 'app/contexts/reducers/accountgroup/accountgroupSlice';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { convertAccountId } from 'app/utils/convertId';
import Loading from 'app/components/MatxLoading';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' }
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main }
}));

const AddEmployeeCard = ({ handleApiCall }) => {
  const dispatch = useDispatch();
  const { accountgroupData } = useSelector((state) => state.accountGroup);
  const { isAccountGroupSuccess } = useSelector((state) => state.accountGroup);
  const intialData = {
    accountGroupId: '',
    name: '',
    email: '',
    remark: '',
    address: '',
    phoneNumber: '',
    debt: '',
    totalSalary: ''
  };
  const [accountgroup, setAccountGroup] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [accountGroupName, setAccountGroupName] = useState();
  const [accountGroupId, setAccountGroupId] = useState();
  const [employeeData, setEmployeeData] = useState(intialData);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [employeeNameError, setEmployeeError] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState();

  useEffect(() => {
    dispatch(getaccountgroup());
  }, [dispatch]);
  useEffect(() => {
    if (isAccountGroupSuccess) {
      setIsLoading(true);
      setAccountGroup(accountgroupData.data.data);
      convertAccountId(accountgroupData.data.data, 'employee').then((data) => {
        setAccountGroupName(data.account_group_name);
        setEmployeeData({ ...employeeData, accountGroupId: data.id });
        setAccountGroupId(data.id);
      });

      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [isAccountGroupSuccess, accountgroupData]);
  function handleClickError() {
    setSuccessOpen(false);
    setErrorOpen(true);
  }
  function handleClickSuccess(message) {
    setErrorOpen(false);
    setSuccessMessage(message);
    setSuccessOpen(true);
  }
  function handleSnakeBarClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
    setErrorOpen(false);
  }
  const handleAccountGroupSelect = async (event) => {
    setEmployeeData({ ...employeeData, accountGroupId: accountGroupId });
    setAccountGroupName(event.target.value.toLowerCase());
    dispatch(updateAccountType(event.target.value.toLowerCase()));
  };
  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (accountGroupName.toLowerCase() === 'employee') {
        if (employeeData.name.length > 0) {
          const updateEmployeeData = { ...employeeData };
          Object.keys(updateEmployeeData).forEach((key) => {
            if (updateEmployeeData[key] === '') {
              delete updateEmployeeData[key];
            }
          });
          const responseValue = await dispatch(addEmployeeAccountData(updateEmployeeData));
          if (!responseValue.hasOwnProperty('error')) {
            handleClickSuccess('Employee added successfully');
            handleApiCall();
            setEmployeeData({ ...intialData, accountGroupId: accountGroupId });
            setLoading(false);
          } else {
            throw responseValue;
          }
        } else {
          setEmployeeError(true);
          handleClickError();
          setError({ payload: 'Name Is Required!' });
          setLoading(false);
        }
      }
    } catch (error) {
      handleClickError();
      setError(error);
      setLoading(false);
    }
  };
  const filteredAccountGroup = accountgroup.filter((accountGroupData) => {
    const groupName = accountGroupData.account_group_name.toLowerCase();
    return groupName === 'customer' || groupName === 'employee' || groupName === 'vendor';
  });
  const menuItems = filteredAccountGroup.map((accountGroupData, index) => (
    <MenuItem value={accountGroupData.account_group_name.toLowerCase()} id={index} key={index}>
      {accountGroupData.account_group_name}
    </MenuItem>
  ));

  return (
    <React.Fragment>
      <Snackbar open={successOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='error'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {error?.payload}
        </Alert>
      </Snackbar>
      <Grid container spacing={3} sx={{ mb: '24px' }}>
        <Grid item xs={12} md={12}>
          <StyledCard elevation={6}>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <InputLabel id='accountGroupName'>Account Group</InputLabel>
                <Select
                  size='small'
                  labelId='accountGroupName'
                  sx={{ width: '50%', my: 1 }}
                  label='Account Group'
                  variant='standard'
                  defaultValue={'employee'}
                  onChange={handleAccountGroupSelect}
                >
                  {!isLoading ? menuItems : <Loading />}
                </Select>

                <ContentBox>
                  <TextField
                    error={employeeNameError}
                    id='employeeName'
                    type='text'
                    size='small'
                    margin='dense'
                    label='Employee Name'
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    required
                    InputLabelProps={{ shrink: true }}
                    value={employeeData.name}
                    helperText={employeeNameError ? 'Please Enter Employee Name' : ''}
                    onChange={(e) => {
                      setEmployeeError(false);
                      setEmployeeData({ ...employeeData, name: e.target.value });
                    }}
                  />
                </ContentBox>

                <ContentBox>
                  <TextField
                    multiline
                    maxRows={4}
                    id='remark'
                    type='text'
                    margin='dense'
                    size='small'
                    label='Remark'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    value={employeeData.remark}
                    variant='standard'
                    onChange={(e) => {
                      setEmployeeData({ ...employeeData, remark: e.target.value });
                    }}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    id='address'
                    type='text'
                    margin='dense'
                    size='small'
                    label='Address'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    multiline={true}
                    maxRows={6}
                    variant='standard'
                    value={employeeData.address}
                    onChange={(e) => {
                      setEmployeeData({ ...employeeData, address: e.target.value });
                    }}
                  />
                </ContentBox>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ContentBox>
                  <TextField
                    id='debt'
                    type='number'
                    size='small'
                    margin='dense'
                    label='Debt'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={employeeData.debt}
                    onChange={(e) => {
                      setEmployeeData({ ...employeeData, debt: e.target.value });
                    }}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    id='totalSalaray'
                    type='number'
                    size='small'
                    margin='dense'
                    label='Total Salary'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={employeeData.totalSalary}
                    onChange={(e) => {
                      setEmployeeData({ ...employeeData, totalSalary: e.target.value });
                    }}
                  />
                </ContentBox>

                <ContentBox>
                  <TextField
                    id='phoneNumber'
                    type='text'
                    margin='dense'
                    size='small'
                    label='Contact Number'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={employeeData.phoneNumber}
                    onChange={(e) => {
                      setEmployeeData({ ...employeeData, phoneNumber: e.target.value });
                    }}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    id='email'
                    type='email'
                    margin='dense'
                    size='small'
                    label='Email'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={employeeData.email}
                    onChange={(e) => {
                      setEmployeeData({ ...employeeData, email: e.target.value });
                    }}
                  />
                </ContentBox>
              </Grid>
              <LoadingButton
                onClick={handleSubmit}
                color='primary'
                loading={loading}
                variant='contained'
                sx={{ my: 2, textTransform: 'capitalize' }}
              >
                Add {accountGroupName}
              </LoadingButton>
            </Grid>
          </StyledCard>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AddEmployeeCard;
