const formatDate = (dateString) => {
    const currentDate = new Date();
    const inputDate = new Date(dateString);

    const diffTime = Math.abs(currentDate - inputDate);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 0) {
        return 'Today';
    } else if (diffDays === 1) {
        return 'Yesterday';
    } else if (diffDays <= 30) {
        return `${diffDays} day's ago`;
    } else {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return inputDate.toLocaleDateString('en-GB', options);
    }

}
export { formatDate };