import { LoadingButton } from '@mui/lab';
import { Box, DialogContent, DialogContentText, InputLabel, MenuItem, Select } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import DialogTitle from '@mui/material/DialogTitle';
import { updateInwardType } from 'app/contexts/reducers/transaction/oderSlice';

import { useState } from 'react';
import { useDispatch } from 'react-redux';

export default function AddDiamondInwardType({
  open,
  onClose,
  inwardType,
  handleClickSuccess,
  handleClickError,
  setError,
  orderId,
  handleApiCall
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedInwardType, setSelectedInwardType] = useState(null);
  const handleDiamondTypeSelect = async (e) => {
    setSelectedInwardType(e.target.value);
  };
  const handleTypeSelect = async () => {
    try {
      const updateOrderDetails = {
        inwardOrderType: selectedInwardType
      };
      setLoading(true);
      const responseValue = await dispatch(updateInwardType({ orderId, updateOrderDetails }));
      if (!responseValue.hasOwnProperty('error')) {
        onClose();
        handleClickSuccess('Inward-Type Updated successfully!!');
        handleApiCall();
        setLoading(false);
      } else {
        throw responseValue;
      }
      onClose();
      setLoading(false);
    } catch (error) {
      handleClickError();
      setError(error);
      setLoading(false);
    }
  };

  const InwardType = [
    {
      name: 'SELF'
    },
    {
      name: 'CUSTOMER_SELF'
    },
    {
      name: 'BULK_SELF'
    }
  ];

  return (
    <Box>
      <Dialog open={open} onClose={onClose} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>Choose Inward Type</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: '10px' }}>
            Please Choose Inward Type For This Order
          </DialogContentText>

          {/* <StyledCard elevation={6}> */}

          <InputLabel sx={{ fontSize: '10px', mt: 4 }} id='diamondType'>
            Inward Diamond Type
          </InputLabel>
          <Select
            size='small'
            sx={{ width: '100%', my: 1 }}
            variant='standard'
            labelId='diamondType'
            value={selectedInwardType === null ? inwardType : selectedInwardType}
            onChange={handleDiamondTypeSelect}
          >
            {InwardType.map((data, index) => (
              <MenuItem value={data.name} key={index} id={index}>
                {data.name}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button variant='standard' color='secondary' onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleTypeSelect}
            color='primary'
            loading={loading}
            variant='contained'
            sx={{ my: 2 }}
          >
            Next
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
