import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import { addBookMaster } from 'app/contexts/reducers/bookMaster/bookMasterSlice';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

export default function AddBookMasterDialog({
  open,
  onClose,
  setOpen,
  setApiCall,
  handleClickSuccess,
  setError,
  handleClickError
}) {
  const dispatch = useDispatch();
  const currentDate = new Date();
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState(currentDate.getFullYear());

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const data = {
        year: year
      };
      const responseValue = await dispatch(addBookMaster(data));
      if (!responseValue.hasOwnProperty('error')) {
        setApiCall((prev) => !prev);
        setOpen(false);
        handleClickSuccess('Year added successfully');
        setYear(currentDate.getFullYear());
        setLoading(false);
      } else {
        throw responseValue;
      }
    } catch (e) {
      handleClickError();
      setError(e);
      setLoading(false);
    }
  };
  const generateYears = () => {
    const years = [];
    for (let year = 2001; year <= 2050; year++) {
      year <= currentDate.getFullYear() && years.push(year);
    }
    return years;
  };
  const yearsArray = generateYears();

  const handleYearSelect = (e) => {
    setYear(e.target.value);
  };
  return (
    <Box>
      <Dialog open={open} onClose={onClose} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>Add Year</DialogTitle>

        <DialogContent>
          <DialogContentText>Please Select Business-Year</DialogContentText>
          <InputLabel id='bookMasterYear' sx={{ mt: 3 }}>
            Select Year
          </InputLabel>
          <Select
            size='small'
            labelId='bookMasterYear'
            sx={{ width: '50%', my: 1 }}
            label='Book Master Year'
            variant='standard'
            value={year}
            onChange={handleYearSelect}
          >
            {yearsArray.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' color='secondary' onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleSubmit}
            color='primary'
            loading={loading}
            variant='contained'
            sx={{ my: 2 }}
          >
            Add Item
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
