import { LoadingButton } from '@mui/lab';
import { Alert, Card, Grid, InputLabel, MenuItem, Select, Snackbar, styled } from '@mui/material';
import { H4 } from 'app/components/Typography';
import { genrateInwardOutwardPdf } from 'app/contexts/reducers/transaction/diamondOutwardSlice';
import { updateOrderStatus } from 'app/contexts/reducers/transaction/oderSlice';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' }
}));

const UpdateOrderStatus = ({ singleOrder, handleApiCall }) => {
  const dispatch = useDispatch();
  const [statusId, setStatusId] = useState(singleOrder.status);
  const [orderId, setOrderId] = useState();
  const [error, setError] = useState();
  const [errorOpen, setErrorOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [successOpen, setSuccessOpen] = useState(false);

  useEffect(() => {
    setOrderId(singleOrder.id);
    setStatusId(singleOrder.status);
  }, [singleOrder]);
  const [loading, setLoading] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  function handleClickSuccess(message) {
    setErrorOpen(false);
    setSuccessMessage(message);
    setSuccessOpen(true);
  }
  function handleSnakeBarClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
    setErrorOpen(false);
  }
  function handleClickError() {
    setSuccessOpen(false);
    setErrorOpen(true);
  }
  const handleSubmit = async () => {
    try {
      setLoading(true);
      const responseValue = await dispatch(updateOrderStatus({ orderId, statusId }));
      if (!responseValue.hasOwnProperty('error')) {
        handleClickSuccess('Order Status Updated Successfully!!');
        handleApiCall();
        setLoading(false);
      } else {
        throw responseValue;
      }
    } catch (error) {
      handleClickError();
      setError(error);
      setLoading(false);
    }
  };
  const handleReport = async () => {
    try {
      setLoadingReport(true);
      const responseValue = await dispatch(genrateInwardOutwardPdf({ orderId }));
      if (!responseValue.hasOwnProperty('error')) {
        handleClickSuccess('Report Genrated Successfully!!');
        handleApiCall();
        setLoadingReport(false);
      } else {
        throw responseValue;
      }
    } catch (error) {
      handleClickError();
      setError(error);
      setLoadingReport(false);
    }
  };
  const orderStatusData = [
    { statusId: 0, statusValue: 'Pending' },
    { statusId: 1, statusValue: 'Running' },
    { statusId: 2, statusValue: 'Done' },
    { statusId: 3, statusValue: 'Sold' }
  ];
  return (
    <React.Fragment>
      <Grid container spacing={3} sx={{ mb: '24px' }}>
        <Grid item xs={12} md={12}>
          <StyledCard elevation={6}>
            <H4 sx={{ mb: 2 }}>Update Order Status</H4>
            <Grid container>
              <InputLabel id='customerType'>Order Status</InputLabel>
              <Select
                size='small'
                sx={{ width: '100%', my: 1 }}
                labelId='customerType'
                variant='standard'
                onChange={(e) => {
                  setStatusId(e.target.value);
                }}
                disabled={Number(statusId) === 3 || Number(statusId) === 0 ? true : false}
                value={statusId}
              >
                {orderStatusData.map((data, index) => (
                  <MenuItem
                    value={data.statusId}
                    disabled={
                      Number(data.statusId) === 3 || Number(data.statusId) === 0 ? true : false
                    }
                    key={index}
                    id={index}
                  >
                    {data.statusValue}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <LoadingButton
              color='primary'
              variant='contained'
              onClick={handleSubmit}
              disabled={Number(statusId) === 3 ? true : false}
              loading={loading}
            >
              Update Status
            </LoadingButton>
            <LoadingButton
              color='primary'
              variant='contained'
              onClick={handleReport}
              loading={loadingReport}
            >
              Genrate Report
            </LoadingButton>
          </StyledCard>
        </Grid>
      </Grid>
      {/* Alert */}
      <Snackbar open={successOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='error'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {error?.payload}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default UpdateOrderStatus;
