import {
  Alert,
  Box,
  Card,
  Chip,
  Grid,
  Icon,
  IconButton,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import { H6 } from 'app/components/Typography';
import { getStockData } from 'app/contexts/reducers/stockMaster/stockMasterSlice';
import { useState } from 'react';
import { Fragment } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteStock from './DeleteStock';
import FilterDiamondType from './FilterDiamondType';
import SearchVendorStock from './SearchVendorStock';
import { useNavigate } from 'react-router-dom';
import UpdateStockStatus from './UpdateStockStatus';
import FilterStatus from './FilterStatus';

const CardHeader = styled(Box)(() => ({
  display: 'flex',
  paddingLeft: '24px',
  paddingRight: '24px',
  marginBottom: '12px',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  textTransform: 'capitalize'
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)'
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' }
}));

const StockTable = ({ apiCall, setApiCall }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { stockData, isStockMasterSuccess } = useSelector((state) => state.stock);
  const [vendorStockData, setVendorStockData] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [updateStockId, setUpdateStockId] = useState(null);
  const [deleteStockId, setDeleteStockId] = useState(null);
  const [successOpen, setSuccessOpen] = useState(false);
  const [currentStatus, setCurrentStatus] = useState();

  const [errorOpen, setErrorOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [error, setError] = useState();
  useEffect(() => {
    dispatch(getStockData());
  }, [dispatch, apiCall]);

  useEffect(() => {
    if (isStockMasterSuccess) {
      const ordersWithStatusZero = stockData.data.data.filter(
        (stock) => Number(stock.status) === 0
      );
      setVendorStockData(ordersWithStatusZero);
    }
  }, [isStockMasterSuccess, stockData]);

  function handleDeleteClose() {
    setDeleteOpen(false);
  }
  function handleStatusUpdateClose() {
    setStatusOpen(false);
  }
  function handleSnakeBarClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
    setErrorOpen(false);
  }
  function handleClickSuccess(message) {
    setErrorOpen(false);
    setSuccessMessage(message);
    setSuccessOpen(true);
  }
  function handleClickError() {
    setSuccessOpen(false);
    setErrorOpen(true);
  }
  return (
    <Fragment>
      <Card elevation={3} sx={{ pt: '20px', mb: 3 }}>
        <CardHeader>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <Title>Stock Master</Title>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <SearchVendorStock setVendorStockData={setVendorStockData} />
                </Grid>
                <Grid item>
                  <FilterStatus setVendorStockData={setVendorStockData} />
                </Grid>
                <Grid item>
                  <FilterDiamondType setVendorStockData={setVendorStockData} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardHeader>

        <Box overflow='auto'>
          <ProductTable>
            <TableHead sx={{ backgroundColor: 'rgb(34,41,67)' }}>
              <TableRow>
                <TableCell colSpan={3} sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Index
                </TableCell>
                <TableCell colSpan={3} sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Vendor Name
                </TableCell>
                <TableCell colSpan={3} sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Diamond Type
                </TableCell>
                <TableCell colSpan={3} sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Total Piece
                </TableCell>
                <TableCell colSpan={3} sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Total Stock
                </TableCell>
                <TableCell colSpan={3} sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Used Pcs
                </TableCell>
                <TableCell colSpan={3} sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Used Stock
                </TableCell>

                <TableCell colSpan={3} sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Broken Piece
                </TableCell>
                <TableCell colSpan={3} sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Broken Stock
                </TableCell>
                <TableCell colSpan={3} sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Missing Stock
                </TableCell>
                <TableCell colSpan={4} sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Remaining Piece
                </TableCell>
                <TableCell colSpan={4} sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Remaining Stock
                </TableCell>
                <TableCell colSpan={3} sx={{ px: 2, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Status
                </TableCell>
                <TableCell sx={{ px: 2, fontWeight: 'bold', color: '#FFFFFF' }} colSpan={3}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {console.log(vendorStockData)}
              {vendorStockData && vendorStockData.length > 0 ? (
                vendorStockData.map((stock, index) => (
                  <TableRow key={index} hover>
                    <TableCell colSpan={3} sx={{ px: 0, textTransform: 'capitalize' }}>
                      <H6 sx={{ px: 2 }}>{index + 1}</H6>
                    </TableCell>
                    <TableCell
                      colSpan={3}
                      sx={{ px: 0, textTransform: 'capitalize', fontWeight: 'bold' }}
                    >
                      {stock.vendor_name}
                    </TableCell>
                    <TableCell colSpan={3} sx={{ px: 2, textTransform: 'capitalize' }}>
                      <Chip
                        label={stock.diamond_type}
                        component='p'
                        variant='filled'
                        color={stock.diamond_type === 'REAL' ? 'primary' : 'secondary'}
                      />
                    </TableCell>
                    <TableCell colSpan={3} sx={{ px: 2, textTransform: 'capitalize' }}>
                      {parseFloat(parseFloat(stock.total_pcs).toFixed(3))}
                    </TableCell>
                    <TableCell colSpan={3} sx={{ px: 2, textTransform: 'capitalize' }}>
                      {parseFloat(parseFloat(stock.total_stock).toFixed(3))}
                    </TableCell>
                    <TableCell colSpan={3} sx={{ px: 2, textTransform: 'capitalize' }}>
                      {stock.used_pcs === null ? 0 : stock.used_pcs}
                    </TableCell>
                    <TableCell colSpan={3} sx={{ px: 2, textTransform: 'capitalize' }}>
                      {stock.used_stock === null
                        ? 0
                        : parseFloat(parseFloat(stock.used_stock).toFixed(3))}
                    </TableCell>
                    <TableCell colSpan={3} sx={{ px: 2, textTransform: 'capitalize' }}>
                      {stock.broken_pcs === null ? 0 : stock.broken_pcs}
                    </TableCell>
                    <TableCell colSpan={3} sx={{ px: 2, textTransform: 'capitalize' }}>
                      {stock.broken_stock === null
                        ? 0
                        : parseFloat(parseFloat(stock.broken_stock).toFixed(3))}
                    </TableCell>
                    <TableCell colSpan={3} sx={{ px: 2, textTransform: 'capitalize' }}>
                      {stock.missing_stock === null
                        ? 0
                        : parseFloat(parseFloat(stock.missing_stock).toFixed(3))}
                    </TableCell>
                    <TableCell
                      colSpan={4}
                      sx={{ px: 2, textTransform: 'capitalize', textAlign: 'left' }}
                    >
                      {stock.remaining_pcs}
                    </TableCell>
                    <TableCell
                      colSpan={4}
                      sx={{ px: 2, textTransform: 'capitalize', textAlign: 'left' }}
                    >
                      {parseFloat(parseFloat(stock.remaining_stock).toFixed(3))}
                    </TableCell>
                    <TableCell
                      colSpan={3}
                      sx={{ px: 0, textTransform: 'capitalize', textAlign: 'left' }}
                    >
                      <Chip
                        label={stock.status === '0' ? 'Running' : 'Completed'}
                        component='p'
                        variant='filled'
                        color={stock.status === '0' ? 'primary' : 'success'}
                      />
                    </TableCell>
                    <TableCell sx={{ px: 0 }} colSpan={3}>
                      <Tooltip title='Delete'>
                        <IconButton
                          onClick={() => {
                            setDeleteStockId(stock.id);
                            setDeleteOpen(true);
                          }}
                        >
                          <Icon color='error'>delete</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Update-Stauts'>
                        <IconButton
                          onClick={() => {
                            setUpdateStockId(stock.id);
                            setStatusOpen(true);
                            setCurrentStatus(stock.status);
                          }}
                        >
                          <Icon color='primary'>edit</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='view'>
                        <IconButton
                          onClick={() => {
                            const queryParams = {
                              vendorId: stock.vendor_id,
                              diamondType: stock.diamond_type,
                              status: stock.status,
                              stockId: stock.id
                            };
                            const queryString = new URLSearchParams(queryParams).toString();
                            navigate(`/specificstock?${queryString}`);
                          }}
                        >
                          <Icon color='primary'>keyboard_arrow_right</Icon>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={38} sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                    No data found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </ProductTable>
        </Box>
      </Card>
      {/* Alert */}
      <Snackbar open={successOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='error'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {error?.payload}
        </Alert>
      </Snackbar>
      {/* Delete-Stock */}
      <DeleteStock
        open={deleteOpen}
        onClose={handleDeleteClose}
        deleteStockId={deleteStockId}
        setApiCall={setApiCall}
        handleClickSuccess={handleClickSuccess}
        handleClickError={handleClickError}
        setError={setError}
      />
      {/* Update-Stock-Status */}
      <UpdateStockStatus
        open={statusOpen}
        onClose={handleStatusUpdateClose}
        updateStockId={updateStockId}
        setApiCall={setApiCall}
        handleClickSuccess={handleClickSuccess}
        handleClickError={handleClickError}
        currentStatus={currentStatus}
        setError={setError}
      />
    </Fragment>
  );
};

export default StockTable;
