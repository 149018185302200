import { getHeaders } from 'app/utils/handleHeader';
import axios from 'axios';
const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');
const initialState = {
    isInitialised: true,
    isLoading: false,
    isError: false,
    isReceiptGenrateSuccess: false,
    isReceiptDeleteSuccess: false,
    isReceiptUpdateSuccess: false,
    isAllVendorReceiptsSuccess: false,
    isVendorReceiptsSuccess: false,
    isSpecificVendorReceiptSuccess: false,
    isVendorAmountFoundedSuccess: false,
    isAllVendorAmountFoundedSuccess: false,
    isGenerateVendorReceiptSuccess: false,
    allVendorReceiptData: null,
    vendorReceiptsData: null,
    singleReceiptData: null,
    vendorAmountData: null,
    allVendorAmountData: null,
};
// --------------------------------------Create Vendor Receipt --------------------------------
export const createVendorPaymentReceipt = createAsyncThunk('createVendorPaymentReceipt', async (vendorReceiptDetails, { rejectWithValue }) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/transaction/createvendorreceipt`,
            vendorReceiptDetails,
            {
                headers: getHeaders()
            }
        );
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

//--------------Asyncthunk for Get All Vendor Receipt Data--------------------------------
export const getAllVendorReceiptData = createAsyncThunk('getAllVendorReceiptData', async (object, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transaction/getallvendorreceipt`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Get Vendor Receipt Data--------------------------------
export const getVendorsReceiptData = createAsyncThunk('getVendorsReceiptData', async ({ vendorId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transaction/getallvendorspecificvendorreceipt/${vendorId}`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Get Specific Receipt Data--------------------------------
export const getSpecificVendorReceiptData = createAsyncThunk('getSpecificVendorReceiptData', async ({ receiptId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transaction/getspecificvendorreceipt/${receiptId}`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Get Vendor Amount Data--------------------------------
export const getVendorAmountData = createAsyncThunk('getVendorAmountData', async ({ vendorId, bookNumber }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transaction/getvendoramountdata/${vendorId}/${bookNumber}`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Get All Vendor Amount Data--------------------------------
export const getAllVendorAmountData = createAsyncThunk('getAllVendorAmountData', async ({ bookNumber }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transaction/getallvendoramountdata/${bookNumber}`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Delete Vendor Receipt--------------------------------
export const deleteVendorReceipt = createAsyncThunk('deleteVendorReceipt', async ({ receiptId }, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/transaction/deletevendorreceipt/${receiptId}`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Update Vendor Receipt--------------------------------
export const updateVendorReceipt = createAsyncThunk('updateVendorReceipt', async ({ receiptId, vendorReceiptDetails }, { rejectWithValue }) => {
    try {
        const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/transaction/updatevendorreceipt/${receiptId}`, vendorReceiptDetails, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
// ----------------------------------------Genrate Vendor-Receipt PDF --------------------------------
export const genrateVendorReceiptPdf = createAsyncThunk('genrateVendorReceiptPdf', async ({ invoiceId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/transaction/generatevendorreceiptpdf/${invoiceId}`,

            {
                responseType: 'arraybuffer',
                headers: { 'Accept': 'application/pdf', ...getHeaders() }
            }
        );
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Vendor_Receipt_${invoiceId}.pdf`; // Set the desired file name
        a.click();
        window.URL.revokeObjectURL(url);
        // return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
const vendorReceiptSlice = createSlice({
    name: 'vendorReceiptSlice',
    initialState,
    reducers: {},
    extraReducers: {
        //--------------Slice action for the Create Vendor Receipt
        [createVendorPaymentReceipt.pending]: (state, action) => {
            state.isLoading = true;
            state.isReceiptGenrateSuccess = false;
            state.isError = false;
        },
        [createVendorPaymentReceipt.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isReceiptGenrateSuccess = true;
            state.isError = false;
        },
        [createVendorPaymentReceipt.rejected]: (state, action) => {
            state.isLoading = false;
            state.isReceiptGenrateSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Vendor Receipt's Data --------------------------------
        [getAllVendorReceiptData.pending]: (state, action) => {
            state.pending = false;
            state.isAllVendorReceiptsSuccess = false;
            state.isError = false;
        },
        [getAllVendorReceiptData.fulfilled]: (state, action) => {
            state.pending = false;
            state.isAllVendorReceiptsSuccess = true;
            state.allVendorReceiptData = action.payload;
            state.isError = false;
        },
        [getAllVendorReceiptData.rejected]: (state, action) => {
            state.pending = false;
            state.isAllVendorReceiptsSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Vendor's Receipt Data --------------------------------
        [getVendorsReceiptData.pending]: (state, action) => {
            state.pending = false;
            state.isVendorReceiptsSuccess = false;
            state.isError = false;
        },
        [getVendorsReceiptData.fulfilled]: (state, action) => {
            state.pending = false;
            state.isVendorReceiptsSuccess = true;
            state.vendorReceiptsData = action.payload;
            state.isError = false;
        },
        [getVendorsReceiptData.rejected]: (state, action) => {
            state.pending = false;
            state.isVendorReceiptsSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Specific Receipt Data --------------------------------
        [getSpecificVendorReceiptData.pending]: (state, action) => {
            state.pending = false;
            state.isSpecificVendorReceiptSuccess = false;
            state.isError = false;
        },
        [getSpecificVendorReceiptData.fulfilled]: (state, action) => {
            state.pending = false;
            state.isSpecificVendorReceiptSuccess = true;
            state.singleReceiptData = action.payload;
            state.isError = false;
        },
        [getSpecificVendorReceiptData.rejected]: (state, action) => {
            state.pending = false;
            state.isSpecificVendorReceiptSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Amount  Data --------------------------------
        [getVendorAmountData.pending]: (state, action) => {
            state.pending = false;
            state.isVendorAmountFoundedSuccess = false;
            state.isError = false;
        },
        [getVendorAmountData.fulfilled]: (state, action) => {
            state.pending = false;
            state.isVendorAmountFoundedSuccess = true;
            state.vendorAmountData = action.payload;
            state.isError = false;
        },
        [getVendorAmountData.rejected]: (state, action) => {
            state.pending = false;
            state.isVendorAmountFoundedSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get All Vendor Amount Data --------------------------------
        [getAllVendorAmountData.pending]: (state, action) => {
            state.pending = false;
            state.isAllVendorAmountFoundedSuccess = false;
            state.isError = false;
        },
        [getAllVendorAmountData.fulfilled]: (state, action) => {
            state.pending = false;
            state.isAllVendorAmountFoundedSuccess = true;
            state.allVendorAmountData = action.payload;
            state.isError = false;
        },
        [getAllVendorAmountData.rejected]: (state, action) => {
            state.pending = false;
            state.isAllVendorAmountFoundedSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Delete Vendor Receipt
        [deleteVendorReceipt.pending]: (state, action) => {
            state.isLoading = true;
            state.isReceiptDeleteSuccess = false;
            state.isError = false;
        },
        [deleteVendorReceipt.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isReceiptDeleteSuccess = true;
            state.isError = false;
        },
        [deleteVendorReceipt.rejected]: (state, action) => {
            state.isLoading = false;
            state.isReceiptDeleteSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Update Vendor Receipt
        [updateVendorReceipt.pending]: (state, action) => {
            state.isLoading = true;
            state.isReceiptUpdateSuccess = false;
            state.isError = false;
        },
        [updateVendorReceipt.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isReceiptUpdateSuccess = true;
            state.isError = false;
        },
        [updateVendorReceipt.rejected]: (state, action) => {
            state.isLoading = false;
            state.isReceiptUpdateSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Create Vendor Receipt
        [genrateVendorReceiptPdf.pending]: (state, action) => {
            state.isLoading = true;
            state.isGenerateVendorReceiptSuccess = false;
            state.isError = false;
        },
        [genrateVendorReceiptPdf.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isGenerateVendorReceiptSuccess = true;
            state.isError = false;
        },
        [genrateVendorReceiptPdf.rejected]: (state, action) => {
            state.isLoading = false;
            state.isGenerateVendorReceiptSuccess = false;
            state.isError = true;
        },

    }
});
export default vendorReceiptSlice.reducer;