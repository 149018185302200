import { getHeaders } from 'app/utils/handleHeader';
import axios from 'axios';
const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');
const initialState = {
  isInitialised: true,
  isItemSuccess: false,
  isLoading: false,
  isError: false,
  itemData: null
};

// --------------------------------------Add Item --------------------------------
export const addItem = createAsyncThunk('addItem', async (item, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/item/addItem`, item, {
      headers: getHeaders(),
    });
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
// --------------------------------------Get Items --------------------------------
export const getItems = createAsyncThunk('getItems', async (object, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/item/getItems`, {
      headers: getHeaders(),
    }
    );
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
// --------------------------------------Delete Item --------------------------------
export const deleteItem = createAsyncThunk('deleteItem', async (object, { rejectWithValue }) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/item/deleteItem/${object}`,
      {
        headers: getHeaders()
      }
    );
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
// --------------------------------------Update Item --------------------------------
export const updateItem = createAsyncThunk(
  'updateItem',
  async ({ updateItemId, data }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/item/updateItem/${updateItemId}`,
        data,
        {
          headers: getHeaders()
        }
      );
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
const itemSlice = createSlice({
  name: 'item',
  initialState,
  reducers: {},
  extraReducers: {
    //--------------_Slice action for the Add items
    [addItem.pending]: (state, action) => {
      state.isLoading = true;
      state.isItemSuccess = false;
      state.isError = false;
    },
    [addItem.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isItemSuccess = true;
      state.isError = false;
    },
    [addItem.rejected]: (state, action) => {
      state.isLoading = false;
      state.isItemSuccess = false;
      state.isError = true;
    },

    //--------------_Slice action for the get items
    [getItems.pending]: (state, action) => {
      state.isLoading = true;
      state.isItemSuccess = false;
      state.isError = false;
    },
    [getItems.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isItemSuccess = true;
      state.isError = false;
      state.itemData = action.payload;
    },
    [getItems.rejected]: (state, action) => {
      state.isLoading = false;
      state.isItemSuccess = false;
      state.isError = true;
    },
    //--------------_Slice action for the Delete Item
    [deleteItem.pending]: (state, action) => {
      state.isLoading = true;
      state.isItemSuccess = false;
      state.isError = false;
    },
    [deleteItem.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isItemSuccess = true;
      state.isError = false;
    },
    [deleteItem.rejected]: (state, action) => {
      state.isLoading = false;
      state.isItemSuccess = false;
      state.isError = true;
    },
    //--------------_Slice action for the Update Item
    [updateItem.pending]: (state, action) => {
      state.isLoading = true;
      state.isItemSuccess = false;
      state.isError = false;
    },
    [updateItem.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isItemSuccess = true;
      state.isError = false;
    },
    [updateItem.rejected]: (state, action) => {
      state.isLoading = false;
      state.isItemSuccess = false;
      state.isError = true;
    }
  }
});
export default itemSlice.reducer;
