import { getHeaders } from 'app/utils/handleHeader';
import axios from 'axios';
const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');
const initialState = {
    inwardType: '',
    isInitialised: true,
    isLoading: false,
    isInwardSuccess: false,
    isSingleInwardSuccess: false,
    isVendorTotalInwardSuccess: false,
    isError: false,
    inwardData: null,
    singleInwardData: null,
    vendorTotalInwardData: null,
};
// --------------------------------------Add Diamond Inward To Order --------------------------------
export const addDiamondInward = createAsyncThunk('addDiamondInward', async (addDiamondInward, { rejectWithValue }) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/transaction/adddiamondinward`,
            addDiamondInward,
            {
                headers: getHeaders()
            }
        );
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
// --------------------------------------Get Diamond Inward Of Order --------------------------------
export const getDiamondInward = createAsyncThunk('getDiamondInward', async ({ orderId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transaction/getdiamondinward/${orderId}`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {

        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
// --------------------------------------Get Specific Diamond Inward Of Order--------------------------------

export const getSingleDiamondInward = createAsyncThunk('getSingleDiamondInward', async ({ inwardId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transaction/getsinglediamondinward/${inwardId}`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {

        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
// --------------------------------------Get Total Vendor Diamond Inward Of Order--------------------------------
export const getTotalVendorDiamondInward = createAsyncThunk('getTotalVendorDiamondInward', async ({ orderId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transaction/getvendortotalinward/${orderId}`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {

        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Delete Inward-Entry Data--------------------------------
export const deleteDiamondInward = createAsyncThunk(
    '/deleteDiamondInward',
    async ({ deleteInwardId }, { rejectWithValue }) => {
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_BASE_URL}/transaction/deletediamondinward/${deleteInwardId}`,
                { headers: getHeaders() }
            );
            return response;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);
//--------------Asyncthunk for Update Inward-Entry Data--------------------------------
export const updateInwardEntryData = createAsyncThunk(
    '/updateInwardEntryData',
    async ({ inwardId, updateInwardDetails }, { rejectWithValue }) => {

        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/transaction/updatediamondinward/${inwardId}`, updateInwardDetails,
                { headers: getHeaders() }
            );
            return response;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

const diamondInwardSlice = createSlice({
    name: 'diamondInward',
    initialState,
    reducers: {
        updateInwardType: (state, action) => {
            state.inwardType = action.payload;
        }
    },
    extraReducers: {
        //--------------Slice action for the Create Order
        [addDiamondInward.pending]: (state, action) => {
            state.isLoading = true;
            state.isInwardSuccess = false;
            state.isError = false;
        },
        [addDiamondInward.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isInwardSuccess = true;
            state.isError = false;
        },
        [addDiamondInward.rejected]: (state, action) => {
            state.isLoading = false;
            state.isInwardSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Inward --------------------------------
        [getDiamondInward.pending]: (state, action) => {
            state.isLoading = true;
            state.isInwardSuccess = false;
            state.isError = false;
        },
        [getDiamondInward.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isInwardSuccess = true;
            state.inwardData = action.payload;
            state.isError = false;
        },
        [getDiamondInward.rejected]: (state, action) => {
            state.isLoading = false;
            state.isInwardSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Single Diamond Inward --------------------------------
        [getSingleDiamondInward.pending]: (state, action) => {
            state.isLoading = true;
            state.isSingleInwardSuccess = false;
            state.isError = false;
        },
        [getSingleDiamondInward.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isSingleInwardSuccess = true;
            state.singleInwardData = action.payload;
            state.isError = false;
        },
        [getSingleDiamondInward.rejected]: (state, action) => {
            state.isLoading = false;
            state.isSingleInwardSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Total Based On1 Vendor Diamond Inward -------
        [getTotalVendorDiamondInward.pending]: (state, action) => {
            state.isLoading = true;
            state.isVendorTotalInwardSuccess = false;
            state.isError = false;
        },
        [getTotalVendorDiamondInward.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isVendorTotalInwardSuccess = true;
            state.vendorTotalInwardData = action.payload;
            state.isError = false;
        },
        [getTotalVendorDiamondInward.rejected]: (state, action) => {
            state.isLoading = false;
            state.isVendorTotalInwardSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Update Inward --------------------------------
        [updateInwardEntryData.pending]: (state, action) => {
            state.isLoading = true;
            state.isInwardSuccess = false;
            state.isError = false;
        },
        [updateInwardEntryData.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isInwardSuccess = true;
            state.isError = false;
        },
        [updateInwardEntryData.rejected]: (state, action) => {
            state.isLoading = false;
            state.isInwardSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Delete Inward --------------------------------
        [deleteDiamondInward.pending]: (state, action) => {
            state.isLoading = true;
            state.isInwardSuccess = false;
            state.isError = false;
        },
        [deleteDiamondInward.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isInwardSuccess = true;
            state.isError = false;
        },
        [deleteDiamondInward.rejected]: (state, action) => {
            state.isLoading = false;
            state.isInwardSuccess = false;
            state.isError = true;
        },
    }
});
export const { updateInwardType } = diamondInwardSlice.actions;
export default diamondInwardSlice.reducer;
