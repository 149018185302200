import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Chip,
  DialogContent,
  DialogContentText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  styled,
  useTheme
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import DialogTitle from '@mui/material/DialogTitle';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleOrder } from '../helper/getSingleOrder';
import {
  convertCustomerId,
  convertItemId,
  convertProductId,
  convertYearId
} from 'app/utils/convertId';
import { updateOrders } from 'app/contexts/reducers/transaction/oderSlice';
const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' }
}));
const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main }
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
export default function UpdateOrderDialog({
  open,
  onClose,
  updateOrderId,
  setApiCall,
  handleClickSuccess,
  setError,
  handleClickError
}) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const { ordersData, isOrderSuccess } = useSelector((state) => state.order);
  const { isCustomerSuccess, customerData, isEmployeeSuccess, employeeData } = useSelector(
    (state) => state.account
  );
  const { isItemSuccess, itemData } = useSelector((state) => state.item);
  const { isProductSuccess, productData } = useSelector((state) => state.product);
  const { isBookMasterSuccess, bookMasterData } = useSelector((state) => state.bookMaster);

  const [productsData, setProductsData] = useState([]);
  const [accountCustomerData, setAccountCustomerData] = useState([]);
  const [accountEmployeeData, setAccountEmployeeData] = useState([]);
  const [itemsData, setItemsData] = useState([]);
  const [yearData, setYearData] = useState([]);
  const [updateValue, setUpdateValue] = useState({
    bookId: null,
    yearName: '',
    accountName: '',
    employeeName: [],
    accountId: null,
    phoneNumber: null,
    date: null,
    address: '',
    email: '',
    remark: '',
    itemName: '',
    itemId: null,
    productName: '',
    productId: null,
    productPrice: null,
    extraCost: null
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    itemData && setItemsData(itemData.data.data);
    productData && setProductsData(productData.data.data);
    customerData && setAccountCustomerData(customerData.data.data);
    isBookMasterSuccess && setYearData(bookMasterData.data.data);
    isEmployeeSuccess && setAccountEmployeeData(employeeData.data.data);
  }, [
    isItemSuccess,
    isCustomerSuccess,
    isEmployeeSuccess,
    employeeData,
    isProductSuccess,
    isBookMasterSuccess,
    customerData,
    itemData,
    productData,
    bookMasterData
  ]);

  useEffect(() => {
    if (ordersData) {
      getSingleOrder(ordersData.data.data, updateOrderId).then((orderDetails) => {
        orderDetails &&
          setUpdateValue({
            bookId: orderDetails.book_id,
            yearName: yearData.find((book) => book.id === orderDetails.book_id).name,
            accountName: orderDetails.account_name,
            accountId: orderDetails.account_id,
            phoneNumber: orderDetails.phone_number,
            date: orderDetails.date,
            address: orderDetails.address,
            email: orderDetails.email_id,
            remark: orderDetails.remark,
            itemName: orderDetails.item_name,
            itemId: orderDetails.item_id,
            productName: orderDetails.product_name,
            productId: orderDetails.product_id,
            productPrice: orderDetails.product_price,
            extraCost: orderDetails.extra_cost,
            employeeName:
              orderDetails.connected_employee.length > 0
                ? orderDetails.connected_employee.split(', ')
                : []
          });
        orderDetails && filterProduct(orderDetails.item_name);
      });
    }
    // eslint-disable-next-line
  }, [updateOrderId, isOrderSuccess, ordersData, open]);

  async function handleUpdate() {
    try {
      if (
        updateValue.accountName &&
        updateValue.accountName.length > 0 &&
        updateValue.itemName &&
        updateValue.itemName.length > 0 &&
        updateValue.productName &&
        updateValue.productName.length > 0 &&
        updateValue.itemId !== null &&
        updateValue.productId !== null
      ) {
        const updateOrderDetails = { ...updateValue };
        Object.keys(updateOrderDetails).forEach((key) => {
          if (updateOrderDetails[key] === '') {
            delete updateOrderDetails[key];
          }
          if (key === 'employeeName') {
            updateOrderDetails[key] = updateOrderDetails[key].join(', ');
          }
        });
        const responseValue = await dispatch(updateOrders({ updateOrderId, updateOrderDetails }));
        if (!responseValue.hasOwnProperty('error')) {
          onClose();
          handleClickSuccess('Order Updated successfully!!');
          setApiCall((prev) => !prev);
          setLoading(false);
        } else {
          throw responseValue;
        }
      } else {
        handleClickError();
        setError({ payload: 'Please Fill Mandatory Data!' });
        setLoading(false);
      }
    } catch (e) {
      handleClickError();
      setError(e);
      setLoading(false);
    }
  }
  const handleCustomerSelect = async (e) => {
    const customerId = await convertCustomerId(accountCustomerData, e.target.value);
    setUpdateValue({ ...updateValue, accountId: customerId.id, accountName: e.target.value });
  };
  const filterProduct = async (itemName) => {
    const filterProduct = await productData.data.data.filter(
      (product) => product.itemname.toLowerCase() === itemName.toLowerCase()
    );
    setProductsData(filterProduct);
  };

  const handleItemSelect = async (e) => {
    const itemId = await convertItemId(itemsData, e.target.value);
    setUpdateValue({ ...updateValue, itemId: itemId.id, itemName: e.target.value });
    await filterProduct(e.target.value);
  };

  const handleProductSelect = async (e) => {
    const productId = await convertProductId(productData.data.data, e.target.value);
    setUpdateValue({ ...updateValue, productId: productId.id, productName: e.target.value });
  };
  const handleYearSelect = async (e) => {
    const yearId = await convertYearId(yearData, e.target.value);

    setUpdateValue({ ...updateValue, bookId: yearId.id, yearName: yearId.name });
  };
  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    setUpdateValue({
      ...updateValue,
      employeeName: typeof value === 'string' ? value.split(',') : value
    });
  };
  function getStyles(name, employeeName, theme) {
    return {
      fontWeight:
        employeeName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium
    };
  }
  return (
    <Box>
      <Dialog open={open} onClose={onClose} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>Update Order</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: '10px' }}>
            You can Update Your Order Details From Here!{' '}
          </DialogContentText>

          <Grid container spacing={3} sx={{ mb: '24px' }}>
            <Grid item xs={12} md={12}>
              <StyledCard elevation={6}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <InputLabel sx={{ fontSize: '10px' }} id='accountGroupName'>
                      Account Name
                    </InputLabel>

                    <Select
                      size='small'
                      sx={{ width: '80%', my: 1 }}
                      variant='standard'
                      labelId='accountGroupName'
                      value={updateValue.accountName}
                      onChange={handleCustomerSelect}
                    >
                      {accountCustomerData.map((data, index) => (
                        <MenuItem value={data.account_name} key={index} id={index}>
                          {data.account_name}
                        </MenuItem>
                      ))}
                    </Select>

                    <InputLabel sx={{ fontSize: '10px' }} id='itemName'>
                      Item Name
                    </InputLabel>

                    <Select
                      size='small'
                      sx={{ width: '80%', my: 1 }}
                      variant='standard'
                      labelId='itemName'
                      value={updateValue.itemName}
                      onChange={handleItemSelect}
                    >
                      {itemsData.map((data, index) => (
                        <MenuItem value={data.itemname} key={index} id={index}>
                          {data.itemname}
                        </MenuItem>
                      ))}
                    </Select>
                    <InputLabel sx={{ fontSize: '10px' }} id='productName'>
                      Product Name
                    </InputLabel>

                    <Select
                      size='small'
                      sx={{ width: '80%', my: 1 }}
                      variant='standard'
                      labelId='productName'
                      value={updateValue.productName}
                      onChange={handleProductSelect}
                    >
                      {productsData.map((data, index) => (
                        <MenuItem value={data.productname} key={index} id={index}>
                          {data.productname}
                        </MenuItem>
                      ))}
                    </Select>
                    <ContentBox>
                      <TextField
                        id='productPrice'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Product Price'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '80%' }}
                        variant='standard'
                        value={updateValue.productPrice == null ? 'null' : updateValue.productPrice}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, productPrice: e.target.value });
                        }}
                      />
                    </ContentBox>
                    <InputLabel sx={{ fontSize: '10px' }} id='demo-multiple-employee-label'>
                      Employee
                    </InputLabel>

                    <Select
                      labelId='demo-multiple-employee-label'
                      id='demo-multiple-employee'
                      multiple
                      value={updateValue.employeeName}
                      variant='standard'
                      InputLabelProps={{ shrink: true }}
                      onChange={handleChange}
                      sx={{ my: 1, width: '90%' }}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {accountEmployeeData.map((employee, index) => (
                        <MenuItem
                          value={employee.name}
                          style={getStyles(employee.name, updateValue.employeeName, theme)}
                          key={index}
                          id={index}
                        >
                          {employee.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <InputLabel sx={{ fontSize: '10px' }} id='yeaName'>
                      Default Year
                    </InputLabel>

                    <Select
                      size='small'
                      sx={{ width: '80%', my: 1 }}
                      variant='standard'
                      labelId='yeaName'
                      value={updateValue.yearName}
                      onChange={handleYearSelect}
                    >
                      {yearData.map((data, index) => (
                        <MenuItem value={data.name} key={index} id={index}>
                          {data.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <ContentBox>
                      <TextField
                        id='email'
                        type='text'
                        margin='dense'
                        size='small'
                        label='Email'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '80%' }}
                        variant='standard'
                        value={updateValue.email == null ? '' : updateValue.email}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, email: e.target.value });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='phoneNumber'
                        type='text'
                        margin='dense'
                        size='small'
                        label='Contact Number'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '80%' }}
                        variant='standard'
                        value={updateValue.phoneNumber == null ? '' : updateValue.phoneNumber}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, phoneNumber: e.target.value });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='extraCost'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Extra Cost'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '80%' }}
                        variant='standard'
                        value={updateValue.extraCost == null ? 'null' : updateValue.extraCost}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, extraCost: e.target.value });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='address'
                        type='text'
                        margin='dense'
                        size='small'
                        label='Address'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '80%' }}
                        variant='standard'
                        value={updateValue.address == null ? '' : updateValue.address}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, address: e.target.value });
                        }}
                      />
                    </ContentBox>
                  </Grid>
                  <TextField
                    fullWidth
                    maxRows={8}
                    id='remark'
                    type='text'
                    margin='dense'
                    size='small'
                    label='Remark'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '90%' }}
                    variant='standard'
                    multiline
                    value={updateValue.remark == null ? '' : updateValue.remark}
                    onChange={(e) => {
                      setUpdateValue({ ...updateValue, remark: e.target.value });
                    }}
                  />
                </Grid>
              </StyledCard>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='standard' color='secondary' onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleUpdate}
            color='primary'
            loading={loading}
            variant='contained'
            sx={{ my: 2 }}
          >
            Update Order
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
