import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  DialogContent,
  DialogContentText,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  styled
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { getSingleBulkStockEntryData } from '../helper/getSingleBulkStockEntryData';
import { convertCustomerId } from 'app/utils/convertId';
import { getAccountData } from 'app/contexts/reducers/account/accountSlice';
import { updateBulkStockEntryData } from 'app/contexts/reducers/bulkStockMaster/bulkStockMasterSlice';
import {
  bulkStockBillImage,
  removeStockBillImage
} from 'app/contexts/reducers/imageUpload/imageUploadSlice';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' }
}));
const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main }
}));
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});
const FlexBox = styled(Box)(() => ({ display: 'flex', alignItems: 'center' }));
const JustifyBox = styled(FlexBox)(() => ({ justifyContent: 'center' }));
export default function UpdateBulkStockEntryDialog({
  open,
  onClose,
  updateStockEntryId,
  setApiCall,
  handleClickSuccess,
  setError,
  handleClickError
}) {
  const dispatch = useDispatch();
  const { isSingleBulkStockEntrySuccess, singleBulkStockEntryData } = useSelector(
    (state) => state.bulkStock
  );
  const { isCustomerSuccess, customerData } = useSelector((state) => state.account);

  const [updateValue, setUpdateValue] = useState({
    diamondType: '',
    currentWeight: null,
    ratePerCarat: null,
    price: null,
    currentPcs: null,
    date: dayjs(),
    customerId: null,
    customerName: '',
    remark: '',
    billImage: null
  });
  const [stockEntryData, setStockEntryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [stockCustomerData, setStockCustomerData] = useState([]);
  const [imageName, setImageName] = useState();
  useEffect(() => {
    dispatch(getAccountData());
  }, [dispatch]);

  useEffect(() => {
    if (isCustomerSuccess) {
      setStockCustomerData(customerData.data.data);
    }
  }, [isCustomerSuccess, customerData]);

  useEffect(() => {
    if (isSingleBulkStockEntrySuccess) {
      setStockEntryData(singleBulkStockEntryData.data.data);
    }
  }, [isSingleBulkStockEntrySuccess, singleBulkStockEntryData]);

  useEffect(() => {
    if (stockEntryData) {
      getSingleBulkStockEntryData(stockEntryData, updateStockEntryId).then((stockEntry) => {
        stockEntry &&
          setUpdateValue({
            diamondType: stockEntry.diamond_type,
            currentWeight: stockEntry.current_weight,
            ratePerCarat: stockEntry.rate_per_carat,
            price: stockEntry.price,
            currentPcs: stockEntry.pcs,
            date: dayjs(stockEntry.date),
            customerId: stockEntry.customer_id,
            customerName: stockEntry.customer_name,
            remark: stockEntry.remark,
            billImage: stockEntry.bill_image
          });
        setImageName(
          stockEntry && stockEntry.bill_image ? stockEntry.bill_image.split('/').pop() : ''
        );
      });
    }
  }, [updateStockEntryId, singleBulkStockEntryData, stockEntryData, open]);

  const handleCustomerSelect = async (e) => {
    const customerId = await convertCustomerId(stockCustomerData, e.target.value);
    setUpdateValue({ ...updateValue, customerId: customerId.id, customerName: e.target.value });
  };
  const handleDiamondTypeSelect = async (e) => {
    setUpdateValue({ ...updateValue, diamondType: e.target.value });
  };
  async function handleImageUpdate(event) {
    if (imageName !== undefined) {
      await dispatch(
        removeStockBillImage({ fileName: imageName, folderName: 'Bulk-Stock-Bill-Image' })
      );
      const data = new FormData();
      data.append('bulkStockBillImage', event.target.files[0]);
      const response = await dispatch(bulkStockBillImage(data));

      setUpdateValue({ ...updateValue, billImage: response.payload.data.data });
      await setImageName(event.target.files[0].name);
    } else {
      const data = new FormData();
      data.append('bulkStockBillImage', event.target.files[0]);
      const response = await dispatch(bulkStockBillImage(data));
      setUpdateValue({ ...updateValue, billImage: response.payload.data.data });
      await setImageName(event.target.files[0].name);
    }
  }
  async function handleRemoveImage() {
    const code = window.prompt('Please enter the code to delete the Image');
    if (code === '3535') {
      setUpdateValue({ ...updateValue, billImage: '' });
    } else {
      window.alert('Incorrect code entered. Stock deletion canceled.');
    }
  }
  async function handleUpdate() {
    try {
      if (
        updateValue.customerName &&
        updateValue.customerName.length > 0 &&
        updateValue.diamondType &&
        updateValue.diamondType.length > 0 &&
        updateValue.currentWeight !== null
      ) {
        const updateStockDetails = { ...updateValue };
        const propertiesToConvert = ['currentWeight', 'ratePerCarat', 'price', 'currentPcs'];
        propertiesToConvert.forEach((property) => {
          if (updateValue.hasOwnProperty(property)) {
            if (property !== 'currentWeight') {
              updateValue[property] = parseInt(updateValue[property]);
            } else {
              updateValue['currentWeight'] = parseFloat(
                parseFloat(updateValue['currentWeight']).toFixed(3)
              );
            }
          }
        });
        Object.keys(updateStockDetails).forEach((key) => {
          if (updateStockDetails[key] === '' || updateStockDetails[key] === null) {
            delete updateStockDetails[key];
          }
        });
        const responseValue = await dispatch(
          updateBulkStockEntryData({ updateStockEntryId, updateStockDetails })
        );
        if (!responseValue.hasOwnProperty('error')) {
          onClose();
          handleClickSuccess('Bulk Stock Updated successfully!!');
          setApiCall((prev) => !prev);
          setLoading(false);
        } else {
          throw responseValue;
        }
      } else {
        handleClickError();
        setError({ payload: 'Please Fill Mandatory Data!' });
        setLoading(false);
      }
    } catch (e) {
      handleClickError();
      setError(e);
      setLoading(false);
    }
  }
  useEffect(() => {
    // Calculate the price when there's a change in currentWeight or ratePerCarat
    if (updateValue.currentWeight !== null && updateValue.ratePerCarat !== null) {
      const calculatedPrice = Math.round(updateValue.currentWeight * updateValue.ratePerCarat);
      setUpdateValue((prevAddStock) => ({
        ...prevAddStock,
        price: calculatedPrice
      }));
    } else {
      // If currentWeight or ratePerCarat is null, set price to null
      setUpdateValue((prevAddStock) => ({
        ...prevAddStock,
        price: null
      }));
    }
  }, [updateValue.currentWeight, updateValue.ratePerCarat]);

  const DiamondType = [
    {
      name: 'REAL'
    },
    {
      name: 'CVD'
    }
  ];
  return (
    <Box>
      <Dialog open={open} onClose={onClose} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>Update Bulk Stock Entry</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: '10px' }}>
            You can Update Your Stock Entry Details From Here!{' '}
          </DialogContentText>

          <Grid container spacing={3} sx={{ mb: '24px' }}>
            <Grid item xs={12} md={12}>
              <StyledCard elevation={6}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <InputLabel sx={{ fontSize: '10px' }} id='customerName'>
                      Customer Name
                    </InputLabel>

                    <Select
                      size='small'
                      sx={{ width: '50%', my: 1 }}
                      variant='standard'
                      labelId='customerName'
                      value={updateValue.customerName}
                      onChange={handleCustomerSelect}
                    >
                      {stockCustomerData.map((data, index) => (
                        <MenuItem value={data.account_name} key={index} id={index}>
                          {data.account_name}
                        </MenuItem>
                      ))}
                    </Select>

                    <ContentBox>
                      <TextField
                        id='stockWeight'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Current Weight'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '50%' }}
                        variant='standard'
                        value={updateValue.currentWeight}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, currentWeight: e.target.value });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='stockPcs'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Diamond Count'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '50%' }}
                        variant='standard'
                        value={updateValue.currentPcs}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, currentPcs: e.target.value });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        multiline
                        maxRows={4}
                        id='specialNote'
                        type='text'
                        margin='dense'
                        size='small'
                        label='Special Note'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '75%' }}
                        variant='standard'
                        value={updateValue.remark}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, remark: e.target.value });
                        }}
                      />
                    </ContentBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <InputLabel sx={{ fontSize: '10px' }} id='diamondType'>
                      Diamond Type
                    </InputLabel>
                    <Select
                      size='small'
                      sx={{ width: '50%', my: 1 }}
                      variant='standard'
                      labelId='diamondType'
                      value={updateValue.diamondType}
                      onChange={handleDiamondTypeSelect}
                    >
                      {DiamondType.map((data, index) => (
                        <MenuItem value={data.name} key={index} id={index}>
                          {data.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <ContentBox>
                      <TextField
                        id='caratPrice'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Carat Price'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '50%' }}
                        variant='standard'
                        value={updateValue.ratePerCarat}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, ratePerCarat: e.target.value });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='stockPrice'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Price'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '50%' }}
                        variant='standard'
                        value={updateValue.price}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, price: e.target.value });
                        }}
                      />
                    </ContentBox>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{ width: '50%', my: 1 }}
                        format='DD/MM/YYYY'
                        label='Date'
                        value={updateValue.date}
                        onChange={(newValue) => setUpdateValue({ ...updateValue, date: newValue })}
                        slotProps={{ textField: { variant: 'standard' } }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  {updateValue.billImage ? (
                    <React.Fragment>
                      <JustifyBox p={1} height='100%' sx={{ width: '50%' }}>
                        <img src={updateValue.billImage} width='100%' alt={'Bill'} />
                      </JustifyBox>
                      <IconButton onClick={handleRemoveImage}>
                        <Icon color='error'>delete</Icon>
                      </IconButton>
                    </React.Fragment>
                  ) : (
                    <Button
                      component='label'
                      variant='outlined'
                      onChange={handleImageUpdate}
                      startIcon={<CloudUploadIcon />}
                    >
                      Upload file
                      <VisuallyHiddenInput type='file' />
                    </Button>
                  )}
                </Grid>
              </StyledCard>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='standard' color='secondary' onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleUpdate}
            color='primary'
            loading={loading}
            variant='contained'
            sx={{ my: 2 }}
          >
            Update Stock Entry
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
