export const searchAccountValue = async (searchValue, dataHouse) => {
    if (searchValue.trim() === '') {
        // If the search term is empty, return the whole array
        return dataHouse;
    }
    const searchData = await dataHouse.filter((data) => {
        return (data.customer_name.toLowerCase().includes(searchValue.toLowerCase()) ||
            Number(data.id) === Number(searchValue));
    });
    return searchData;
};