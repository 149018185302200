// TableRowData.js
import React, { useState } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { H5, H6, Paragraph } from 'app/components/Typography';
import { Avatar } from '@mui/material';
import styled from '@emotion/styled';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)'
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' }
}));
function SellTableRowData(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  const convertDate = (isoDateString) => {
    const date = new Date(isoDateString);

    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    };
    const formattedDate = date.toLocaleString('en-GB', options).replace(/\//g, '-');
    return formattedDate;
  };

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} onClick={() => setOpen(!open)}>
        <TableCell
          component='th'
          scope='row'
          colSpan={2}
          align='left'
          sx={{ px: 1, textTransform: 'capitalize' }}
        >
          <H5 sx={{ px: 0 }}>{row.id}</H5>
        </TableCell>
        <TableCell
          component='th'
          scope='row'
          colSpan={2}
          align='left'
          sx={{ px: 0, textTransform: 'capitalize' }}
        >
          {row.book_number}
        </TableCell>
        <TableCell
          component='th'
          scope='row'
          colSpan={2}
          align='left'
          sx={{ px: 0, textTransform: 'capitalize' }}
        >
          {convertDate(row.date)}
        </TableCell>
        <TableCell
          component='th'
          scope='row'
          align='left'
          colSpan={4}
          sx={{ px: 2, textTransform: 'capitalize' }}
        >
          {row.customer_name}
        </TableCell>
        <TableCell
          component='th'
          scope='row'
          colSpan={2}
          align='left'
          sx={{ px: 3, textTransform: 'capitalize' }}
        >
          {row.studd_pieces}
        </TableCell>
        <TableCell
          component='th'
          scope='row'
          colSpan={3}
          align='left'
          sx={{ px: 3, textTransform: 'capitalize' }}
        >
          {row.labour_charge_per_pcs}
        </TableCell>
        <TableCell
          component='th'
          scope='row'
          colSpan={3}
          align='left'
          sx={{ px: 3, textTransform: 'capitalize' }}
        >
          {row.labour_charge}
        </TableCell>
        <TableCell
          component='th'
          scope='row'
          colSpan={3}
          align='left'
          sx={{
            px: 3,

            textTransform: 'capitalize'
          }}
        >
          {row.self_weight}
        </TableCell>
        <TableCell
          component='th'
          scope='row'
          colSpan={3}
          align='left'
          sx={{
            px: 4,

            textTransform: 'capitalize'
          }}
        >
          {row.studd_weight}
        </TableCell>
        <TableCell
          component='th'
          scope='row'
          colSpan={3}
          align='left'
          sx={{ px: 3, textTransform: 'capitalize' }}
        >
          {row.product_price}
        </TableCell>
        <TableCell
          component='th'
          scope='row'
          colSpan={3}
          align='left'
          sx={{
            px: 2,
            textTransform: 'capitalize'
          }}
        >
          {row.extra_cost}
        </TableCell>
        <TableCell
          component='th'
          scope='row'
          colSpan={3}
          align='left'
          sx={{
            px: 3,
            textTransform: 'capitalize'
          }}
        >
          {row.quantity}
        </TableCell>
        <TableCell
          component='th'
          scope='row'
          colSpan={2}
          align='left'
          sx={{
            px: 1,
            textTransform: 'capitalize'
          }}
        >
          {row.total}
        </TableCell>
        <TableCell>
          <IconButton aria-label='expand row' size='small'>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={36}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <ProductTable>
                <TableHead sx={{ backgroundColor: 'rgb(34,41,67)' }}>
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      align='center'
                      sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}
                    >
                      Order-Id
                    </TableCell>
                    <TableCell
                      colSpan={2}
                      align='center'
                      sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                    >
                      Item-Name
                    </TableCell>
                    <TableCell
                      align='left'
                      sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}
                      colSpan={4}
                    >
                      Product-Name
                    </TableCell>
                    <TableCell
                      align='center'
                      sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                      colSpan={2}
                    >
                      Inward Type
                    </TableCell>
                    <TableCell
                      colSpan={2}
                      align='center'
                      sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      align='center'
                      sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}
                      colSpan={2}
                    >
                      Pcs
                    </TableCell>
                    <TableCell
                      align='center'
                      sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}
                      colSpan={2}
                    >
                      Self-Wt
                    </TableCell>
                    <TableCell
                      align='center'
                      sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}
                      colSpan={2}
                    >
                      Cus-Wt
                    </TableCell>
                    <TableCell
                      align='center'
                      sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}
                      colSpan={2}
                    >
                      Total-Wt
                    </TableCell>
                    <TableCell
                      align='center'
                      sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}
                      colSpan={2}
                    >
                      Labour
                    </TableCell>
                    <TableCell
                      align='center'
                      sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}
                      colSpan={2}
                    >
                      Diamond
                    </TableCell>
                    <TableCell
                      align='center'
                      sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}
                      colSpan={2}
                    >
                      Product
                    </TableCell>
                    <TableCell
                      align='center'
                      sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}
                      colSpan={2}
                    >
                      Extra Amt
                    </TableCell>

                    <TableCell
                      align='center'
                      sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                      colSpan={2}
                    >
                      Total Amt
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {row.orders && row.orders.length > 0 ? (
                    row.orders.map((orderDetails, index) => (
                      <TableRow key={index} hover>
                        <TableCell
                          colSpan={2}
                          align='center'
                          sx={{ px: 0, textTransform: 'capitalize' }}
                        >
                          <H6 sx={{ px: 1 }}>{orderDetails.id}</H6>
                        </TableCell>

                        <TableCell
                          colSpan={2}
                          align='center'
                          sx={{ px: 0, textTransform: 'capitalize' }}
                        >
                          {orderDetails.item_name}
                        </TableCell>
                        <TableCell
                          align='left'
                          colSpan={4}
                          sx={{ px: 2, textTransform: 'capitalize' }}
                        >
                          <Box display='flex' alignItems='center'>
                            <Avatar src={orderDetails.productimage} />
                            <Paragraph sx={{ m: 0, ml: 1 }}>{orderDetails.product_name}</Paragraph>
                          </Box>
                        </TableCell>
                        <TableCell colSpan={2} align='center' sx={{ px: 0 }}>
                          {orderDetails.order_inward_type}
                        </TableCell>
                        <TableCell colSpan={2} align='center' sx={{ px: 0 }}>
                          {convertDate(orderDetails.date)}
                        </TableCell>
                        <TableCell colSpan={2} align='center' sx={{ px: 0 }}>
                          {orderDetails.studdPiece ? orderDetails.studdPiece : 0}
                        </TableCell>
                        <TableCell colSpan={2} align='center' sx={{ px: 0 }}>
                          {orderDetails.studdSelfWeight ? orderDetails.studdSelfWeight : 0}
                        </TableCell>
                        <TableCell colSpan={2} align='center' sx={{ px: 0 }}>
                          {orderDetails.studdWeight ? orderDetails.studdWeight : 0}
                        </TableCell>
                        <TableCell colSpan={2} align='center' sx={{ px: 0 }}>
                          {parseFloat(
                            parseFloat(
                              (orderDetails.studdWeight
                                ? parseFloat(parseFloat(orderDetails.studdWeight).toFixed(3))
                                : 0) +
                                (orderDetails.studdSelfWeight
                                  ? parseFloat(parseFloat(orderDetails.studdSelfWeight).toFixed(3))
                                  : 0)
                            ).toFixed(3)
                          )}
                        </TableCell>
                        <TableCell colSpan={2} align='center' sx={{ px: 0 }}>
                          {orderDetails.labourCharge ? orderDetails.labourCharge : 0}
                        </TableCell>
                        <TableCell colSpan={2} align='center' sx={{ px: 0 }}>
                          {orderDetails.diamondPrice ? orderDetails.diamondPrice : 0}
                        </TableCell>
                        <TableCell colSpan={2} align='center' sx={{ px: 0 }}>
                          {orderDetails.product_price ? orderDetails.product_price : 0}
                        </TableCell>
                        <TableCell colSpan={2} align='center' sx={{ px: 0 }}>
                          {orderDetails.extra_cost ? orderDetails.extra_cost : 0}
                        </TableCell>

                        <TableCell align='center' sx={{ px: 2, fontWeight: 'bold' }} colSpan={2}>
                          {orderDetails.totalAmount ? orderDetails.totalAmount : 0}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={30}
                        align='center'
                        sx={{ color: 'rgb(34,41,67)', fontWeight: 'bold' }}
                      >
                        No Stock Data Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </ProductTable>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default SellTableRowData;
