export function filterDataByTypeAndValue(data, type, value) {
    if (value === 0) {
        // If the value is 0, return the whole data
        return { ...data };
    }
    // Determine which array to filter based on the provided type
    const dataArray = type.toLowerCase() === 'expensetype' ? data.filteredExpenseData : data.filteredIncomeData;

    // Filter the array based on the provided value
    const filteredData = dataArray.filter(
        (item) => item[`${type.toLowerCase() === 'expensetype' ? 'expense' : 'income'}_type`] === value
    );

    // Create a new object with updated properties
    const newData = {
        ...data,
        [type.toLowerCase() === 'expensetype' ? 'filteredExpenseData' : 'filteredIncomeData']: filteredData,
        [type.toLowerCase() === 'expensetype' ? 'filteredIncomeData' : 'filteredExpenseData']: [],
    };

    return newData;
}

