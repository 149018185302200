function calculateTotalSum(arr) {
    let totalSum = 0;
    let totalStudPcs = 0;
    let totalStudWeight = 0;
    let totalSelfWeight = 0;

    arr.forEach(obj => {
        // Check if the 'total' field exists and is not null or undefined
        if (obj.hasOwnProperty('total') && obj.total !== null && obj.total !== undefined) {
            // Convert 'total' to a number if it's a string
            const totalValue = typeof obj.total === 'string' ? parseFloat(obj.total) : obj.total;

            // Add the numeric value to the total sum
            if (!isNaN(totalValue)) {
                totalSum += totalValue;
            }
        }

        // Check if the 'studd_pieces' field exists and is not null or undefined
        if (obj.hasOwnProperty('studd_pieces') && obj.studd_pieces !== null && obj.studd_pieces !== undefined) {
            const studPcsValue = typeof obj.studd_pieces === 'string' ? parseFloat(obj.studd_pieces) : obj.studd_pieces;

            if (!isNaN(studPcsValue)) {
                totalStudPcs += studPcsValue;
            }
        }

        // Check if the 'studd_weight' field exists and is not null or undefined
        if (obj.hasOwnProperty('studd_weight') && obj.studd_weight !== null && obj.studd_weight !== undefined) {
            const studWeightValue = typeof obj.studd_weight === 'string' ? parseFloat(obj.studd_weight) : obj.studd_weight;

            if (!isNaN(studWeightValue)) {
                totalStudWeight += studWeightValue;
            }
        }

        // Check if the 'self_weight' field exists and is not null or undefined
        if (obj.hasOwnProperty('self_weight') && obj.self_weight !== null && obj.self_weight !== undefined) {
            const selfWeightValue = typeof obj.self_weight === 'string' ? parseFloat(obj.self_weight) : obj.self_weight;

            if (!isNaN(selfWeightValue)) {
                totalSelfWeight += selfWeightValue;
            }
        }
    });

    return {
        totalSum: totalSum.toLocaleString('en-IN'),
        totalStudPcs: totalStudPcs.toLocaleString('en-IN'),
        totalStudWeight: totalStudWeight.toLocaleString('en-IN'),
        totalSelfWeight: totalSelfWeight.toLocaleString('en-IN')
    };
}
export { calculateTotalSum };