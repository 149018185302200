export const convertAccountId = async (dataHouse, accountValue) => {
  const matchedAccountData = await dataHouse.find(
    (account) => account.account_group_name.toLowerCase() === accountValue.toLowerCase()
  );
  return matchedAccountData;
};
export const convertCustomerId = async (dataHouse, accountValue) => {
  const matchedAccountData = await dataHouse.find(
    (account) => account.account_name.toLowerCase() === accountValue.toLowerCase()
  );
  return matchedAccountData;
};
export const convertCustomerAmountDataId = async (dataHouse, accountValue) => {
  const matchedAccountData = await dataHouse.find(
    (account) => account.customer_name.toLowerCase() === accountValue.toLowerCase()
  );
  return matchedAccountData;
};
export const convertCustomerReportDataId = async (dataHouse, accountValue) => {
  const matchedAccountData = await dataHouse.find(
    (account) => account.account_name.toLowerCase() === accountValue.toLowerCase()
  );
  return matchedAccountData;
};
export const convertVendorId = async (dataHouse, accountValue) => {
  const matchedAccountData = await dataHouse.find(
    (account) => account.account_name.toLowerCase() === accountValue.toLowerCase()
  );
  return matchedAccountData;
};
export const convertVendorAmountDataId = async (dataHouse, accountValue) => {
  const matchedAccountData = await dataHouse.find(
    (account) => account.vendor_name.toLowerCase() === accountValue.toLowerCase()
  );
  return matchedAccountData;
};
export const convertItemId = async (dataHouse, itemName) => {
  const matchedAccountData = await dataHouse.find(
    (item) => item.itemname.toLowerCase() === itemName.toLowerCase()
  );
  return matchedAccountData;
};
export const convertProductId = async (dataHouse, productName) => {
  const matchedAccountData = await dataHouse.find(
    (product) => product.productname.toLowerCase() === productName.toLowerCase()
  );
  return matchedAccountData;
};
export const convertYearId = async (dataHouse, yearName) => {
  const matchedAccountData = await dataHouse.find((year) => year.name === yearName);

  return matchedAccountData;
};
