import {
  Avatar,
  Box,
  Card,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { H6, Paragraph } from 'app/components/Typography';
import { getInvoices } from 'app/contexts/reducers/transaction/invoiceSlice';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { calculateTotals, findInvoiceById } from '../helper/customerInvoiceOrder';

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)'
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' }
}));

const CustomerInvoiceOrder = ({ userInvoiceOrders, invoiceId }) => {
  const dispatch = useDispatch();
  const { isAllInvoiceSuccess, invoiceData } = useSelector((state) => state.invoice);
  const [invoiceOrder, setInvoiceOrder] = useState(null);
  useEffect(() => {
    dispatch(getInvoices());
  }, [dispatch, invoiceId]);
  useEffect(() => {
    if (isAllInvoiceSuccess) {
      if (Number(invoiceData.data.code) === 200) {
        const foundedInvoiceData = findInvoiceById(invoiceData.data.data, invoiceId);
        setInvoiceOrder(foundedInvoiceData);
      } else {
        setInvoiceOrder(null);
      }
    }
  }, [isAllInvoiceSuccess, invoiceData, invoiceId]);
  const convertDate = (isoDateString) => {
    const date = new Date(isoDateString);

    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    };

    const formattedDate = date.toLocaleString('en-GB', options).replace(/\//g, '-');
    return formattedDate;
  };

  return (
    <React.Fragment>
      <Card elevation={3} sx={{ mb: 3 }}>
        <Box overflow='auto'>
          <ProductTable>
            <TableHead sx={{ backgroundColor: 'rgb(34,41,67)' }}>
              <TableRow>
                <TableCell
                  colSpan={2}
                  align='center'
                  sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}
                >
                  Order-Id
                </TableCell>
                <TableCell
                  colSpan={2}
                  align='center'
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                >
                  Item-Name
                </TableCell>
                <TableCell
                  align='left'
                  sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={4}
                >
                  Product-Name
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  Inward Type
                </TableCell>
                <TableCell
                  colSpan={2}
                  align='center'
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                >
                  Date
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  Pcs
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  Self-Wt
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  Cus-Wt
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  Total-Wt
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  Labour
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  Diamond
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  Product
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  Extra Amt
                </TableCell>

                <TableCell
                  align='center'
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  Total Amt
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {invoiceOrder && invoiceOrder.hasOwnProperty('orders') ? (
                invoiceOrder.orders.map((orderDetails, index) => (
                  <TableRow key={index} hover>
                    <TableCell
                      colSpan={2}
                      align='center'
                      sx={{ px: 0, textTransform: 'capitalize' }}
                    >
                      <H6 sx={{ px: 1 }}>{orderDetails.id}</H6>
                    </TableCell>

                    <TableCell
                      colSpan={2}
                      align='center'
                      sx={{ px: 0, textTransform: 'capitalize' }}
                    >
                      {orderDetails.item_name}
                    </TableCell>
                    <TableCell align='left' colSpan={4} sx={{ px: 2, textTransform: 'capitalize' }}>
                      <Box display='flex' alignItems='center'>
                        <Avatar src={orderDetails.productimage} />
                        <Paragraph sx={{ m: 0, ml: 1 }}>{orderDetails.product_name}</Paragraph>
                      </Box>
                    </TableCell>
                    <TableCell colSpan={2} align='center' sx={{ px: 0 }}>
                      {orderDetails.order_inward_type}
                    </TableCell>
                    <TableCell colSpan={2} align='center' sx={{ px: 0 }}>
                      {convertDate(orderDetails.date)}
                    </TableCell>
                    <TableCell colSpan={2} align='center' sx={{ px: 0 }}>
                      {orderDetails.studdPiece ? orderDetails.studdPiece : 0}
                    </TableCell>
                    <TableCell colSpan={2} align='center' sx={{ px: 0 }}>
                      {orderDetails.studdSelfWeight ? orderDetails.studdSelfWeight : 0}
                    </TableCell>
                    <TableCell colSpan={2} align='center' sx={{ px: 0 }}>
                      {orderDetails.studdWeight ? orderDetails.studdWeight : 0}
                    </TableCell>
                    <TableCell colSpan={2} align='center' sx={{ px: 0 }}>
                      {parseFloat(
                        parseFloat(
                          (orderDetails.studdWeight
                            ? parseFloat(parseFloat(orderDetails.studdWeight).toFixed(3))
                            : 0) +
                            (orderDetails.studdSelfWeight
                              ? parseFloat(parseFloat(orderDetails.studdSelfWeight).toFixed(3))
                              : 0)
                        ).toFixed(3)
                      )}
                    </TableCell>
                    <TableCell colSpan={2} align='center' sx={{ px: 0 }}>
                      {orderDetails.labourCharge ? orderDetails.labourCharge : 0}
                    </TableCell>
                    <TableCell colSpan={2} align='center' sx={{ px: 0 }}>
                      {orderDetails.diamondPrice ? orderDetails.diamondPrice : 0}
                    </TableCell>
                    <TableCell colSpan={2} align='center' sx={{ px: 0 }}>
                      {orderDetails.product_price ? orderDetails.product_price : 0}
                    </TableCell>
                    <TableCell colSpan={2} align='center' sx={{ px: 0 }}>
                      {orderDetails.extra_cost ? orderDetails.extra_cost : 0}
                    </TableCell>

                    <TableCell align='center' sx={{ px: 2, fontWeight: 'bold' }} colSpan={2}>
                      {orderDetails.totalAmount ? orderDetails.totalAmount : 0}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={30}
                    align='center'
                    sx={{ color: 'rgb(34,41,67)', fontWeight: 'bold' }}
                  >
                    No Stock Data Found
                  </TableCell>
                </TableRow>
              )}
              <TableRow sx={{ backgroundColor: 'rgb(34,41,67)' }}>
                <TableCell
                  colSpan={12}
                  align='center'
                  sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}
                >
                  Total
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  {calculateTotals(invoiceOrder).studdPiece}
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  {calculateTotals(invoiceOrder).studdSelfWeight}
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  {calculateTotals(invoiceOrder).studdWeight}
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  {parseFloat(
                    calculateTotals(invoiceOrder).studdSelfWeight +
                      calculateTotals(invoiceOrder).studdWeight
                  ).toFixed(3)}
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  {calculateTotals(invoiceOrder).labourCharge}
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  {calculateTotals(invoiceOrder).diamondPrice}
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  {calculateTotals(invoiceOrder).product_price}
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  {calculateTotals(invoiceOrder).extra_cost}
                </TableCell>

                <TableCell
                  align='center'
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  {calculateTotals(invoiceOrder).totalAmount}
                </TableCell>
              </TableRow>
            </TableBody>
          </ProductTable>
        </Box>
      </Card>
    </React.Fragment>
  );
};

export default CustomerInvoiceOrder;
