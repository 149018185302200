import { LoadingButton } from '@mui/lab';
import { Box, InputLabel, MenuItem, Select } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { updateBulkStockStatus } from 'app/contexts/reducers/bulkStockMaster/bulkStockMasterSlice';

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export default function UpdateBulkStockStatus({
  open,
  onClose,
  updateStockId,
  currentStatus,
  setApiCall,
  handleClickSuccess,
  handleClickError,
  setError
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [stockStatus, setStockStatus] = useState(currentStatus);
  useEffect(() => {
    setStockStatus(currentStatus);
  }, [currentStatus]);
  const handleDelete = async () => {
    try {
      const response = await dispatch(
        updateBulkStockStatus({ stockId: updateStockId, status: stockStatus })
      );
      if (!response.hasOwnProperty('error')) {
        onClose();
        setApiCall((prev) => !prev);
        handleClickSuccess('Bulk Stock Status Update Successfully');
        setLoading(false);
      } else {
        throw response;
      }
    } catch (e) {
      handleClickError();
      setError(e);
      setLoading(false);
    }
  };
  const handleSelect = (e) => {
    setStockStatus(e.target.value);
  };
  return (
    <Box>
      <Dialog
        open={open}
        onClose={onclose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Bulk Stock Status Update</DialogTitle>

        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to update this stock status? If you want then you can change the
            status later.
          </DialogContentText>
        </DialogContent>
        <InputLabel sx={{ fontSize: '10px', mx: 3 }} id='diamondType'>
          Bulk Stock Status
        </InputLabel>
        <Select
          size='small'
          value={stockStatus}
          sx={{ width: '50%', mx: 3 }}
          onChange={handleSelect}
        >
          <MenuItem value='0' id={0}>
            Pending
          </MenuItem>
          <MenuItem value='1' id={1}>
            Completed
          </MenuItem>
        </Select>
        <DialogActions>
          <Button onClick={onClose} color='primary'>
            Disagree
          </Button>
          <LoadingButton
            onClick={handleDelete}
            color='primary'
            loading={loading}
            variant='contained'
            sx={{ my: 2 }}
          >
            Update Status
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
