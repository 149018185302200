import styled from '@emotion/styled';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@mui/material';
import { H5 } from 'app/components/Typography';
import { addProduct } from 'app/contexts/reducers/product/productSlice';
import { Fragment } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});
const FlexBox = styled(Box)(() => ({ display: 'flex', alignItems: 'center' }));
const JustifyBox = styled(FlexBox)(() => ({ justifyContent: 'center' }));
const AutoComplete = styled(Autocomplete)(() => ({ width: 300 }));
export default function AddDialog({
  open,
  onClose,
  setOpen,
  setApiCall,
  handleClickSuccess,
  setError,
  handleClickError
}) {
  const dispatch = useDispatch();
  const { itemData } = useSelector((state) => state.item);
  const { isItemSuccess } = useSelector((state) => state.item);
  const [productName, setProductName] = useState();
  const [productImage, setProductImage] = useState();
  const [previweImage, setPreviweImage] = useState();
  const [loading, setLoading] = useState(false);
  const [openAutoComplete, setOpenAutoComplete] = useState(false);
  const [options, setOptions] = useState([]);
  const [itemId, setItemId] = useState(null);
  const loadingAutoComplete = open && options.length === 0;
  useEffect(() => {
    if (isItemSuccess) {
      setLoading(true);
      setOptions(itemData.data.data);
      setLoading(false);
      setPreviweImage('');
    }
  }, [isItemSuccess, itemData]);
  const isBrandSelected = itemId !== null;
  const isWatchBrandDisabled = !isBrandSelected;
  async function handleImageUpdate(event) {
    setProductImage(event.target.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      setPreviweImage(reader.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  }
  const handleSubmit = async () => {
    try {
      setLoading(true);
      const formdata = new FormData();
      formdata.append('productName', productName);
      formdata.append('itemId', itemId);
      formdata.append('productImage', productImage);
      const responseValue = await dispatch(addProduct(formdata));
      if (!responseValue.hasOwnProperty('error')) {
        setApiCall((prev) => !prev);
        setOpen(false);
        handleClickSuccess('Product added successfully');
        setLoading(false);
      } else {
        throw responseValue;
      }
    } catch (e) {
      handleClickError();
      setError(e);
      setLoading(false);
    }
  };
  return (
    <Box>
      <Dialog open={open} onClose={onClose} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>Add Product</DialogTitle>

        <DialogContent>
          <DialogContentText>Please Select Watch Brand</DialogContentText>

          <AutoComplete
            fullWidth
            open={openAutoComplete}
            options={options}
            loading={loadingAutoComplete}
            id={(option) => option.id}
            onOpen={() => setOpenAutoComplete(true)}
            onClose={() => {
              setOpenAutoComplete(false);
            }}
            sx={{ mt: 2 }}
            getOptionLabel={(option) => option.itemname}
            onChange={(event, value) => {
              value && setItemId(value.id);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant='outlined'
                label='Watch Brand'
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {loading ? <CircularProgress color='primary' size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  )
                }}
              />
            )}
          />

          <DialogContentText sx={{ mt: 4 }}>
            Please enter the name of the watch brand
          </DialogContentText>
          <TextField
            fullWidth
            autoFocus
            id='itemName'
            type='text'
            margin='dense'
            label='Watch Model Name'
            disabled={isWatchBrandDisabled}
            onChange={(e) => {
              setProductName(e.target.value);
            }}
          />
          <Button
            component='label'
            variant='contained'
            onChange={handleImageUpdate}
            disabled={isWatchBrandDisabled}
            sx={{ my: 2 }}
            startIcon={<CloudUploadIcon />}
          >
            Upload Model Image
            <VisuallyHiddenInput type='file' />
          </Button>
          {/* <TextField
            fullWidth
            autoFocus
            id='productImage'
            type='file'
            margin='dense'
            label='Upload Model Image'
            disabled={isWatchBrandDisabled}
            onChange={handleImageUpdate}
          /> */}
          <Box sx={{ mt: 2 }}>
            <H5>Product Image</H5>
            {previweImage && (
              <JustifyBox p={4} height='100%' sx={{ maxWidth: 320, m: 'auto' }}>
                <img src={previweImage} width='100%' alt={productName} />
              </JustifyBox>
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant='outlined' color='secondary' onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleSubmit}
            color='primary'
            loading={loading}
            variant='contained'
            sx={{ my: 2 }}
          >
            Add Item
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
