import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders } from 'app/utils/handleHeader';
const initialState = {
    vendorReportData: null,
    isInitialised: false,
    isVendorReportSuccess: false,
    isVendorReportPDFSuccess: false,
    isError: false,
    isLoading: false
};
//--------------Asyncthunk for Get Vendor Report --------------------------------
export const getVendorReportData = createAsyncThunk('getVendorReportData', async ({ fromDate, toDate, vendorId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/report/vendorreport/${vendorId}?fromDate=${fromDate}&toDate=${toDate}`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

// ----------------------------------------Genrate Vendor-Report PDF --------------------------------
export const genrateVendorReportPdf = createAsyncThunk('genrateVendorReportPdf', async ({ fromDate, toDate, vendorId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/report/generatevendorreport/${vendorId}?fromDate=${fromDate}&toDate=${toDate}`, {
            responseType: 'arraybuffer',
            headers: { 'Accept': 'application/pdf', ...getHeaders() }
        });

        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Vendor_Report_${vendorId}.pdf`; // Set the desired file name
        a.click();
        window.URL.revokeObjectURL(url);
        // return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

const vendorReportSlice = createSlice({
    name: 'vendorReportSlice',
    initialState,
    reducers: {},
    extraReducers: {
        //--------------Slice action for the Get Stock Data --------------------------------
        [getVendorReportData.pending]: (state, action) => {
            state.isLoading = false;
            state.isVendorReportSuccess = false;
            state.isError = false;
        },
        [getVendorReportData.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isVendorReportSuccess = true;
            state.vendorReportData = action.payload;
            state.isError = false;
        },
        [getVendorReportData.rejected]: (state, action) => {
            state.isLoading = false;
            state.isVendorReportSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Stock Data --------------------------------
        [genrateVendorReportPdf.pending]: (state, action) => {
            state.isLoading = false;
            state.isVendorReportPDFSuccess = false;
            state.isError = false;
        },
        [genrateVendorReportPdf.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isVendorReportPDFSuccess = true;
            state.isError = false;
        },
        [genrateVendorReportPdf.rejected]: (state, action) => {
            state.isLoading = false;
            state.isVendorReportPDFSuccess = false;
            state.isError = true;
        },
    }
})
export default vendorReportSlice.reducer;
