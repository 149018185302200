import React from 'react';
import {
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled
} from '@mui/material';
import { H5 } from 'app/components/Typography';
const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)'
  },
  '& td': { borderBottom: '1px solid lightgray' },
  '& td:first-of-type': { paddingLeft: '16px !important' }
}));
const EmployeeTable = ({
  accountCustomerData,
  setUpdateAccountId,
  setUpdateEmployeeOpen,
  setDeleteAccountId,
  setDeleteOpen
}) => {
  return (
    <ProductTable>
      <TableHead sx={{ backgroundColor: 'rgb(34,41,67)' }}>
        <TableRow>
          <TableCell sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}>Index</TableCell>
          <TableCell sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>Group Name</TableCell>
          <TableCell sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>Name</TableCell>
          <TableCell sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }} colSpan={1}>
            Phone Number
          </TableCell>
          <TableCell sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }} colSpan={2}>
            Email
          </TableCell>
          <TableCell sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }} colSpan={2}>
            Remark
          </TableCell>
          <TableCell sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>Debt</TableCell>
          <TableCell sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>Total Salary</TableCell>
          <TableCell sx={{ fontWeight: 'bold', color: '#FFFFFF', px: 2 }}>Action</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {accountCustomerData && accountCustomerData.length > 0 ? (
          accountCustomerData.map((account, index) => (
            <TableRow key={index} hover>
              <TableCell align='left' sx={{ px: 3, textTransform: 'capitalize' }}>
                <H5 sx={{ textAlign: 'left', px: 3 }}>{index + 1}</H5>
              </TableCell>
              <TableCell align='left' sx={{ px: 0, textTransform: 'capitalize' }}>
                <H5 sx={{ textAlign: 'left' }}>{account.account_group_name}</H5>
              </TableCell>

              <TableCell align='left' sx={{ px: 0, textTransform: 'capitalize' }}>
                <H5 sx={{ textAlign: 'left' }}>{account.name}</H5>
              </TableCell>

              <TableCell sx={{ px: 0 }} colSpan={1}>
                <H5 sx={{ textAlign: 'left', px: 0 }}>{account.phone_number}</H5>
              </TableCell>
              <TableCell sx={{ px: 0 }} align='left' colSpan={2}>
                <H5 sx={{ textAlign: 'left' }}>{account.email}</H5>
              </TableCell>

              <TableCell sx={{ px: 0 }} colSpan={2}>
                <H5 sx={{ textAlign: 'left', px: 2 }}>{account.remark}</H5>
              </TableCell>
              <TableCell sx={{ px: 0 }}>
                <H5 sx={{ textAlign: 'left', color: '#DC3545' }}>{account.debt}</H5>
              </TableCell>
              <TableCell sx={{ px: 0 }}>
                <H5 sx={{ textAlign: 'left', px: 2 }}>{account.total_salary}</H5>
              </TableCell>
              <TableCell>
                <IconButton
                  sx={{ ml: 1 }}
                  onClick={() => {
                    setUpdateAccountId(account.id);
                    setUpdateEmployeeOpen(true);
                  }}
                >
                  <Icon color='primary'>edit</Icon>
                </IconButton>
                <IconButton
                  onClick={() => {
                    setDeleteOpen(true);
                    setDeleteAccountId(account.id);
                  }}
                >
                  <Icon color='error'>close</Icon>
                </IconButton>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={8} sx={{ textAlign: 'center' }}>
              No data found
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </ProductTable>
  );
};

export default EmployeeTable;
