import {
  Avatar,
  Box,
  Card,
  Checkbox,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme
} from '@mui/material';
import { Paragraph } from 'app/components/Typography';
import React from 'react';

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)'
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' }
}));

const Small = styled('small')(({ bgcolor }) => ({
  width: 50,
  height: 15,
  color: '#fff',
  padding: '2px 8px',
  borderRadius: '4px',
  overflow: 'hidden',
  background: bgcolor,
  boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)'
}));

const CustomerRunningOrder = ({
  customerRunningOrder,
  selectedOrderDetails,
  handleCheckboxChange
}) => {
  const { palette } = useTheme();
  const bgSuccess = palette.success.main;
  const bgSecondary = palette.secondary.main;
  const bgError = palette.error.main;

  const convertDate = (isoDateString) => {
    const date = new Date(isoDateString);

    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    };

    const formattedDate = date.toLocaleString('en-GB', options).replace(/\//g, '-');
    return formattedDate;
  };

  return (
    <React.Fragment>
      <Card elevation={3} sx={{ mb: 3 }}>
        <Box overflow='auto'>
          <ProductTable>
            <TableHead sx={{ backgroundColor: 'rgb(34,41,67)' }}>
              <TableRow>
                <TableCell align='center' sx={{ px: 2, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Select
                </TableCell>
                <TableCell align='center' sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Item-Name
                </TableCell>
                <TableCell
                  align='left'
                  sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={3}
                >
                  Product-Name
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  Account-Name
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  Order Status
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  Inward Type
                </TableCell>

                <TableCell align='center' sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Date
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {customerRunningOrder && customerRunningOrder.length > 0 ? (
                customerRunningOrder.map((orderDetails, index) => (
                  <TableRow key={index} hover>
                    <TableCell align='center' sx={{ px: 0, textTransform: 'capitalize' }}>
                      <Checkbox
                        checked={selectedOrderDetails.includes(orderDetails)}
                        onChange={() => handleCheckboxChange(orderDetails)}
                      />
                    </TableCell>

                    <TableCell align='center' sx={{ px: 0, textTransform: 'capitalize' }}>
                      {orderDetails.item_name}
                    </TableCell>
                    <TableCell align='left' colSpan={3} sx={{ px: 2, textTransform: 'capitalize' }}>
                      <Box display='flex' alignItems='center'>
                        <Avatar src={orderDetails.productimage} />
                        <Paragraph sx={{ m: 0, ml: 1 }}>{orderDetails.product_name}</Paragraph>
                      </Box>
                    </TableCell>
                    <TableCell align='center' sx={{ px: 2 }} colSpan={2}>
                      {orderDetails.account_name}
                    </TableCell>

                    <TableCell align='center' sx={{ px: 2 }} colSpan={2}>
                      {orderDetails.status === 0 && <Small bgcolor={bgSecondary}>Pending</Small>}
                      {orderDetails.status === 1 && <Small bgcolor={bgSecondary}>Running</Small>}
                      {orderDetails.status === 2 && <Small bgcolor={bgSuccess}>Completed</Small>}
                      {orderDetails.status === 3 && <Small bgcolor={bgError}>Sold</Small>}
                    </TableCell>
                    <TableCell align='center' sx={{ px: 2 }} colSpan={2}>
                      {orderDetails.order_inward_type}
                    </TableCell>

                    <TableCell align='center' sx={{ px: 0 }}>
                      {convertDate(orderDetails.date)}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={12}
                    align='center'
                    sx={{ color: 'rgb(34,41,67)', fontWeight: 'bold' }}
                  >
                    No Stock Data Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </ProductTable>
        </Box>
      </Card>
    </React.Fragment>
  );
};

export default CustomerRunningOrder;
