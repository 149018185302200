import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Chip,
  DialogContent,
  DialogContentText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  styled,
  useTheme
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import DialogTitle from '@mui/material/DialogTitle';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { getSingleInwardEntryData } from '../../helper/getSingleInwardEntryData';
import { convertVendorId } from 'app/utils/convertId';
import {
  getSingleDiamondInward,
  updateInwardEntryData
} from 'app/contexts/reducers/transaction/diamondInwardSlice';
const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' }
}));
const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main }
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
export default function UpdateSelfInward({
  open,
  onClose,
  inwardId,
  handleApiCall,
  handleClickSuccess,
  setError,
  apiCall,
  handleClickError
}) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const { isVendorSuccess, vendorData, isEmployeeSuccess, employeeData } = useSelector(
    (state) => state.account
  );
  const { isSingleInwardSuccess, singleInwardData } = useSelector((state) => state.inwardDetails);

  const [updateValue, setUpdateValue] = useState({
    orderDiamondType: '',
    orderType: '',
    vendorId: null,
    vendorName: '',
    diamondType: '',
    sieve: '',
    stockWeight: null,
    ratePerCaret: null,
    stockPrice: null,
    piece: null,
    employeeName: [],
    date: dayjs(),
    inwardEntryId: null
  });
  const [inwardEntryData, setInwardEntryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inwardVendorData, setInwardVendorData] = useState([]);
  const [inwardEmployeeData, setInwardEmployeeData] = useState([]);

  useEffect(() => {
    inwardId && dispatch(getSingleDiamondInward({ inwardId }));
  }, [dispatch, inwardId, apiCall]);

  useEffect(() => {
    if (isSingleInwardSuccess) {
      setInwardEntryData(singleInwardData.data.data);
    }
  }, [isSingleInwardSuccess, singleInwardData]);
  useEffect(() => {
    // Calculate the price when there's a change in stockWeight or ratePerCaret
    if (updateValue.stockWeight !== null && updateValue.ratePerCaret !== null) {
      const calculatedPrice = Math.round(updateValue.stockWeight * updateValue.ratePerCaret);
      setUpdateValue((prevAddStock) => ({
        ...prevAddStock,
        stockPrice: calculatedPrice
      }));
    } else {
      // If stockWeight or ratePerCaret is null, set price to null
      setUpdateValue((prevAddStock) => ({
        ...prevAddStock,
        stockPrice: null
      }));
    }
  }, [updateValue.stockWeight, updateValue.ratePerCaret]);
  useEffect(() => {
    if (isVendorSuccess) {
      setInwardVendorData(vendorData.data.data);
    }
    if (isEmployeeSuccess) {
      setInwardEmployeeData(employeeData.data.data);
    }
  }, [isVendorSuccess, vendorData, isEmployeeSuccess, employeeData]);
  useEffect(() => {
    if (singleInwardData) {
      getSingleInwardEntryData(singleInwardData.data.data, inwardId).then((inwardEntry) => {
        inwardEntry &&
          setUpdateValue({
            orderDiamondType: inwardEntry.order_diamond_type,
            orderType: inwardEntry.order_type,
            vendorId: inwardEntry.vendor_id,
            vendorName:
              getVendorName(inwardEntry.vendor_id) === undefined
                ? 'Vendor Deleted'
                : getVendorName(inwardEntry.vendor_id).account_name,
            diamondType: inwardEntry.diamond_type,
            sieve: inwardEntry.sieve ? inwardEntry.sieve : '',
            stockWeight: inwardEntry.weight,
            ratePerCaret: inwardEntry.rate_per_caret ? inwardEntry.rate_per_caret : '',
            stockPrice: inwardEntry.price ? inwardEntry.price : '',
            piece: inwardEntry.piece ? inwardEntry.piece : '',
            employeeName:
              inwardEntry.connected_employee.length > 0
                ? inwardEntry.connected_employee.split(',')
                : [],
            date: dayjs(inwardEntry.date),
            inwardEntryId: inwardEntry.id
          });
      });
    }
    // eslint-disable-next-line
  }, [inwardId, singleInwardData, inwardEntryData, open]);

  function getVendorName(vendorId) {
    const partyName = inwardVendorData.find((partyName) => {
      return partyName.id === vendorId;
    });
    return partyName;
  }
  const handleVendorSelect = async (e) => {
    const vendorId = await convertVendorId(inwardVendorData, e.target.value);
    setUpdateValue({ ...updateValue, vendorId: vendorId.id, vendorName: e.target.value });
  };
  const handleDiamondTypeSelect = async (e) => {
    setUpdateValue({ ...updateValue, diamondType: e.target.value });
  };

  async function handleUpdate() {
    try {
      if (
        updateValue.sieve &&
        updateValue.sieve.length > 0 &&
        updateValue.orderDiamondType &&
        updateValue.orderDiamondType.length > 0 &&
        updateValue.orderType &&
        updateValue.orderType.length > 0 &&
        updateValue.diamondType &&
        updateValue.diamondType.length > 0 &&
        updateValue.stockWeight &&
        updateValue.stockWeight !== null &&
        updateValue.vendorId &&
        updateValue.vendorId !== null
      ) {
        const updateInwardDetails = { ...updateValue };
        const propertiesToConvert = ['stockWeight', 'ratePerCaret', 'stockPrice', 'piece'];
        propertiesToConvert.forEach((property) => {
          if (updateValue.hasOwnProperty(property)) {
            if (property !== 'stockWeight') {
              updateValue[property] = parseInt(updateValue[property]);
            } else {
              updateValue['stockWeight'] = parseFloat(
                parseFloat(parseFloat(updateValue['stockWeight']).toFixed(3))
              );
            }
          }
        });
        Object.keys(updateInwardDetails).forEach((key) => {
          if (updateInwardDetails[key] === '' || updateInwardDetails[key] === null) {
            delete updateInwardDetails[key];
          }
          if (key === 'employeeName') {
            updateInwardDetails[key] = updateInwardDetails[key].join(',');
          }
        });
        const responseValue = await dispatch(
          updateInwardEntryData({ inwardId, updateInwardDetails })
        );

        if (!responseValue.hasOwnProperty('error')) {
          onClose();
          handleClickSuccess('Stock Updated successfully!!');
          handleApiCall();
          setLoading(false);
        } else {
          throw responseValue;
        }
      } else {
        handleClickError();
        setError({ payload: 'Please Fill Mandatory Data!' });
        setLoading(false);
      }
    } catch (e) {
      handleClickError();
      setError(e);
      setLoading(false);
    }
  }
  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    setUpdateValue({
      ...updateValue,
      employeeName: typeof value === 'string' ? value.split(',') : value
    });
  };
  function getStyles(name, employeeName, theme) {
    return {
      fontWeight:
        employeeName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium
    };
  }
  const DiamondType = [
    {
      name: 'REAL'
    },
    {
      name: 'CVD'
    }
  ];
  return (
    <Box>
      <Dialog open={open} onClose={onClose} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>Update Stock Entry</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: '10px' }}>
            You can Update Your Inward Entry Details From Here!{' '}
          </DialogContentText>

          <Grid container spacing={3} sx={{ mb: '24px' }}>
            <Grid item xs={12} md={12}>
              <StyledCard elevation={6}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <InputLabel sx={{ fontSize: '10px' }} id='vendorName'>
                      Vendor Name
                    </InputLabel>

                    <Select
                      size='small'
                      sx={{ width: '50%', my: 1 }}
                      variant='standard'
                      labelId='vendorName'
                      value={updateValue.vendorName}
                      onChange={handleVendorSelect}
                    >
                      {inwardVendorData.map((data, index) => (
                        <MenuItem value={data.account_name} key={index} id={index}>
                          {data.account_name}
                        </MenuItem>
                      ))}
                    </Select>

                    <ContentBox>
                      <TextField
                        id='stockWeight'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Current Weight'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '50%' }}
                        variant='standard'
                        value={updateValue.stockWeight}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, stockWeight: e.target.value });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='stockPrice'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Stock Price'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '50%' }}
                        variant='standard'
                        value={updateValue.stockPrice}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, stockPrice: e.target.value });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='sieve'
                        type='text'
                        margin='dense'
                        size='small'
                        label='Sieve'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '50%' }}
                        variant='standard'
                        value={updateValue.sieve}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, sieve: e.target.value });
                        }}
                      />
                    </ContentBox>

                    <InputLabel sx={{ fontSize: '10px' }} id='demo-multiple-employee-label'>
                      Employee
                    </InputLabel>

                    <Select
                      labelId='demo-multiple-employee-label'
                      id='demo-multiple-employee'
                      multiple
                      value={updateValue.employeeName}
                      variant='standard'
                      InputLabelProps={{ shrink: true }}
                      onChange={handleChange}
                      sx={{ my: 1, width: '90%' }}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {inwardEmployeeData.map((employee, index) => (
                        <MenuItem
                          value={employee.name}
                          style={getStyles(employee.name, updateValue.employeeName, theme)}
                          key={index}
                          id={index}
                        >
                          {employee.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <InputLabel sx={{ fontSize: '10px' }} id='diamondType'>
                      Diamond Type
                    </InputLabel>
                    <Select
                      size='small'
                      sx={{ width: '50%', my: 1 }}
                      variant='standard'
                      labelId='diamondType'
                      value={updateValue.diamondType}
                      onChange={handleDiamondTypeSelect}
                    >
                      {DiamondType.map((data, index) => (
                        <MenuItem value={data.name} key={index} id={index}>
                          {data.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <ContentBox>
                      <TextField
                        id='caratPrice'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Carat Price'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '50%' }}
                        variant='standard'
                        value={updateValue.ratePerCaret}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, ratePerCaret: e.target.value });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='stockPcs'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Diamond Count'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '50%' }}
                        variant='standard'
                        value={updateValue.piece}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, piece: e.target.value });
                        }}
                      />
                    </ContentBox>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{ width: '50%', my: 1 }}
                        label='Date'
                        format='DD/MM/YYYY'
                        value={updateValue.date}
                        onChange={(newValue) => setUpdateValue({ ...updateValue, date: newValue })}
                        slotProps={{ textField: { variant: 'standard' } }}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </StyledCard>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='standard' color='secondary' onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleUpdate}
            color='primary'
            loading={loading}
            variant='contained'
            sx={{ my: 2 }}
          >
            Update Inward Entry
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
