export function findInvoiceById(invoiceArray, invoiceId) {
    // Using Array.find to find the first element that matches the condition
    const foundInvoice = invoiceArray.find(invoice => Number(invoice.id) === Number(invoiceId));

    // Return the found invoice or null if not found
    return foundInvoice || null;
}
export function calculateTotals(data) {
    // Initialize totals object
    let totals = {
        totalAmount: 0,
        studdWeight: 0,
        studdPiece: 0,
        labourCharge: 0,
        extra_cost: 0,
        product_price: 0,
        diamondPrice: 0,
        studdSelfWeight: 0
    };

    // Iterate over the data array
    data && data.orders.forEach(entry => {
        totals.totalAmount += Number(entry.totalAmount) || 0;
        totals.studdWeight += parseFloat(entry.studdWeight) || 0;
        totals.studdPiece += Number(entry.studdPiece) || 0;
        totals.labourCharge += Number(entry.labourCharge) || 0;
        totals.extra_cost += Number(entry.extra_cost) || 0;
        totals.product_price += Number(entry.product_price) || 0;
        totals.diamondPrice += Number(entry.diamondPrice) || 0;
        totals.studdSelfWeight += parseFloat(entry.studdSelfWeight) || 0;
    });

    // Convert studdWeight and studdSelfWeight to fixed(3)
    totals.studdWeight = parseFloat(totals.studdWeight.toFixed(3));
    totals.studdSelfWeight = parseFloat(totals.studdSelfWeight.toFixed(3));

    return totals;
}
