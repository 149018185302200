import { getHeaders } from 'app/utils/handleHeader';
import axios from 'axios';
const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');
const initialState = {
    isInitialised: true,
    isLoading: false,
    isError: false,
    isExpenseEntryGenrateSuccess: false,
    isExpenseEntryDeleteSuccess: false,
    isExpenseEntryUpdateSuccess: false,
    isAllExpenseEntriesSuccess: false,
    allExpenseEntriesData: null,
};
// --------------------------------------Create Expense Entry --------------------------------
export const addExpenseEntry = createAsyncThunk('addExpenseEntry', async (expenseEntryDetails, { rejectWithValue }) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/transaction/createexpenseentry`,
            expenseEntryDetails,
            {
                headers: getHeaders()
            }
        );
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Get All Expense Entries--------------------------------
export const getAllExpenseEntriesData = createAsyncThunk('getAllExpenseEntriesData', async (object, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transaction/getexpenseentries`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Delete Expense Entry--------------------------------
export const deleteExpenseEntry = createAsyncThunk('deleteExpenseEntry', async ({ entryId }, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/transaction/deleteexpenseentry/${entryId}`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Update Expense Entry--------------------------------
export const updateExpenseEntry = createAsyncThunk('updateExpenseEntry', async ({ entryId, expenseEntryDetails }, { rejectWithValue }) => {
    try {
        const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/transaction/updateexpenseentry/${entryId}`, expenseEntryDetails, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
const expenseEntrySlice = createSlice({
    name: 'expenseEntrySlice',
    initialState,
    reducers: {},
    extraReducers: {
        //--------------Slice action for the Create Customer Receipt
        [addExpenseEntry.pending]: (state, action) => {
            state.isLoading = true;
            state.isExpenseEntryGenrateSuccess = false;
            state.isError = false;
        },
        [addExpenseEntry.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isExpenseEntryGenrateSuccess = true;
            state.isError = false;
        },
        [addExpenseEntry.rejected]: (state, action) => {
            state.isLoading = false;
            state.isExpenseEntryGenrateSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Customer Receipt's Data --------------------------------
        [getAllExpenseEntriesData.pending]: (state, action) => {
            state.pending = false;
            state.isAllExpenseEntriesSuccess = false;
            state.isError = false;
        },
        [getAllExpenseEntriesData.fulfilled]: (state, action) => {
            state.pending = false;
            state.isAllExpenseEntriesSuccess = true;
            state.allExpenseEntriesData = action.payload;
            state.isError = false;
        },
        [getAllExpenseEntriesData.rejected]: (state, action) => {
            state.pending = false;
            state.isAllExpenseEntriesSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Customer's Receipt Data --------------------------------
        [deleteExpenseEntry.pending]: (state, action) => {
            state.pending = false;
            state.isExpenseEntryDeleteSuccess = false;
            state.isError = false;
        },
        [deleteExpenseEntry.fulfilled]: (state, action) => {
            state.pending = false;
            state.isExpenseEntryDeleteSuccess = true;
            state.isError = false;
        },
        [deleteExpenseEntry.rejected]: (state, action) => {
            state.pending = false;
            state.isExpenseEntryDeleteSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Specific Receipt Data --------------------------------
        [updateExpenseEntry.pending]: (state, action) => {
            state.pending = false;
            state.isExpenseEntryUpdateSuccess = false;
            state.isError = false;
        },
        [updateExpenseEntry.fulfilled]: (state, action) => {
            state.pending = false;
            state.isExpenseEntryUpdateSuccess = true;
            state.isError = false;
        },
        [updateExpenseEntry.rejected]: (state, action) => {
            state.pending = false;
            state.isExpenseEntryUpdateSuccess = false;
            state.isError = true;
        }
    }
});
export default expenseEntrySlice.reducer;
