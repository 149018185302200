import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Card,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  styled,
  TextField
} from '@mui/material';
import React from 'react';
import { H4 } from 'app/components/Typography';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { addExpenseEntry } from 'app/contexts/reducers/transaction/expenseEntrySlice';
import { getExpenseTypeData } from 'app/contexts/reducers/expenseType/expenseTypeSlice';
import { useEffect } from 'react';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' }
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main }
}));

const CreateExpenseEntry = ({ handleApiCall }) => {
  const dispatch = useDispatch();
  const { isExpenseTypeSuccess, expenseTypeData } = useSelector((state) => state.expenseType);

  const intialData = {
    expenseType: '',
    amount: '',
    paymentType: '',
    bookNumber: dayjs().year(),
    date: dayjs(),
    remark: ''
  };

  const [expenseType, setExpenseType] = useState([]);

  const [createExpenseEntry, setCreateExpenseEntry] = useState(intialData);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [dataError, setDataError] = useState({
    expenseTypeError: false,
    amountError: false,
    paymentTypeError: false,
    bookNumberError: false,
    dateError: false
  });
  useEffect(() => {
    dispatch(getExpenseTypeData());
  }, [dispatch]);
  useEffect(() => {
    if (isExpenseTypeSuccess) {
      if (Number(expenseTypeData.data.code) === 200) {
        setExpenseType(expenseTypeData.data.data);
      } else {
        setExpenseType([]);
      }
    }
  }, [isExpenseTypeSuccess, expenseTypeData]);
  function handleClickError() {
    setSuccessOpen(false);
    setErrorOpen(true);
  }
  function handleClickSuccess(message) {
    setErrorOpen(false);
    setSuccessMessage(message);
    setSuccessOpen(true);
  }
  function handleSnakeBarClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
    setErrorOpen(false);
  }

  const handleExpenseTypeSelect = async (e) => {
    setCreateExpenseEntry({ ...createExpenseEntry, expenseType: e.target.value });
    setDataError({ ...dataError, expenseTypeError: false });
  };
  const handleBookNameSelect = async (e) => {
    setCreateExpenseEntry({ ...createExpenseEntry, bookNumber: e.target.value });
    setDataError({ ...dataError, bookNameError: false });
  };
  const getFiveYearsBack = () => {
    const currentYear = new Date().getFullYear();
    const fiveYearsBackArray = [];

    for (let i = 0; i < 3; i++) {
      const year = currentYear - i;
      fiveYearsBackArray.push(String(year));
    }

    return fiveYearsBackArray;
  };
  const handlePaymentTypeSelect = async (e) => {
    setCreateExpenseEntry({ ...createExpenseEntry, paymentType: e.target.value });
    setDataError({ ...dataError, paymentTypeError: false });
  };
  const handleSubmit = async () => {
    try {
      if (
        createExpenseEntry.expenseType &&
        createExpenseEntry.expenseType.length > 0 &&
        createExpenseEntry.amount &&
        Number(createExpenseEntry.amount) > 0 &&
        createExpenseEntry.paymentType &&
        createExpenseEntry.paymentType.length > 0 &&
        createExpenseEntry.bookNumber &&
        Number(createExpenseEntry.bookNumber) > 0 &&
        createExpenseEntry.date
      ) {
        const expenseEntryDetails = { ...createExpenseEntry };
        Object.keys(expenseEntryDetails).forEach((key) => {
          if (expenseEntryDetails[key] === '') {
            delete expenseEntryDetails[key];
          }
          // if (key === 'date') {
          //   createExpenseEntry[key] = dayjs(createExpenseEntry[key]).format('YYYY-MM-DD');
          // }
        });

        const responseValue = await dispatch(addExpenseEntry(expenseEntryDetails));
        if (!responseValue.hasOwnProperty('error')) {
          handleClickSuccess('Expense-Entry Created successfully!!');
          handleApiCall();
          setCreateExpenseEntry(intialData);
          setLoading(false);
        } else {
          throw responseValue;
        }
      } else {
        const validationRules = [
          {
            property: 'expenseType',
            condition: createExpenseEntry.expenseType.length <= 0,
            errorKey: 'expenseTypeError'
          },
          {
            property: 'amount',
            condition: Number(createExpenseEntry.amount) <= 0,
            errorKey: 'amountError'
          },
          {
            property: 'bookNumber',
            condition: Number(createExpenseEntry.bookNumber) <= 0,
            errorKey: 'bookNumberError'
          },
          {
            property: 'paymentType',
            condition: createExpenseEntry.paymentType.length <= 0,
            errorKey: 'paymentTypeError'
          }
        ];

        const updatedErrors = validationRules.reduce(
          (errors, rule) => {
            if (rule.condition) {
              errors[rule.errorKey] = true;
            }
            return errors;
          },
          { ...dataError }
        );
        setDataError(updatedErrors);
        handleClickError();
        setError({ payload: 'Please Fill Mandatory Data!' });
        setLoading(false);
      }
    } catch (error) {
      handleClickError();
      setError(error);
      setLoading(false);
    }
  };

  const PaymentType = [
    {
      name: 'CASH'
    }
    // {
    //   name: 'BANK'
    // }
  ];
  return (
    <React.Fragment>
      {' '}
      <Snackbar open={successOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='error'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {error?.payload}
        </Alert>
      </Snackbar>
      <Grid container spacing={3} sx={{ mb: '24px' }}>
        <Grid item xs={12} md={12}>
          <StyledCard elevation={6}>
            <H4 sx={{ mb: 2 }}>CREATE EXPENSE ENTRY</H4>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <InputLabel
                  sx={{ fontSize: '10px' }}
                  error={dataError.expenseTypeError}
                  id='expenseType'
                >
                  Expense Type
                </InputLabel>
                <Select
                  size='small'
                  sx={{ my: 1, width: '50%' }}
                  variant='standard'
                  labelId='expenseType'
                  value={createExpenseEntry.expenseType}
                  onChange={handleExpenseTypeSelect}
                  error={dataError.expenseTypeError}
                >
                  {expenseType &&
                    expenseType.map((data, index) => (
                      <MenuItem value={data.expense_type} key={index} id={index}>
                        {data.expense_type}
                      </MenuItem>
                    ))}
                </Select>
                <ContentBox>
                  <TextField
                    id='amount'
                    type='number'
                    margin='dense'
                    size='small'
                    label='Amount'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={createExpenseEntry.amount == null ? 'null' : createExpenseEntry.amount}
                    onChange={(e) => {
                      setCreateExpenseEntry({ ...createExpenseEntry, amount: e.target.value });
                      setDataError({ ...dataError, amountError: false });
                    }}
                    error={dataError.amountError}
                    helperText={dataError.amountError ? 'Please Enter Amount' : null}
                  />
                </ContentBox>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: '50%', my: 1 }}
                    label='Date'
                    format='DD/MM/YYYY'
                    value={createExpenseEntry.date}
                    onChange={(newValue) => {
                      setCreateExpenseEntry({ ...createExpenseEntry, date: newValue });
                      setDataError({ ...dataError, dateError: false });
                    }}
                    slotProps={{
                      textField: {
                        variant: 'standard',
                        error: dataError.dateError,
                        helperText: dataError.dateError ? 'Please Select Date' : null
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <InputLabel
                  sx={{ fontSize: '10px' }}
                  error={dataError.bookNumberError}
                  required
                  id='bookNumber'
                >
                  Book Number
                </InputLabel>

                <Select
                  size='small'
                  sx={{ width: '50%', my: 1 }}
                  variant='standard'
                  labelId='customerReceiptBookNumber'
                  value={createExpenseEntry.bookNumber}
                  onChange={handleBookNameSelect}
                >
                  {getFiveYearsBack().map((data, index) => (
                    <MenuItem value={data} key={index} id={index}>
                      {data}
                    </MenuItem>
                  ))}
                </Select>
                <InputLabel
                  sx={{ fontSize: '10px' }}
                  error={dataError.paymentTypeError}
                  id='paymentType'
                >
                  Payment Type
                </InputLabel>
                <Select
                  size='small'
                  sx={{ my: 1, width: '50%' }}
                  variant='standard'
                  labelId='diamondType'
                  value={createExpenseEntry.paymentType}
                  onChange={handlePaymentTypeSelect}
                  error={dataError.paymentTypeError}
                >
                  {PaymentType.map((data, index) => (
                    <MenuItem value={data.name} key={index} id={index}>
                      {data.name}
                    </MenuItem>
                  ))}
                </Select>

                <ContentBox>
                  <TextField
                    multiline
                    maxRows={4}
                    id='remark'
                    type='text'
                    margin='dense'
                    size='small'
                    label='Remark'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={createExpenseEntry.remark}
                    onChange={(e) => {
                      setCreateExpenseEntry({ ...createExpenseEntry, remark: e.target.value });
                    }}
                  />
                </ContentBox>
              </Grid>
            </Grid>
            <LoadingButton
              onClick={handleSubmit}
              color='primary'
              loading={loading}
              variant='contained'
              sx={{ my: 2, textTransform: 'capitalize' }}
            >
              Create Expense Entry
            </LoadingButton>
          </StyledCard>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CreateExpenseEntry;
