import {
  Alert,
  Box,
  Card,
  Grid,
  Icon,
  IconButton,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { H5 } from 'app/components/Typography';
import { getTotalVendorDiamondInward } from 'app/contexts/reducers/transaction/diamondInwardSlice';
import AddDiamondOutward from './AddDiamondOutward';
import { getDiamondOutward } from 'app/contexts/reducers/transaction/diamondOutwardSlice';
import UpdateDiamondOutward from './UpdateDiamondOutward';

const CardHeader = styled(Box)(() => ({
  display: 'flex',
  paddingLeft: '24px',
  paddingRight: '24px',
  marginBottom: '12px',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)'
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' }
}));

const DiamondInwardTable = ({ apiCall, handleApiCall, orderId, customerId, orderStatus }) => {
  const dispatch = useDispatch();
  const { isVendorSuccess, vendorData } = useSelector((state) => state.account);
  const { isVendorTotalInwardSuccess, vendorTotalInwardData } = useSelector(
    (state) => state.inwardDetails
  );
  const { isOutwardSuccess, outwardData } = useSelector((state) => state.outwardDetails);
  const [totalVendorInwardData, setTotalVendorInwardData] = useState([]);
  const [isEntryChange, setIsEntryChange] = useState(false);
  const [orderOutwardData, setOrderOutwardData] = useState([]);
  const [partyData, setPartyData] = useState([]);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [updateOutwardData, setUpdatOutwardData] = useState(null);
  const [vendorId, setVendorId] = useState(null);
  const [errorOpen, setErrorOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [error, setError] = useState();
  const [outwardOpen, setOutwardOpen] = useState(false);
  const [outwardVendorName, setOutwardVendorName] = useState(null);
  const [outwardTotalPcs, setOutwardTotalPcs] = useState(null);
  const [outwardTotalWeight, setOutwardTotalWeight] = useState(null);
  const [outwardDiamondType, setOutwardDiamondType] = useState(null);
  const [inwardDiamondType, setInwardDiamondType] = useState(null);
  const [inwardOrderType, setInwardOrderType] = useState(null);
  // const [inwardType, setInwardType] = useState('');
  const [isDataFound, setIsDataFound] = useState(false);
  useEffect(() => {
    dispatch(getTotalVendorDiamondInward({ orderId }));
  }, [dispatch, orderId, apiCall]);
  useEffect(() => {
    dispatch(getDiamondOutward({ orderId }));
  }, [dispatch, orderId, isEntryChange, apiCall]);
  useEffect(() => {
    if (isOutwardSuccess) {
      setOrderOutwardData(outwardData.data.data);
    }
  }, [isOutwardSuccess, outwardData]);
  const isEntryFound = (arr, vendorId, diamondType, inwardType) => {
    if (arr?.length > 0) {
      if (inwardType.toLocaleLowerCase() === 'customer') {
        return arr.some(
          (entry) => entry.diamond_type === diamondType && entry.order_diamond_type === 'CUSTOMER'
        );
      } else if (inwardType.toLocaleLowerCase() === 'self') {
        return arr.some(
          (entry) => entry.vendor_id === vendorId && entry.diamond_type === diamondType
        );
      } else if (inwardType.toLocaleLowerCase() === 'bulk') {
        return arr.some(
          (entry) => entry.diamond_type === diamondType && entry.order_diamond_type === 'BULK'
        );
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (isVendorTotalInwardSuccess) {
      if (vendorTotalInwardData.data.code === 200) {
        setIsDataFound(true);
        setTotalVendorInwardData(vendorTotalInwardData.data.data);
      } else {
        setIsDataFound(false);
        setTotalVendorInwardData([]);
      }
    }
  }, [isVendorTotalInwardSuccess, vendorTotalInwardData]);
  useEffect(() => {
    if (isVendorSuccess) {
      setPartyData(vendorData.data.data);
    }
  }, [isVendorSuccess, vendorData]);

  function handleOutwardClose() {
    setOutwardOpen(false);
  }
  function handleUpdateOutwardClose() {
    setUpdateOpen(false);
  }
  function handleIsEntryCall() {
    setIsEntryChange((prev) => !prev);
  }
  function handleClickSuccess(message) {
    setErrorOpen(false);
    setSuccessMessage(message);
    setSuccessOpen(true);
  }
  function handleClickError() {
    setSuccessOpen(false);
    setErrorOpen(true);
  }
  function handleSnakeBarClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
    setErrorOpen(false);
  }
  function getVendorName(vendorId) {
    const partyName = partyData.find((partyName) => {
      return partyName.id === vendorId;
    });
    return partyName;
  }

  const findObjectByVendorAndDiamond = async (
    arr,
    vendor_id,
    customer_id,
    diamond_type,
    order_diamond_type
  ) => {
    let foundedObject;
    if (order_diamond_type.toLowerCase() === 'customer') {
      foundedObject = arr.find(
        (obj) =>
          obj.diamond_type === diamond_type && obj.order_diamond_type.toLowerCase() === 'customer'
      );
    } else if (order_diamond_type.toLowerCase() === 'bulk') {
      foundedObject = arr.find(
        (obj) =>
          obj.diamond_type === diamond_type &&
          obj.order_diamond_type.toLowerCase() === 'bulk' &&
          Number(obj.customer_id) === Number(customer_id)
      );
    } else {
      foundedObject = arr.find(
        (obj) => obj.vendor_id === vendor_id && obj.diamond_type === diamond_type
      );
    }

    return foundedObject ? foundedObject : null;
  };
  return (
    <React.Fragment>
      <Card elevation={3} sx={{ pt: '20px', mb: 3 }}>
        <CardHeader>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <H5 sx={{ fontWeight: 'bold' }}>Inward Total Data</H5>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>{/* <SearchAccount setOrderData={setOrderData} /> */}</Grid>
                <Grid item>{/* <FilterOrderByMonth setOrderdata={setOrderData} /> */}</Grid>
                <Grid item>{/* <FilterStatus setOrderdata={setOrderData} /> */}</Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardHeader>

        <Box overflow='auto'>
          <ProductTable>
            <TableHead sx={{ backgroundColor: 'rgb(34,41,67)' }}>
              <TableRow>
                <TableCell
                  colSpan={2}
                  align='center'
                  sx={{ px: 2, fontWeight: 'bold', color: '#FFFFFF' }}
                >
                  Index
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  Vendor Name
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  Diamond Type
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  Total Piece
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  Total Weight
                </TableCell>

                {orderOutwardData && orderOutwardData.length > 0 && (
                  <TableCell
                    align='center'
                    sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                    colSpan={2}
                  >
                    Return Piece
                  </TableCell>
                )}

                {orderOutwardData && orderOutwardData.length > 0 && (
                  <TableCell
                    colSpan={2}
                    align='center'
                    sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                  >
                    Return Weight
                  </TableCell>
                )}

                {Number(orderStatus) === 0 ||
                  (Number(orderStatus) === 1 && (
                    <TableCell
                      align='center'
                      sx={{ px: 5, fontWeight: 'bold', color: '#FFFFFF' }}
                      colSpan={3}
                    >
                      Action
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isDataFound ? (
                totalVendorInwardData.map((inwardDetails, index) => (
                  <TableRow key={index} hover>
                    <TableCell
                      align='center'
                      colSpan={2}
                      sx={{ px: 2, fontWeight: 'bold', textTransform: 'capitalize' }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      align='center'
                      colSpan={2}
                      sx={{ px: 0, textTransform: 'capitalize', fontWeight: 'bold' }}
                    >
                      {inwardDetails.order_diamond_type.toLocaleLowerCase() === 'self'
                        ? getVendorName(inwardDetails.vendor_id) === undefined
                          ? 'Vendor Deleted'
                          : getVendorName(inwardDetails.vendor_id).account_name
                        : 'CUSTOMER'}
                    </TableCell>
                    <TableCell
                      align='center'
                      sx={{ px: 3, textTransform: 'capitalize' }}
                      colSpan={2}
                    >
                      {inwardDetails.diamond_type}
                    </TableCell>
                    <TableCell align='center' sx={{ px: 2 }} colSpan={2}>
                      {inwardDetails.piece}
                    </TableCell>
                    <TableCell align='center' sx={{ px: 2 }} colSpan={2}>
                      {parseFloat(inwardDetails.weight).toFixed(3)}
                    </TableCell>
                    {orderOutwardData && orderOutwardData.length > 0 && (
                      <TableCell colSpan={2} align='center' sx={{ px: 0 }}>
                        {orderOutwardData.some((data) => {
                          return data.order_diamond_type !== 'SELF'
                            ? Number(data.customer_id) === Number(inwardDetails.customer_id)
                            : Number(data.vendor_id) === Number(inwardDetails.vendor_id);
                        })
                          ? orderOutwardData.find((data) => {
                              return data.order_diamond_type !== 'SELF'
                                ? Number(data.customer_id) === Number(inwardDetails.customer_id)
                                : Number(data.vendor_id) === Number(inwardDetails.vendor_id);
                            }).return_pieces
                          : 'NA'}
                      </TableCell>
                    )}

                    {orderOutwardData && orderOutwardData.length > 0 ? (
                      <TableCell colSpan={2} align='center' sx={{ px: 0 }}>
                        {orderOutwardData.some((data) => {
                          return data.order_diamond_type !== 'SELF'
                            ? Number(data.customer_id) === Number(inwardDetails.customer_id)
                            : Number(data.vendor_id) === Number(inwardDetails.vendor_id);
                        })
                          ? orderOutwardData.find((data) => {
                              return data.order_diamond_type !== 'SELF'
                                ? Number(data.customer_id) === Number(inwardDetails.customer_id)
                                : Number(data.vendor_id) === Number(inwardDetails.vendor_id);
                            }).return_weight
                          : 'NA'}
                      </TableCell>
                    ) : null}

                    {Number(orderStatus) === 0 ||
                      (Number(orderStatus) === 1 && (
                        <TableCell align='center' sx={{ px: 2 }} colSpan={3}>
                          <Tooltip title='Edit'>
                            <IconButton
                              color='primary'
                              disabled={
                                !isEntryFound(
                                  orderOutwardData,
                                  inwardDetails.vendor_id,
                                  inwardDetails.diamond_type,
                                  inwardDetails.order_diamond_type
                                )
                              }
                              onClick={async () => {
                                const outwardData = await findObjectByVendorAndDiamond(
                                  orderOutwardData,
                                  inwardDetails.vendor_id,
                                  inwardDetails.customer_id,
                                  inwardDetails.diamond_type,
                                  inwardDetails.order_diamond_type
                                );

                                setUpdatOutwardData(outwardData);
                                setUpdateOpen(true);
                              }}
                            >
                              <Icon>edit</Icon>
                            </IconButton>
                          </Tooltip>
                          <Tooltip title='View'>
                            <IconButton
                              color='primary'
                              disabled={isEntryFound(
                                orderOutwardData,
                                inwardDetails.vendor_id,
                                inwardDetails.diamond_type,
                                inwardDetails.order_diamond_type
                              )}
                              onClick={() => {
                                setVendorId(inwardDetails.vendor_id);
                                setOutwardOpen(true);
                                setOutwardDiamondType(inwardDetails.diamond_type);
                                setInwardDiamondType(inwardDetails.order_diamond_type);
                                setInwardOrderType(inwardDetails.order_type);
                                setOutwardTotalPcs(inwardDetails.piece);
                                setOutwardTotalWeight(inwardDetails.weight);
                                setOutwardVendorName(
                                  inwardDetails.order_diamond_type.toLocaleLowerCase() === 'self'
                                    ? getVendorName(inwardDetails.vendor_id) === undefined
                                      ? 'Vendor Deleted'
                                      : getVendorName(inwardDetails.vendor_id).account_name
                                    : 'CUSTOMER'
                                );
                              }}
                            >
                              <Icon>keyboard_arrow_right</Icon>
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} sx={{ textAlign: 'center' }}>
                    No data found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </ProductTable>
        </Box>
      </Card>
      {/* Alert */}
      <Snackbar open={successOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='error'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {error?.payload}
        </Alert>
      </Snackbar>
      {/* Add Diamond Outward Model */}
      <AddDiamondOutward
        open={outwardOpen}
        onClose={handleOutwardClose}
        handleIsEntryCall={handleIsEntryCall}
        orderId={orderId}
        vendorId={vendorId}
        customerId={customerId}
        handleClickSuccess={handleClickSuccess}
        setError={setError}
        handleClickError={handleClickError}
        outwardDiamondType={outwardDiamondType}
        inwardDiamondType={inwardDiamondType}
        inwardOrderType={inwardOrderType}
        outwardTotalPcs={outwardTotalPcs}
        outwardTotalWeight={outwardTotalWeight}
        outwardVendorName={outwardVendorName}
      />
      {/* Add Diamond Outward Model */}
      {/* Update Diamond Outward Model */}

      {updateOutwardData && (
        <UpdateDiamondOutward
          open={updateOpen}
          onClose={handleUpdateOutwardClose}
          apiCall={apiCall}
          updateOutwardData={updateOutwardData}
          handleClickSuccess={handleClickSuccess}
          setError={setError}
          handleClickError={handleClickError}
        />
      )}
      {/* Update Diamond Outward Model */}
    </React.Fragment>
  );
};

export default DiamondInwardTable;
