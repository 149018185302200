import {
  Alert,
  Box,
  Card,
  Grid,
  Icon,
  IconButton,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import { H6 } from 'app/components/Typography';
import { getSpecificStockEntry } from 'app/contexts/reducers/stockMaster/stockMasterSlice';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import DeleteStockEntry from './DeleteStockEntry';
import { Fragment } from 'react';
import UpdateStockEntryDialog from './UpdateStockEntry';
import FilterSpecificStock from './FilterSpecificStock';

const CardHeader = styled(Box)(() => ({
  display: 'flex',
  paddingLeft: '24px',
  paddingRight: '24px',
  marginBottom: '12px',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  textTransform: 'capitalize'
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)'
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' }
}));

const VendorStockEntry = ({ setApiCall, apiCall }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isSingleStockEntrySuccess, singleStockEntryData } = useSelector((state) => state.stock);
  const queryParams = new URLSearchParams(location.search);
  const { stockId, status } = Object.fromEntries(queryParams.entries());
  const [vendorStockData, setVendorStockData] = useState([]);
  const [deleteStockEntryId, setDeleteStockEntryId] = useState(null);
  const [updateStockEntryId, setUpdateStockEntryId] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [error, setError] = useState();
  useEffect(() => {
    dispatch(getSpecificStockEntry({ stockId }));
  }, [dispatch, apiCall, stockId]);
  useEffect(() => {
    if (isSingleStockEntrySuccess) {
      setVendorStockData(singleStockEntryData.data.data);
    }
  }, [isSingleStockEntrySuccess, singleStockEntryData]);
  function handleDeleteClose() {
    setDeleteOpen(false);
  }
  function handleUpdateClose() {
    setUpdateOpen(false);
  }
  function handleSnakeBarClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
    setErrorOpen(false);
  }
  function handleClickSuccess(message) {
    setErrorOpen(false);
    setSuccessMessage(message);
    setSuccessOpen(true);
  }
  function handleClickError() {
    setSuccessOpen(false);
  }
  async function handleDownloadImage(billImageUrl) {
    const fileName = billImageUrl.split('/').pop();
    const response = await fetch(billImageUrl);

    const blobImage = await response.blob();

    const href = URL.createObjectURL(blobImage);

    const anchorElement = document.createElement('a');
    anchorElement.href = href;
    anchorElement.download = fileName;

    document.body.appendChild(anchorElement);
    anchorElement.click();

    document.body.removeChild(anchorElement);
    window.URL.revokeObjectURL(href);
  }
  return (
    <Fragment>
      <Card elevation={3} sx={{ pt: '20px', mb: 3 }}>
        <CardHeader>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <Title>Stock Master</Title>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <FilterSpecificStock setVendorStockData={setVendorStockData} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardHeader>

        <Box overflow='auto'>
          <ProductTable>
            <TableHead sx={{ backgroundColor: 'rgb(34,41,67)' }}>
              <TableRow>
                <TableCell sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}>Index</TableCell>
                <TableCell sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>Piece</TableCell>
                <TableCell sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>Weight</TableCell>
                <TableCell sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Rate Per Caret
                </TableCell>
                <TableCell sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>Price</TableCell>
                <TableCell sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }} colSpan={2}>
                  Remark
                </TableCell>
                <TableCell sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>Date</TableCell>
                <TableCell sx={{ px: 2, fontWeight: 'bold', color: '#FFFFFF' }}>Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {vendorStockData && vendorStockData.length > 0 ? (
                vendorStockData.map((stockEntry, index) => (
                  <TableRow key={index} hover>
                    <TableCell sx={{ px: 0, textTransform: 'capitalize', fontWeight: 'bold' }}>
                      <H6 sx={{ px: 2 }}>{index + 1}</H6>
                    </TableCell>
                    <TableCell sx={{ px: 0, textTransform: 'capitalize', fontWeight: 'bold' }}>
                      {stockEntry.pcs}
                    </TableCell>
                    <TableCell sx={{ px: 1, textTransform: 'capitalize', fontWeight: 'bold' }}>
                      {stockEntry.current_weight}
                    </TableCell>
                    <TableCell sx={{ px: 3, textTransform: 'capitalize' }}>
                      {stockEntry.rate_per_carat}
                    </TableCell>
                    <TableCell sx={{ px: 0, textTransform: 'capitalize' }}>
                      {stockEntry.price}
                    </TableCell>
                    <TableCell sx={{ px: 0, textTransform: 'capitalize' }} colSpan={2}>
                      {stockEntry.remark}
                    </TableCell>
                    <TableCell sx={{ px: 0, textTransform: 'capitalize' }}>
                      {dayjs(stockEntry.date_of_purchase).format('DD-MM-YYYY')}
                    </TableCell>
                    <TableCell sx={{ px: 0, textTransform: 'capitalize' }}>
                      <Tooltip title='Edit'>
                        <IconButton
                          color='primary'
                          disabled={Number(status) === 1 ? true : false}
                          onClick={() => {
                            setUpdateStockEntryId(stockEntry.id);
                            setUpdateOpen(true);
                          }}
                        >
                          <Icon color='primary'>edit</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Delete'>
                        <IconButton
                          onClick={() => {
                            setDeleteStockEntryId(stockEntry.id);
                            setDeleteOpen(true);
                          }}
                        >
                          <Icon color='error'>delete</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Download'>
                        <IconButton
                          onClick={() => {
                            handleDownloadImage(stockEntry.bill_image);
                          }}
                        >
                          <Icon color='primary'>download</Icon>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={8}
                    sx={{ textAlign: 'center', color: 'rgb(34,41,67)', fontWeight: 'bold' }}
                  >
                    No Stock Data Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </ProductTable>
        </Box>
      </Card>
      {/* Alert */}
      <Snackbar open={successOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='error'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {error?.payload}
        </Alert>
      </Snackbar>
      {/* Delete-Stock */}
      <DeleteStockEntry
        open={deleteOpen}
        onClose={handleDeleteClose}
        deleteStockEntryId={deleteStockEntryId}
        setApiCall={setApiCall}
        handleClickSuccess={handleClickSuccess}
        handleClickError={handleClickError}
        setError={setError}
      />
      {/* Update-Stock */}
      <UpdateStockEntryDialog
        open={updateOpen}
        onClose={handleUpdateClose}
        updateStockEntryId={updateStockEntryId}
        setApiCall={setApiCall}
        setError={setError}
        handleClickSuccess={handleClickSuccess}
        handleClickError={handleClickError}
      />
    </Fragment>
  );
};

export default VendorStockEntry;
