import { MenuItem, Select } from '@mui/material';

import { useSelector } from 'react-redux';
import { selectAccountCustomerValue, selectAccountVendorValue } from '../../helper/selectData';
import { useState } from 'react';
import { useEffect } from 'react';

const AccountTypeSelect = ({ setAccountCustomerData }) => {
  const { accountType, customerData, vendorData } = useSelector((state) => state.account);
  const [defaultSelect, setDefaultSelect] = useState('all');
  useEffect(() => {
    setDefaultSelect('all');
  }, [accountType]);
  const handleSelect = async (event) => {
    setDefaultSelect(event.target.value);
    if (accountType.toLowerCase() === 'customer') {
      const selectedData = await selectAccountCustomerValue(
        event.target.value,
        customerData.data.data
      );
      setAccountCustomerData(selectedData);
    } else {
      const selectedData = await selectAccountVendorValue(event.target.value, vendorData.data.data);
      setAccountCustomerData(selectedData);
    }
  };
  return (
    <Select size='small' value={defaultSelect} onChange={handleSelect}>
      <MenuItem value='all' id='all'>
        Select Customer Type
      </MenuItem>

      <MenuItem value='firm' id='firm'>
        FIRM
      </MenuItem>

      <MenuItem value='retailer' id='retailer'>
        RETAILER
      </MenuItem>
    </Select>
  );
};

export default AccountTypeSelect;
