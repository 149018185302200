import { CircularProgress, Box, styled } from '@mui/material';

const StyledLoading = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& img': {
    width: 'auto',
    height: '50px'
  },
  '& .circleProgress': {
    position: 'absolute',
    left: -2,
    right: 0,
    top: 'calc(50% - 22px)',
    color: 'rgb(166,102,48)'
  }
});

const Loading = () => {
  return (
    <StyledLoading>
      <Box position='relative'>
        <img src='/assets/images/logo-circle.svg' width={100} height={100} alt='' />
        <CircularProgress className='circleProgress' />
      </Box>
    </StyledLoading>
  );
};

export default Loading;
