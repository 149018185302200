import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Card,
  Chip,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  styled,
  TextField,
  useTheme
} from '@mui/material';
import React from 'react';
import { H5 } from 'app/components/Typography';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  getEmployeeAccountData,
  getVendorAccountData
} from 'app/contexts/reducers/account/accountSlice';
import { convertVendorId } from 'app/utils/convertId';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { addDiamondInward } from 'app/contexts/reducers/transaction/diamondInwardSlice';
const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' }
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main }
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
const AddInwardDetails = ({ handleApiCall, inwardType, orderId, orderStatus }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { isVendorSuccess, vendorData, isEmployeeSuccess, employeeData } = useSelector(
    (state) => state.account
  );

  const intialData = {
    orderId: orderId,
    orderDiamondType: 'SELF',
    orderType: inwardType,
    vendorId: null,
    vendorName: '',
    diamondType: '',
    sieve: '',
    stockWeight: null,
    ratePerCaret: null,
    stockPrice: null,
    piece: null,
    employeeName: [],
    date: dayjs()
  };
  const [addInward, setAddInward] = useState(intialData);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [inwardVendorData, setInwardVendorData] = useState([]);
  const [inwardEmployeeData, setInwardEmployeeData] = useState([]);
  const [vendorNameError, setVendorNameError] = useState(false);
  const [stockWeightError, setStockWeightError] = useState(false);
  const [diamondTypeError, setDiamondTypeError] = useState(false);
  const [sieveError, setSieveError] = useState(false);

  useEffect(() => {
    dispatch(getVendorAccountData());
    dispatch(getEmployeeAccountData());
  }, [dispatch]);
  useEffect(() => {
    if (isVendorSuccess) {
      setInwardVendorData(vendorData.data.data);
    }
    if (isEmployeeSuccess) {
      setInwardEmployeeData(employeeData.data.data);
    }
  }, [isVendorSuccess, vendorData, isEmployeeSuccess, employeeData]);

  useEffect(() => {
    // Calculate the price when there's a change in stockWeight or ratePerCaret
    if (addInward.stockWeight !== null && addInward.ratePerCaret !== null) {
      const calculatedPrice = Math.round(addInward.stockWeight * addInward.ratePerCaret);
      setAddInward((prevAddStock) => ({
        ...prevAddStock,
        stockPrice: calculatedPrice
      }));
    } else {
      // If stockWeight or ratePerCaret is null, set price to null
      setAddInward((prevAddStock) => ({
        ...prevAddStock,
        stockPrice: null
      }));
    }
  }, [addInward.stockWeight, addInward.ratePerCaret]);

  function handleClickError() {
    setSuccessOpen(false);
    setErrorOpen(true);
  }
  function handleClickSuccess(message) {
    setErrorOpen(false);
    setSuccessMessage(message);
    setSuccessOpen(true);
  }
  function handleSnakeBarClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
    setErrorOpen(false);
  }
  const handleVendorSelect = async (e) => {
    const vendorId = await convertVendorId(inwardVendorData, e.target.value);
    setAddInward({ ...addInward, vendorId: vendorId.id, vendorName: e.target.value });
    setVendorNameError(false);
  };
  const handleDiamondTypeSelect = async (e) => {
    setAddInward({ ...addInward, diamondType: e.target.value });
    setDiamondTypeError(false);
  };
  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    setAddInward({
      ...addInward,
      employeeName: typeof value === 'string' ? value.split(',') : value
    });
  };
  function getStyles(name, employeeName, theme) {
    return {
      fontWeight:
        employeeName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium
    };
  }
  const handleSubmit = async () => {
    try {
      if (
        addInward.sieve &&
        addInward.sieve.length > 0 &&
        addInward.orderDiamondType &&
        addInward.orderDiamondType.length > 0 &&
        addInward.diamondType &&
        addInward.diamondType.length > 0 &&
        addInward.stockWeight &&
        addInward.stockWeight !== null &&
        addInward.vendorId &&
        addInward.vendorId !== null &&
        addInward.orderId &&
        addInward.orderId !== null
      ) {
        const inwardDetails = { ...addInward };

        const propertiesToConvert = ['stockWeight', 'ratePerCaret', 'stockPrice', 'piece'];
        propertiesToConvert.forEach((property) => {
          if (inwardDetails.hasOwnProperty(property)) {
            if (property !== 'stockWeight') {
              inwardDetails[property] = parseInt(inwardDetails[property]);
            } else {
              inwardDetails['stockWeight'] = parseFloat(
                parseFloat(inwardDetails['stockWeight']).toFixed(3)
              );
            }
          }
        });
        Object.keys(inwardDetails).forEach((key) => {
          if (inwardDetails[key] === '') {
            delete inwardDetails[key];
          }
          if (key === 'employeeName') {
            inwardDetails[key] = inwardDetails[key].join(', ');
          }
        });

        const responseValue = await dispatch(addDiamondInward(inwardDetails));
        if (!responseValue.hasOwnProperty('error')) {
          handleClickSuccess('Stock Addes successfully!!');
          setAddInward(intialData);
          handleApiCall();
          setLoading(false);
        } else {
          throw responseValue;
        }
      } else {
        addInward.vendorName.length <= 0 && setVendorNameError(true);
        addInward.diamondType.length <= 0 && setDiamondTypeError(true);
        addInward.stockWeight <= 0 && setStockWeightError(true);
        addInward.sieve.length <= 0 && setSieveError(true);
        handleClickError();
        setError({ payload: 'Please Fill Mandatory Data!' });
        setLoading(false);
      }
    } catch (error) {
      handleClickError();
      setError(error);
      setLoading(false);
    }
  };
  const DiamondType = [
    {
      name: 'REAL'
    },
    {
      name: 'CVD'
    }
  ];
  return (
    <React.Fragment>
      {' '}
      <Snackbar open={successOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='error'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {error?.hasOwnProperty('payload') ? error.payload : 'Something went wrong!!'}
        </Alert>
      </Snackbar>
      <Grid container spacing={3} sx={{ mb: '24px' }}>
        <Grid item xs={12} md={12}>
          <StyledCard elevation={6}>
            <H5 sx={{ mb: 2, fontWeight: 'bold' }}>ADD INWARD ENTRY</H5>

            <Grid container>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <InputLabel
                  sx={{ fontSize: '10px', color: vendorNameError ? 'red' : '' }}
                  id='vendorName'
                >
                  Vendor Name
                </InputLabel>

                <Select
                  size='small'
                  sx={{ width: '80%', my: 1 }}
                  variant='standard'
                  labelId='vendorName'
                  value={addInward.vendorName}
                  onChange={handleVendorSelect}
                  error={vendorNameError}
                >
                  {inwardVendorData.map((data, index) => (
                    <MenuItem value={data.account_name} key={index} id={index}>
                      {data.account_name}
                    </MenuItem>
                  ))}
                </Select>
                {vendorNameError && <FormHelperText>Please select Vendor</FormHelperText>}

                <ContentBox>
                  <TextField
                    id='stockPcs'
                    type='number'
                    margin='dense'
                    size='small'
                    label='Diamond Count'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='standard'
                    value={addInward.piece == null ? '' : addInward.piece}
                    onChange={(e) => {
                      setAddInward({ ...addInward, piece: e.target.value });
                    }}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    id='caratPrice'
                    type='number'
                    margin='dense'
                    size='small'
                    label='Carat Price'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='standard'
                    value={addInward.ratePerCaret == null ? '' : addInward.ratePerCaret}
                    onChange={(e) => {
                      setAddInward({ ...addInward, ratePerCaret: e.target.value });
                    }}
                  />
                </ContentBox>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <InputLabel
                  sx={{ fontSize: '10px', color: diamondTypeError ? 'red' : '' }}
                  id='diamondType'
                >
                  Diamond Type
                </InputLabel>
                <Select
                  size='small'
                  sx={{ width: '80%', my: 1 }}
                  variant='standard'
                  labelId='diamondType'
                  value={addInward.diamondType}
                  onChange={handleDiamondTypeSelect}
                  error={diamondTypeError}
                >
                  {DiamondType.map((data, index) => (
                    <MenuItem value={data.name} key={index} id={index}>
                      {data.name}
                    </MenuItem>
                  ))}
                </Select>
                {diamondTypeError && <FormHelperText>Please Diamond Type</FormHelperText>}
                <ContentBox>
                  <TextField
                    id='stockWeight'
                    type='number'
                    margin='dense'
                    size='small'
                    label='Stock Weight'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='standard'
                    error={stockWeightError}
                    helperText={stockWeightError ? 'Please Add Stock Weight' : ''}
                    value={addInward.stockWeight == null ? '' : addInward.stockWeight}
                    required
                    onChange={(e) => {
                      setStockWeightError(false);
                      setAddInward({ ...addInward, stockWeight: e.target.value });
                    }}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    id='stockPrice'
                    type='number'
                    margin='dense'
                    size='small'
                    label='Stock Price'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='standard'
                    value={addInward.stockPrice == null ? '' : addInward.stockPrice}
                    onChange={(e) => {
                      setAddInward({ ...addInward, stockPrice: e.target.value });
                    }}
                  />
                </ContentBox>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <ContentBox>
                  <TextField
                    id='sieve'
                    type='text'
                    margin='dense'
                    size='small'
                    label='Sieve'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='standard'
                    value={addInward.sieve == null ? '' : addInward.sieve}
                    error={sieveError}
                    helperText={sieveError ? 'Please Add Sieve Value' : ''}
                    onChange={(e) => {
                      setSieveError(false);
                      setAddInward({ ...addInward, sieve: e.target.value });
                    }}
                  />
                </ContentBox>
                <InputLabel sx={{ fontSize: '10px' }} id='demo-multiple-employee-label'>
                  Employee
                </InputLabel>

                <Select
                  labelId='demo-multiple-employee-label'
                  id='demo-multiple-employee'
                  multiple
                  onChange={handleChange}
                  value={addInward.employeeName}
                  variant='standard'
                  InputLabelProps={{ shrink: true }}
                  sx={{ my: 1, width: '80%' }}
                  // input={
                  //   <OutlinedInput id='select-multiple-chip'  label='Chip' sx={{ width: '50%' }} />
                  // }
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {inwardEmployeeData.map((employee, index) => (
                    <MenuItem
                      value={employee.name}
                      style={getStyles(employee.name, addInward.employeeName, theme)}
                      key={index}
                      id={index}
                    >
                      {employee.name}
                    </MenuItem>
                  ))}
                </Select>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: '80%', my: 1 }}
                    label='Date'
                    format='DD/MM/YYYY'
                    value={addInward.date}
                    onChange={(newValue) => setAddInward({ ...addInward, date: newValue })}
                    slotProps={{ textField: { variant: 'standard' } }}
                  />
                </LocalizationProvider>
              </Grid>

              <LoadingButton
                onClick={handleSubmit}
                color='primary'
                loading={loading}
                variant='contained'
                sx={{ my: 2, textTransform: 'capitalize' }}
              >
                Add Inward
              </LoadingButton>
            </Grid>
          </StyledCard>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AddInwardDetails;
