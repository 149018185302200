import {
  Alert,
  Box,
  Card,
  Grid,
  Icon,
  IconButton,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import dayjs from 'dayjs';
import DeleteInwardEntry from './DeleteInwardEntry';
import UpdateSelfInward from './UpdateSelfInward';
import { H5 } from 'app/components/Typography';
import UpdateCustomerInward from './UpdateCustomerInward';
import { getDiamondInward } from 'app/contexts/reducers/transaction/diamondInwardSlice';

const CardHeader = styled(Box)(() => ({
  display: 'flex',
  paddingLeft: '24px',
  paddingRight: '24px',
  marginBottom: '12px',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)'
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' }
}));

const DiamondInwardTable = ({ apiCall, handleApiCall, customerId, orderId, orderStatus }) => {
  const dispatch = useDispatch();

  const { isVendorSuccess, vendorData } = useSelector((state) => state.account);
  const { isInwardSuccess, inwardData } = useSelector((state) => state.inwardDetails);
  const { isOutwardSuccess, outwardData } = useSelector((state) => state.outwardDetails);
  const [diamondInwardData, setDiamondInwardData] = useState([]);
  const [orderOutwardData, setOrderOutwardData] = useState([]);
  // const [diamondInwardPossible, setDiamondInwardPossible] = useState(false);
  const [partyData, setPartyData] = useState([]);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [updateInwardId, setUpdatInwardId] = useState(null);
  const [deleteInwardId, setDeleteInwardId] = useState(null);
  const [errorOpen, setErrorOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [error, setError] = useState();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [inwardType, setInwardType] = useState('');
  const [isDataFound, setIsDataFound] = useState(false);
  useEffect(() => {
    dispatch(getDiamondInward({ orderId }));
  }, [dispatch, orderId, apiCall]);
  useEffect(() => {
    if (isInwardSuccess) {
      if (inwardData.data.code === 200) {
        setIsDataFound(true);
        setDiamondInwardData(inwardData.data.data);
      } else {
        setIsDataFound(false);
        setDiamondInwardData([]);
      }
    }
  }, [isInwardSuccess, inwardData]);
  useEffect(() => {
    if (isOutwardSuccess) {
      if (outwardData.data.code === 200) {
        setOrderOutwardData(outwardData.data.data);
        // setDiamondInwardPossible(true);
      } else {
        setOrderOutwardData([]);
        // setDiamondInwardPossible(false);
      }
    }
  }, [isOutwardSuccess, outwardData]);

  useEffect(() => {
    if (isVendorSuccess) {
      setPartyData(vendorData.data.data);
    }
  }, [isVendorSuccess, vendorData]);
  const isEntryFound = (arr, vendorId, diamondType, inwardType) => {
    if (arr?.length > 0) {
      if (inwardType.toLocaleLowerCase() === 'customer') {
        return arr.some(
          (entry) => entry.diamond_type === diamondType && entry.order_diamond_type === 'CUSTOMER'
        );
      } else if (inwardType.toLocaleLowerCase() === 'self') {
        return arr.some(
          (entry) => entry.vendor_id === vendorId && entry.diamond_type === diamondType
        );
      } else if (inwardType.toLocaleLowerCase() === 'bulk') {
        return arr.some(
          (entry) => entry.diamond_type === diamondType && entry.order_diamond_type === 'BULK'
        );
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  function handleUpdateClose() {
    setUpdateOpen(false);
  }
  function handleDeleteClose() {
    setDeleteOpen(false);
  }
  function handleClickSuccess(message) {
    setErrorOpen(false);
    setSuccessMessage(message);
    setSuccessOpen(true);
  }
  function handleClickError() {
    setSuccessOpen(false);
    setErrorOpen(true);
  }
  function handleSnakeBarClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
    setErrorOpen(false);
  }
  function getVendorName(vendorId) {
    const partyName = partyData.find((partyName) => {
      return partyName.id === vendorId;
    });
    return partyName;
  }

  return (
    <React.Fragment>
      <Card elevation={3} sx={{ pt: '20px', mb: 3 }}>
        <CardHeader>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <H5 sx={{ fontWeight: 'bold' }}>Diamond Inward Entry Data</H5>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>{/* <SearchAccount setOrderData={setOrderData} /> */}</Grid>
                <Grid item>{/* <FilterOrderByMonth setOrderdata={setOrderData} /> */}</Grid>
                <Grid item>{/* <FilterStatus setOrderdata={setOrderData} /> */}</Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardHeader>

        <Box overflow='auto'>
          <ProductTable>
            <TableHead sx={{ backgroundColor: 'rgb(34,41,67)' }}>
              <TableRow>
                <TableCell align='center' sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Index
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  Vendor Name
                </TableCell>
                <TableCell
                  colSpan={2}
                  align='center'
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                >
                  Diamond Type
                </TableCell>
                <TableCell align='center' sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Piece
                </TableCell>
                <TableCell align='center' sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Weight
                </TableCell>
                <TableCell
                  colSpan={2}
                  align='center'
                  sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}
                >
                  Sieve
                </TableCell>
                <TableCell
                  align='center'
                  colSpan={2}
                  sx={{ px: 2, fontWeight: 'bold', color: '#FFFFFF' }}
                >
                  Date
                </TableCell>

                {Number(orderStatus) === 0 ||
                  (Number(orderStatus) === 1 && (
                    <TableCell
                      align='center'
                      sx={{ px: 5, fontWeight: 'bold', color: '#FFFFFF' }}
                      colSpan={2}
                    >
                      Action
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {isDataFound ? (
                diamondInwardData.map((inwardDetails, index) => (
                  <TableRow key={index} hover>
                    <TableCell
                      align='center'
                      sx={{ px: 2, fontWeight: 'bold', textTransform: 'capitalize' }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      align='center'
                      colSpan={2}
                      sx={{ px: 0, textTransform: 'capitalize', fontWeight: 'bold' }}
                    >
                      {inwardDetails.order_diamond_type.toLocaleLowerCase() === 'self'
                        ? getVendorName(inwardDetails.vendor_id) === undefined
                          ? 'Vendor Deleted'
                          : getVendorName(inwardDetails.vendor_id).account_name
                        : 'CUSTOMER'}
                    </TableCell>
                    <TableCell
                      colSpan={2}
                      align='center'
                      sx={{ px: 3, textTransform: 'capitalize' }}
                    >
                      {inwardDetails.diamond_type}
                    </TableCell>
                    <TableCell align='center' sx={{ px: 2 }}>
                      {inwardDetails.piece}
                    </TableCell>
                    <TableCell align='center' sx={{ px: 1 }}>
                      {inwardDetails.weight}
                    </TableCell>
                    <TableCell colSpan={2} align='center' sx={{ px: 1 }}>
                      {inwardDetails.sieve}
                    </TableCell>
                    <TableCell align='center' colSpan={2} sx={{ px: 1 }}>
                      {dayjs(inwardDetails.date).format('DD-MM-YYYY')}
                    </TableCell>

                    {Number(orderStatus) === 0 ||
                      (Number(orderStatus) === 1 && (
                        <TableCell align='center' sx={{ px: 2 }} colSpan={2}>
                          <Tooltip title='Edit'>
                            <IconButton
                              color='primary'
                              disabled={isEntryFound(
                                orderOutwardData,
                                inwardDetails.vendor_id,
                                inwardDetails.diamond_type,
                                inwardDetails.order_diamond_type
                              )}
                              onClick={() => {
                                setUpdatInwardId(inwardDetails.id);
                                setUpdateOpen(true);
                                setInwardType(inwardDetails.order_diamond_type.toLocaleLowerCase());
                              }}
                            >
                              <Icon>edit</Icon>
                            </IconButton>
                          </Tooltip>
                          <Tooltip title='Delete'>
                            <IconButton
                              color='error'
                              disabled={isEntryFound(
                                orderOutwardData,
                                inwardDetails.vendor_id,
                                inwardDetails.diamond_type,
                                inwardDetails.order_diamond_type
                              )}
                              onClick={() => {
                                setDeleteInwardId(inwardDetails.id);
                                setDeleteOpen(true);
                              }}
                            >
                              <Icon>close</Icon>
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} sx={{ textAlign: 'center' }}>
                    No data found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </ProductTable>
        </Box>
      </Card>
      {/* Alert */}
      <Snackbar open={successOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='error'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {error?.payload}
        </Alert>
      </Snackbar>
      {/* Update-Customer-Dialog */}
      {
        // inwardOrderType.toLocaleLowerCase() === 'self' ? (
        inwardType === 'self' ? (
          <UpdateSelfInward
            open={updateOpen}
            onClose={handleUpdateClose}
            inwardId={updateInwardId}
            handleApiCall={handleApiCall}
            handleClickSuccess={handleClickSuccess}
            setError={setError}
            handleClickError={handleClickError}
            apiCall={apiCall}
          />
        ) : (
          <UpdateCustomerInward
            open={updateOpen}
            onClose={handleUpdateClose}
            inwardId={updateInwardId}
            handleApiCall={handleApiCall}
            handleClickSuccess={handleClickSuccess}
            setError={setError}
            handleClickError={handleClickError}
            apiCall={apiCall}
          />
        )
      }
      {/* Start-Delete-Account */}
      <DeleteInwardEntry
        open={deleteOpen}
        onClose={handleDeleteClose}
        deleteInwardId={deleteInwardId}
        handleApiCall={handleApiCall}
        handleClickSuccess={handleClickSuccess}
        handleClickError={handleClickError}
        setError={setError}
      />
    </React.Fragment>
  );
};

export default DiamondInwardTable;
