import { LoadingButton } from '@mui/lab';
import { Box, TextField, styled } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUpdateSingleItem } from '../helper/updateHelper';
import { updateItem } from 'app/contexts/reducers/item/itemSlice';
import { H5 } from 'app/components/Typography';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});
const FlexBox = styled(Box)(() => ({ display: 'flex', alignItems: 'center' }));
const JustifyBox = styled(FlexBox)(() => ({ justifyContent: 'center' }));
export default function UpdateDialog({
  open,
  onClose,
  updateItemId,
  setApiCall,
  handleClickSuccess,
  setError,
  handleClickError
}) {
  const dispatch = useDispatch();
  const { itemData } = useSelector((state) => state.item);
  const { isItemSuccess } = useSelector((state) => state.item);
  const [updateValue, setUpdateValue] = useState('');
  const [updateImage, setUpdateImage] = useState();
  const [previweImage, setPreviweImage] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (itemData) {
      getUpdateSingleItem(itemData.data.data, updateItemId).then((data) => {
        setUpdateValue(data.itemname);
        setPreviweImage(data.itemimage);
      });
    }
  }, [updateItemId, isItemSuccess, itemData]);
  async function handleImageUpdate(event) {
    setUpdateImage(event.target.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      setPreviweImage(reader.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  }
  async function handleUpdate() {
    try {
      setLoading(true);
      const currentItem = await getUpdateSingleItem(itemData.data.data, updateItemId);
      if (updateValue !== currentItem.itemname) {
        const data = new FormData();
        data.append('itemName', updateValue);
        data.append('itemImage', updateImage);
        const response = await dispatch(updateItem({ updateItemId, data }));
        if (!response.hasOwnProperty('error')) {
          onClose();
          setApiCall((prev) => !prev);
          handleClickSuccess('Item Update successfully');
          setUpdateValue(null);
          setUpdateImage(null);
          setPreviweImage(null);
          setLoading(false);
        } else {
          throw response;
        }
      } else {
        if (updateImage) {
          const data = new FormData();
          data.append('itemImage', updateImage);
          const response = await dispatch(updateItem({ updateItemId, data }));
          if (!response.hasOwnProperty('error')) {
            onClose();
            setApiCall((prev) => !prev);
            handleClickSuccess('Item Update successfully');
            setUpdateValue(null);
            setUpdateImage(null);
            setPreviweImage(null);
            setLoading(false);
          } else {
            throw response;
          }
        } else {
          onClose();
          setLoading(false);
        }
      }
    } catch (e) {
      handleClickError();
      setError(e);
      setLoading(false);
    }
  }
  return (
    <Box>
      <Dialog open={open} onClose={onClose} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>Update Item</DialogTitle>

        <DialogContent>
          <DialogContentText>Please update the name of the watch brand</DialogContentText>

          <TextField
            fullWidth
            autoFocus
            id='itemName'
            type='text'
            margin='dense'
            label='Watch Brand Name'
            value={updateValue}
            sx={{ my: 2 }}
            onChange={(e) => {
              setUpdateValue(e.target.value);
            }}
          />
          <Button
            component='label'
            variant='contained'
            onChange={handleImageUpdate}
            sx={{ my: 2 }}
            startIcon={<CloudUploadIcon />}
          >
            Upload Brand Image
            <VisuallyHiddenInput type='file' />
          </Button>
          <Box sx={{ mt: 2 }}>
            <H5>Item Image</H5>
            {previweImage && (
              <JustifyBox p={4} height='100%' sx={{ maxWidth: 320, m: 'auto' }}>
                <img
                  src={previweImage}
                  width='100%'
                  alt={updateValue ? updateValue : 'Item-Image'}
                />
              </JustifyBox>
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant='outlined' color='secondary' onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleUpdate}
            color='primary'
            loading={loading}
            disabled={updateValue.length > 0 ? false : true}
            variant='contained'
            sx={{ my: 2 }}
          >
            Update Item
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
