import { Grid, styled } from '@mui/material';
import { Fragment, useState } from 'react';
import CreateInvoice from './shared/CreateInvoice';
import CustomerInvoiceTable from './shared/CustomerInvoiceTable';

const ContentBox = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' }
}));

const Sell = () => {
  const [apiCall, setApiCall] = useState(false);
  const handleApiCall = () => {
    setApiCall((prev) => !prev);
  };
  return (
    <Fragment>
      <ContentBox className='analytics'>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <CreateInvoice handleApiCall={handleApiCall} apiCall={apiCall} />
            <CustomerInvoiceTable apiCall={apiCall} handleApiCall={handleApiCall} />
          </Grid>
        </Grid>
      </ContentBox>
    </Fragment>
  );
};

export default Sell;
