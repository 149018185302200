import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders } from 'app/utils/handleHeader';
const initialState = {
    isInitialised: false,
    isStockBillSuccess: false,
    isBulkStockBillSuccess: false,
    isStockBillRemoveSuccess: false,
    isError: false,
    isLoading: false
};
// --------------------------------------Add Stock-Bill Image --------------------------------
export const stockBillImage = createAsyncThunk('stockBillImage', async (billImage, { rejectWithValue }) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/stockbillimage/stockbillimage`, billImage, {
            headers: getHeaders(),
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
// --------------------------------------Add Bulk-Stock-Bill Image --------------------------------
export const bulkStockBillImage = createAsyncThunk('bulkStockBillImage', async (bulkStockBillImage, { rejectWithValue }) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/stockbillimage/bulkstockbillimage`, bulkStockBillImage, {
            headers: getHeaders(),
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
// --------------------------------------Add Bulk-Stock-Bill Image --------------------------------
export const removeStockBillImage = createAsyncThunk('removeStockBillImage', async (imageData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/stockbillimage/removebillimage`, imageData, {
            headers: getHeaders(),
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
const billImageSlice = createSlice({
    name: 'billImage',
    initialState,
    reducers: {},
    extraReducers: {
        //--------------_Slice action for the Add stock Bill Image--------------
        [stockBillImage.pending]: (state, action) => {
            state.isLoading = true;
            state.isStockBillSuccess = false;
            state.isError = false;
        },
        [stockBillImage.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isStockBillSuccess = true;
            state.isError = false;
        },
        [stockBillImage.rejected]: (state, action) => {
            state.isLoading = false;
            state.isStockBillSuccess = false;
            state.isError = true;
        },
        //--------------_Slice action for the  Add Bulk-Stock Bill
        [bulkStockBillImage.pending]: (state, action) => {
            state.isLoading = true;
            state.isBulkStockBillSuccess = false;
            state.isError = false;
        },
        [bulkStockBillImage.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isBulkStockBillSuccess = true;
            state.isError = false;
        },
        [bulkStockBillImage.rejected]: (state, action) => {
            state.isLoading = false;
            state.isBulkStockBillSuccess = false;
            state.isError = true;
        },
        //--------------_Slice action for the Remove Stock Bill
        [removeStockBillImage.pending]: (state, action) => {
            state.isLoading = true;
            state.isStockBillRemoveSuccess = false;
            state.isError = false;
        },
        [removeStockBillImage.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isStockBillRemoveSuccess = true;
            state.isError = false;
        },
        [removeStockBillImage.rejected]: (state, action) => {
            state.isLoading = false;
            state.isStockBillRemoveSuccess = false;
            state.isError = true;
        },
    }
});
export default billImageSlice.reducer;