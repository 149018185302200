import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders } from 'app/utils/handleHeader';
const initialState = {
  isInitialised: true,
  isProductSuccess: false,
  isLoading: false,
  isError: false,
  productData: null
};

// --------------------------------------Add Product --------------------------------
export const addProduct = createAsyncThunk('product', async (product, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/product/addProduct`,
      product,
      { headers: getHeaders() }
    );
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
export const getProducts = createAsyncThunk('getProducts', async (object, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/product/getProducts`, {
      headers: getHeaders()
    });
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
export const deleteProducts = createAsyncThunk(
  'deleteProducts',
  async (object, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/product/deleteProduct/${object}`,
        { headers: getHeaders() }
      );
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const updateProducts = createAsyncThunk(
  'updateProducts',
  async ({ updateItemId, data }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/product/updateProduct/${updateItemId}`,
        data,
        { headers: getHeaders() }
      );
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
// --------------------------------------Product Slice--------------------------------
const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {},
  extraReducers: {
    // --------------------------------------Add -Product--------------------------------
    [addProduct.pending]: (state, action) => {
      state.isLoading = true;
      state.isError = false;
      state.isProductSuccess = false;
    },
    [addProduct.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isProductSuccess = true;
    },
    [addProduct.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isProductSuccess = false;
    },
    // --------------------------------------Get-Product--------------------------------
    [getProducts.pending]: (state, action) => {
      state.isLoading = true;
      state.isError = false;
      state.isProductSuccess = false;
    },
    [getProducts.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isProductSuccess = true;
      state.productData = action.payload;
    },
    [getProducts.rejected]: (state, action) => {
      state.isLoading = true;
      state.isError = false;
      state.isProductSuccess = false;
    },
    // --------------------------------------Delete-Product--------------------------------
    [deleteProducts.pending]: (state, action) => {
      state.isLoading = true;
      state.isError = false;
      state.isProductSuccess = false;
    },
    [deleteProducts.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isProductSuccess = true;
    },
    [deleteProducts.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isProductSuccess = false;
    },
    // --------------------------------------Update-Product--------------------------------
    [updateProducts.pending]: (state, action) => {
      state.isLoading = true;
      state.isError = false;
      state.isProductSuccess = false;
    },
    [updateProducts.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isProductSuccess = true;
    },
    [updateProducts.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isProductSuccess = false;
    }
  }
});

export default productSlice.reducer;
