import { getHeaders } from 'app/utils/handleHeader';
import axios from 'axios';
const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');
const initialState = {

    isInitialised: true,
    isLoading: false,
    isOutwardSuccess: false,
    isSpecificOutwardSuccess: false,
    isInwardOutwardReport: false,
    isError: false,
    outwardData: null,
    specificOutwardData: null
};
// --------------------------------------Add Diamond Outward To Order --------------------------------
export const addDiamondOutward = createAsyncThunk('addDiamondOutward', async (addDiamondOutward, { rejectWithValue }) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/transaction/adddiamondoutward`,
            addDiamondOutward,
            {
                headers: getHeaders()
            }
        );
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
export const getDiamondOutward = createAsyncThunk('getDiamondOutward', async ({ orderId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transaction/getdiamondoutward/${orderId}`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {

        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
export const getSpecificOutward = createAsyncThunk('getSpecificOutward', async ({ outwardId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transaction/getspecificdiamondoutward/${outwardId}`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {

        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Update Outward-Entry Data--------------------------------
export const updateOutwardEntryDetails = createAsyncThunk(
    '/updateOutwardEntryDetails',
    async ({ outwardId, updateOutwardDetails }, { rejectWithValue }) => {

        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/transaction/updatediamondoutward/${outwardId}`, updateOutwardDetails,
                { headers: getHeaders() }
            );
            return response;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);
// ----------------------------------------Genrate Invoice PDF --------------------------------
export const genrateInwardOutwardPdf = createAsyncThunk('genrateInwardOutwardPdf', async ({ orderId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/transaction/genrateinwardoutwardreport/${orderId}`,

            {
                responseType: 'arraybuffer',
                headers: { 'Accept': 'application/pdf', ...getHeaders() }
            }
        );
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Inward_Outward.pdf'; // Set the desired file name
        a.click();
        window.URL.revokeObjectURL(url);
        // return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
const diamondOutwardSlice = createSlice({
    name: 'diamondOutward',
    initialState,
    reducers: {},
    extraReducers: {
        //--------------Slice action for the Add Outward --------------------
        [addDiamondOutward.pending]: (state, action) => {
            state.isLoading = true;
            state.isOutwardSuccess = false;
            state.isError = false;
        },
        [addDiamondOutward.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isOutwardSuccess = true;
            state.isError = false;
        },
        [addDiamondOutward.rejected]: (state, action) => {
            state.isLoading = false;
            state.isOutwardSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Outward --------------------------------
        [getDiamondOutward.pending]: (state, action) => {
            state.isLoading = true;
            state.isOutwardSuccess = false;
            state.isError = false;
        },
        [getDiamondOutward.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isOutwardSuccess = true;
            state.outwardData = action.payload;
            state.isError = false;
        },
        [getDiamondOutward.rejected]: (state, action) => {
            state.isLoading = false;
            state.isOutwardSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Specific Outward --------------------------------
        [getSpecificOutward.pending]: (state, action) => {
            state.isLoading = true;
            state.isSpecificOutwardSuccess = false;
            state.isError = false;
        },
        [getSpecificOutward.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isSpecificOutwardSuccess = true;
            state.specificOutwardData = action.payload;
            state.isError = false;
        },
        [getSpecificOutward.rejected]: (state, action) => {
            state.isLoading = false;
            state.isSpecificOutwardSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for Update Specific Outward --------------------------------
        [updateOutwardEntryDetails.pending]: (state, action) => {
            state.isLoading = true;
            state.isOutwardSuccess = false;
            state.isError = false;
        },
        [updateOutwardEntryDetails.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isOutwardSuccess = true;
            state.isError = false;
        },
        [updateOutwardEntryDetails.rejected]: (state, action) => {
            state.isLoading = false;
            state.isOutwardSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for Genrate Inward-Outward Report--------------------------------
        [genrateInwardOutwardPdf.pending]: (state, action) => {
            state.isLoading = true;
            state.isInwardOutwardReport = false;
            state.isError = false;
        },
        [genrateInwardOutwardPdf.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isInwardOutwardReport = true;
            state.isError = false;
        },
        [genrateInwardOutwardPdf.rejected]: (state, action) => {
            state.isLoading = false;
            state.isInwardOutwardReport = false;
            state.isError = true;
        }
    }
})
export default diamondOutwardSlice.reducer;
