import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { deleteIncomeEntry } from 'app/contexts/reducers/transaction/incomeEntrySlice';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

export default function DeleteIncomeEntry({
  open,
  onClose,
  deleteEntryId,
  setApiCall,
  handleClickSuccess,
  handleClickError,
  setError
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const handleDelete = async () => {
    try {
      const code = window.prompt('Please enter the code to delete the entry:');
      if (code === '3535') {
        const response = await dispatch(deleteIncomeEntry({ entryId: deleteEntryId }));
        if (!response.hasOwnProperty('error')) {
          onClose();
          setApiCall((prev) => !prev);
          handleClickSuccess('Income Entry delete successfully');
          setLoading(false);
        } else {
          throw response;
        }
      } else {
        window.alert('Incorrect code entered. Entry deletion canceled.');
      }
    } catch (e) {
      handleClickError();
      setError(e);
      setLoading(false);
    }
  };
  return (
    <Box>
      <Dialog
        open={open}
        onClose={onclose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Income-Entry Delete Confirmation Dialog</DialogTitle>

        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to delete this Income-Entry? This action cannot be undone.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} color='primary'>
            Disagree
          </Button>

          <LoadingButton
            onClick={handleDelete}
            color='primary'
            loading={loading}
            variant='contained'
            sx={{ my: 2 }}
          >
            Delete Entry
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
