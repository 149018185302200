import { Grid, styled } from '@mui/material';
import { Fragment } from 'react';
import AddBulkStock from './shared/AddBulkStock';
import BulkStockTable from './shared/BulkStockTable';
import { useState } from 'react';

const ContentBox = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' }
}));

const BulkStockMaster = () => {
  const [apiCall, setApiCall] = useState(false);
  function handleApiCall() {
    setApiCall((prev) => !prev);
  }
  return (
    <Fragment>
      <ContentBox className='analytics'>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <AddBulkStock handleApiCall={handleApiCall} />
            <BulkStockTable apiCall={apiCall} setApiCall={setApiCall} />
          </Grid>
        </Grid>
      </ContentBox>
    </Fragment>
  );
};

export default BulkStockMaster;
