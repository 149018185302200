import { LoadingButton } from '@mui/lab';
import { Alert, Box, Card, Grid, Snackbar, styled, TextField } from '@mui/material';
import React from 'react';
import { H4 } from 'app/components/Typography';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createExpenseType } from 'app/contexts/reducers/expenseType/expenseTypeSlice';
const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' }
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main }
}));

const AddExpenseType = ({ handleApiCall }) => {
  const dispatch = useDispatch();

  const intialData = {
    expenseType: ''
  };
  const [addExpenseType, setAddExpenseType] = useState(intialData);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState();

  const [expenseTypeError, setExpenseTypeError] = useState(false);

  function handleClickError() {
    setSuccessOpen(false);
    setErrorOpen(true);
  }
  function handleClickSuccess(message) {
    setErrorOpen(false);
    setSuccessMessage(message);
    setSuccessOpen(true);
  }
  function handleSnakeBarClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
    setErrorOpen(false);
  }

  const handleSubmit = async () => {
    try {
      if (addExpenseType.expenseType && addExpenseType.expenseType.length > 0) {
        const expenseTypeDetails = { ...addExpenseType };

        const responseValue = await dispatch(createExpenseType(expenseTypeDetails));
        if (!responseValue.hasOwnProperty('error')) {
          handleClickSuccess('Expense Type Added successfully!!');
          setAddExpenseType(intialData);
          handleApiCall();
          setLoading(false);
        } else {
          throw responseValue;
        }
      } else {
        addExpenseType.expenseType.length <= 0 && setExpenseTypeError(true);
        handleClickError();
        setError({ payload: 'Please Fill Mandatory Data!' });
        setLoading(false);
      }
    } catch (error) {
      handleClickError();
      setError(error);
      setLoading(false);
    }
  };
  return (
    <React.Fragment>
      {' '}
      <Snackbar open={successOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='error'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {error?.hasOwnProperty('payload') ? error.payload : 'Something went wrong!!'}
        </Alert>
      </Snackbar>
      <Grid container spacing={3} sx={{ mb: '24px' }}>
        <Grid item xs={12} md={12}>
          <StyledCard elevation={6}>
            <H4 sx={{ mb: 2 }}>ADD EXPENSE-TYPE</H4>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ContentBox>
                  <TextField
                    id='expenseType'
                    type='text'
                    margin='dense'
                    size='small'
                    label='Expense Type'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    error={expenseTypeError}
                    helperText={expenseTypeError ? 'Please Write Expense-type' : ''}
                    value={addExpenseType.expenseType}
                    required
                    onChange={(e) => {
                      setExpenseTypeError(false);
                      setAddExpenseType({ ...addExpenseType, expenseType: e.target.value });
                    }}
                  />
                </ContentBox>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
            </Grid>
            <LoadingButton
              onClick={handleSubmit}
              color='primary'
              loading={loading}
              variant='contained'
              sx={{ my: 2, textTransform: 'capitalize' }}
            >
              Add Expense-type
            </LoadingButton>
          </StyledCard>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AddExpenseType;
