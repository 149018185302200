import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders } from 'app/utils/handleHeader';
const initialState = {
    stockEntryData: null,
    stockData: null,
    singleStockData: null,
    singleStockEntryData: null,
    totalFieldData: null,
    totalRealData: null,
    totalCvdData: null,
    isInitialised: false,
    isStockMasterEntrySuccess: false,
    isStockMasterSuccess: false,
    isSingleStockDataSuccess: false,
    isSingleStockEntrySuccess: false,
    isTotalFieldDataSuccess: false,
    isTotalRealDataSuccess: false,
    isTotalCvdDataSuccess: false,
    isUpdateStockStatus: false,
    isError: false,
    isLoading: false
};

//--------------Asyncthunk for Add Book Master

export const addStockEntry = createAsyncThunk(
    '/addStock',
    async (object, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/stock/addstockentry`,
                object,
                { headers: getHeaders() }
            );
            return response;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);
//--------------Asyncthunk for Get Stock Data--------------------------------

export const getStockData = createAsyncThunk('getStockData', async (object, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/stock/getstock`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Get Specific Stock Data Based On Vendor--------------------------------

export const getVendorStockData = createAsyncThunk('getVendorStockData', async ({ stockId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/stock/getsinglestock/${stockId}`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Get Total Stock Value Based On Field-Name--------------------------------

export const getTotalFieldValue = createAsyncThunk('getTotalFieldValue', async (object, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/stock/gettotal`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Get Total Real Stock Value Based On Field-Name--------------------------------

export const getRealTotalFieldValue = createAsyncThunk('getRealTotalFieldValue', async (object, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/stock/gettotalreal`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Get Total Cvd Stock Value Based On Field-Name--------------------------------

export const getCvdTotalFieldValue = createAsyncThunk('getCvdTotalFieldValue', async (object, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/stock/gettotalcvd`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Get Specific Stock-Entry Data Based On Vendor And Diamond Type--------------------------------

export const getSpecificStockEntry = createAsyncThunk('getSpecificStockEntry', async ({ stockId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/stock/getspecificstockentry/${stockId}`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Delete Stock Data--------------------------------
export const deleteStock = createAsyncThunk(
    '/deleteStock',
    async ({ deleteStockId }, { rejectWithValue }) => {
        try {
            const bookNumber = new Date().getFullYear();
            const response = await axios.delete(
                `${process.env.REACT_APP_BASE_URL}/stock/deletestock/${deleteStockId}/${bookNumber}`,
                { headers: getHeaders() }
            );
            return response;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);
//--------------Asyncthunk for Delete Stock-Entry Data--------------------------------
export const deleteStockEntry = createAsyncThunk(
    '/deleteStockEntry',
    async ({ deleteStockEntryId }, { rejectWithValue }) => {
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_BASE_URL}/stock/deletestockentry/${deleteStockEntryId}`,
                { headers: getHeaders() }
            );
            return response;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);
//--------------Asyncthunk for Update Stock-Entry Data--------------------------------
export const updateStockEntryData = createAsyncThunk(
    '/updateStockEntryData',
    async ({ updateStockEntryId, updateStockDetails }, { rejectWithValue }) => {

        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/stock/updatestockentry/${updateStockEntryId}`, updateStockDetails,
                { headers: getHeaders() }
            );
            return response;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);
//--------------Asyncthunk for Update Stock Status--------------------------------
export const updateStockStatus = createAsyncThunk(
    '/updateStockStatus',
    async ({ stockId, status }, { rejectWithValue }) => {

        try {
            const response = await axios.patch(
                `${process.env.REACT_APP_BASE_URL}/stock/updatestockstatus/${stockId}/${status}`, {},
                { headers: getHeaders() }
            );
            return response;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);
const stockMasterSlice = createSlice({
    name: 'stock',
    initialState,
    reducers: {},
    extraReducers: {
        //--------------Slice action for the Add Stock entry --------------------------------
        [addStockEntry.pending]: (state, action) => {
            state.pending = true;
            state.isStockMasterEntrySuccess = false;
            state.isError = false;
        },
        [addStockEntry.fulfilled]: (state, action) => {
            state.pending = false;
            state.isStockMasterEntrySuccess = true;
            state.isError = false;
        },
        [addStockEntry.rejected]: (state, action) => {
            state.pending = false;
            state.isStockMasterEntrySuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Stock Data --------------------------------
        [getStockData.pending]: (state, action) => {
            state.pending = false;
            state.isStockMasterSuccess = false;
            state.isError = false;
        },
        [getStockData.fulfilled]: (state, action) => {
            state.pending = false;
            state.isStockMasterSuccess = true;
            state.stockData = action.payload;
            state.isError = false;
        },
        [getStockData.rejected]: (state, action) => {
            state.pending = false;
            state.isStockMasterSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get-Total Of Stock Data --------------------------------
        [getTotalFieldValue.pending]: (state, action) => {
            state.pending = false;
            state.isTotalFieldDataSuccess = false;
            state.isError = false;
        },
        [getTotalFieldValue.fulfilled]: (state, action) => {
            state.pending = false;
            state.isTotalFieldDataSuccess = true;
            state.totalFieldData = action.payload;
            state.isError = false;
        },
        [getTotalFieldValue.rejected]: (state, action) => {
            state.pending = false;
            state.isTotalFieldDataSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get-Total Of Stock Data --------------------------------
        [getRealTotalFieldValue.pending]: (state, action) => {
            state.pending = false;
            state.isTotalRealDataSuccess = false;
            state.isError = false;
        },
        [getRealTotalFieldValue.fulfilled]: (state, action) => {
            state.pending = false;
            state.isTotalRealDataSuccess = true;
            state.totalRealData = action.payload;
            state.isError = false;
        },
        [getRealTotalFieldValue.rejected]: (state, action) => {
            state.pending = false;
            state.isTotalRealDataSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get-Total Of Stock Data --------------------------------
        [getCvdTotalFieldValue.pending]: (state, action) => {
            state.pending = false;
            state.isTotalCvdDataSuccess = false;
            state.isError = false;
        },
        [getCvdTotalFieldValue.fulfilled]: (state, action) => {
            state.pending = false;
            state.isTotalCvdDataSuccess = true;
            state.totalCvdData = action.payload;
            state.isError = false;
        },
        [getCvdTotalFieldValue.rejected]: (state, action) => {
            state.pending = false;
            state.isTotalCvdDataSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Vendor Stock Data --------------------------------
        [getVendorStockData.pending]: (state, action) => {
            state.pending = false;
            state.isSingleStockDataSuccess = false;
            state.isError = false;
        },
        [getVendorStockData.fulfilled]: (state, action) => {
            state.pending = false;
            state.isSingleStockDataSuccess = true;
            state.singleStockData = action.payload;
            state.isError = false;
        },
        [getVendorStockData.rejected]: (state, action) => {
            state.pending = false;
            state.isSingleStockDataSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Stock Entry Data Based On VendorId And Diamond Type---------------------
        [getSpecificStockEntry.pending]: (state, action) => {
            state.pending = false;
            state.isSingleStockEntrySuccess = false;
            state.isError = false;
        },
        [getSpecificStockEntry.fulfilled]: (state, action) => {
            state.pending = false;
            state.isSingleStockEntrySuccess = true;
            state.singleStockEntryData = action.payload;
            state.isError = false;
        },
        [getSpecificStockEntry.rejected]: (state, action) => {
            state.pending = false;
            state.isSingleStockEntrySuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Delete Stock Data
        [deleteStock.pending]: (state, action) => {
            state.pending = true;
            state.isStockMasterSuccess = false;
            state.isError = false;
        },
        [deleteStock.fulfilled]: (state, action) => {
            state.pending = false;
            state.isStockMasterSuccess = true;
            state.isError = false;
        },
        [deleteStock.rejected]: (state, action) => {
            state.pending = false;
            state.isStockMasterSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Delete Stock-Entry Data
        [deleteStockEntry.pending]: (state, action) => {
            state.pending = true;
            state.isSingleStockEntrySuccess = false;
            state.isError = false;
        },
        [deleteStockEntry.fulfilled]: (state, action) => {
            state.pending = false;
            state.isSingleStockEntrySuccess = true;
            state.isError = false;
        },
        [deleteStockEntry.rejected]: (state, action) => {
            state.pending = false;
            state.isSingleStockEntrySuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Update Stock-Entry Data
        [updateStockEntryData.pending]: (state, action) => {
            state.pending = true;
            state.isSingleStockEntrySuccess = false;
            state.isError = false;
        },
        [updateStockEntryData.fulfilled]: (state, action) => {
            state.pending = false;
            state.isSingleStockEntrySuccess = true;
            state.isError = false;
        },
        [updateStockEntryData.rejected]: (state, action) => {
            state.pending = false;
            state.isSingleStockEntrySuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Update Bulk Stock status--------------
        [updateStockStatus.pending]: (state, action) => {
            state.pending = true;
            state.isUpdateStockStatus = false;
            state.isError = false;
        },
        [updateStockStatus.fulfilled]: (state, action) => {
            state.pending = false;
            state.isUpdateStockStatus = true;
            state.isError = false;
        },
        [updateStockStatus.rejected]: (state, action) => {
            state.pending = false;
            state.isUpdateStockStatus = false;
            state.isError = true;
        },
    }
})
export default stockMasterSlice.reducer;
