import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders } from 'app/utils/handleHeader';
const initialState = {
    customerReportData: null,
    isInitialised: false,
    isCustomerReportSuccess: false,
    isCustomerReportPDFSuccess: false,
    isError: false,
    isLoading: false
};
//--------------Asyncthunk for Get Customer Report --------------------------------
export const getCustomerReportData = createAsyncThunk('getCustomerReportData', async ({ fromDate, toDate, customerId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/report/customerreport/${customerId}?fromDate=${fromDate}&toDate=${toDate}`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

// ----------------------------------------Genrate Customer-Report PDF --------------------------------
export const genrateCustomerReportPdf = createAsyncThunk('genrateCustomerReportPdf', async ({ fromDate, toDate, customerId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/report/generatecustomerreport/${customerId}?fromDate=${fromDate}&toDate=${toDate}`, {
            responseType: 'arraybuffer',
            headers: { 'Accept': 'application/pdf', ...getHeaders() }
        });

        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Customer_Report_${customerId}.pdf`; // Set the desired file name
        a.click();
        window.URL.revokeObjectURL(url);
        // return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

const customerReportSlice = createSlice({
    name: 'customerReportSlice',
    initialState,
    reducers: {},
    extraReducers: {
        //--------------Slice action for the Get Stock Data --------------------------------
        [getCustomerReportData.pending]: (state, action) => {
            state.isLoading = false;
            state.isCustomerReportSuccess = false;
            state.isError = false;
        },
        [getCustomerReportData.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isCustomerReportSuccess = true;
            state.customerReportData = action.payload;
            state.isError = false;
        },
        [getCustomerReportData.rejected]: (state, action) => {
            state.isLoading = false;
            state.isCustomerReportSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Stock Data --------------------------------
        [genrateCustomerReportPdf.pending]: (state, action) => {
            state.isLoading = false;
            state.isCustomerReportPDFSuccess = false;
            state.isError = false;
        },
        [genrateCustomerReportPdf.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isCustomerReportPDFSuccess = true;
            state.isError = false;
        },
        [genrateCustomerReportPdf.rejected]: (state, action) => {
            state.isLoading = false;
            state.isCustomerReportPDFSuccess = false;
            state.isError = true;
        },
    }
})
export default customerReportSlice.reducer;
