const selectMonth = async (selectedData, dataHouse) => {

    if (typeof selectedData !== 'number') {

        if (selectedData.trim() === 'all') {
            // If the search term is all, return the whole array
            return dataHouse;
        }
    }
    else {
        const filteredMonthData = [];
        dataHouse.forEach((data) => {
            const date = new Date(data.date);
            const month = date.getMonth() + 1;
            if (month === selectedData) {
                filteredMonthData.push(data);
            }
        });

        return filteredMonthData;
    }
};
const selectStatus = async (selectedData, dataHouse) => {

    if (typeof selectedData !== 'number') {

        if (selectedData.trim() === 'all') {
            // If the search term is all, return the whole array
            return dataHouse;
        }
        else {
            const filteredMonthData = [];
            dataHouse.forEach((data) => {
                if (data.payment_type.toLowerCase() === selectedData.toLowerCase()) {
                    filteredMonthData.push(data);
                }
            });

            return filteredMonthData;
        }
    }
};

export { selectMonth, selectStatus }