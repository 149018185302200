import { Box, Card, Grid, styled, TextField } from '@mui/material';
import dayjs from 'dayjs';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React from 'react';
import { H4 } from 'app/components/Typography';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useEffect } from 'react';
import { getUserInvoiceOrder } from 'app/contexts/reducers/transaction/oderSlice';
import { getSpecificInvoice } from 'app/contexts/reducers/transaction/invoiceSlice';
import { useParams } from 'react-router-dom';
import CustomerInvoiceOrder from './Shared/CustomerInvoiceOrder';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' }
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main }
}));

const SingleInvoice = ({ handleApiCall, apiCall }) => {
  const dispatch = useDispatch();
  const { invoiceId } = useParams();

  const { isuserInvoiceOrderSuccess, userInvoiceOrder } = useSelector((state) => state.order);
  const { isSpecificInvoiceSuccess, specificInvoiceData } = useSelector((state) => state.invoice);
  const intialInvoiceData = {
    bookNumber: dayjs().year(),
    customerName: '',
    customerId: null,
    usedWeight: '',
    selfWeight: '',
    usedPcs: '',
    labourChargePerPcs: '',
    labourCharge: '',
    purity: '',
    diamondCaratPrice: '',
    diamondPrice: '',
    productPrice: '',
    extraCost: 0,
    mobileNumber: '',
    address: '',
    email: '',
    date: dayjs(),
    remark: '',
    quantity: '',
    subTotal: '',
    total: '',
    orders: []
  };

  const [invoiceDetails, setInvoiceDetails] = useState(intialInvoiceData);

  const [specificInvoiceOrderData, setSpecificInvoiceOrderData] = useState([]);
  const [userInvoiceOrders, setUserInvoiceOrdrs] = useState([]);

  const [selectedOrderDetails, setSelectedOrderDetails] = useState([]);

  useEffect(() => {
    dispatch(getSpecificInvoice({ invoiceId }));
    dispatch(getUserInvoiceOrder({ invoiceId }));
  }, [dispatch, invoiceId]);
  // -----------------Set Details For Invoice ----------------
  useEffect(() => {
    if (specificInvoiceData) {
      for (const invoiceDetails of specificInvoiceOrderData) {
        setInvoiceDetails({
          bookNumber: invoiceDetails.book_number,
          customerName: invoiceDetails.customer_name,
          customerId: invoiceDetails.customer_id,
          usedWeight: invoiceDetails.studd_weight,
          selfWeight: invoiceDetails.self_weight,
          usedPcs: invoiceDetails.studd_pieces,
          labourChargePerPcs: invoiceDetails.labour_charge_per_pcs,
          labourCharge: invoiceDetails.labour_charge,
          purity: invoiceDetails.purity,
          diamondCaratPrice: invoiceDetails.diamond_carat_price,
          diamondPrice: invoiceDetails.diamond_price,
          productPrice: invoiceDetails.product_price,
          extraCost: invoiceDetails.extra_cost,
          mobileNumber: invoiceDetails.mobile_number,
          address: invoiceDetails.address,
          email: invoiceDetails.email,
          date: dayjs(invoiceDetails.date),
          remark: invoiceDetails.remark,
          quantity: invoiceDetails.quantity,
          subTotal: invoiceDetails.sub_total,
          total: invoiceDetails.total,
          orders: []
        });
      }
    }
  }, [invoiceId, specificInvoiceData, specificInvoiceOrderData, isSpecificInvoiceSuccess]);
  useEffect(() => {
    if (isuserInvoiceOrderSuccess) {
      setUserInvoiceOrdrs(userInvoiceOrder.data.data);
    }
  }, [userInvoiceOrder, isuserInvoiceOrderSuccess]);
  useEffect(() => {
    if (isSpecificInvoiceSuccess) {
      setSpecificInvoiceOrderData(specificInvoiceData.data.data);
    }
  }, [specificInvoiceData, isSpecificInvoiceSuccess]);
  return (
    <React.Fragment>
      {' '}
      <Grid container spacing={3} sx={{ mb: '24px' }}>
        <Grid item xs={12} md={12}>
          <StyledCard elevation={6}>
            <H4 sx={{ mb: 2 }}>CREATE INVOICE</H4>
            <Grid container sx={{ marginBottom: 3 }}>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <ContentBox>
                  <TextField
                    id='customerName'
                    type='text'
                    margin='dense'
                    size='small'
                    label='Customer Name'
                    required
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='standard'
                    value={invoiceDetails.customerName}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    id='usedPcs'
                    type='text'
                    margin='dense'
                    size='small'
                    label='Used Piece'
                    required
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='standard'
                    value={invoiceDetails.usedPcs}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    id='labourpiececharge'
                    type='number'
                    margin='dense'
                    size='small'
                    label='Labour/Piece Charge'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='standard'
                    value={invoiceDetails.labourChargePerPcs}
                  />
                </ContentBox>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <ContentBox>
                  <TextField
                    id='customerId'
                    required
                    type='text'
                    margin='dense'
                    size='small'
                    label='Customer Id'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='standard'
                    value={invoiceDetails.customerId}
                  />
                </ContentBox>

                <ContentBox>
                  <TextField
                    id='usedWeight'
                    required
                    type='text'
                    margin='dense'
                    size='small'
                    label='Used Weight'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='standard'
                    value={invoiceDetails.usedWeight}
                  />
                </ContentBox>

                <ContentBox>
                  <TextField
                    id='labourcharge'
                    required
                    type='text'
                    margin='dense'
                    size='small'
                    label='Labour Charge'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='standard'
                    value={invoiceDetails.labourCharge}
                  />
                </ContentBox>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: '80%', my: 1 }}
                    label='Date'
                    disabled
                    format='DD/MM/YYYY'
                    value={invoiceDetails.date}
                    slotProps={{ textField: { variant: 'standard' } }}
                  />
                </LocalizationProvider>
                <ContentBox>
                  <TextField
                    id='selfUsedWeight'
                    type='number'
                    margin='dense'
                    size='small'
                    label='Self Used Weight'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='standard'
                    value={invoiceDetails.selfWeight}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    id='diamondcaratprice'
                    type='number'
                    margin='dense'
                    size='small'
                    label='Diamond/Carat Price'
                    disabled={Number(invoiceDetails.selfWeight) === 0 ? true : false}
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='standard'
                    value={invoiceDetails.diamondCaratPrice}
                  />
                </ContentBox>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <ContentBox>
                  <TextField
                    id='bookNumber'
                    type='text'
                    margin='dense'
                    size='small'
                    label='Book Number'
                    required
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='standard'
                    value={invoiceDetails.bookNumber}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    id='productPrice'
                    type='number'
                    margin='dense'
                    size='small'
                    label='Product Price'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='standard'
                    value={invoiceDetails.productPrice}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    id='diamondPrice'
                    type='text'
                    margin='dense'
                    size='small'
                    label='Diamond Price'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='standard'
                    value={invoiceDetails.diamondPrice}
                  />
                </ContentBox>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                  <ContentBox>
                    <TextField
                      id='purity'
                      type='text'
                      margin='dense'
                      size='small'
                      label='Purity'
                      InputLabelProps={{ shrink: true }}
                      sx={{ my: 1, width: '80%' }}
                      variant='standard'
                      value={invoiceDetails.purity}
                    />
                  </ContentBox>
                  <ContentBox>
                    <TextField
                      multiline
                      maxRows={4}
                      id='specialNote'
                      type='text'
                      margin='dense'
                      size='small'
                      label='Special Note'
                      InputLabelProps={{ shrink: true }}
                      sx={{ my: 1, width: '80%' }}
                      variant='standard'
                      value={invoiceDetails.remark}
                    />
                  </ContentBox>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                  <ContentBox>
                    <TextField
                      id='email'
                      type='email'
                      margin='dense'
                      size='small'
                      label='Email'
                      InputLabelProps={{ shrink: true }}
                      sx={{ my: 1, width: '80%' }}
                      variant='standard'
                      value={invoiceDetails.email}
                    />
                  </ContentBox>
                  <ContentBox>
                    <TextField
                      id='extraCost'
                      type='text'
                      margin='dense'
                      size='small'
                      label='Extra Cost'
                      InputLabelProps={{ shrink: true }}
                      sx={{ my: 1, width: '80%' }}
                      variant='standard'
                      value={invoiceDetails.extraCost}
                    />
                  </ContentBox>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                  <ContentBox>
                    <TextField
                      multiline
                      maxRows={4}
                      id='address'
                      type='text'
                      margin='dense'
                      size='small'
                      label='Address'
                      InputLabelProps={{ shrink: true }}
                      sx={{ my: 1, width: '80%' }}
                      variant='standard'
                      value={invoiceDetails.address}
                    />
                  </ContentBox>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                  <ContentBox>
                    <TextField
                      id='mobileNumber'
                      type='tel'
                      margin='dense'
                      size='small'
                      label='Mobile Number'
                      InputLabelProps={{ shrink: true }}
                      sx={{ my: 1, width: '80%' }}
                      variant='standard'
                      value={invoiceDetails.mobileNumber == null ? '' : invoiceDetails.mobileNumber}
                    />
                  </ContentBox>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                  <ContentBox>
                    <TextField
                      id='quantity'
                      type='text'
                      margin='dense'
                      size='small'
                      label='Quantity'
                      InputLabelProps={{ shrink: true }}
                      sx={{ my: 1, width: '80%' }}
                      variant='outlined'
                      value={invoiceDetails.quantity}
                    />
                  </ContentBox>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                  <ContentBox>
                    <TextField
                      id='subtotal'
                      type='text'
                      margin='dense'
                      size='small'
                      label='Sub Total'
                      InputLabelProps={{ shrink: true }}
                      sx={{ my: 1, width: '80%' }}
                      variant='outlined'
                      value={invoiceDetails.subTotal}
                    />
                  </ContentBox>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                  <ContentBox>
                    <TextField
                      id='tota;'
                      type='text'
                      margin='dense'
                      size='small'
                      label='Total'
                      InputLabelProps={{ shrink: true }}
                      sx={{ my: 1, width: '80%' }}
                      variant='outlined'
                      value={invoiceDetails.total}
                    />
                  </ContentBox>
                </Grid>
              </Grid>
            </Grid>
            {userInvoiceOrders.length > 0 && (
              <CustomerInvoiceOrder
                invoiceId={invoiceId}
                userInvoiceOrders={userInvoiceOrders}
                selectedOrderDetails={selectedOrderDetails}
                setSelectedOrderDetails={setSelectedOrderDetails}
              />
            )}
          </StyledCard>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default SingleInvoice;
