import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  deleteAccountData,
  deleteEmployeeAccountData,
  deleteVendorAccountData
} from 'app/contexts/reducers/account/accountSlice';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

export default function DeleteAccountDialog({
  open,
  onClose,
  deleteAccountId,
  setApiCall,
  handleClickSuccess,
  handleClickError,
  setError,
  accountType
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const handleDelete = async () => {
    try {
      const code = window.prompt('Please enter the code to delete the stock:');
      if (code === '3535') {
        let response;
        if (accountType.toLowerCase() === 'customer') {
          response = await dispatch(deleteAccountData({ deleteAccountId }));
        } else if (accountType.toLowerCase() === 'vendor') {
          response = await dispatch(deleteVendorAccountData({ deleteAccountId }));
        } else {
          response = await dispatch(deleteEmployeeAccountData({ deleteAccountId }));
        }
        if (!response.hasOwnProperty('error')) {
          onClose();
          setApiCall((prev) => !prev);
          handleClickSuccess('Account delete successfully');
          setLoading(false);
        } else {
          throw response;
        }
      } else {
        window.alert('Incorrect code entered. Stock deletion canceled.');
      }
    } catch (e) {
      handleClickError();
      setError(e);
      setLoading(false);
    }
  };
  return (
    <Box>
      <Dialog
        open={open}
        onClose={onclose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Account Delete Confirmation Dialog</DialogTitle>

        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to delete this Account? This action cannot be undone.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} color='primary'>
            Disagree
          </Button>

          <LoadingButton
            onClick={handleDelete}
            color='primary'
            loading={loading}
            variant='contained'
            sx={{ my: 2 }}
          >
            Delete Account
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
