import {
  Alert,
  Box,
  Card,
  Grid,
  Icon,
  IconButton,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  useTheme
} from '@mui/material';
import { H6 } from 'app/components/Typography';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteCustomerReceipt from './DeleteCustomerReceipt';

import {
  genrateCustomerReceiptPdf,
  getAllCustomerReceiptData
} from 'app/contexts/reducers/transaction/customerReceiptSlice';
import UpdateCustomerReceiptDialog from './UpdateCustomerReceiptDialog';
import SearchAccount from './SearchAccount';
import FilterOrderByMonth from './FilterReceiptByMonth';
import FilterStatus from './FilterStatus';

const CardHeader = styled(Box)(() => ({
  display: 'flex',
  paddingLeft: '24px',
  paddingRight: '24px',
  marginBottom: '12px',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  textTransform: 'capitalize'
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)'
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' }
}));

const Small = styled('small')(({ bgcolor }) => ({
  width: 50,
  height: 15,
  color: '#fff',
  padding: '2px 8px',
  borderRadius: '4px',
  overflow: 'hidden',
  background: bgcolor,
  boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)'
}));

const CustomerReceiptTable = ({ apiCall, handleApiCall }) => {
  const { palette } = useTheme();
  const bgSuccess = palette.success.main;
  const bgSecondary = palette.secondary.main;
  const { isAllCustomerReceiptsSuccess, allCustomerReceiptData } = useSelector(
    (state) => state.customerReceipt
  );
  const [allCustomerReceiptsData, setAllCustomerReceiptsData] = useState([]);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [receiptId, setReceiptId] = useState(null);
  const [deleteReceiptId, setDeleteReceiptId] = useState(null);
  const [errorOpen, setErrorOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [error, setError] = useState();
  const [deleteOpen, setDeleteOpen] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllCustomerReceiptData());
  }, [dispatch, apiCall]);

  useEffect(() => {
    if (isAllCustomerReceiptsSuccess) {
      if (Number(allCustomerReceiptData.data.code) === 200) {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const customerReceiptWithCurrentMonth = allCustomerReceiptData.data.data.filter(
          (receipt) => {
            const receiptDate = new Date(receipt.date);
            const receiptMonth = receiptDate.getMonth() + 1; // Adding 1 since getMonth() returns a zero-based index
            return receiptMonth === currentMonth;
          }
        );
        setAllCustomerReceiptsData(customerReceiptWithCurrentMonth);
      } else {
        setAllCustomerReceiptsData([]);
      }
    }
  }, [isAllCustomerReceiptsSuccess, allCustomerReceiptData]);
  const convertDate = (isoDateString) => {
    const date = new Date(isoDateString);

    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    };

    const formattedDate = date.toLocaleString('en-GB', options).replace(/\//g, '-');
    return formattedDate;
  };
  function handleUpdateClose() {
    setUpdateOpen(false);
  }
  function handleDeleteClose() {
    setDeleteOpen(false);
  }
  function handleClickSuccess(message) {
    setErrorOpen(false);
    setSuccessMessage(message);
    setSuccessOpen(true);
  }
  function handleClickError() {
    setSuccessOpen(false);
    setErrorOpen(true);
  }
  function handleSnakeBarClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
    setErrorOpen(false);
  }
  function calculateTotalAndDiscount(allCustomerReceiptsData) {
    let amount = 0;
    let discountAmount = 0;
    for (let i = 0; i < allCustomerReceiptsData.length; i++) {
      const receipt = allCustomerReceiptsData[i];

      // Add the amount to the total
      amount += parseInt(receipt.amount);

      // Add the discount_amount to the discountAmount
      discountAmount += parseInt(receipt.discount_amount);
    }

    return { amount, discountAmount };
  }

  return (
    <React.Fragment>
      <Card elevation={3} sx={{ pt: '20px', mb: 3 }}>
        <CardHeader>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <Title>Customer Receipt Table</Title>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <SearchAccount setAllCustomerReceiptsData={setAllCustomerReceiptsData} />
                </Grid>
                <Grid item>
                  <FilterOrderByMonth setAllCustomerReceiptsData={setAllCustomerReceiptsData} />
                </Grid>
                <Grid item>
                  <FilterStatus setAllCustomerReceiptsData={setAllCustomerReceiptsData} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardHeader>

        <Box overflow='auto'>
          <ProductTable>
            <TableHead sx={{ backgroundColor: 'rgb(34,41,67)' }}>
              <TableRow>
                <TableCell align='center' sx={{ px: 2, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Index
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  Customer Name
                </TableCell>
                <TableCell align='left' sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Amount
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  Discount Amount
                </TableCell>
                <TableCell align='center' sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Payment Type
                </TableCell>
                <TableCell align='center' sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Book Number
                </TableCell>

                <TableCell align='center' sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Date
                </TableCell>

                <TableCell
                  align='center'
                  sx={{ px: 5, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {allCustomerReceiptsData && allCustomerReceiptsData.length > 0 ? (
                allCustomerReceiptsData.map((customerReceiptDetails, index) => (
                  <TableRow key={index} hover>
                    <TableCell align='center' sx={{ px: 0, textTransform: 'capitalize' }}>
                      <H6 sx={{ px: 2 }}>{index + 1}</H6>
                    </TableCell>
                    <TableCell
                      align='center'
                      sx={{ px: 0, textTransform: 'capitalize', fontWeight: 'bold' }}
                      colSpan={2}
                    >
                      {customerReceiptDetails.customer_name}
                    </TableCell>
                    <TableCell
                      align='left'
                      sx={{ px: 2, fontWeight: 'bold', textTransform: 'capitalize' }}
                    >
                      {customerReceiptDetails.amount}
                    </TableCell>
                    <TableCell align='center' sx={{ px: 2, fontWeight: 'bold' }} colSpan={2}>
                      {customerReceiptDetails.discount_amount}
                    </TableCell>

                    <TableCell align='center' sx={{ px: 2 }}>
                      {customerReceiptDetails.payment_type === 'CASH' && (
                        <Small bgcolor={bgSecondary}>CASH</Small>
                      )}
                      {customerReceiptDetails.payment_type === 'BANK' && (
                        <Small bgcolor={bgSuccess}>BANK</Small>
                      )}
                    </TableCell>
                    <TableCell align='center' sx={{ px: 2 }}>
                      {customerReceiptDetails.book_number}
                    </TableCell>

                    <TableCell align='center' sx={{ px: 0 }}>
                      {convertDate(customerReceiptDetails.date)}
                    </TableCell>

                    <TableCell align='center' sx={{ px: 2 }} colSpan={2}>
                      <Tooltip title='Print'>
                        <IconButton
                          onClick={() => {
                            const invoiceId = customerReceiptDetails.id;
                            dispatch(genrateCustomerReceiptPdf({ invoiceId }));
                          }}
                        >
                          <Icon color='primary'>print</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Edit'>
                        <IconButton
                          onClick={() => {
                            setReceiptId(customerReceiptDetails.id);
                            setUpdateOpen(true);
                          }}
                        >
                          <Icon color='primary'>edit</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Delete'>
                        <IconButton
                          onClick={() => {
                            setDeleteReceiptId(customerReceiptDetails.id);
                            setDeleteOpen(true);
                          }}
                        >
                          <Icon color='error'>close</Icon>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={11}
                    align='center'
                    sx={{ color: 'rgb(34,41,67)', fontWeight: 'bold' }}
                  >
                    No Receipt Data Found
                  </TableCell>
                </TableRow>
              )}
              <TableRow sx={{ backgroundColor: 'rgb(34,41,67)' }}>
                <TableCell align='center' sx={{ px: 0, textTransform: 'capitalize' }}></TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 0, textTransform: 'capitalize', fontWeight: 'bold' }}
                  colSpan={2}
                ></TableCell>
                <TableCell
                  align='left'
                  sx={{ px: 2, fontWeight: 'bold', color: 'white', textTransform: 'capitalize' }}
                >
                  {calculateTotalAndDiscount(allCustomerReceiptsData).amount}
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 2, color: 'white', fontWeight: 'bold' }}
                  colSpan={2}
                >
                  {calculateTotalAndDiscount(allCustomerReceiptsData).discountAmount}
                </TableCell>
                <TableCell align='center' sx={{ px: 2 }}></TableCell>
                <TableCell align='center' sx={{ px: 2 }}></TableCell>
                <TableCell align='center' sx={{ px: 0 }}></TableCell>
                <TableCell align='center' sx={{ px: 2 }} colSpan={2}></TableCell>
              </TableRow>
            </TableBody>
          </ProductTable>
        </Box>
      </Card>
      {/* Alert */}
      <Snackbar open={successOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='error'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {error?.payload}
        </Alert>
      </Snackbar>
      {/* Update-Customer-Dialog */}
      <UpdateCustomerReceiptDialog
        open={updateOpen}
        onClose={handleUpdateClose}
        receiptId={receiptId}
        handleApiCall={handleApiCall}
        handleClickSuccess={handleClickSuccess}
        setError={setError}
        handleClickError={handleClickError}
      />
      {/* Start-Delete-Account */}
      <DeleteCustomerReceipt
        open={deleteOpen}
        onClose={handleDeleteClose}
        receiptId={deleteReceiptId}
        handleApiCall={handleApiCall}
        handleClickSuccess={handleClickSuccess}
        handleClickError={handleClickError}
        setError={setError}
      />
    </React.Fragment>
  );
};

export default CustomerReceiptTable;
