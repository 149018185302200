import { MenuItem, Select } from '@mui/material';

import { useSelector } from 'react-redux';
import { useState } from 'react';
import { selectMonth } from '../helper/filterIncomeEntry';

const FilterIncomeEntryByMonth = ({ setIncomeEntriesData }) => {
  const { allIncomeEntriesData } = useSelector((state) => state.incomeEntry);
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const [defaultSelect, setDefaultSelect] = useState(currentMonth);

  const handleSelect = async (event) => {
    setDefaultSelect(event.target.value);
    const seletctedData = await selectMonth(event.target.value, allIncomeEntriesData.data.data);
    setIncomeEntriesData(seletctedData);
  };
  return (
    <Select size='small' value={defaultSelect} onChange={handleSelect}>
      <MenuItem value='all' id='all'>
        Select Month
      </MenuItem>

      <MenuItem value={currentMonth} id={currentMonth}>
        This Month
      </MenuItem>

      <MenuItem
        value={currentMonth === 1 ? 12 : currentMonth - 1}
        id={currentMonth === 1 ? 12 : currentMonth - 1}
      >
        Last Month
      </MenuItem>
    </Select>
  );
};

export default FilterIncomeEntryByMonth;
