import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Card,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  styled,
  TextField
} from '@mui/material';
import {
  addVendorAccountData,
  updateAccountType
} from 'app/contexts/reducers/account/accountSlice';
import { getaccountgroup } from 'app/contexts/reducers/accountgroup/accountgroupSlice';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { convertAccountId } from 'app/utils/convertId';
import Loading from 'app/components/MatxLoading';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' }
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main }
}));

const AddVendorCard = ({ handleApiCall }) => {
  const dispatch = useDispatch();
  const { accountgroupData } = useSelector((state) => state.accountGroup);
  const { isAccountGroupSuccess } = useSelector((state) => state.accountGroup);
  const intialData = {
    accountGroupId: '',
    vendorType: 'RETAILER',
    accountName: '',
    openingBalance: '',
    reference: '',
    specialNote: '',
    firstName: '',
    lastName: '',
    firmName: '',
    address: '',
    mobileNumber: '',
    email: ''
  };
  const [accountgroup, setAccountGroup] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [accountGroupName, setAccountGroupName] = useState();
  const [accountGroupId, setAccountGroupId] = useState();
  const [vendorData, setVendorData] = useState(intialData);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [accountNameError, setAccountNameError] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState();

  useEffect(() => {
    dispatch(getaccountgroup());
  }, [dispatch]);
  useEffect(() => {
    if (isAccountGroupSuccess) {
      setIsLoading(true);
      setAccountGroup(accountgroupData.data.data);
      convertAccountId(accountgroupData.data.data, 'vendor').then((data) => {
        setAccountGroupName(data.account_group_name);
        setVendorData({ ...vendorData, accountGroupId: data.id });
        setAccountGroupId(data.id);
      });

      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [isAccountGroupSuccess, accountgroupData]);
  function handleClickError() {
    setSuccessOpen(false);
    setErrorOpen(true);
  }
  function handleClickSuccess(message) {
    setErrorOpen(false);
    setSuccessMessage(message);
    setSuccessOpen(true);
  }
  function handleSnakeBarClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
    setErrorOpen(false);
  }
  const handleAccountGroupSelect = async (event) => {
    setVendorData({ ...vendorData, accountGroupId: accountGroupId });
    setAccountGroupName(event.target.value.toLowerCase());
    dispatch(updateAccountType(event.target.value.toLowerCase()));
  };
  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (accountGroupName.toLowerCase() === 'vendor') {
        if (vendorData.accountName.length > 0) {
          const updateVendorData = { ...vendorData };
          Object.keys(updateVendorData).forEach((key) => {
            if (updateVendorData[key] === '') {
              delete updateVendorData[key];
            }
          });
          const responseValue = await dispatch(addVendorAccountData(updateVendorData));
          if (!responseValue.hasOwnProperty('error')) {
            handleClickSuccess('Vendor added successfully');
            setVendorData({ ...intialData, accountGroupId: accountGroupId });
            handleApiCall();
            setLoading(false);
          } else {
            throw responseValue;
          }
        } else {
          setAccountNameError(true);
          handleClickError();
          setError({ payload: 'Account-Name Is Required!' });
          setLoading(false);
        }
      }
    } catch (error) {
      handleClickError();
      setError(error);
      setLoading(false);
    }
  };
  const filteredAccountGroup = accountgroup.filter((accountGroupData) => {
    const groupName = accountGroupData.account_group_name.toLowerCase();
    return groupName === 'customer' || groupName === 'employee' || groupName === 'vendor';
  });
  const menuItems = filteredAccountGroup.map((accountGroupData, index) => (
    <MenuItem value={accountGroupData.account_group_name.toLowerCase()} id={index} key={index}>
      {accountGroupData.account_group_name}
    </MenuItem>
  ));

  return (
    <React.Fragment>
      <Snackbar open={successOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='error'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {error?.payload}
        </Alert>
      </Snackbar>
      <Grid container spacing={3} sx={{ mb: '24px' }}>
        <Grid item xs={12} md={12}>
          <StyledCard elevation={6}>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <InputLabel id='accountGroupName'>Account Group</InputLabel>
                <Select
                  size='small'
                  sx={{ width: '50%', my: 1 }}
                  defaultValue={'vendor'}
                  variant='standard'
                  onChange={handleAccountGroupSelect}
                  labelId='accountGroupName'
                >
                  {!isLoading ? menuItems : <Loading />}
                </Select>

                <InputLabel id='vendorType'>Vendor Type</InputLabel>
                <Select
                  size='small'
                  sx={{ width: '50%', my: 1 }}
                  labelId='vendorType'
                  variant='standard'
                  onChange={(e) => {
                    setVendorData({ ...vendorData, vendorType: e.target.value });
                  }}
                  value={vendorData.vendorType}
                >
                  <MenuItem value='FIRM' id={1}>
                    FIRM
                  </MenuItem>
                  <MenuItem value='RETAILER' id={2}>
                    RETAILER
                  </MenuItem>
                </Select>

                <ContentBox>
                  <TextField
                    error={accountNameError}
                    id='accountName'
                    type='text'
                    size='small'
                    margin='dense'
                    label='Account Name'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    required
                    helperText={accountNameError ? 'Please Enter Account Name' : ''}
                    value={vendorData.accountName}
                    onChange={(e) => {
                      setAccountNameError(false);
                      setVendorData({ ...vendorData, accountName: e.target.value });
                    }}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    id='openingBalance'
                    type='number'
                    size='small'
                    margin='dense'
                    label='Opening Balance'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={vendorData.openingBalance}
                    onChange={(e) => {
                      setVendorData({ ...vendorData, openingBalance: e.target.value });
                    }}
                  />
                </ContentBox>

                <ContentBox>
                  <TextField
                    id='reference'
                    type='text'
                    margin='dense'
                    size='small'
                    label='Reference'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={vendorData.reference}
                    onChange={(e) => {
                      setVendorData({ ...vendorData, reference: e.target.value });
                    }}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    multiline
                    maxRows={4}
                    id='specialNote'
                    type='text'
                    margin='dense'
                    size='small'
                    label='Special Note'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={vendorData.specialNote}
                    onChange={(e) => {
                      setVendorData({ ...vendorData, specialNote: e.target.value });
                    }}
                  />
                </ContentBox>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ContentBox>
                  <TextField
                    id='firmName'
                    type='text'
                    margin='dense'
                    size='small'
                    label='Firm Name'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={vendorData.firmName}
                    onChange={(e) => {
                      setVendorData({ ...vendorData, firmName: e.target.value });
                    }}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    id='firstName'
                    type='text'
                    margin='dense'
                    size='small'
                    label='First Name'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={vendorData.firstName}
                    onChange={(e) => {
                      setVendorData({ ...vendorData, firstName: e.target.value });
                    }}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    id='lastName'
                    type='text'
                    margin='dense'
                    size='small'
                    label='Last Name'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={vendorData.lastName}
                    onChange={(e) => {
                      setVendorData({ ...vendorData, lastName: e.target.value });
                    }}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    id='address'
                    type='text'
                    margin='dense'
                    size='small'
                    label='Address'
                    InputLabelProps={{ shrink: true }}
                    multiline
                    maxRows={4}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={vendorData.address}
                    onChange={(e) => {
                      setVendorData({ ...vendorData, address: e.target.value });
                    }}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    id='contactNumber'
                    type='text'
                    margin='dense'
                    size='small'
                    label='Contact Number'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={vendorData.mobileNumber}
                    onChange={(e) => {
                      setVendorData({ ...vendorData, mobileNumber: e.target.value });
                    }}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    id='email'
                    type='email'
                    margin='dense'
                    size='small'
                    label='Email'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={vendorData.email}
                    onChange={(e) => {
                      setVendorData({ ...vendorData, email: e.target.value });
                    }}
                  />
                </ContentBox>
              </Grid>
              <LoadingButton
                onClick={handleSubmit}
                color='primary'
                loading={loading}
                variant='contained'
                sx={{ my: 2, textTransform: 'capitalize' }}
              >
                Add {accountGroupName}
              </LoadingButton>
            </Grid>
          </StyledCard>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AddVendorCard;
