import {
  Alert,
  Box,
  Card,
  Grid,
  Icon,
  IconButton,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import { H6 } from 'app/components/Typography';
import { genrateInvoicePdf, getInvoices } from 'app/contexts/reducers/transaction/invoiceSlice';

import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { calculateTotalSum } from '../helper/invoiceTotal';
import { useNavigate } from 'react-router-dom';
import UpdateInvoiceDialog from './UpdateInvoiceDialog';
import DeleteInvoice from './DeleteInvoice';
import FilterInvoiceByMonth from './FilterInvoiceByMonth';
import SearchAccount from './SearchAccount';
const CardHeader = styled(Box)(() => ({
  display: 'flex',
  paddingLeft: '24px',
  paddingRight: '24px',
  marginBottom: '12px',
  alignItems: 'center',
  justifyContent: 'space-between'
}));
const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)'
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' }
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  textTransform: 'capitalize'
}));
const CustomerInvoiceTable = ({ apiCall, handleApiCall }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAllInvoiceSuccess, invoiceData } = useSelector((state) => state.invoice);

  const [customerInvoiceData, setCustomerInvoiceData] = useState([]);
  const [updateInvoiceId, setUpdateInvoiceId] = useState(null);
  const [deleteInvoiceId, setDeleteInvoiceId] = useState(null);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [error, setError] = useState();

  const convertDate = (isoDateString) => {
    const date = new Date(isoDateString);

    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    };

    const formattedDate = date.toLocaleString('en-GB', options).replace(/\//g, '-');
    return formattedDate;
  };
  useEffect(() => {
    dispatch(getInvoices());
  }, [dispatch, apiCall]);
  useEffect(() => {
    if (isAllInvoiceSuccess) {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1;
      const invoiceWithCurrentMonth = invoiceData.data.data.filter((invoice) => {
        const invoiceDate = new Date(invoice.date);
        const invoiceMonth = invoiceDate.getMonth() + 1; // Adding 1 since getMonth() returns a zero-based index
        return invoiceMonth === currentMonth;
      });
      setCustomerInvoiceData(invoiceWithCurrentMonth);
    }
  }, [invoiceData, isAllInvoiceSuccess]);
  function handleUpdateClose() {
    setUpdateOpen(false);
  }
  function handleDeleteClose() {
    setDeleteOpen(false);
  }
  function handleClickSuccess(message) {
    setErrorOpen(false);
    setSuccessMessage(message);
    setSuccessOpen(true);
  }
  function handleClickError() {
    setSuccessOpen(false);
    setErrorOpen(true);
  }
  function handleSnakeBarClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
    setErrorOpen(false);
  }
  return (
    <React.Fragment>
      <Card elevation={3} sx={{ pt: '20px', mb: 3 }}>
        <CardHeader>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <Title>Invoice Table</Title>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <SearchAccount setCustomerInvoiceData={setCustomerInvoiceData} />
                </Grid>
                <Grid item>
                  <FilterInvoiceByMonth setCustomerInvoiceData={setCustomerInvoiceData} />
                </Grid>
                <Grid item>{/* <FilterStatus setOrderdata={setOrderData} /> */}</Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardHeader>

        <Box overflow='auto'>
          <ProductTable>
            <TableHead sx={{ backgroundColor: 'rgb(34,41,67)' }}>
              <TableRow>
                <TableCell
                  colSpan={2}
                  align='center'
                  sx={{ px: 2, fontWeight: 'bold', color: '#FFFFFF' }}
                >
                  Bill Number
                </TableCell>
                <TableCell
                  colSpan={2}
                  align='center'
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                >
                  Customer Name
                </TableCell>
                <TableCell
                  colSpan={3}
                  align='left'
                  sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}
                >
                  Mobile Number
                </TableCell>
                <TableCell
                  colSpan={2}
                  align='left'
                  sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}
                >
                  Studd Pcs
                </TableCell>
                <TableCell
                  colSpan={2}
                  align='left'
                  sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}
                >
                  Self Wt
                </TableCell>
                <TableCell
                  colSpan={2}
                  align='left'
                  sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}
                >
                  Studd Wt
                </TableCell>
                <TableCell
                  colSpan={2}
                  align='center'
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                >
                  Quantity
                </TableCell>
                <TableCell
                  colSpan={2}
                  align='center'
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                >
                  Total Price
                </TableCell>

                <TableCell
                  colSpan={2}
                  align='center'
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                >
                  Date
                </TableCell>
                <TableCell
                  align='center'
                  colSpan={4}
                  sx={{ px: 5, fontWeight: 'bold', color: '#FFFFFF' }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {customerInvoiceData && customerInvoiceData.length > 0 ? (
                customerInvoiceData.map((invoiceDetails, index) => (
                  <TableRow key={index} hover>
                    <TableCell
                      colSpan={2}
                      align='center'
                      sx={{ px: 0, textTransform: 'capitalize' }}
                    >
                      <H6 sx={{ px: 2 }}>{invoiceDetails.id}</H6>
                    </TableCell>

                    <TableCell
                      colSpan={2}
                      align='center'
                      sx={{ px: 0, textTransform: 'capitalize' }}
                    >
                      {invoiceDetails.customer_name}
                    </TableCell>
                    <TableCell
                      colSpan={3}
                      align='center'
                      sx={{ px: 2, textTransform: 'capitalize' }}
                    >
                      {invoiceDetails.mobile_number}
                    </TableCell>
                    <TableCell
                      colSpan={2}
                      align='center'
                      sx={{ px: 2, textTransform: 'capitalize' }}
                    >
                      {invoiceDetails.studd_pieces}
                    </TableCell>
                    <TableCell
                      colSpan={2}
                      align='center'
                      sx={{ px: 2, textTransform: 'capitalize' }}
                    >
                      {invoiceDetails.self_weight ? invoiceDetails.self_weight : 0}
                    </TableCell>
                    <TableCell
                      colSpan={2}
                      align='center'
                      sx={{ px: 2, textTransform: 'capitalize' }}
                    >
                      {invoiceDetails.studd_weight}
                    </TableCell>
                    <TableCell colSpan={2} align='center' sx={{ px: 2 }}>
                      {invoiceDetails.quantity}
                    </TableCell>

                    <TableCell colSpan={2} align='center' sx={{ px: 2 }}>
                      {invoiceDetails.total}
                    </TableCell>

                    <TableCell colSpan={2} align='center' sx={{ px: 0 }}>
                      {convertDate(invoiceDetails.date)}
                    </TableCell>
                    <TableCell align='center' colSpan={4} sx={{ px: 2 }}>
                      <Tooltip title='Delete'>
                        <IconButton
                          onClick={() => {
                            setDeleteInvoiceId(invoiceDetails.id);
                            setDeleteOpen(true);
                          }}
                        >
                          <Icon color='error'>close</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Edit'>
                        <IconButton
                          onClick={() => {
                            setUpdateInvoiceId(invoiceDetails.id);
                            setUpdateOpen(true);
                          }}
                        >
                          <Icon color='primary'>edit</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='View'>
                        <IconButton
                          onClick={() => {
                            navigate(`/invoice/${invoiceDetails.id}`);
                          }}
                        >
                          <Icon color='primary'>keyboard_arrow_right</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Print'>
                        <IconButton
                          onClick={() => {
                            dispatch(genrateInvoicePdf({ invoiceId: invoiceDetails.id }));
                          }}
                        >
                          <Icon color='primary'>print</Icon>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    align='center'
                    colSpan={23}
                    sx={{ color: 'rgb(34,41,67)', fontWeight: 'bold' }}
                  >
                    Orders Are Prepairing!!
                  </TableCell>
                </TableRow>
              )}
              <TableRow sx={{ backgroundColor: 'rgb(34,41,67)' }}>
                <TableCell
                  colSpan={2}
                  align='center'
                  sx={{ px: 0, textTransform: 'capitalize' }}
                ></TableCell>

                <TableCell
                  colSpan={2}
                  align='center'
                  sx={{ px: 0, textTransform: 'capitalize' }}
                ></TableCell>
                <TableCell
                  colSpan={3}
                  align='left'
                  sx={{ px: 2, textTransform: 'capitalize' }}
                ></TableCell>
                <TableCell
                  colSpan={2}
                  align='center'
                  sx={{ px: 2, fontSize: '15px', color: 'white' }}
                >
                  {calculateTotalSum(customerInvoiceData).totalStudPcs}
                </TableCell>
                <TableCell colSpan={2} align='center' sx={{ px: 2 }}></TableCell>
                <TableCell colSpan={2} align='center' sx={{ px: 2 }}></TableCell>
                <TableCell colSpan={2} align='center' sx={{ px: 2 }}></TableCell>

                <TableCell
                  colSpan={2}
                  align='center'
                  sx={{ px: 2, fontSize: '15px', color: 'white' }}
                >
                  <b> {calculateTotalSum(customerInvoiceData).totalSum}</b>
                </TableCell>

                <TableCell colSpan={2} align='center' sx={{ px: 0 }}></TableCell>
                <TableCell colSpan={4} align='center' sx={{ px: 2 }}></TableCell>
              </TableRow>
            </TableBody>
          </ProductTable>
        </Box>
        {/* Alert */}
        <Snackbar open={successOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
          <Alert
            onClose={handleSnakeBarClose}
            severity='success'
            sx={{ width: '100%' }}
            variant='filled'
          >
            {successMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
          <Alert
            onClose={handleSnakeBarClose}
            severity='error'
            sx={{ width: '100%' }}
            variant='filled'
          >
            {error?.payload}
          </Alert>
        </Snackbar>
      </Card>
      {/* Update-Invoice-Dialog */}
      {updateInvoiceId && (
        <UpdateInvoiceDialog
          open={updateOpen}
          onClose={handleUpdateClose}
          updateInvoiceId={updateInvoiceId}
          handleApiCall={handleApiCall}
          apiCall={apiCall}
          handleClickSuccess={handleClickSuccess}
          setError={setError}
          handleClickError={handleClickError}
        />
      )}
      {/* Delete Invoice_Dialog */}
      <DeleteInvoice
        open={deleteOpen}
        onClose={handleDeleteClose}
        deleteInvoiceId={deleteInvoiceId}
        handleApiCall={handleApiCall}
        handleClickSuccess={handleClickSuccess}
        handleClickError={handleClickError}
        setError={setError}
      />
    </React.Fragment>
  );
};

export default CustomerInvoiceTable;
