import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders } from 'app/utils/handleHeader';
const currentDate = new Date();
const initialState = {
  bookYear: currentDate.getFullYear(),
  bookMasterData: null,
  isInitialised: false,
  isBookMasterSuccess: false,
  isError: false,
  isLoading: false
};
//--------------Asyncthunk for Add Book Master

export const addBookMaster = createAsyncThunk(
  '/addBookMaster',
  async (object, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/bookmaster/addbookmaster`,
        object,
        { headers: getHeaders() }
      );
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//--------------Asyncthunk for Add Get Book Master

export const getBookMaster = createAsyncThunk(
  '/getBookMaster',
  async (object, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/bookmaster/getbookmaster`,
        { headers: getHeaders() }
      );
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//--------------Asyncthunk for Delete Book Master
export const deleteBookMaster = createAsyncThunk(
  '/deleteBookMaster',
  async ({ deleteYearId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/bookmaster/deletebookmaster/${deleteYearId}`,
        { headers: getHeaders() }
      );
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
const bookMasterSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    updateDefaultYear: (state, action) => {
      state.bookYear = action.payload;
    }
  },
  extraReducers: {
    //--------------Slice action for the Add Bookmaster
    [addBookMaster.pending]: (state, action) => {
      state.pending = true;
      state.isBookMasterSuccess = false;
      state.isError = false;
    },
    [addBookMaster.fulfilled]: (state, action) => {
      state.pending = false;
      state.isBookMasterSuccess = true;
      state.isError = false;
    },
    [addBookMaster.rejected]: (state, action) => {
      state.pending = false;
      state.isBookMasterSuccess = false;
      state.isError = true;
    },
    //--------------Slice action for the Get Bookmaster
    [getBookMaster.pending]: (state, action) => {
      state.pending = true;
      state.isBookMasterSuccess = false;
      state.isError = false;
    },
    [getBookMaster.fulfilled]: (state, action) => {
      state.pending = false;
      state.isBookMasterSuccess = true;
      state.bookMasterData = action.payload;
      state.isError = false;
    },
    [getBookMaster.rejected]: (state, action) => {
      state.pending = false;
      state.isBookMasterSuccess = false;
      state.isError = true;
    },
    //--------------Slice action for the Delete Bookmaster
    [deleteBookMaster.pending]: (state, action) => {
      state.pending = true;
      state.isBookMasterSuccess = false;
      state.isError = false;
    },
    [deleteBookMaster.fulfilled]: (state, action) => {
      state.pending = false;
      state.isBookMasterSuccess = true;
      state.isError = false;
    },
    [deleteBookMaster.rejected]: (state, action) => {
      state.pending = false;
      state.isBookMasterSuccess = false;
      state.isError = true;
    }
  }
});
export const { updateDefaultYear } = bookMasterSlice.actions;
export default bookMasterSlice.reducer;
