import { LoadingButton } from '@mui/lab';
import { Alert, Box, Card, Grid, Snackbar, styled, TextField } from '@mui/material';
import React from 'react';
import { H4 } from 'app/components/Typography';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createIncomeType } from 'app/contexts/reducers/incomeType/incomeTypeSlice';
const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' }
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main }
}));

const AddIncomeType = ({ handleApiCall }) => {
  const dispatch = useDispatch();

  const intialData = {
    incomeType: ''
  };
  const [addIncomeType, setAddIncomeType] = useState(intialData);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState();

  const [incomeTypeError, setIncomeTypeError] = useState(false);

  function handleClickError() {
    setSuccessOpen(false);
    setErrorOpen(true);
  }
  function handleClickSuccess(message) {
    setErrorOpen(false);
    setSuccessMessage(message);
    setSuccessOpen(true);
  }
  function handleSnakeBarClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
    setErrorOpen(false);
  }

  const handleSubmit = async () => {
    try {
      if (addIncomeType.incomeType && addIncomeType.incomeType.length > 0) {
        const incomeTypeDetails = { ...addIncomeType };

        const responseValue = await dispatch(createIncomeType(incomeTypeDetails));
        if (!responseValue.hasOwnProperty('error')) {
          handleClickSuccess('Income Type Added successfully!!');
          setAddIncomeType(intialData);
          handleApiCall();
          setLoading(false);
        } else {
          throw responseValue;
        }
      } else {
        addIncomeType.incomeType.length <= 0 && setIncomeTypeError(true);
        handleClickError();
        setError({ payload: 'Please Fill Mandatory Data!' });
        setLoading(false);
      }
    } catch (error) {
      handleClickError();
      setError(error);
      setLoading(false);
    }
  };
  return (
    <React.Fragment>
      {' '}
      <Snackbar open={successOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='error'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {error?.hasOwnProperty('payload') ? error.payload : 'Something went wrong!!'}
        </Alert>
      </Snackbar>
      <Grid container spacing={3} sx={{ mb: '24px' }}>
        <Grid item xs={12} md={12}>
          <StyledCard elevation={6}>
            <H4 sx={{ mb: 2 }}>ADD INCOME-TYPE</H4>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ContentBox>
                  <TextField
                    id='incomeType'
                    type='text'
                    margin='dense'
                    size='small'
                    label='Income Type'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    error={incomeTypeError}
                    helperText={incomeTypeError ? 'Please Write Income-type' : ''}
                    value={addIncomeType.incomeType}
                    required
                    onChange={(e) => {
                      setIncomeTypeError(false);
                      setAddIncomeType({ ...addIncomeType, incomeType: e.target.value });
                    }}
                  />
                </ContentBox>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
            </Grid>
            <LoadingButton
              onClick={handleSubmit}
              color='primary'
              loading={loading}
              variant='contained'
              sx={{ my: 2, textTransform: 'capitalize' }}
            >
              Add Income-type
            </LoadingButton>
          </StyledCard>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AddIncomeType;
