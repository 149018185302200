import {
  Box,
  Card,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import {
  getCvdTotalFieldValue,
  getRealTotalFieldValue,
  getTotalFieldValue
} from 'app/contexts/reducers/stockMaster/stockMasterSlice';
import { useState } from 'react';
import { Fragment } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const CardHeader = styled(Box)(() => ({
  display: 'flex',
  paddingLeft: '24px',
  paddingRight: '24px',
  marginBottom: '12px',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  textTransform: 'capitalize'
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)'
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' }
}));

const OverAllStockTable = ({ apiCall }) => {
  const {
    isTotalCvdDataSuccess,
    totalCvdData,
    isTotalRealDataSuccess,
    totalRealData,
    isTotalFieldDataSuccess,
    totalFieldData
  } = useSelector((state) => state.stock);

  const dispatch = useDispatch();
  const [totalStockData, setTotalStockData] = useState([]);
  const [totalRealStockData, setTotalRealStockData] = useState([]);
  const [totalCvdStockData, setTotalCvdStockData] = useState([]);
  console.log(totalStockData, totalRealStockData, totalCvdStockData);
  useEffect(() => {
    dispatch(getTotalFieldValue());
    dispatch(getRealTotalFieldValue());
    dispatch(getCvdTotalFieldValue());
  }, [dispatch, apiCall]);

  useEffect(() => {
    if (isTotalCvdDataSuccess) {
      setTotalCvdStockData(totalCvdData.data.data);
    }
  }, [isTotalCvdDataSuccess, totalCvdData]);
  useEffect(() => {
    if (isTotalRealDataSuccess) {
      setTotalRealStockData(totalRealData.data.data);
    }
  }, [isTotalRealDataSuccess, totalRealData]);

  useEffect(() => {
    if (isTotalFieldDataSuccess) {
      setTotalStockData(totalFieldData.data.data);
    }
  }, [isTotalFieldDataSuccess, totalFieldData]);
  return (
    <Fragment>
      <Card elevation={3} sx={{ pt: '20px', mb: 3 }}>
        <CardHeader>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <Title>Over All Stock </Title>
            </Grid>
          </Grid>
        </CardHeader>

        <Box overflow='auto'>
          <ProductTable>
            <TableHead sx={{ backgroundColor: 'rgb(34,41,67)' }}>
              <TableRow>
                <TableCell
                  colSpan={4}
                  align='center'
                  sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}
                >
                  Diamond Type
                </TableCell>

                <TableCell
                  align='center'
                  colSpan={3}
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                >
                  Total Piece
                </TableCell>
                <TableCell
                  align='center'
                  colSpan={3}
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                >
                  Total Stock
                </TableCell>

                <TableCell
                  align='center'
                  colSpan={3}
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                >
                  Used Piece
                </TableCell>
                <TableCell
                  align='center'
                  colSpan={3}
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                >
                  Used Stock
                </TableCell>

                <TableCell
                  colSpan={3}
                  align='center'
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                >
                  Broken Piece
                </TableCell>
                <TableCell
                  colSpan={3}
                  align='center'
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                >
                  Broken Stock
                </TableCell>

                <TableCell
                  colSpan={3}
                  align='center'
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                >
                  Missing Piece
                </TableCell>
                <TableCell
                  colSpan={3}
                  align='center'
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                >
                  Missing Stock
                </TableCell>

                <TableCell
                  colSpan={4}
                  align='center'
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                >
                  Remaining Piece
                </TableCell>
                <TableCell
                  colSpan={4}
                  align='center'
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                >
                  Remaining Stock
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {totalStockData && totalStockData.length > 0 ? (
                <TableRow hover>
                  {/* --------------------All -Diamond-Type---------------------- */}
                  <TableCell colSpan={4} align='center' sx={{ px: 3, color: 'black' }}>
                    All
                  </TableCell>
                  <TableCell colSpan={3} align='center' sx={{ px: 0, color: 'black' }}>
                    {totalStockData[0].total_pcs ? totalStockData[0].total_pcs : 0}
                  </TableCell>
                  <TableCell colSpan={3} align='center' sx={{ px: 0, color: 'black' }}>
                    {totalStockData[0].total_stock
                      ? parseFloat(totalStockData[0].total_stock).toFixed(3)
                      : 0}
                  </TableCell>
                  <TableCell colSpan={3} align='center' sx={{ px: 0, color: 'black' }}>
                    {totalStockData[0].used_pcs ? totalStockData[0].used_pcs : 0}
                  </TableCell>
                  <TableCell colSpan={3} align='center' sx={{ px: 0, color: 'black' }}>
                    {totalStockData[0].used_stock
                      ? parseFloat(totalStockData[0].used_stock).toFixed(3)
                      : 0}
                  </TableCell>
                  <TableCell colSpan={3} align='center' sx={{ px: 0, color: 'black' }}>
                    {totalStockData[0].broken_pcs ? totalStockData[0].broken_pcs : 0}
                  </TableCell>
                  <TableCell colSpan={3} align='center' sx={{ px: 0, color: 'black' }}>
                    {totalStockData[0].broken_stock
                      ? parseFloat(totalStockData[0].broken_stock).toFixed(3)
                      : 0}
                  </TableCell>
                  <TableCell colSpan={3} align='center' sx={{ px: 0, color: 'black' }}>
                    {totalStockData[0].missing_pcs ? totalStockData[0].missing_pcs : 0}
                  </TableCell>
                  <TableCell colSpan={3} align='center' sx={{ px: 0, color: 'black' }}>
                    {totalStockData[0].missing_stock
                      ? parseFloat(totalStockData[0].missing_stock).toFixed(3)
                      : 0}
                  </TableCell>
                  <TableCell colSpan={4} align='center' sx={{ px: 0, color: 'black' }}>
                    {totalStockData[0].remaining_pcs ? totalStockData[0].remaining_pcs : 0}
                  </TableCell>
                  <TableCell colSpan={4} align='center' sx={{ px: 0, color: 'black' }}>
                    {totalStockData[0].remaining_stock
                      ? parseFloat(totalStockData[0].remaining_stock).toFixed(3)
                      : 0}
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell colSpan={41} sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                    No data found
                  </TableCell>
                </TableRow>
              )}

              {/* --------------------Real-Diamond-Type---------------------- */}
              {totalRealStockData && totalRealStockData.length > 0 ? (
                <TableRow hover>
                  {/* --------------------All -Diamond-Type---------------------- */}
                  <TableCell colSpan={4} align='center' sx={{ px: 3, color: 'black' }}>
                    Real
                  </TableCell>
                  <TableCell colSpan={3} align='center' sx={{ px: 0, color: 'black' }}>
                    {totalRealStockData[0].total_pcs ? totalRealStockData[0].total_pcs : 0}
                  </TableCell>
                  <TableCell colSpan={3} align='center' sx={{ px: 0, color: 'black' }}>
                    {totalRealStockData[0].total_stock
                      ? parseFloat(totalRealStockData[0].total_stock).toFixed(3)
                      : 0}
                  </TableCell>
                  <TableCell colSpan={3} align='center' sx={{ px: 0, color: 'black' }}>
                    {totalRealStockData[0].used_pcs ? totalRealStockData[0].used_pcs : 0}
                  </TableCell>
                  <TableCell colSpan={3} align='center' sx={{ px: 0, color: 'black' }}>
                    {totalRealStockData[0].used_stock
                      ? parseFloat(totalRealStockData[0].used_stock).toFixed(3)
                      : 0}
                  </TableCell>
                  <TableCell colSpan={3} align='center' sx={{ px: 0, color: 'black' }}>
                    {totalRealStockData[0].broken_pcs ? totalRealStockData[0].broken_pcs : 0}
                  </TableCell>
                  <TableCell colSpan={3} align='center' sx={{ px: 0, color: 'black' }}>
                    {totalRealStockData[0].broken_stock
                      ? parseFloat(totalRealStockData[0].broken_stock).toFixed(3)
                      : 0}
                  </TableCell>
                  <TableCell colSpan={3} align='center' sx={{ px: 0, color: 'black' }}>
                    {totalRealStockData[0].missing_pcs ? totalRealStockData[0].missing_pcs : 0}
                  </TableCell>
                  <TableCell colSpan={3} align='center' sx={{ px: 0, color: 'black' }}>
                    {totalRealStockData[0].missing_stock
                      ? parseFloat(totalRealStockData[0].missing_stock).toFixed(3)
                      : 0}
                  </TableCell>
                  <TableCell colSpan={4} align='center' sx={{ px: 0, color: 'black' }}>
                    {totalRealStockData[0].remaining_pcs ? totalRealStockData[0].remaining_pcs : 0}
                  </TableCell>
                  <TableCell colSpan={4} align='center' sx={{ px: 0, color: 'black' }}>
                    {totalRealStockData[0].remaining_stock
                      ? parseFloat(totalRealStockData[0].remaining_stock).toFixed(3)
                      : 0}
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell colSpan={41} sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                    No data found
                  </TableCell>
                </TableRow>
              )}

              {/* --------------------CVD-Diamond-Type---------------------- */}
              {totalCvdStockData && totalCvdStockData.length > 0 ? (
                <TableRow hover>
                  {/* --------------------All -Diamond-Type---------------------- */}
                  <TableCell colSpan={4} align='center' sx={{ px: 3, color: 'black' }}>
                    Cvd
                  </TableCell>
                  <TableCell colSpan={3} align='center' sx={{ px: 0, color: 'black' }}>
                    {totalCvdStockData[0].total_pcs ? totalCvdStockData[0].total_pcs : 0}
                  </TableCell>
                  <TableCell colSpan={3} align='center' sx={{ px: 0, color: 'black' }}>
                    {totalCvdStockData[0].total_stock
                      ? parseFloat(totalCvdStockData[0].total_stock).toFixed(3)
                      : 0}
                  </TableCell>
                  <TableCell colSpan={3} align='center' sx={{ px: 0, color: 'black' }}>
                    {totalCvdStockData[0].used_pcs ? totalCvdStockData[0].used_pcs : 0}
                  </TableCell>
                  <TableCell colSpan={3} align='center' sx={{ px: 0, color: 'black' }}>
                    {totalCvdStockData[0].used_stock
                      ? parseFloat(totalCvdStockData[0].used_stock).toFixed(3)
                      : 0}
                  </TableCell>
                  <TableCell colSpan={3} align='center' sx={{ px: 0, color: 'black' }}>
                    {totalCvdStockData[0].broken_pcs ? totalCvdStockData[0].broken_pcs : 0}
                  </TableCell>
                  <TableCell colSpan={3} align='center' sx={{ px: 0, color: 'black' }}>
                    {totalCvdStockData[0].broken_stock
                      ? parseFloat(totalCvdStockData[0].broken_stock).toFixed(3)
                      : 0}
                  </TableCell>
                  <TableCell colSpan={3} align='center' sx={{ px: 0, color: 'black' }}>
                    {totalCvdStockData[0].missing_pcs ? totalCvdStockData[0].missing_pcs : 0}
                  </TableCell>
                  <TableCell colSpan={3} align='center' sx={{ px: 0, color: 'black' }}>
                    {totalCvdStockData[0].missing_stock
                      ? parseFloat(totalCvdStockData[0].missing_stock).toFixed(3)
                      : 0}
                  </TableCell>
                  <TableCell colSpan={4} align='center' sx={{ px: 0, color: 'black' }}>
                    {totalCvdStockData[0].remaining_pcs ? totalCvdStockData[0].remaining_pcs : 0}
                  </TableCell>
                  <TableCell colSpan={4} align='center' sx={{ px: 0, color: 'black' }}>
                    {totalCvdStockData[0].remaining_stock
                      ? parseFloat(totalCvdStockData[0].remaining_stock).toFixed(3)
                      : 0}
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell colSpan={41} sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                    No data found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </ProductTable>
        </Box>
      </Card>
    </Fragment>
  );
};

export default OverAllStockTable;
