import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
  styled
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import DialogTitle from '@mui/material/DialogTitle';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUpdateSingleAccount } from '../helper/getUpdateSingleAccount';
import { updateEmployeeAccountData } from 'app/contexts/reducers/account/accountSlice';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' }
}));
const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main }
}));
export default function UpdateEmployeeDialog({
  open,
  onClose,
  updateAccountId,
  setApiCall,
  handleClickSuccess,
  setError,
  handleClickError
}) {
  const dispatch = useDispatch();
  const { employeeData, accountType } = useSelector((state) => state.account);
  const { isEmployeeSuccess } = useSelector((state) => state.account);
  const [updateValue, setUpdateValue] = useState({
    name: '',
    email: '',
    remark: '',
    address: '',
    phoneNumber: ''
  });
  const [employeeNameError, setEmployeeNameError] = useState(false);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (employeeData) {
      getUpdateSingleAccount(employeeData.data.data, updateAccountId).then((data) => {
        data &&
          setUpdateValue({
            name: data.name,
            email: data.email,
            remark: data.remark,
            address: data.address,
            phoneNumber: data.phone_number
          });
      });
    }
  }, [updateAccountId, isEmployeeSuccess, employeeData]);

  async function handleUpdate() {
    try {
      setLoading(true);
      const currentAccount = await getUpdateSingleAccount(employeeData.data.data, updateAccountId);

      if (accountType.toLowerCase() === 'employee') {
        if (updateValue.name !== currentAccount.name) {
          const updateEmployeeData = { ...updateValue };
          Object.keys(updateEmployeeData).forEach((key) => {
            if (updateEmployeeData[key] === '') {
              delete updateEmployeeData[key];
            }
          });
          const response = await dispatch(
            updateEmployeeAccountData({ updateAccountId, updateEmployeeData })
          );
          if (!response.hasOwnProperty('error')) {
            onClose();
            setApiCall((prev) => !prev);
            handleClickSuccess('Employee Update successfully');
            setLoading(false);
          } else {
            throw response;
          }
        } else {
          if (updateValue.name.length > 0) {
            const updateEmployeeData = { ...updateValue };
            Object.keys(updateEmployeeData).forEach((key) => {
              if (
                key === 'name' ||
                updateEmployeeData[key] === '' ||
                updateEmployeeData[key] === null
              ) {
                delete updateEmployeeData[key];
              }
            });
            const response = await dispatch(
              updateEmployeeAccountData({ updateAccountId, updateEmployeeData })
            );
            if (!response.hasOwnProperty('error')) {
              onClose();
              setApiCall((prev) => !prev);
              handleClickSuccess('Employee Update successfully');
              setLoading(false);
            } else {
              throw response;
            }
          } else {
            onClose();
            setLoading(false);
          }
        }
      }
    } catch (e) {
      handleClickError();
      setError(e);
      setLoading(false);
    }
  }

  return (
    <Box>
      <Dialog open={open} onClose={onClose} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>Update Employee</DialogTitle>
        <DialogContent>
          <DialogContentText>You can Update Your Employee Details From Here!</DialogContentText>

          <Grid container spacing={3} sx={{ mb: '24px' }}>
            <Grid item xs={12} md={12}>
              <StyledCard elevation={6}>
                <Grid container>
                  <TextField
                    fullWidth
                    error={employeeNameError}
                    id='name'
                    type='text'
                    size='small'
                    margin='dense'
                    label='Account Name'
                    sx={{ my: 1 }}
                    variant='standard'
                    required
                    helperText={employeeNameError ? 'Please Enter Employee Name' : ''}
                    value={updateValue.name}
                    onChange={(e) => {
                      setEmployeeNameError(false);
                      setUpdateValue({ ...updateValue, name: e.target.value });
                    }}
                  />

                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ContentBox>
                      <TextField
                        id='email'
                        type='email'
                        margin='dense'
                        size='small'
                        label='Email'
                        sx={{ my: 1, width: '95%' }}
                        variant='standard'
                        value={updateValue.email == null ? '' : updateValue.email}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, email: e.target.value });
                        }}
                      />
                    </ContentBox>

                    <ContentBox>
                      <TextField
                        id='address'
                        type='text'
                        margin='dense'
                        size='small'
                        label='Address'
                        multiline
                        maxRows={6}
                        sx={{ my: 1, width: '95%' }}
                        variant='standard'
                        value={updateValue.address == null ? '' : updateValue.address}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, address: e.target.value });
                        }}
                      />
                    </ContentBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ContentBox>
                      <TextField
                        id='phoneNumber'
                        type='text'
                        margin='dense'
                        size='small'
                        label='Contact Number'
                        sx={{ my: 1, width: '95%' }}
                        variant='standard'
                        value={updateValue.phoneNumber == null ? '' : updateValue.phoneNumber}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, phoneNumber: e.target.value });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='remark'
                        type='text'
                        margin='dense'
                        size='small'
                        label='Remark'
                        multiline
                        maxRows={6}
                        sx={{ my: 1, width: '95%' }}
                        variant='standard'
                        value={updateValue.remark == null ? '' : updateValue.remark}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, remark: e.target.value });
                        }}
                      />
                    </ContentBox>
                  </Grid>
                </Grid>
              </StyledCard>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='standard' color='secondary' onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleUpdate}
            color='primary'
            loading={loading}
            variant='contained'
            sx={{ my: 2 }}
          >
            Update Employee
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
