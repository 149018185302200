import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders } from 'app/utils/handleHeader';
const initialState = {
    incomeTypeData: null,
    isInitialised: false,
    isIncomeTypeEntrySuccess: false,
    isIncomeTypeSuccess: false,
    isIncomeTypeUpdateSuccess: false,
    isIncomeTypeDeleteSuccess: false,
    isError: false,
    isLoading: false
};

//--------------Asyncthunk for Add Income-Type --------------------
export const createIncomeType = createAsyncThunk(
    '/createIncomeType',
    async (object, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/incometype/createincometype`,
                object,
                { headers: getHeaders() }
            );
            return response;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);
//--------------Asyncthunk for Get Income-Types--------------------------------
export const getIncomeTypeData = createAsyncThunk('getIncomeTypeData', async (object, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/incometype/getincometype`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Delete Income-Type Data--------------------------------
export const deleteIncomeType = createAsyncThunk(
    '/deleteIncomeType',
    async ({ incomeTypeId }, { rejectWithValue }) => {
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_BASE_URL}/incometype/deleteincometype/${incomeTypeId}`,
                { headers: getHeaders() }
            );
            return response;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);
//--------------Asyncthunk for Update Income-Type Data--------------------------------
export const updateIncomeType = createAsyncThunk(
    '/updateIncomeType',
    async ({ incomeTypeId, incomeEntryDetails }, { rejectWithValue }) => {
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/incometype/updateincometype/${incomeTypeId}`,
                { incomeType: incomeEntryDetails }, { headers: getHeaders() }
            );
            return response;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);
const incomeTypeSlice = createSlice({
    name: 'Income-Type',
    initialState,
    reducers: {},
    extraReducers: {
        //--------------Slice action for the Add Income-Type --------------------------------
        [createIncomeType.pending]: (state, action) => {
            state.pending = true;
            state.isIncomeTypeEntrySuccess = false;
            state.isError = false;
        },
        [createIncomeType.fulfilled]: (state, action) => {
            state.pending = false;
            state.isIncomeTypeEntrySuccess = true;
            state.isError = false;
        },
        [createIncomeType.rejected]: (state, action) => {
            state.pending = false;
            state.isIncomeTypeEntrySuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Income-Type --------------------------------
        [getIncomeTypeData.pending]: (state, action) => {
            state.pending = false;
            state.isIncomeTypeSuccess = false;
            state.isError = false;
        },
        [getIncomeTypeData.fulfilled]: (state, action) => {
            state.pending = false;
            state.isIncomeTypeSuccess = true;
            state.incomeTypeData = action.payload;
            state.isError = false;
        },
        [getIncomeTypeData.rejected]: (state, action) => {
            state.pending = false;
            state.isIncomeTypeSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Delete Income-Type
        [deleteIncomeType.pending]: (state, action) => {
            state.pending = true;
            state.isIncomeTypeDeleteSuccess = false;
            state.isError = false;
        },
        [deleteIncomeType.fulfilled]: (state, action) => {
            state.pending = false;
            state.isIncomeTypeDeleteSuccess = true;
            state.isError = false;
        },
        [deleteIncomeType.rejected]: (state, action) => {
            state.pending = false;
            state.isIncomeTypeDeleteSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Update Income-Type
        [updateIncomeType.pending]: (state, action) => {
            state.pending = true;
            state.isIncomeTypeUpdateSuccess = false;
            state.isError = false;
        },
        [updateIncomeType.fulfilled]: (state, action) => {
            state.pending = false;
            state.isIncomeTypeUpdateSuccess = true;
            state.isError = false;
        },
        [updateIncomeType.rejected]: (state, action) => {
            state.pending = false;
            state.isIncomeTypeUpdateSuccess = false;
            state.isError = true;
        }
    }
})
export default incomeTypeSlice.reducer;
