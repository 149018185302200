import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
  styled
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import { updateOutwardEntryDetails } from 'app/contexts/reducers/transaction/diamondOutwardSlice';
const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' }
}));
const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main }
}));

export default function UpdateDiamondOutward({
  open,
  onClose,
  updateOutwardData,
  handleClickSuccess,
  setError,
  apiCall,
  handleClickError
}) {
  const dispatch = useDispatch();
  const { isVendorSuccess, vendorData } = useSelector((state) => state.account);

  const intialData = {
    vendorName: '',
    diamondType: '',
    orderDiamondType: '',
    outwardId: null,
    outwardDate: null,
    totalPcs: null,
    remark: null,
    returnPcs: null,
    brokenPcs: null,
    missingPcs: null,
    studdPcs: '',
    returnWeight: '',
    brokenWeight: '',
    missingWeight: '',
    studdWeight: '',
    totalWeight: ''
  };

  const [updateOutwardEntry, setUpdateOutwardEntry] = useState(intialData);
  const [partyData, setPartyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [outwardDisable, setOutwardDisable] = useState(false);
  useEffect(() => {
    if (isVendorSuccess) {
      setPartyData(vendorData.data.data);
    }
  }, [isVendorSuccess, vendorData]);
  useEffect(() => {
    setUpdateOutwardEntry((prevData) => ({
      ...prevData,
      vendorName: getVendorName(updateOutwardData.vendor_id),
      diamondType: updateOutwardData.diamond_type,
      orderDiamondType: updateOutwardData.order_diamond_type,
      outwardId: updateOutwardData.id,
      outwardDate: dayjs(updateOutwardData.outwardDate),
      remark: updateOutwardData.remark,
      returnPcs: updateOutwardData.return_pieces,
      brokenPcs: updateOutwardData.broken_pieces,
      missingPcs: updateOutwardData.missing_pieces,
      totalPcs: updateOutwardData.total_pieces,
      studdPcs: updateOutwardData.studd_pieces,
      totalWeight: updateOutwardData.total_weight,
      returnWeight: updateOutwardData.return_weight,
      brokenWeight: updateOutwardData.broken_weight,
      missingWeight: updateOutwardData.missing_weight,
      studdWeight: updateOutwardData.studd_weight
    }));
    // eslint-disable-next-line
  }, [updateOutwardData, apiCall]);
  function getVendorName(vendorId) {
    const partyName = partyData.find((partyName) => {
      return partyName.id === vendorId;
    });
    return partyName ? partyName.account_name : null;
  }
  async function handleOutward() {
    try {
      if (updateOutwardEntry.outwardId && Number(updateOutwardEntry.outwardId) > 0) {
        const updateOutwardDetails = { ...updateOutwardEntry };
        const propertiesToConvert = [
          'returnWeight',
          'brokenWeight',
          'missingWeight',
          'studdWeight',
          'returnPcs',
          'brokenPcs',
          'missingPcs',
          'studdPcs'
        ];
        propertiesToConvert.forEach((property) => {
          if (updateOutwardEntry.hasOwnProperty(property)) {
            updateOutwardEntry[property] = [
              'returnPcs',
              'brokenPcs',
              'missingPcs',
              'studdPcs'
            ].includes(property)
              ? parseInt(updateOutwardEntry[property])
              : parseFloat(parseFloat(updateOutwardEntry[property]).toFixed(3));
          }
        });
        Object.keys(updateOutwardDetails).forEach((key) => {
          if (updateOutwardDetails[key] === '' || updateOutwardDetails[key] === null) {
            delete updateOutwardDetails[key];
          }
        });

        const responseValue = await dispatch(
          updateOutwardEntryDetails({
            outwardId: updateOutwardDetails.outwardId,
            updateOutwardDetails
          })
        );
        if (!responseValue.hasOwnProperty('error')) {
          onClose();
          handleClickSuccess('Diamond Outward successfully!!');
          setLoading(false);
        } else {
          throw responseValue;
        }
      } else {
        handleClickError();
        setError({ payload: 'Please Fill Mandatory Data!' });
        setLoading(false);
      }
    } catch (error) {
      handleClickError();
      setError(error);
      setLoading(false);
    }
  }
  useEffect(() => {
    const parsedBrokenWeight = parseFloat(updateOutwardEntry.brokenWeight) || 0;
    const parsedMissingWeight = parseFloat(updateOutwardEntry.missingWeight) || 0;
    const parsedReturnWeight = parseFloat(updateOutwardEntry.returnWeight) || 0;
    const parsedStuddWeight = parseFloat(
      (
        parseFloat(updateOutwardEntry.totalWeight).toFixed(3) -
        parsedReturnWeight -
        parsedBrokenWeight -
        parsedMissingWeight
      ).toFixed(3)
    );
    const studdPieces =
      Number(updateOutwardEntry.totalPcs) -
      Number(updateOutwardEntry.returnPcs) -
      Number(updateOutwardEntry.brokenPcs) -
      Number(updateOutwardEntry.missingPcs);
    setUpdateOutwardEntry((prevAddOutwardEntry) => ({
      ...prevAddOutwardEntry,
      studdWeight: parsedStuddWeight,
      studdPcs: studdPieces
    }));
    if (
      Number(updateOutwardEntry.brokenPcs) +
        Number(updateOutwardEntry.missingPcs) +
        Number(updateOutwardEntry.returnPcs) +
        Number(updateOutwardEntry.studdPcs) >
      updateOutwardEntry.totalPcs
    ) {
      setOutwardDisable(true);
    } else if (
      parseFloat(
        parseFloat(
          parseFloat(parseFloat(updateOutwardEntry.brokenWeight).toFixed(3)) +
            parseFloat(parseFloat(updateOutwardEntry.missingWeight).toFixed(3)) +
            parseFloat(parseFloat(updateOutwardEntry.returnWeight).toFixed(3)) +
            parseFloat(parseFloat(updateOutwardEntry.studdWeight).toFixed(3))
        ).toFixed(3)
      ) > parseFloat(parseFloat(updateOutwardEntry.totalWeight).toFixed(3))
    ) {
      setOutwardDisable(true);
    } else {
      setOutwardDisable(false);
    }
  }, [
    updateOutwardEntry.brokenPcs,
    updateOutwardEntry.missingPcs,
    updateOutwardEntry.brokenWeight,
    updateOutwardEntry.missingWeight,
    updateOutwardEntry.returnPcs,
    updateOutwardEntry.returnWeight,
    updateOutwardEntry.studdPcs,
    updateOutwardEntry.studdWeight,
    updateOutwardEntry.totalPcs,
    updateOutwardEntry.totalWeight
  ]);

  return (
    <Box>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth='md'
        fullWidth
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>Update Outward-Diamond Data</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: '10px' }}>
            You can Update Your Outward Entry Details From Here!{' '}
          </DialogContentText>
          <Grid container spacing={3} sx={{ mb: '24px' }}>
            <Grid item xs={12} md={12}>
              <StyledCard elevation={6}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ContentBox>
                      <TextField
                        id='vendor_name'
                        type='text'
                        margin='dense'
                        size='small'
                        label='Vendor Name'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '70%' }}
                        variant='standard'
                        value={
                          updateOutwardEntry.vendorName ? updateOutwardEntry.vendorName : 'CUSTOMER'
                        }
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='orderDiamondType'
                        type='text'
                        margin='dense'
                        size='small'
                        label='Inward Type'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '70%' }}
                        variant='standard'
                        value={updateOutwardEntry.orderDiamondType}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='totalPieces'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Total Pieces'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '70%' }}
                        variant='standard'
                        value={updateOutwardEntry.totalPcs}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='returnPieces'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Return Pieces'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '70%' }}
                        variant='standard'
                        value={updateOutwardEntry.returnPcs}
                        onChange={(e) => {
                          setUpdateOutwardEntry({
                            ...updateOutwardEntry,
                            returnPcs: e.target.value
                          });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='brokenPcs'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Broken Pieces'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '70%' }}
                        variant='standard'
                        value={updateOutwardEntry.brokenPcs}
                        onChange={(e) => {
                          setUpdateOutwardEntry({
                            ...updateOutwardEntry,
                            brokenPcs: e.target.value
                          });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='missingPcs'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Missing Pieces'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '70%' }}
                        variant='standard'
                        value={updateOutwardEntry.missingPcs}
                        onChange={(e) => {
                          setUpdateOutwardEntry({
                            ...updateOutwardEntry,
                            missingPcs: e.target.value
                          });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='studdPieces'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Studd Pieces'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '70%' }}
                        variant='standard'
                        value={updateOutwardEntry.studdPcs}
                        onChange={(e) => {
                          setUpdateOutwardEntry({
                            ...updateOutwardEntry,
                            studdPcs: e.target.value
                          });
                        }}
                      />
                    </ContentBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ContentBox>
                      <TextField
                        id='diamondType'
                        type='text'
                        margin='dense'
                        size='small'
                        label='Diamond Type'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '70%' }}
                        variant='standard'
                        value={updateOutwardEntry.diamondType}
                      />
                    </ContentBox>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{ width: '70%', my: 1 }}
                        label='Date'
                        format='DD/MM/YYYY'
                        value={updateOutwardEntry?.outwardDate}
                        onChange={(newValue) =>
                          setUpdateOutwardEntry({ ...updateOutwardEntry, outwardDate: newValue })
                        }
                        slotProps={{ textField: { variant: 'standard' } }}
                      />
                    </LocalizationProvider>
                    <ContentBox>
                      <TextField
                        id='totalWeight'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Total Weight'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '70%' }}
                        variant='standard'
                        value={updateOutwardEntry.totalWeight}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='returnWeight'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Return Weight'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '70%' }}
                        variant='standard'
                        value={updateOutwardEntry.returnWeight}
                        onChange={(e) => {
                          setUpdateOutwardEntry({
                            ...updateOutwardEntry,
                            returnWeight: e.target.value
                          });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='brokenWeight'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Broken Weight'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '70%' }}
                        variant='standard'
                        value={updateOutwardEntry.brokenWeight}
                        onChange={(e) => {
                          setUpdateOutwardEntry({
                            ...updateOutwardEntry,
                            brokenWeight: e.target.value
                          });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='missingWeight'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Missing Weight'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '70%' }}
                        variant='standard'
                        value={updateOutwardEntry.missingWeight}
                        onChange={(e) => {
                          setUpdateOutwardEntry({
                            ...updateOutwardEntry,
                            missingWeight: e.target.value
                          });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='studdWeight'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Studd Weight'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '70%' }}
                        variant='standard'
                        value={updateOutwardEntry.studdWeight}
                        onChange={(e) => {
                          setUpdateOutwardEntry({
                            ...updateOutwardEntry,
                            studdWeight: e.target.value
                          });
                        }}
                      />
                    </ContentBox>
                  </Grid>
                </Grid>

                <TextField
                  id='remark'
                  type='text'
                  multiline
                  maxRows={4}
                  margin='dense'
                  size='small'
                  label='Remark'
                  InputLabelProps={{ shrink: true }}
                  sx={{ my: 1, width: '85%' }}
                  variant='standard'
                  value={updateOutwardEntry.remark}
                  onChange={(e) => {
                    setUpdateOutwardEntry({ ...updateOutwardEntry, remark: e.target.value });
                  }}
                />
              </StyledCard>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='standard' color='secondary' onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleOutward}
            disabled={outwardDisable}
            color='primary'
            loading={loading}
            variant='contained'
            sx={{ my: 2 }}
          >
            Update Outward Entry
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
