import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Card,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  styled,
  TextField
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React from 'react';
import { H4 } from 'app/components/Typography';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { convertVendorAmountDataId } from 'app/utils/convertId';
import dayjs from 'dayjs';
import {
  createVendorPaymentReceipt,
  getAllVendorAmountData,
  getVendorAmountData
} from 'app/contexts/reducers/transaction/vendorReceiptSlice';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' }
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main }
}));

const CreateVendorReceipt = ({ handleApiCall }) => {
  const dispatch = useDispatch();

  const {
    isVendorAmountFoundedSuccess,
    isAllVendorAmountFoundedSuccess,
    vendorAmountData,
    allVendorAmountData
  } = useSelector((state) => state.vendorReceipt);

  const intialData = {
    vendorName: '',
    vendorId: null,
    amount: null,
    vendorRemainingAmount: null,
    vendorDiscountAmount: null,
    vendorPaidAmount: null,
    discountAmount: null,
    paymentType: '',
    bookNumber: dayjs().year(),
    date: dayjs(),
    remark: ''
  };
  const [createVendorReceipt, setCreateVendorReceipt] = useState(intialData);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [accountVendorData, setAccountVendorData] = useState([]);
  const [dataError, setDataError] = useState({
    vendorNameError: false,
    vendorIdError: false,
    amountError: false,
    paymentTypeError: false,
    bookNumberError: false
  });
  useEffect(() => {
    dispatch(getAllVendorAmountData({ bookNumber: createVendorReceipt.bookNumber }));
  }, [dispatch, createVendorReceipt.bookNumber]);

  // ---------------------Get the Vendor Amount Data
  useEffect(() => {
    Number(createVendorReceipt.vendorId) > 0 &&
      Number(createVendorReceipt.bookNumber) > 0 &&
      dispatch(
        getVendorAmountData({
          vendorId: createVendorReceipt.vendorId,
          bookNumber: createVendorReceipt.bookNumber
        })
      );
  }, [dispatch, createVendorReceipt.vendorId, createVendorReceipt.bookNumber]);

  useEffect(() => {
    if (isAllVendorAmountFoundedSuccess) {
      if (Number(allVendorAmountData.data.code) === 200) {
        setAccountVendorData(allVendorAmountData.data.data);
      } else {
        setAccountVendorData([]);
      }
    }
  }, [isAllVendorAmountFoundedSuccess, allVendorAmountData]);
  // ---------------------Get the Vendor Amount Data And Setit into State
  useEffect(() => {
    if (isVendorAmountFoundedSuccess) {
      if (vendorAmountData.data.code === 200) {
        setCreateVendorReceipt((prevState) => ({
          ...prevState,
          vendorRemainingAmount: vendorAmountData.data.data.remaining_amount,
          vendorDiscountAmount: vendorAmountData.data.data.discount_amount,
          vendorPaidAmount: vendorAmountData.data.data.paid_amount
        }));
      } else {
        setCreateVendorReceipt((prevState) => ({
          ...prevState,
          vendorRemainingAmount: 0,
          vendorDiscountAmount: 0,
          vendorPaidAmount: 0
        }));
      }
    }
  }, [isVendorAmountFoundedSuccess, vendorAmountData]);

  function handleClickError() {
    setSuccessOpen(false);
    setErrorOpen(true);
  }
  function handleClickSuccess(message) {
    setErrorOpen(false);
    setSuccessMessage(message);
    setSuccessOpen(true);
  }
  function handleSnakeBarClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
    setErrorOpen(false);
  }
  const handleVendorSelect = async (e) => {
    const vendorId = await convertVendorAmountDataId(accountVendorData, e.target.value);
    setCreateVendorReceipt({
      ...createVendorReceipt,
      vendorId: vendorId.vendor_id,
      vendorName: e.target.value
    });
    setDataError({ ...dataError, vendorNameError: false, vendorIdError: false });
  };

  const handleBookNameSelect = async (e) => {
    setCreateVendorReceipt({ ...createVendorReceipt, bookNumber: e.target.value });
    setDataError({ ...dataError, bookNameError: false });
  };
  const getFiveYearsBack = () => {
    const currentYear = new Date().getFullYear();
    const fiveYearsBackArray = [];

    for (let i = 0; i < 3; i++) {
      const year = currentYear - i;
      fiveYearsBackArray.push(String(year));
    }

    return fiveYearsBackArray;
  };
  const handlePaymentTypeSelect = async (e) => {
    setCreateVendorReceipt({ ...createVendorReceipt, paymentType: e.target.value });
    setDataError({ ...dataError, paymentTypeError: false });
  };
  const handleSubmit = async () => {
    try {
      if (
        createVendorReceipt.vendorName &&
        createVendorReceipt.vendorName.length > 0 &&
        createVendorReceipt.vendorId &&
        Number(createVendorReceipt.vendorId) > 0 &&
        createVendorReceipt.paymentType &&
        createVendorReceipt.paymentType.length > 0 &&
        createVendorReceipt.amount &&
        Number(createVendorReceipt.amount) > 0 &&
        createVendorReceipt.bookNumber &&
        Number(createVendorReceipt.bookNumber) > 0
      ) {
        const vendorReceiptDetails = { ...createVendorReceipt };
        const propertiesToConvert = ['amount', 'discountAmount', 'vendorId', 'bookNumber'];
        propertiesToConvert.forEach((property) => {
          vendorReceiptDetails[property] = parseInt(vendorReceiptDetails[property]);
        });
        Object.keys(vendorReceiptDetails).forEach((key) => {
          if (vendorReceiptDetails[key] === '' || vendorReceiptDetails[key] === null) {
            delete vendorReceiptDetails[key];
          }
        });

        const responseValue = await dispatch(createVendorPaymentReceipt(vendorReceiptDetails));
        if (!responseValue.hasOwnProperty('error')) {
          handleClickSuccess('Vendor Receipt Created successfully!!');
          setCreateVendorReceipt(intialData);
          handleApiCall();
          setLoading(false);
        } else {
          throw responseValue;
        }
      } else {
        const validationRules = [
          {
            property: 'vendorName',
            condition: createVendorReceipt.vendorName.length <= 0,
            errorKey: 'vendorNameError'
          },
          {
            property: 'vendorId',
            condition: Number(createVendorReceipt.vendorId) <= 0,
            errorKey: 'vendorIdError'
          },
          {
            property: 'amount',
            condition: Number(createVendorReceipt.amount) <= 0,
            errorKey: 'amountError'
          },
          {
            property: 'bookNumber',
            condition: Number(createVendorReceipt.bookNumber) <= 0,
            errorKey: 'bookNumberError'
          },
          {
            property: 'paymentType',
            condition: createVendorReceipt.paymentType.length <= 0,
            errorKey: 'paymentTypeError'
          }
        ];

        const updatedErrors = validationRules.reduce(
          (errors, rule) => {
            if (rule.condition) {
              errors[rule.errorKey] = true;
            }
            return errors;
          },
          { ...dataError }
        );

        setDataError(updatedErrors);

        handleClickError();
        setError({ payload: 'Please Fill Mandatory Data!' });
        setLoading(false);
      }
    } catch (error) {
      handleClickError();
      setError(error);
      setLoading(false);
    }
  };

  const PaymentType = [
    {
      name: 'CASH'
    },
    {
      name: 'BANK'
    }
  ];
  return (
    <React.Fragment>
      {' '}
      <Snackbar open={successOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='error'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {error?.payload}
        </Alert>
      </Snackbar>
      <Grid container spacing={3} sx={{ mb: '24px' }}>
        <Grid item xs={12} md={12}>
          <StyledCard elevation={6}>
            <H4 sx={{ mb: 2 }}>CREATE VENDOR RECEIPT</H4>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <InputLabel
                  sx={{ fontSize: '10px' }}
                  error={dataError.vendorNameError}
                  id='vendorName'
                >
                  Vendor Name
                </InputLabel>

                <Select
                  size='small'
                  sx={{ width: '50%', my: 1 }}
                  variant='standard'
                  labelId='vendorName'
                  value={createVendorReceipt.vendorName}
                  onChange={handleVendorSelect}
                  error={dataError.vendorNameError}
                >
                  {accountVendorData.map((data, index) => (
                    <MenuItem value={data.vendor_name} key={index} id={index}>
                      {data.vendor_name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ContentBox>
                  <TextField
                    id='vendorId'
                    type='text'
                    margin='dense'
                    size='small'
                    disabled
                    label='Vendor Id'
                    InputLabelProps={{ shrink: true }}
                    error={dataError.vendorIdError}
                    helperText={dataError.vendorIdError ? 'Please Select Vendor' : null}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={
                      createVendorReceipt.vendorId == null
                        ? 'Please Select Vendor'
                        : createVendorReceipt.vendorId
                    }
                  />
                </ContentBox>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ContentBox>
                  <TextField
                    id='amount'
                    type='number'
                    margin='dense'
                    size='small'
                    label='Amount'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={createVendorReceipt.amount == null ? 0 : createVendorReceipt.amount}
                    error={dataError.amountError}
                    helperText={dataError.amountError ? 'Pleae Enter Amount' : null}
                    onChange={(e) => {
                      setCreateVendorReceipt({
                        ...createVendorReceipt,
                        amount: e.target.value
                      });
                      setDataError({ ...dataError, amountError: false });
                    }}
                  />
                </ContentBox>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ContentBox>
                  <TextField
                    id='discountAmount'
                    type='number'
                    margin='dense'
                    size='small'
                    label='Discount Amount'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={
                      createVendorReceipt.discountAmount == null
                        ? 0
                        : createVendorReceipt.discountAmount
                    }
                    onChange={(e) => {
                      setCreateVendorReceipt({
                        ...createVendorReceipt,
                        discountAmount: e.target.value
                      });
                    }}
                  />
                </ContentBox>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: '50%', my: 1 }}
                    label='Date'
                    format='DD/MM/YYYY'
                    value={createVendorReceipt.date}
                    onChange={(newValue) =>
                      setCreateVendorReceipt({ ...createVendorReceipt, date: newValue })
                    }
                    slotProps={{ textField: { variant: 'standard' } }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <InputLabel sx={{ fontSize: '10px' }} required id='bookNumber'>
                  Book Number
                </InputLabel>

                <Select
                  size='small'
                  sx={{ width: '50%', my: 1 }}
                  variant='standard'
                  labelId='vendorReceiptBookNumber'
                  value={createVendorReceipt.bookNumber}
                  onChange={handleBookNameSelect}
                >
                  {getFiveYearsBack().map((data, index) => (
                    <MenuItem value={data} key={index} id={index}>
                      {data}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <InputLabel
                  sx={{ fontSize: '10px' }}
                  error={dataError.paymentTypeError}
                  id='paymentType'
                >
                  Payment Type
                </InputLabel>
                <Select
                  size='small'
                  sx={{ my: 1, width: '50%' }}
                  variant='standard'
                  labelId='diamondType'
                  value={createVendorReceipt.paymentType}
                  onChange={handlePaymentTypeSelect}
                  error={dataError.paymentTypeError}
                >
                  {PaymentType.map((data, index) => (
                    <MenuItem value={data.name} key={index} id={index}>
                      {data.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ContentBox>
                  <TextField
                    id='remark'
                    type='text'
                    multiline
                    maxRows={3}
                    margin='dense'
                    size='small'
                    label='Remak'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={createVendorReceipt.remark == null ? '' : createVendorReceipt.remark}
                    onChange={(e) => {
                      setCreateVendorReceipt({
                        ...createVendorReceipt,
                        remark: e.target.value
                      });
                    }}
                  />
                </ContentBox>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <ContentBox>
                  <TextField
                    id='vendorRemainingAmount'
                    type='number'
                    margin='dense'
                    size='small'
                    label='Remaining Amount'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='filled'
                    value={
                      createVendorReceipt.vendorRemainingAmount == null
                        ? 0
                        : createVendorReceipt.vendorRemainingAmount
                    }
                  />
                </ContentBox>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <ContentBox>
                  <TextField
                    id='vendorDiscountAmount'
                    type='number'
                    margin='dense'
                    size='small'
                    label='Discount Amount'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='filled'
                    value={
                      createVendorReceipt.vendorDiscountAmount == null
                        ? 0
                        : createVendorReceipt.vendorDiscountAmount
                    }
                  />
                </ContentBox>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <ContentBox>
                  <TextField
                    id='vendorPaidAmount'
                    type='number'
                    margin='dense'
                    size='small'
                    label='Paid Amount'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='filled'
                    value={
                      createVendorReceipt.vendorPaidAmount == null
                        ? 0
                        : createVendorReceipt.vendorPaidAmount
                    }
                  />
                </ContentBox>
              </Grid>
            </Grid>
            <LoadingButton
              onClick={handleSubmit}
              color='primary'
              loading={loading}
              variant='contained'
              sx={{ my: 2, textTransform: 'capitalize' }}
            >
              Create Receipt
            </LoadingButton>
          </StyledCard>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CreateVendorReceipt;
