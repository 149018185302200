const setCookieValue = (cookieValue) => {
  const { authToken, userName } = cookieValue;
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + 12 * 60 * 60 * 1000);
  const expires = expirationDate.toUTCString();
  document.cookie = `token=${authToken}; Expires=${expires};`;
  document.cookie = `userName=${userName};Expires=${expires};`;
};
const getCookieValue = () => {
  const cookieToken = document.cookie.split(';');
  let authToken;
  let userInfo = { userName: null, imgUrl: null };
  for (const token of cookieToken) {
    if (token.includes('token=')) {
      const tokenParts = token.split('=');
      authToken = tokenParts[1].trim();
      break;
    }
  }
  for (const userName of cookieToken) {
    if (userName.includes('userName=')) {
      const userNameParts = userName.split('=');
      const value = userNameParts[1].trim();
      userInfo.userName = value;
      break;
    }
  }
  return { authToken, userInfo };
};
export { setCookieValue, getCookieValue };
