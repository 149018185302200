import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders } from 'app/utils/handleHeader';

const initialState = {
  accountType: 'customer',
  customerData: null,
  invoiceCustomerData: null,
  vendorData: null,
  employeeData: null,
  isInitialised: false,
  isCustomerSuccess: false,
  isInvoiceCustomerSuccess: false,
  isVendorSuccess: false,
  isEmployeeSuccess: false,
  isError: false,
  isLoading: false
};

//--------------Asyncthunk for Add Customer Account

export const addAccountData = createAsyncThunk(
  '/addAccont',
  async (object, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/accountgroup/addcustomer`,
        object,
        { headers: getHeaders() }
      );
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//--------------Asyncthunk for Add Get Customers Account

export const getAccountData = createAsyncThunk(
  '/getAccount',
  async (object, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/accountgroup/getcustomers`,
        { headers: getHeaders() }
      );
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//--------------Asyncthunk for Add Get Invoice Customers Account

export const getInvoiceAccountData = createAsyncThunk(
  '/getInvoiceAccount',
  async (object, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/accountgroup/getinvoicecustomers`,
        { headers: getHeaders() }
      );
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//--------------Asyncthunk for Update Customer Account

export const updateAccountData = createAsyncThunk(
  '/updateAccount',
  async ({ updateAccountId, updatedCustomerData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/accountgroup/updatecustomer/${updateAccountId}`,
        updatedCustomerData,
        { headers: getHeaders() }
      );
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//--------------Asyncthunk for Delete Customer Account

export const deleteAccountData = createAsyncThunk(
  '/deleteAccount',
  async ({ deleteAccountId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/accountgroup/deletecustomer/${deleteAccountId}`,
        { headers: getHeaders() }
      );
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

//--------------Asyncthunk for Add Vendor Account

export const addVendorAccountData = createAsyncThunk(
  '/addVendorAccont',
  async (object, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/accountgroup/addvendor`,
        object,
        { headers: getHeaders() }
      );
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//--------------Asyncthunk for Add Get Vendors Account

export const getVendorAccountData = createAsyncThunk(
  '/getVendorAccount',
  async (object, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/accountgroup/getvendors`,
        { headers: getHeaders() }
      );
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//--------------Asyncthunk for Update Vendor Account

export const updateVendorAccountData = createAsyncThunk(
  '/updateVendorAccount',
  async ({ updateAccountId, updateVendorData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/accountgroup/updatevendor/${updateAccountId}`,
        updateVendorData,
        { headers: getHeaders() }
      );
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//--------------Asyncthunk for Delete Vendor Account
export const deleteVendorAccountData = createAsyncThunk(
  '/deleteAccount',
  async ({ deleteAccountId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/accountgroup/deletevendor/${deleteAccountId}`,
        { headers: getHeaders() }
      );
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//--------------Asyncthunk for Add Employee Account

export const addEmployeeAccountData = createAsyncThunk(
  '/addEmployee',
  async (object, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/accountgroup/addemployee`,
        object,
        { headers: getHeaders() }
      );
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//--------------Asyncthunk for Get Employee Account

export const getEmployeeAccountData = createAsyncThunk(
  '/getEmployeeAccount',
  async (object, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/accountgroup/getemployee`,
        { headers: getHeaders() }
      );
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//--------------Asyncthunk for Update Employee Account

export const updateEmployeeAccountData = createAsyncThunk(
  '/updateEmployeeAccount',
  async ({ updateAccountId, updateEmployeeData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/accountgroup/updateemployee/${updateAccountId}`,
        updateEmployeeData,
        { headers: getHeaders() }
      );
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//--------------Asyncthunk for Delete Employee Account
export const deleteEmployeeAccountData = createAsyncThunk(
  '/deleteEmployeeAccount',
  async ({ deleteAccountId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/accountgroup/deleteemployee/${deleteAccountId}`,
        { headers: getHeaders() }
      );
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    updateAccountType: (state, action) => {
      state.accountType = action.payload;
    }
  },
  extraReducers: {
    //--------------_Slice action for the Add Customer Account
    [addAccountData.pending]: (state, action) => {
      state.pending = true;
      state.isCustomerSuccess = false;
      state.isError = false;
    },
    [addAccountData.fulfilled]: (state, action) => {
      state.pending = false;
      state.isCustomerSuccess = true;
      state.isError = false;
      state.accountType = 'CUSTOMER';
    },
    [addAccountData.rejected]: (state, action) => {
      state.pending = false;
      state.isCustomerSuccess = false;
      state.isError = true;
    },
    //--------------_Slice action for the Get Customer Account
    [getAccountData.pending]: (state, action) => {
      state.pending = true;
      state.isCustomerSuccess = false;
      state.isError = false;
    },
    [getAccountData.fulfilled]: (state, action) => {
      state.pending = false;
      state.isCustomerSuccess = true;
      state.customerData = action.payload;
      state.isError = false;
    },
    [getAccountData.rejected]: (state, action) => {
      state.pending = false;
      state.isCustomerSuccess = false;
      state.isError = true;
    },
    //--------------_Slice action for the Get Invoice Customer Account
    [getInvoiceAccountData.pending]: (state, action) => {
      state.pending = true;
      state.isInvoiceCustomerSuccess = false;
      state.isError = false;
    },
    [getInvoiceAccountData.fulfilled]: (state, action) => {
      state.pending = false;
      state.isInvoiceCustomerSuccess = true;
      state.invoiceCustomerData = action.payload;
      state.isError = false;
    },
    [getInvoiceAccountData.rejected]: (state, action) => {
      state.pending = false;
      state.isInvoiceCustomerSuccess = false;
      state.isError = true;
    },
    //--------------_Slice action for the Update Customer Account
    [updateAccountData.pending]: (state, action) => {
      state.pending = true;
      state.isCustomerSuccess = false;
      state.isError = false;
    },
    [updateAccountData.fulfilled]: (state, action) => {
      state.pending = false;
      state.isCustomerSuccess = true;
      state.isError = false;
    },
    [updateAccountData.rejected]: (state, action) => {
      state.pending = false;
      state.isCustomerSuccess = false;
      state.isError = true;
    },
    //--------------_Slice action for the Delete Customer Account
    [deleteAccountData.pending]: (state, action) => {
      state.pending = true;
      state.isCustomerSuccess = false;
      state.isError = false;
    },
    [deleteAccountData.fulfilled]: (state, action) => {
      state.pending = false;
      state.isCustomerSuccess = true;
      state.isError = false;
    },
    [deleteAccountData.rejected]: (state, action) => {
      state.pending = false;
      state.isCustomerSuccess = false;
      state.isError = true;
    },
    //--------------_Slice action for the Add Vendor Account
    [addVendorAccountData.pending]: (state, action) => {
      state.pending = true;
      state.isVendorSuccess = false;
      state.isError = false;
    },
    [addVendorAccountData.fulfilled]: (state, action) => {
      state.pending = false;
      state.isVendorSuccess = true;
      state.isError = false;
      state.accountType = 'VENDOR';
    },
    [addVendorAccountData.rejected]: (state, action) => {
      state.pending = false;
      state.isVendorSuccess = false;
      state.isError = true;
    },
    //--------------_Slice action for the Get Vendor Account
    [getVendorAccountData.pending]: (state, action) => {
      state.pending = true;
      state.isVendorSuccess = false;
      state.isError = false;
    },
    [getVendorAccountData.fulfilled]: (state, action) => {
      state.pending = false;
      state.isVendorSuccess = true;
      state.vendorData = action.payload;
      state.isError = false;
    },
    [getVendorAccountData.rejected]: (state, action) => {
      state.pending = false;
      state.isVendorSuccess = false;
      state.isError = true;
    },
    //--------------_Slice action for the Update Vendor Account
    [updateVendorAccountData.pending]: (state, action) => {
      state.pending = true;
      state.isVendorSuccess = false;
      state.isError = false;
    },
    [updateVendorAccountData.fulfilled]: (state, action) => {
      state.pending = false;
      state.isVendorSuccess = true;
      state.isError = false;
    },
    [updateVendorAccountData.rejected]: (state, action) => {
      state.pending = false;
      state.isVendorSuccess = false;
      state.isError = true;
    },
    //--------------_Slice action for the Delete Vendor Account
    [deleteVendorAccountData.pending]: (state, action) => {
      state.pending = true;
      state.isVendorSuccess = false;
      state.isError = false;
    },
    [deleteVendorAccountData.fulfilled]: (state, action) => {
      state.pending = false;
      state.isVendorSuccess = true;
      state.isError = false;
    },
    [deleteVendorAccountData.rejected]: (state, action) => {
      state.pending = false;
      state.isVendorSuccess = false;
      state.isError = true;
    },
    //--------------Slice action for the Add Employee Account
    [addEmployeeAccountData.pending]: (state, action) => {
      state.pending = true;
      state.isEmployeeSuccess = false;
      state.isError = false;
    },
    [addEmployeeAccountData.fulfilled]: (state, action) => {
      state.pending = false;
      state.isEmployeeSuccess = true;
      state.isError = false;
      state.accountType = 'EMPLOYEE';
    },
    [addEmployeeAccountData.rejected]: (state, action) => {
      state.pending = false;
      state.isEmployeeSuccess = false;
      state.isError = true;
    },
    //--------------Slice action for the Get Employee Account
    [getEmployeeAccountData.pending]: (state, action) => {
      state.pending = true;
      state.isEmployeeSuccess = false;
      state.isError = false;
    },
    [getEmployeeAccountData.fulfilled]: (state, action) => {
      state.pending = false;
      state.isEmployeeSuccess = true;
      state.employeeData = action.payload;
      state.isError = false;
    },
    [getEmployeeAccountData.rejected]: (state, action) => {
      state.pending = false;
      state.isEmployeeSuccess = false;
      state.isError = true;
    },
    //--------------Slice action for the Update Employee Account
    [updateEmployeeAccountData.pending]: (state, action) => {
      state.pending = true;
      state.isEmployeeSuccess = false;
      state.isError = false;
    },
    [updateEmployeeAccountData.fulfilled]: (state, action) => {
      state.pending = false;
      state.isEmployeeSuccess = true;
      state.isError = false;
    },
    [updateEmployeeAccountData.rejected]: (state, action) => {
      state.pending = false;
      state.isEmployeeSuccess = false;
      state.isError = true;
    },
    //--------------Slice action for the Delete Employee Account
    [deleteEmployeeAccountData.pending]: (state, action) => {
      state.pending = true;
      state.isEmployeeSuccess = false;
      state.isError = false;
    },
    [deleteEmployeeAccountData.fulfilled]: (state, action) => {
      state.pending = false;
      state.isEmployeeSuccess = true;
      state.isError = false;
    },
    [deleteEmployeeAccountData.rejected]: (state, action) => {
      state.pending = false;
      state.isEmployeeSuccess = false;
      state.isError = true;
    }
  }
});
export const { updateAccountType } = accountSlice.actions;
export default accountSlice.reducer;
