import { Grid, styled } from '@mui/material';
import { Fragment } from 'react';
import VendorStockEntry from './shared/VendorStockEntry';
import VendorDetailsCard from './shared/VendorDetailsCard';
import { useState } from 'react';

const ContentBox = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' }
}));

const SpecificStock = () => {
  const [apiCall, setApiCall] = useState(false);

  return (
    <Fragment>
      <ContentBox className='analytics'>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <VendorDetailsCard apiCall={apiCall} />
            <VendorStockEntry apiCall={apiCall} setApiCall={setApiCall} />
          </Grid>
        </Grid>
      </ContentBox>
    </Fragment>
  );
};

export default SpecificStock;
