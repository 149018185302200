import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders } from 'app/utils/handleHeader';
const initialState = {
    sellReportData: null,
    isInitialised: false,
    isSellReportSuccess: false,
    isSellReportPDFSuccess: false,
    isError: false,
    isLoading: false
};
//--------------Asyncthunk for Get Sell Report --------------------------------
export const getSellReportData = createAsyncThunk('getSellReportData', async ({ fromDate, toDate, productName, itemName }, { rejectWithValue }) => {
    try {
        let url = `${process.env.REACT_APP_BASE_URL}/report/sellreport?fromDate=${fromDate}&toDate=${toDate}`;

        if (itemName && itemName !== 'all') {
            url += `&itemName=${itemName}`;
        }

        if (productName && productName !== 'all') {
            url += `&productName=${productName}`;
        }
        const response = await axios.get(url, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
// ----------------------------------------Genrate Sell-Report PDF --------------------------------
export const genrateSellReportPdf = createAsyncThunk('genrateSellReportPdf', async ({ fromDate, toDate, customerName }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/report/sellreportpdf?fromDate=${fromDate}&toDate=${toDate}&customerName=${customerName}`, {
            responseType: 'arraybuffer',
            headers: { 'Accept': 'application/pdf', ...getHeaders() }
        });

        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Customer_Report_${fromDate}-${toDate}.pdf`; // Set the desired file name
        a.click();
        window.URL.revokeObjectURL(url);
        // return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

const sellReportSlice = createSlice({
    name: 'sellReportSlice',
    initialState,
    reducers: {},
    extraReducers: {
        //--------------Slice action for the Get Stock Data --------------------------------
        [getSellReportData.pending]: (state, action) => {
            state.isLoading = false;
            state.isSellReportSuccess = false;
            state.isError = false;
        },
        [getSellReportData.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isSellReportSuccess = true;
            state.sellReportData = action.payload;
            state.isError = false;
        },
        [getSellReportData.rejected]: (state, action) => {
            state.isLoading = false;
            state.isSellReportSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Generate Sell-Pdf Report --------------------------------
        [genrateSellReportPdf.pending]: (state, action) => {
            state.isLoading = false;
            state.isSellReportPDFSuccess = false;
            state.isError = false;
        },
        [genrateSellReportPdf.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isSellReportPDFSuccess = true;
            state.isError = false;
        },
        [genrateSellReportPdf.rejected]: (state, action) => {
            state.isLoading = false;
            state.isSellReportPDFSuccess = false;
            state.isError = true;
        },
    }
})
export default sellReportSlice.reducer