import { MenuItem, Select } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectHeadGroupValue } from '../../helper/selectData';
import Loading from 'app/components/MatxLoading';

const HeaderGroupSelect = ({ setAccountGroup }) => {
  const { accountgroupData } = useSelector((state) => state.accountGroup);
  const { isAccountGroupSuccess } = useSelector((state) => state.accountGroup);

  const [dropdownValue, setDropdownValue] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isAccountGroupSuccess) {
      setIsLoading(true);
      setDropdownValue(accountgroupData.data.data);
      setIsLoading(false);
    }
  }, [isAccountGroupSuccess, accountgroupData]);
  const handleSelect = async (event) => {
    const selectedData = await selectHeadGroupValue(event.target.value, accountgroupData.data.data);
    setAccountGroup(selectedData);
  };
  return (
    <Select size='small' defaultValue='all' onChange={handleSelect}>
      <MenuItem value='all' id='all'>
        Select Head Group
      </MenuItem>
      {!isLoading ? (
        Array.from(new Set(dropdownValue.map((data) => data.head_group_name.toLowerCase()))).map(
          (data, index) => (
            <MenuItem value={data} id={index}>
              {data.toUpperCase()}
            </MenuItem>
          )
        )
      ) : (
        <Loading />
      )}
    </Select>
  );
};

export default HeaderGroupSelect;
