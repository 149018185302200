import {
  Box,
  Card,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { getIEReportData, getIEReportPdf } from 'app/contexts/reducers/report/IEReportSlice';
import { Small } from 'app/components/Typography';
import { getIncomeTypeData } from 'app/contexts/reducers/incomeType/incomeTypeSlice';
import { getExpenseTypeData } from 'app/contexts/reducers/expenseType/expenseTypeSlice';
import { filterDataByTypeAndValue } from '../helper/incomeExpenseFilter';

const CardHeader = styled(Box)(() => ({
  display: 'flex',
  paddingLeft: '24px',
  paddingRight: '24px',
  marginBottom: '12px',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  textTransform: 'capitalize'
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 'max-content !important',
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)'
  },
  '& td': { borderBottom: '1px solid lightgray' },
  '& td:first-of-type': { paddingLeft: '16px !important' }
}));

const IEReportTable = () => {
  const dispatch = useDispatch();
  const { isIEReportSuccess, IEReportData } = useSelector((state) => state.ieReport);
  const { isIncomeTypeSuccess, incomeTypeData } = useSelector((state) => state.incomeType);
  const { isExpenseTypeSuccess, expenseTypeData } = useSelector((state) => state.expenseType);

  const [reportData, setReportData] = useState({
    fromDate: dayjs().startOf('month'),
    toDate: dayjs()
  });
  const [iEReportDatas, setIEReportDatas] = useState([]);
  const [expenseType, setExpenseType] = useState([]);
  const [filterValue, setFilterValue] = useState(0);
  const [filterType, setFilterType] = useState();
  const [incomeType, setIncomeType] = useState([]);

  const { filteredIncomeData, filteredExpenseData } = iEReportDatas;
  const maxLength = Math.max(filteredIncomeData?.length, filteredExpenseData?.length);
  const [dataError, setDataError] = useState({
    startDateError: false,
    endDateError: false
  });

  useEffect(() => {
    dispatch(
      getIEReportData({
        fromDate: reportData.fromDate.format('MM-DD-YYYY'),
        toDate: reportData.toDate.format('MM-DD-YYYY')
      })
    );
  }, [dispatch, reportData]);
  useEffect(() => {
    dispatch(getIncomeTypeData());
    dispatch(getExpenseTypeData());
  }, [dispatch]);
  useEffect(() => {
    if (isIEReportSuccess) {
      if (Number(IEReportData.data.code) === 200) {
        setIEReportDatas(IEReportData.data.data);
      } else {
        setIEReportDatas([]);
      }
    }
  }, [isIEReportSuccess, IEReportData]);
  useEffect(() => {
    if (isExpenseTypeSuccess) {
      if (Number(expenseTypeData.data.code) === 200) {
        setExpenseType(expenseTypeData.data.data);
      } else {
        setExpenseType([]);
      }
    }
  }, [isExpenseTypeSuccess, expenseTypeData]);
  useEffect(() => {
    if (isIncomeTypeSuccess) {
      if (Number(incomeTypeData.data.code) === 200) {
        setIncomeType(incomeTypeData.data.data);
      } else {
        setIncomeType([]);
      }
    }
  }, [isIncomeTypeSuccess, incomeTypeData]);
  useEffect(() => {
    if (isIEReportSuccess && filterType && filterType) {
      const data = filterDataByTypeAndValue(IEReportData.data.data, filterType, filterValue);
      setIEReportDatas(data);
    } else {
      isIEReportSuccess && setIEReportDatas(IEReportData.data.data);
    }
  }, [filterValue, filterType, IEReportData, isIEReportSuccess]);
  const handleStartDateChange = (newValue) => {
    if (newValue.isBefore(reportData.toDate)) {
      setReportData({ ...reportData, fromDate: dayjs(newValue) });
      setFilterValue(0);
      setDataError({ ...dataError, startDateError: false });
    } else {
      setDataError({ ...dataError, startDateError: true });
    }
  };

  const handleEndDateChange = (newValue) => {
    if (reportData.fromDate.isBefore(newValue)) {
      setReportData({ ...reportData, toDate: dayjs(newValue) });
      setFilterValue(0);

      setDataError({ ...dataError, endDateError: false });
    } else {
      setDataError({ ...dataError, endDateError: true });
    }
  };
  const convertDate = (isoDateString) => {
    const date = new Date(isoDateString);

    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    };
    const formattedDate = date.toLocaleString('en-GB', options).replace(/\//g, '-');
    return formattedDate;
  };
  function calculateTotalAmounts(data) {
    const { filteredIncomeData, filteredExpenseData } = data;

    // Calculate total income amount
    const totalIncomeAmount = filteredIncomeData
      ? filteredIncomeData.reduce((total, income) => total + parseInt(income.amount, 10), 0)
      : 0;

    // Calculate total expense amount
    const totalExpenseAmount = filteredExpenseData
      ? filteredExpenseData.reduce((total, expense) => total + parseInt(expense.amount, 10), 0)
      : 0;

    return { totalIncomeAmount, totalExpenseAmount };
  }
  const handleIncomeTypeSelect = async (e) => {
    setFilterValue(e.target.value);
    setFilterType('IncomeType');
  };
  const handleExpenseTypeSelect = async (e) => {
    setFilterValue(e.target.value);
    setFilterType('ExpenseType');
  };

  return (
    <React.Fragment>
      <Card elevation={3} sx={{ pt: '20px', mb: 3 }}>
        <CardHeader>
          <Grid container justifyContent='space-between'>
            <Grid item display={'flex'} flexDirection={'column'} justifyContent={'space-around'}>
              <Title>Income-Expense-Report</Title>
              <Small>
                Balance :{' '}
                <span
                  style={{
                    color:
                      calculateTotalAmounts(iEReportDatas).totalIncomeAmount -
                        calculateTotalAmounts(iEReportDatas).totalExpenseAmount <
                      0
                        ? 'red'
                        : 'green',
                    fontWeight: 'bold'
                  }}
                >
                  {calculateTotalAmounts(iEReportDatas).totalIncomeAmount -
                    calculateTotalAmounts(iEReportDatas).totalExpenseAmount <
                  0
                    ? Math.abs(
                        calculateTotalAmounts(iEReportDatas).totalIncomeAmount -
                          calculateTotalAmounts(iEReportDatas).totalExpenseAmount
                      )
                    : calculateTotalAmounts(iEReportDatas).totalIncomeAmount -
                      calculateTotalAmounts(iEReportDatas).totalExpenseAmount}{' '}
                </span>{' '}
              </Small>
            </Grid>

            <Grid item>
              <Grid container sx={{ flexWrap: 'nowrap', alignItems: 'end' }}>
                <Grid
                  item
                  sx={{
                    width: '60%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}
                >
                  <Select
                    size='small'
                    sx={{ my: 1, width: '60%' }}
                    variant='standard'
                    labelId='incomeType'
                    value={
                      incomeType.some(
                        (data) =>
                          data.income_type === filterValue || filterValue === 'CUSTOMER_RECEIPT'
                      )
                        ? filterValue
                        : 0
                    }
                    onChange={handleIncomeTypeSelect}
                  >
                    <MenuItem value={0} key={0} id={0}>
                      Income Type
                    </MenuItem>
                    <MenuItem
                      value={'CUSTOMER_RECEIPT'}
                      key={'CUSTOMER_RECEIPT'}
                      id={'CUSTOMER_RECEIPT'}
                    >
                      CUSTOMER_RECEIPT
                    </MenuItem>
                    {incomeType &&
                      incomeType.map((data, index) => (
                        <MenuItem
                          value={data.income_type}
                          key={data.income_type}
                          id={data.income_type}
                        >
                          {data.income_type}
                        </MenuItem>
                      ))}
                  </Select>

                  <Select
                    size='small'
                    sx={{ my: 1, width: '60%' }}
                    variant='standard'
                    labelId='expenseType'
                    value={
                      expenseType.some(
                        (data) =>
                          data.expense_type === filterValue || filterValue === 'VENDOR_RECEIPT'
                      )
                        ? filterValue
                        : 0
                    }
                    onChange={handleExpenseTypeSelect}
                  >
                    <MenuItem value={0} key={0} id={0}>
                      Expense Type
                    </MenuItem>
                    <MenuItem value={'VENDOR_RECEIPT'} key={'VENDOR_RECEIPT'} id={'VENDOR_RECEIPT'}>
                      VENDOR_RECEIPT
                    </MenuItem>

                    {expenseType &&
                      expenseType.map((data, index) => (
                        <MenuItem
                          value={data.expense_type}
                          key={data.expense_type}
                          id={data.expense_type}
                        >
                          {data.expense_type}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
                <Grid item sx={{ textAlign: 'end' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: '40%', my: 1, mr: 2 }}
                      label='From Date'
                      format='DD/MM/YYYY'
                      value={reportData.fromDate}
                      onChange={handleStartDateChange}
                      slotProps={{
                        textField: {
                          variant: 'standard',
                          error: dataError.startDateError,
                          helperText: dataError.startDateError ? 'Please Select Start Date' : null
                        }
                      }}
                    />

                    <DatePicker
                      sx={{ width: '40%', my: 1, mr: 2 }}
                      label='To Date'
                      format='DD/MM/YYYY'
                      value={reportData.toDate}
                      onChange={handleEndDateChange}
                      slotProps={{
                        textField: {
                          variant: 'standard',
                          error: dataError.endDateError,
                          helperText: dataError.endDateError ? 'Please Select End Date' : null
                        }
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item>
                  <IconButton
                    color='primary'
                    sx={{ ml: 2 }}
                    onClick={() => {
                      dispatch(
                        getIEReportPdf({
                          fromDate: reportData.fromDate.format('MM-DD-YYYY'),
                          toDate: reportData.toDate.format('MM-DD-YYYY')
                        })
                      );
                    }}
                  >
                    <Icon>print</Icon>
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardHeader>
        <Box overflow={'auto'}>
          <ProductTable>
            <TableHead sx={{ backgroundColor: 'rgb(34,41,67)' }}>
              <TableRow sx={{ backgroundColor: 'rgb(34,41,67)' }}>
                <TableCell
                  colSpan={12}
                  align='center'
                  sx={{
                    fontWeight: 'bold',
                    color: '#FFFFFF',
                    borderRight: '2px solid #FFFFFF',
                    textTransform: 'uppercase'
                  }}
                >
                  Income
                </TableCell>
                <TableCell
                  colSpan={12}
                  align='center'
                  sx={{ fontWeight: 'bold', color: '#FFFFFF', textTransform: 'uppercase' }}
                >
                  Expense
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Date
                </TableCell>
                <TableCell colSpan={4} sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Account-Name
                </TableCell>
                <TableCell colSpan={2} sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Amount
                </TableCell>
                <TableCell colSpan={4} sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Remark
                </TableCell>
                <TableCell colSpan={2} sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Date
                </TableCell>
                <TableCell colSpan={4} sx={{ px: 2, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Account Name
                </TableCell>
                <TableCell colSpan={2} sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Amount
                </TableCell>
                <TableCell colSpan={4} sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Remark
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {iEReportDatas ? (
                filteredIncomeData &&
                filteredExpenseData &&
                maxLength > 0 &&
                Array.from({ length: maxLength }).map((_, index) => {
                  const incomeData = filteredIncomeData[index];
                  const expenseData = filteredExpenseData[index];
                  return (
                    <TableRow key={index} hover>
                      <TableCell
                        colSpan={2}
                        align='left'
                        sx={{ px: 1, textTransform: 'capitalize' }}
                      >
                        {incomeData ? convertDate(incomeData.date) : '-'}
                      </TableCell>
                      <TableCell
                        colSpan={4}
                        align='left'
                        sx={{ px: 0, textTransform: 'capitalize', fontWeight: 'bold' }}
                      >
                        {incomeData
                          ? incomeData.hasOwnProperty('customer_name')
                            ? incomeData.customer_name
                            : incomeData.income_type
                          : '-'}
                      </TableCell>
                      <TableCell
                        colSpan={2}
                        align='left'
                        sx={{ px: 0, fontWeight: 'bold', textTransform: 'capitalize' }}
                      >
                        {incomeData ? incomeData.amount : '-'}
                      </TableCell>
                      <TableCell
                        align='left'
                        colSpan={4}
                        sx={{ px: 2, textTransform: 'capitalize' }}
                      >
                        {incomeData ? incomeData.remark : '-'}
                      </TableCell>
                      <TableCell
                        colSpan={2}
                        align='left'
                        sx={{ px: 1, textTransform: 'capitalize' }}
                      >
                        {expenseData ? convertDate(expenseData.date) : '-'}
                      </TableCell>
                      <TableCell
                        colSpan={4}
                        align='left'
                        sx={{ px: 2, textTransform: 'capitalize', fontWeight: 'bold' }}
                      >
                        {expenseData
                          ? expenseData.hasOwnProperty('vendor_name')
                            ? expenseData.vendor_name
                            : expenseData.expense_type
                          : '-'}
                      </TableCell>
                      <TableCell
                        colSpan={2}
                        align='left'
                        sx={{ px: 0, textTransform: 'capitalize', fontWeight: 'bold' }}
                      >
                        {expenseData ? expenseData.amount : '-'}
                      </TableCell>

                      <TableCell
                        colSpan={4}
                        align='left'
                        sx={{
                          px: 0,

                          textTransform: 'capitalize'
                        }}
                      >
                        {expenseData ? expenseData.remark : '-'}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={24} sx={{ textAlign: 'center' }}>
                    No data found
                  </TableCell>
                </TableRow>
              )}

              <TableRow sx={{ backgroundColor: 'rgb(34,41,67)' }}>
                <TableCell colSpan={4} sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Total
                </TableCell>
                <TableCell
                  colSpan={2}
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                ></TableCell>
                <TableCell colSpan={2} sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  {calculateTotalAmounts(iEReportDatas).totalIncomeAmount}
                </TableCell>
                <TableCell
                  colSpan={4}
                  sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}
                ></TableCell>
                <TableCell
                  colSpan={2}
                  sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}
                ></TableCell>
                <TableCell
                  colSpan={4}
                  sx={{ px: 2, fontWeight: 'bold', color: '#FFFFFF' }}
                ></TableCell>
                <TableCell colSpan={2} sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  {calculateTotalAmounts(iEReportDatas).totalExpenseAmount}
                </TableCell>
                <TableCell
                  colSpan={4}
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                ></TableCell>
              </TableRow>
            </TableBody>
          </ProductTable>
        </Box>
      </Card>
    </React.Fragment>
  );
};

export default IEReportTable;
