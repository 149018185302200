import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  DialogContent,
  DialogContentText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  styled
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleReceipt } from '../helper/getSingleReceipt';
import dayjs from 'dayjs';
import { updateVendorReceipt } from 'app/contexts/reducers/transaction/vendorReceiptSlice';
const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' }
}));
const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main }
}));
export default function UpdateVendorReceiptDialog({
  open,
  onClose,
  receiptId,
  handleApiCall,
  handleClickSuccess,
  setError,
  handleClickError
}) {
  const dispatch = useDispatch();

  const { isAllVendorReceiptsSuccess, allVendorReceiptData } = useSelector(
    (state) => state.vendorReceipt
  );
  const [allVendorReceiptsData, setAllVendorReceiptsData] = useState([]);

  const [updateValue, setUpdateValue] = useState({
    amount: null,
    vendorName: null,
    discountAmount: null,
    paymentType: '',
    bookNumber: [],
    date: '',
    remark: null
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isAllVendorReceiptsSuccess) {
      if (Number(allVendorReceiptData.data.code) === 200) {
        setAllVendorReceiptsData(allVendorReceiptData.data.data);
      } else {
        setAllVendorReceiptsData([]);
      }
    }
  }, [isAllVendorReceiptsSuccess, allVendorReceiptData]);

  useEffect(() => {
    if (allVendorReceiptsData) {
      getSingleReceipt(allVendorReceiptsData, receiptId).then((receiptDetails) => {
        receiptDetails &&
          setUpdateValue({
            vendorName: receiptDetails.vendor_name,
            amount: receiptDetails.amount,
            discountAmount: receiptDetails.discount_amount,
            paymentType: receiptDetails.payment_type,
            bookNumber: receiptDetails.book_number,
            date: dayjs(receiptDetails.date),
            remark: receiptDetails.remark
          });
      });
    }
    // eslint-disable-next-line
  }, [receiptId, isAllVendorReceiptsSuccess, allVendorReceiptData, open]);
  // const handleBookNameSelect = async (e) => {
  //   setUpdateValue({ ...updateValue, bookNumber: e.target.value });
  // };
  // const getFiveYearsBack = () => {
  //   const currentYear = new Date().getFullYear();
  //   const fiveYearsBackArray = [];

  //   for (let i = 0; i < 3; i++) {
  //     const year = currentYear - i;
  //     fiveYearsBackArray.push(String(year));
  //   }

  //   return fiveYearsBackArray;
  // };
  const handlePaymentTypeSelect = async (e) => {
    setUpdateValue({ ...updateValue, paymentType: e.target.value });
  };
  const PaymentType = [
    {
      name: 'CASH'
    },
    {
      name: 'BANK'
    }
  ];
  async function handleUpdate() {
    try {
      if (
        updateValue.vendorName &&
        updateValue.vendorName.length > 0 &&
        updateValue.bookNumber &&
        Number(updateValue.bookNumber) > 0 &&
        updateValue.paymentType &&
        updateValue.paymentType.length > 0 &&
        updateValue.itemId !== null &&
        updateValue.productId !== null
      ) {
        const vendorReceiptDetails = { ...updateValue };
        Object.keys(vendorReceiptDetails).forEach((key) => {
          if (vendorReceiptDetails[key] === '' || vendorReceiptDetails[key] === null) {
            delete vendorReceiptDetails[key];
          }
          if (
            key === 'amount' &&
            (vendorReceiptDetails[key] === '' || vendorReceiptDetails[key] === null)
          ) {
            vendorReceiptDetails[key] = 0;
          }
          // if (key === 'date') {
          //   vendorReceiptDetails[key] = dayjs(vendorReceiptDetails[key]).format('YYYY-MM-DD');
          // }
          if (
            key === 'discountAmount' &&
            (vendorReceiptDetails[key] === '' || vendorReceiptDetails[key] === null)
          ) {
            vendorReceiptDetails[key] = 0;
          }
        });
        const responseValue = await dispatch(
          updateVendorReceipt({ receiptId, vendorReceiptDetails })
        );
        if (!responseValue.hasOwnProperty('error')) {
          onClose();
          handleClickSuccess('Vendor Receipt Updated successfully!!');
          handleApiCall();
          setLoading(false);
        } else {
          throw responseValue;
        }
      } else {
        handleClickError();
        setError({ payload: 'Please Fill Mandatory Data!' });
        setLoading(false);
      }
    } catch (e) {
      handleClickError();
      setError(e);
      setLoading(false);
    }
  }
  return (
    <Box>
      <Dialog open={open} onClose={onClose} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>Update Vendor Receipt</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: '10px' }}>
            You can Update Your Vendor Receipt Details From Here!
          </DialogContentText>
          <StyledCard elevation={6}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ContentBox>
                  <TextField
                    id='vendorName'
                    type='text'
                    margin='dense'
                    size='small'
                    disabled
                    label='Vendor Name'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='standard'
                    value={updateValue.vendorName}
                  />
                </ContentBox>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ContentBox>
                  <TextField
                    id='amount'
                    type='number'
                    margin='dense'
                    size='small'
                    label='Amount'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='standard'
                    value={updateValue.amount == null ? 0 : updateValue.amount}
                    onChange={(e) => {
                      setUpdateValue({
                        ...updateValue,
                        amount: e.target.value
                      });
                    }}
                  />
                </ContentBox>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ContentBox>
                  <TextField
                    id='discountAmount'
                    type='number'
                    margin='dense'
                    size='small'
                    label='Discount Amount'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='standard'
                    value={updateValue.discountAmount == null ? 0 : updateValue.discountAmount}
                    onChange={(e) => {
                      setUpdateValue({
                        ...updateValue,
                        discountAmount: e.target.value
                      });
                    }}
                  />
                </ContentBox>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: '80%', my: 1 }}
                    label='Date'
                    format='DD/MM/YYYY'
                    value={updateValue.date}
                    onChange={(newValue) => setUpdateValue({ ...updateValue, date: newValue })}
                    slotProps={{ textField: { variant: 'standard' } }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ContentBox>
                  <TextField
                    id='bookNumber'
                    type='text'
                    multiline
                    maxRows={3}
                    margin='dense'
                    size='small'
                    label='Book Number'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '90%' }}
                    variant='standard'
                    value={updateValue.bookNumber == null ? '' : updateValue.bookNumber}
                  />
                </ContentBox>
                {/* <InputLabel sx={{ fontSize: '10px' }} required id='bookNumber'>
                  Book Number
                </InputLabel>

                <Select
                  size='small'
                  sx={{ width: '80%', my: 1 }}
                  variant='standard'
                  labelId='vendorReceiptBookNumber'
                  value={updateValue.bookNumber}
                  onChange={handleBookNameSelect}
                >
                  {getFiveYearsBack().map((data, index) => (
                    <MenuItem value={data} key={index} id={index}>
                      {data}
                    </MenuItem>
                  ))}
                </Select> */}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <InputLabel sx={{ fontSize: '10px' }} id='paymentType'>
                  Payment Type
                </InputLabel>
                <Select
                  size='small'
                  sx={{ my: 1, width: '80%' }}
                  variant='standard'
                  labelId='diamondType'
                  value={updateValue.paymentType}
                  onChange={handlePaymentTypeSelect}
                >
                  {PaymentType.map((data, index) => (
                    <MenuItem value={data.name} key={index} id={index}>
                      {data.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <ContentBox>
                  <TextField
                    id='remark'
                    type='text'
                    multiline
                    maxRows={3}
                    margin='dense'
                    size='small'
                    label='Remak'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '90%' }}
                    variant='standard'
                    value={updateValue.remark == null ? '' : updateValue.remark}
                    onChange={(e) => {
                      setUpdateValue({
                        ...updateValue,
                        remark: e.target.value
                      });
                    }}
                  />
                </ContentBox>
              </Grid>
            </Grid>
          </StyledCard>
        </DialogContent>
        <DialogActions>
          <Button variant='standard' color='secondary' onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleUpdate}
            color='primary'
            loading={loading}
            variant='contained'
            sx={{ my: 2 }}
          >
            Update Receipt
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
