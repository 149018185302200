import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  DialogContent,
  DialogContentText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  styled
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import DialogTitle from '@mui/material/DialogTitle';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUpdateSingleAccount } from '../helper/getUpdateSingleAccount';
import { updateAccountData } from 'app/contexts/reducers/account/accountSlice';
const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' }
}));
const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main }
}));
export default function UpdateAccountDialog({
  open,
  onClose,
  updateAccountId,
  setApiCall,
  handleClickSuccess,
  setError,
  handleClickError
}) {
  const dispatch = useDispatch();
  const { customerData, accountType } = useSelector((state) => state.account);
  const { isCustomerSuccess } = useSelector((state) => state.account);
  const [updateValue, setUpdateValue] = useState({
    customerType: 'RETAILER',
    accountName: '',
    accountCode: '',
    openingBalance: '',
    reference: '',
    specialNote: '',
    firstName: '',
    lastName: '',
    firmName: '',
    address: '',
    mobileNumber: '',
    email: ''
  });
  const [accountNameError, setAccountNameError] = useState(false);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (customerData) {
      getUpdateSingleAccount(customerData.data.data, updateAccountId).then((data) => {
        data &&
          setUpdateValue({
            customerType: data.customer_type,
            accountName: data.account_name,
            accountCode: data.account_code,
            openingBalance: data.opening_balance,
            reference: data.reference,
            specialNote: data.special_note,
            firstName: data.first_name,
            lastName: data.last_name,
            firmName: data.firm_name,
            address: data.address,
            mobileNumber: data.mobile_number,
            email: data.email
          });
      });
    }
  }, [updateAccountId, isCustomerSuccess, customerData]);

  async function handleUpdate() {
    try {
      setLoading(true);
      const currentAccount = await getUpdateSingleAccount(customerData.data.data, updateAccountId);
      if (accountType.toLowerCase() === 'customer') {
        if (
          updateValue.accountName !== currentAccount.account_name &&
          updateValue.accountCode !== currentAccount.account_code
        ) {
          const updatedCustomerData = { ...updateValue };
          Object.keys(updatedCustomerData).forEach((key) => {
            if (updatedCustomerData[key] === '') {
              delete updatedCustomerData[key];
            }
          });
          const response = await dispatch(
            updateAccountData({ updateAccountId, updatedCustomerData })
          );
          if (!response.hasOwnProperty('error')) {
            onClose();
            setApiCall((prev) => !prev);
            handleClickSuccess('Account Update successfully');
            setLoading(false);
          } else {
            throw response;
          }
        } else if (updateValue.accountName !== currentAccount.account_name) {
          const updatedCustomerData = { ...updateValue };
          Object.keys(updatedCustomerData).forEach((key) => {
            if (updatedCustomerData[key] === '' || key === 'accountCode') {
              delete updatedCustomerData[key];
            }
          });
          const response = await dispatch(
            updateAccountData({ updateAccountId, updatedCustomerData })
          );
          if (!response.hasOwnProperty('error')) {
            onClose();
            setApiCall((prev) => !prev);
            handleClickSuccess('Account Update successfully');
            setLoading(false);
          } else {
            throw response;
          }
        } else if (updateValue.accountCode !== currentAccount.account_code) {
          const updatedCustomerData = { ...updateValue };
          Object.keys(updatedCustomerData).forEach((key) => {
            if (updatedCustomerData[key] === '' || key === 'accountName') {
              delete updatedCustomerData[key];
            }
          });
          const response = await dispatch(
            updateAccountData({ updateAccountId, updatedCustomerData })
          );
          if (!response.hasOwnProperty('error')) {
            onClose();
            setApiCall((prev) => !prev);
            handleClickSuccess('Account Update successfully');
            setLoading(false);
          } else {
            throw response;
          }
        } else {
          if (updateValue.customerType.length > 0) {
            const updatedCustomerData = { ...updateValue };
            Object.keys(updatedCustomerData).forEach((key) => {
              if (
                key === 'accountName' ||
                key === 'accountCode' ||
                updatedCustomerData[key] === '' ||
                updatedCustomerData[key] === null
              ) {
                delete updatedCustomerData[key];
              }
            });
            const response = await dispatch(
              updateAccountData({ updateAccountId, updatedCustomerData })
            );
            if (!response.hasOwnProperty('error')) {
              onClose();
              setApiCall((prev) => !prev);
              handleClickSuccess('Account Update successfully');
              setLoading(false);
            } else {
              throw response;
            }
          } else {
            onClose();
            setLoading(false);
          }
        }
      }
    } catch (e) {
      handleClickError();
      setError(e);
      setLoading(false);
    }
  }

  return (
    <Box>
      <Dialog open={open} onClose={onClose} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>Update Customer</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: '10px' }}>
            You can Update Your Customer Details From Here!{' '}
          </DialogContentText>

          <Grid container spacing={3} sx={{ mb: '24px' }}>
            <Grid item xs={12} md={12}>
              <StyledCard elevation={6}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <InputLabel id='customerType' sx={{ fontSize: '11px' }}>
                      Customer Type
                    </InputLabel>
                    <Select
                      size='small'
                      sx={{ width: '80%', my: 1 }}
                      variant='standard'
                      labelId='customerType'
                      onChange={(e) => {
                        setUpdateValue({ ...updateValue, customerType: e.target.value });
                      }}
                      value={updateValue.customerType}
                    >
                      <MenuItem value='FIRM' id={1}>
                        FIRM
                      </MenuItem>
                      <MenuItem value='RETAILER' id={2}>
                        RETAILER
                      </MenuItem>
                    </Select>
                    <ContentBox>
                      <TextField
                        error={accountNameError}
                        id='accountName'
                        type='text'
                        size='small'
                        margin='dense'
                        label='Account Name'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '80%' }}
                        variant='standard'
                        required
                        helperText={accountNameError ? 'Please Enter Account Name' : ''}
                        value={updateValue.accountName}
                        onChange={(e) => {
                          setAccountNameError(false);
                          setUpdateValue({ ...updateValue, accountName: e.target.value });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        error={accountNameError}
                        id='accountCode'
                        type='text'
                        size='small'
                        margin='dense'
                        label='Account Code'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '80%' }}
                        variant='standard'
                        required
                        helperText={accountNameError ? 'Please Enter Account Name' : ''}
                        value={updateValue.accountCode}
                        onChange={(e) => {
                          setAccountNameError(false);
                          setUpdateValue({ ...updateValue, accountCode: e.target.value });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='openingBalance'
                        type='number'
                        size='small'
                        margin='dense'
                        label='Opening Balance'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '80%' }}
                        variant='standard'
                        value={updateValue.openingBalance == null ? '' : updateValue.openingBalance}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, openingBalance: e.target.value });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='email'
                        type='email'
                        margin='dense'
                        size='small'
                        label='Email'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '80%' }}
                        variant='standard'
                        value={updateValue.email == null ? '' : updateValue.email}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, email: e.target.value });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='reference'
                        type='text'
                        margin='dense'
                        size='small'
                        label='Reference'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '80%' }}
                        variant='standard'
                        value={updateValue.reference == null ? '' : updateValue.reference}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, reference: e.target.value });
                        }}
                      />
                    </ContentBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ContentBox>
                      <TextField
                        id='firmName'
                        type='text'
                        margin='dense'
                        size='small'
                        label='Firm Name'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '80%' }}
                        variant='standard'
                        value={updateValue.firmName == null ? '' : updateValue.firmName}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, firmName: e.target.value });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='firstName'
                        type='text'
                        margin='dense'
                        size='small'
                        label='First Name'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '80%' }}
                        variant='standard'
                        value={updateValue.firstName == null ? '' : updateValue.firstName}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, firstName: e.target.value });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='lastName'
                        type='text'
                        margin='dense'
                        size='small'
                        label='Last Name'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '80%' }}
                        variant='standard'
                        value={updateValue.lastName == null ? '' : updateValue.lastName}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, lastName: e.target.value });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='address'
                        type='text'
                        margin='dense'
                        size='small'
                        label='Address'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '80%' }}
                        variant='standard'
                        value={updateValue.address == null ? '' : updateValue.address}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, address: e.target.value });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='contactNumber'
                        type='text'
                        margin='dense'
                        size='small'
                        label='Contact Number'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '80%' }}
                        variant='standard'
                        value={updateValue.mobileNumber == null ? '' : updateValue.mobileNumber}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, mobileNumber: e.target.value });
                        }}
                      />
                    </ContentBox>
                  </Grid>
                </Grid>

                <TextField
                  fullWidth
                  maxRows={6}
                  id='specialNote'
                  type='text'
                  margin='dense'
                  size='small'
                  label='Special Note'
                  InputLabelProps={{ shrink: true }}
                  sx={{ my: 1, width: '100%' }}
                  variant='standard'
                  multiline
                  value={updateValue.specialNote == null ? '' : updateValue.specialNote}
                  onChange={(e) => {
                    setUpdateValue({ ...updateValue, specialNote: e.target.value });
                  }}
                />
              </StyledCard>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='standard' color='secondary' onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleUpdate}
            color='primary'
            loading={loading}
            variant='contained'
            sx={{ my: 2 }}
          >
            Update Customer
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
