import {
  Box,
  Card,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { H5 } from 'app/components/Typography';
import { getaccountgroup } from 'app/contexts/reducers/accountgroup/accountgroupSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchItem from './searchAccountGroup';
import HeaderGroupSelect from './headerGroupSelect';

const CardHeader = styled(Box)(() => ({
  display: 'flex',
  paddingLeft: '24px',
  paddingRight: '24px',
  marginBottom: '12px',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  textTransform: 'capitalize'
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)'
  },
  '& td': { borderBottom: '1px solid lightgray' },
  '& td:first-of-type': { paddingLeft: '16px !important' }
}));

const AccountGroupTable = () => {
  const dispatch = useDispatch();
  const { accountgroupData } = useSelector((state) => state.accountGroup);

  const { isAccountGroupSuccess } = useSelector((state) => state.accountGroup);
  const [accountgroup, setAccountGroup] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    dispatch(getaccountgroup());
  }, [dispatch]);
  useEffect(() => {
    if (isAccountGroupSuccess) {
      setIsLoading(true);
      setAccountGroup(accountgroupData.data.data);
      setIsLoading(false);
    }
  }, [isAccountGroupSuccess, accountgroupData]);
  return (
    <Card elevation={3} sx={{ pt: '20px', mb: 3 }}>
      <CardHeader>
        <Grid container justifyContent='space-between'>
          <Grid item>
            <Title>Account group</Title>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <SearchItem setAccountGroup={setAccountGroup} />
              </Grid>
              <Grid item>
                <HeaderGroupSelect setAccountGroup={setAccountGroup} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardHeader>

      <Box overflow='auto'>
        <ProductTable>
          <TableHead sx={{ backgroundColor: 'rgb(34,41,67)' }}>
            <TableRow>
              <TableCell sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }} colSpan={2}>
                Index
              </TableCell>
              <TableCell sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }} colSpan={4}>
                Account Group Name
              </TableCell>
              <TableCell sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }} colSpan={3}>
                Head Group
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {!isLoading ? (
              accountgroup.map((data, index) => (
                <TableRow key={index} hover>
                  <TableCell align='left' colSpan={2} sx={{ px: 3, textTransform: 'capitalize' }}>
                    <H5 sx={{ px: 3 }}>{index + 1}</H5>
                  </TableCell>
                  <TableCell align='left' colSpan={4} sx={{ px: 0, textTransform: 'capitalize' }}>
                    {data.account_group_name}
                  </TableCell>
                  <TableCell align='left' colSpan={3} sx={{ px: 0, textTransform: 'capitalize' }}>
                    {data.head_group_name}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} sx={{ textAlign: 'center' }}>
                  No data found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </ProductTable>
      </Box>
    </Card>
  );
};

export default AccountGroupTable;
