import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setCookieValue } from 'app/utils/handleCookie';

import axios from 'axios';

const initialState = {
  user: null,
  isInitialised: false,
  isAuthenticated: false,
  isAuthSuccess: false,
  isLoading: false
};

export const login = createAsyncThunk('auth/login', async (credentials, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/login`, credentials);
    const { userName, authToken } = response.data;
    setCookieValue(response.data);
    return { userName, authToken };
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
// export const logout = createAsyncThunk('auth/logout', async () => {
//   // Perform any logout-related tasks here
//   // e.g., clear authentication tokens, make API calls, etc.
// });
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: {
    [login.pending]: (state, action) => {
      state.isLoading = true;
    },
    [login.fulfilled]: (state, action) => {
      const { userName } = action.payload;
      state.isLoading = false;
      state.user = userName;
      state.isAuthSuccess = true;
      state.isAuthenticated = true;
    },
    [login.rejected]: (state, { message }) => {
      state.isLoading = false;
      state.isAuthenticated = message;
    }
  }
});

export default authSlice.reducer;
