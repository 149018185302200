import { getHeaders } from 'app/utils/handleHeader';
import axios from 'axios';
const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');
const initialState = {
    isInitialised: true,
    isLoading: false,
    isError: false,
    isReceiptGenrateSuccess: false,
    isReceiptDeleteSuccess: false,
    isReceiptUpdateSuccess: false,
    isAllCustomerReceiptsSuccess: false,
    isCustomerReceiptsSuccess: false,
    isSpecificCustomerReceiptSuccess: false,
    isCustomerAmountFoundedSuccess: false,
    isAllCustomerAmountFoundedSuccess: false,
    isGenerateCustomerReceiptSuccess: false,
    allCustomerReceiptData: null,
    customerReceiptsData: null,
    singleReceiptData: null,
    customerAmountData: null,
    allCustomerAmountData: null,
};
// --------------------------------------Create Customer Receipt --------------------------------
export const createCustomerPaymentReceipt = createAsyncThunk('createCustomerPaymentReceipt', async (customerReceiptDetails, { rejectWithValue }) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/transaction/createcustomerreceipt`,
            customerReceiptDetails,
            {
                headers: getHeaders()
            }
        );
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

//--------------Asyncthunk for Get All Customer Receipt Data--------------------------------
export const getAllCustomerReceiptData = createAsyncThunk('getAllCustomerReceiptData', async (object, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transaction/getallcustomerreceipt`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Get Customer Receipt Data--------------------------------
export const getCustomersReceiptData = createAsyncThunk('getCustomersReceiptData', async ({ customerId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transaction/getallcustomerspecificcustomerreceipt/${customerId}`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Get Specific Receipt Data--------------------------------
export const getSpecificReceiptData = createAsyncThunk('getSpecificReceiptData', async ({ receiptId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transaction/getspecificcustomerreceipt/${receiptId}`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Get Customer Amount Data--------------------------------
export const getCustomerAmountData = createAsyncThunk('getCustomerAmountData', async ({ customerId, bookNumber }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transaction/getcustomeramountdata/${customerId}/${bookNumber}`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Get All Customer Amount Data--------------------------------
export const getAllCustomerAmountData = createAsyncThunk('getAllCustomerAmountData', async ({ bookNumber }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transaction/getallcustomeramountdata/${bookNumber}`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Delete Customer Receipt--------------------------------
export const deleteCustomerReceipt = createAsyncThunk('deleteCustomerReceipt', async ({ receiptId }, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/transaction/deletecustomerreceipt/${receiptId}`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Update Customer Receipt--------------------------------
export const updateCustomerReceipt = createAsyncThunk('updateCustomerReceipt', async ({ receiptId, customerReceiptDetails }, { rejectWithValue }) => {
    try {
        const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/transaction/updatecustomerreceipt/${receiptId}`, customerReceiptDetails, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
// ----------------------------------------Genrate Customer-Receipt PDF --------------------------------
export const genrateCustomerReceiptPdf = createAsyncThunk('genrateCustomerReceiptPdf', async ({ invoiceId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/transaction/generatecustomerreceiptpdf/${invoiceId}`,

            {
                responseType: 'arraybuffer',
                headers: { 'Accept': 'application/pdf', ...getHeaders() }
            }
        );
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Customer_Receipt_${invoiceId}.pdf`; // Set the desired file name
        a.click();
        window.URL.revokeObjectURL(url);
        // return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
const customerReceiptSlice = createSlice({
    name: 'customerReceiptSlice',
    initialState,
    reducers: {},
    extraReducers: {
        //--------------Slice action for the Create Customer Receipt
        [createCustomerPaymentReceipt.pending]: (state, action) => {
            state.isLoading = true;
            state.isReceiptGenrateSuccess = false;
            state.isError = false;
        },
        [createCustomerPaymentReceipt.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isReceiptGenrateSuccess = true;
            state.isError = false;
        },
        [createCustomerPaymentReceipt.rejected]: (state, action) => {
            state.isLoading = false;
            state.isReceiptGenrateSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Customer Receipt's Data --------------------------------
        [getAllCustomerReceiptData.pending]: (state, action) => {
            state.pending = false;
            state.isAllCustomerReceiptsSuccess = false;
            state.isError = false;
        },
        [getAllCustomerReceiptData.fulfilled]: (state, action) => {
            state.pending = false;
            state.isAllCustomerReceiptsSuccess = true;
            state.allCustomerReceiptData = action.payload;
            state.isError = false;
        },
        [getAllCustomerReceiptData.rejected]: (state, action) => {
            state.pending = false;
            state.isAllCustomerReceiptsSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Customer's Receipt Data --------------------------------
        [getCustomersReceiptData.pending]: (state, action) => {
            state.pending = false;
            state.isCustomerReceiptsSuccess = false;
            state.isError = false;
        },
        [getCustomersReceiptData.fulfilled]: (state, action) => {
            state.pending = false;
            state.isCustomerReceiptsSuccess = true;
            state.customerReceiptsData = action.payload;
            state.isError = false;
        },
        [getCustomersReceiptData.rejected]: (state, action) => {
            state.pending = false;
            state.isCustomerReceiptsSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Specific Receipt Data --------------------------------
        [getSpecificReceiptData.pending]: (state, action) => {
            state.pending = false;
            state.isSpecificCustomerReceiptSuccess = false;
            state.isError = false;
        },
        [getSpecificReceiptData.fulfilled]: (state, action) => {
            state.pending = false;
            state.isSpecificCustomerReceiptSuccess = true;
            state.singleReceiptData = action.payload;
            state.isError = false;
        },
        [getSpecificReceiptData.rejected]: (state, action) => {
            state.pending = false;
            state.isSpecificCustomerReceiptSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Amount  Data --------------------------------
        [getCustomerAmountData.pending]: (state, action) => {
            state.pending = false;
            state.isCustomerAmountFoundedSuccess = false;
            state.isError = false;
        },
        [getCustomerAmountData.fulfilled]: (state, action) => {
            state.pending = false;
            state.isCustomerAmountFoundedSuccess = true;
            state.customerAmountData = action.payload;
            state.isError = false;
        },
        [getCustomerAmountData.rejected]: (state, action) => {
            state.pending = false;
            state.isCustomerAmountFoundedSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get All Customer Amount Data --------------------------------
        [getAllCustomerAmountData.pending]: (state, action) => {
            state.pending = false;
            state.isAllCustomerAmountFoundedSuccess = false;
            state.isError = false;
        },
        [getAllCustomerAmountData.fulfilled]: (state, action) => {
            state.pending = false;
            state.isAllCustomerAmountFoundedSuccess = true;
            state.allCustomerAmountData = action.payload;
            state.isError = false;
        },
        [getAllCustomerAmountData.rejected]: (state, action) => {
            state.pending = false;
            state.isAllCustomerAmountFoundedSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Delete Customer Receipt
        [deleteCustomerReceipt.pending]: (state, action) => {
            state.isLoading = true;
            state.isReceiptDeleteSuccess = false;
            state.isError = false;
        },
        [deleteCustomerReceipt.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isReceiptDeleteSuccess = true;
            state.isError = false;
        },
        [deleteCustomerReceipt.rejected]: (state, action) => {
            state.isLoading = false;
            state.isReceiptDeleteSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Update Customer Receipt
        [updateCustomerReceipt.pending]: (state, action) => {
            state.isLoading = true;
            state.isReceiptUpdateSuccess = false;
            state.isError = false;
        },
        [updateCustomerReceipt.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isReceiptUpdateSuccess = true;
            state.isError = false;
        },
        [updateCustomerReceipt.rejected]: (state, action) => {
            state.isLoading = false;
            state.isReceiptUpdateSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Create Customer Receipt
        [genrateCustomerReceiptPdf.pending]: (state, action) => {
            state.isLoading = true;
            state.isGenerateCustomerReceiptSuccess = false;
            state.isError = false;
        },
        [genrateCustomerReceiptPdf.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isGenerateCustomerReceiptSuccess = true;
            state.isError = false;
        },
        [genrateCustomerReceiptPdf.rejected]: (state, action) => {
            state.isLoading = false;
            state.isGenerateCustomerReceiptSuccess = false;
            state.isError = true;
        },
    }
});
export default customerReceiptSlice.reducer;