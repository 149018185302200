import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders } from 'app/utils/handleHeader';
const initialState = {
    expenseTypeData: null,
    isInitialised: false,
    isExpenseTypeEntrySuccess: false,
    isExpenseTypeSuccess: false,
    isExpenseTypeUpdateSuccess: false,
    isExpenseTypeDeleteSuccess: false,
    isError: false,
    isLoading: false
};

//--------------Asyncthunk for Add Expense-Type --------------------
export const createExpenseType = createAsyncThunk(
    '/createExpenseType',
    async (object, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/expensetype/createexpensetype`,
                object,
                { headers: getHeaders() }
            );
            return response;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);
//--------------Asyncthunk for Get Income-Types--------------------------------
export const getExpenseTypeData = createAsyncThunk('getExpenseTypeData', async (object, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/expensetype/getexpensetype`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Delete Expense-Type Data--------------------------------
export const deleteExpenseType = createAsyncThunk(
    '/deleteExpenseType',
    async ({ expenseTypeId }, { rejectWithValue }) => {
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_BASE_URL}/expensetype/deleteexpensetype/${expenseTypeId}`,
                { headers: getHeaders() }
            );
            return response;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);
//--------------Asyncthunk for Update Expense-Type Data--------------------------------
export const updateExpenseType = createAsyncThunk(
    '/updateExpenseType',
    async ({ expenseTypeId, expenseEntryDetails }, { rejectWithValue }) => {
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/expensetype/updateexpensetype/${expenseTypeId}`,
                { expenseType: expenseEntryDetails }, { headers: getHeaders() }
            );
            return response;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);
const expenseTypeSlice = createSlice({
    name: 'Expense-Type',
    initialState,
    reducers: {},
    extraReducers: {
        //--------------Slice action for the Add Expense-Type --------------------------------
        [createExpenseType.pending]: (state, action) => {
            state.pending = true;
            state.isExpenseTypeEntrySuccess = false;
            state.isError = false;
        },
        [createExpenseType.fulfilled]: (state, action) => {
            state.pending = false;
            state.isExpenseTypeEntrySuccess = true;
            state.isError = false;
        },
        [createExpenseType.rejected]: (state, action) => {
            state.pending = false;
            state.isExpenseTypeEntrySuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Expense-Type --------------------------------
        [getExpenseTypeData.pending]: (state, action) => {
            state.pending = false;
            state.isExpenseTypeSuccess = false;
            state.isError = false;
        },
        [getExpenseTypeData.fulfilled]: (state, action) => {
            state.pending = false;
            state.isExpenseTypeSuccess = true;
            state.expenseTypeData = action.payload;
            state.isError = false;
        },
        [getExpenseTypeData.rejected]: (state, action) => {
            state.pending = false;
            state.isExpenseTypeSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Delete Expense-Type --------------------------------
        [deleteExpenseType.pending]: (state, action) => {
            state.pending = true;
            state.isExpenseTypeDeleteSuccess = false;
            state.isError = false;
        },
        [deleteExpenseType.fulfilled]: (state, action) => {
            state.pending = false;
            state.isExpenseTypeDeleteSuccess = true;
            state.isError = false;
        },
        [deleteExpenseType.rejected]: (state, action) => {
            state.pending = false;
            state.isExpenseTypeDeleteSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Update Expense-Type
        [updateExpenseType.pending]: (state, action) => {
            state.pending = true;
            state.isExpenseTypeUpdateSuccess = false;
            state.isError = false;
        },
        [updateExpenseType.fulfilled]: (state, action) => {
            state.pending = false;
            state.isExpenseTypeUpdateSuccess = true;
            state.isError = false;
        },
        [updateExpenseType.rejected]: (state, action) => {
            state.pending = false;
            state.isExpenseTypeUpdateSuccess = false;
            state.isError = true;
        }
    }
})
export default expenseTypeSlice.reducer;
