import { Box, Card, Grid, Icon, styled } from '@mui/material';
import Loading from 'app/components/MatxLoading';
import { Small } from 'app/components/Typography';
import { getCustomerStockData } from 'app/contexts/reducers/bulkStockMaster/bulkStockMasterSlice';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' }
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main }
}));

const Heading = styled('h6')(({ theme }) => ({
  margin: 0,
  marginTop: '4px',
  fontSize: '14px',
  fontWeight: '500',
  color: theme.palette.primary.main
}));

const CustomerDetailsCard = ({ apiCall }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { stockId } = Object.fromEntries(queryParams.entries());
  const { isSingleBulkStockDataSuccess, singleBulkStockData } = useSelector(
    (state) => state.bulkStock
  );

  const [customerStockData, setCustomerStockData] = useState([]);

  useEffect(() => {
    dispatch(getCustomerStockData({ bulkStockId: stockId }));
  }, [dispatch, apiCall, stockId]);

  useEffect(() => {
    if (isSingleBulkStockDataSuccess) {
      setCustomerStockData(singleBulkStockData.data.data);
    }
  }, [isSingleBulkStockDataSuccess, singleBulkStockData]);

  const cardList =
    customerStockData.length > 0 ? (
      [
        {
          name: 'Customer Name',
          amount: `${customerStockData[0].customer_name}`,
          icon: 'face'
        },
        {
          name: 'Diamond Type',
          amount: `${customerStockData[0].diamond_type}`,
          icon: 'diamond'
        },
        {
          name: 'Total Purchase Amount',
          amount: `${customerStockData[0].total_price}`,
          icon: 'attach_money'
        },
        {
          name: 'Total Piece',
          amount: `${parseFloat(parseFloat(customerStockData[0].total_pcs).toFixed(3))}`,
          icon: 'store'
        },
        {
          name: 'Used Piece',
          amount:
            customerStockData[0].used_pcs === null
              ? 0
              : `${parseFloat(parseFloat(customerStockData[0].used_pcs).toFixed(3))}`,
          icon: 'shopping_cart'
        },
        {
          name: 'Broken Piece',
          amount:
            customerStockData[0].broken_pcs === null
              ? 0
              : `${parseFloat(parseFloat(customerStockData[0].broken_pcs).toFixed(3))}`,
          icon: 'broken_image'
        },
        {
          name: 'Missing Piece',
          amount:
            customerStockData[0].missing_pcs === null
              ? 0
              : `${parseFloat(parseFloat(customerStockData[0].missing_pcs).toFixed(3))}`,
          icon: 'bug_report'
        },
        {
          name: 'Remaining Piece',
          amount: `${parseFloat(parseFloat(customerStockData[0].remaining_pcs).toFixed(3))}`,
          icon: 'hourglass_empty'
        }
      ]
    ) : (
      <Loading />
    );

  return (
    <Grid container spacing={3} sx={{ mb: '24px' }}>
      {customerStockData.length > 0 ? (
        cardList.map((item, index) => (
          <Grid item xs={12} md={3} key={index}>
            <StyledCard elevation={6}>
              <ContentBox>
                <Icon className='icon'>{item.icon}</Icon>
                <Box ml='12px'>
                  <Small>{item.name}</Small>
                  <Heading>{item.amount}</Heading>
                </Box>
              </ContentBox>
            </StyledCard>
          </Grid>
        ))
      ) : (
        <Loading />
      )}
    </Grid>
  );
};

export default CustomerDetailsCard;
