const searchItemValue = async (searchValue, dataHouse) => {
  if (searchValue.trim() === '') {
    // If the search term is empty, return the whole array
    return dataHouse;
  }
  const searchData = await dataHouse.filter((data) => {
    return data.itemname.toLowerCase().includes(searchValue.toLowerCase());
  });
  return searchData;
};
const searchProductValue = async (searchValue, dataHouse) => {
  if (searchValue.trim() === '') {
    // If the search term is empty, return the whole array
    return dataHouse;
  }
  const searchData = await dataHouse.filter((data) => {
    const productNameMatch = data.productname.toLowerCase().includes(searchValue.toLowerCase());
    const itemNameMatch = data.itemname.toLowerCase().includes(searchValue.toLowerCase());

    return productNameMatch || itemNameMatch;
  });
  return searchData;
};
const searchAccountGroupValue = async (searchValue, dataHouse) => {
  if (searchValue.trim() === '') {
    // If the search term is empty, return the whole array
    return dataHouse;
  }
  const searchData = await dataHouse.filter((data) => {
    return data.account_group_name.toLowerCase().includes(searchValue.toLowerCase());
  });
  return searchData;
};
const searchAccountValue = async (searchValue, dataHouse, accountType) => {
  if (searchValue.trim() === '') {
    // If the search term is empty, return the whole array
    return dataHouse;
  }

  const searchData = await dataHouse.filter((data) => {
    const searchTerm = searchValue.toLowerCase();
    if (accountType.toLowerCase() === 'customer') {
      return (
        data.account_name.toLowerCase().includes(searchTerm) ||
        data.account_code.toLowerCase().includes(searchTerm)
      );
    } else {
      return data.account_name.toLowerCase().includes(searchTerm);
    }
  });

  return searchData;
};
const searchEmployeeValue = async (searchValue, dataHouse) => {
  if (searchValue.trim() === '') {
    // If the search term is empty, return the whole array
    return dataHouse;
  }
  const searchData = await dataHouse.filter((data) => {
    return data.name.toLowerCase().includes(searchValue.toLowerCase());
  });
  return searchData;
};

export {
  searchItemValue,
  searchProductValue,
  searchAccountGroupValue,
  searchAccountValue,
  searchEmployeeValue
};
