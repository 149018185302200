import { useState, Fragment } from 'react';
import { Icon, IconButton, styled } from '@mui/material';
import { useSelector } from 'react-redux';
import { searchIncomeTypeEntry } from '../helper/searchIncomeTypeEntry';

const SearchContainer = styled('div')(({ theme }) => ({
  zIndex: 9,
  maxWidth: '60%',
  display: 'flex',
  marginRight: '10%',
  alignItems: 'center',
  boxShadow: theme.shadows[0],
  background: theme.palette.primary.main,
  color: theme.palette.text.primary,
  '&::placeholder': {
    color: theme.palette.text.primary
  }
}));

const SearchInput = styled('input')(({ theme }) => ({
  maxWidth: '100%',
  border: 'none',
  outline: 'none',
  fontSize: '1rem',
  paddingLeft: '20px',
  height: 'calc(100% - 5px)',
  background: theme.palette.primary.main,
  color: theme.palette.text.primary,
  '&::placeholder': { color: theme.palette.text.primary }
}));

const SearchIncomeType = ({ setIncomeEntriesData }) => {
  const { allIncomeEntriesData } = useSelector((state) => state.incomeEntry);
  const [open, setOpen] = useState(false);

  const handleChange = async (e) => {
    const filterData = await searchIncomeTypeEntry(e.target.value, allIncomeEntriesData.data.data);
    setIncomeEntriesData(filterData);
  };

  const toggle = () => {
    setIncomeEntriesData(allIncomeEntriesData.data.data);
    setOpen(!open);
  };
  return (
    <Fragment>
      {!open && (
        <IconButton onClick={toggle}>
          <Icon sx={{ color: 'text.primary' }}>search</Icon>
        </IconButton>
      )}

      {open && (
        <SearchContainer sx={{ bgcolor: 'white' }}>
          <SearchInput
            type='text'
            placeholder='Search here...'
            autoFocus
            onChange={handleChange}
            sx={{ bgcolor: 'white' }}
          />
          <IconButton onClick={toggle} sx={{ mx: 2, verticalAlign: 'middle' }}>
            <Icon sx={{ color: 'text.primary' }}>close</Icon>
          </IconButton>
        </SearchContainer>
      )}
    </Fragment>
  );
};

export default SearchIncomeType;
