// CollapsibleTable.js
import React from 'react';
import {
  Box,
  Card,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import SellTableRowData from './SellTableRowData';
import styled from '@emotion/styled';
import {
  genrateSellReportPdf,
  getSellReportData
} from 'app/contexts/reducers/report/sellReportSlice';
import dayjs from 'dayjs';
import { getAccountData } from 'app/contexts/reducers/account/accountSlice';
import { getObjectByCustomerId } from '../helper/customerFilter';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { calculateSums } from '../helper/totalValue';
import { useMemo } from 'react';
import { getOrderProducts, getOrderItems } from 'app/contexts/reducers/transaction/oderSlice';

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  textTransform: 'capitalize'
}));
const CardHeader = styled(Box)(() => ({
  display: 'flex',
  paddingLeft: '24px',
  paddingRight: '24px',
  marginBottom: '12px',
  alignItems: 'center',
  justifyContent: 'space-between'
}));
const CollapsibleSellTable = () => {
  const intialState = useMemo(
    () => ({
      studd_pieces: 0,
      labour_charge: 0,
      self_weight: 0,
      studd_weight: 0,
      product_price: 0,
      extra_cost: 0,
      quantity: 0,
      total: 0
    }),
    []
  );
  const { isSellReportSuccess, sellReportData } = useSelector((state) => state.sellReport);
  const { isOrderProducts, orderProducts, isOrderItems, orderItems } = useSelector(
    (state) => state.order
  );
  const { isCustomerSuccess, customerData } = useSelector((state) => state.account);
  const [sellReportDatas, setSellReportDatas] = useState([]);
  const [accountCustomerData, setAccountCustomerData] = useState([]);
  const [orderProductsData, setOrderProductsData] = useState([]);
  const [orderItemsData, setOrderItemsData] = useState([]);
  const [customerName, setCustomerName] = useState('all');
  const [productName, setProductName] = useState('all');
  const [itemName, setItemName] = useState('all');
  const [totalValue, setTotalValue] = useState(intialState);
  const dispatch = useDispatch();

  const [reportData, setReportData] = useState({
    fromDate: dayjs().startOf('month'),
    toDate: dayjs(),
    productName: '',
    itemName: ''
  });

  const [dataError, setDataError] = useState({
    startDateError: false,
    endDateError: false
  });

  useEffect(() => {
    dispatch(getAccountData());
    dispatch(getOrderItems());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getOrderProducts({ itemName }));
  }, [dispatch, itemName]);
  useEffect(() => {
    if (isOrderProducts) {
      if (Number(orderProducts.data.code) === 200) {
        setOrderProductsData(orderProducts.data.data);
      } else {
        setOrderProductsData([]);
      }
    }
  }, [orderProducts, isOrderProducts]);
  useEffect(() => {
    if (isOrderItems) {
      if (Number(orderItems.data.code) === 200) {
        setOrderItemsData(orderItems.data.data);
      } else {
        setOrderItemsData([]);
      }
    }
  }, [orderItems, isOrderItems]);
  useEffect(() => {
    dispatch(
      getSellReportData({
        fromDate: reportData.fromDate.format('MM-DD-YYYY'),
        toDate: reportData.toDate.format('MM-DD-YYYY'),
        productName: reportData.productName,
        itemName: reportData.itemName
      })
    );
    setCustomerName('all');
  }, [dispatch, reportData]);

  useEffect(() => {
    if (isSellReportSuccess) {
      if (Number(sellReportData.data.code) === 200) {
        setSellReportDatas(sellReportData.data.data);
        const totalSellValue = calculateSums(sellReportData.data.data);
        setTotalValue(totalSellValue);
      } else {
        setSellReportDatas([]);
        setTotalValue(intialState);
      }
    }
  }, [isSellReportSuccess, sellReportData, intialState]);
  useEffect(() => {
    if (isCustomerSuccess) {
      if (Number(customerData.data.code) === 200) {
        setAccountCustomerData(customerData.data.data);
      } else {
        setAccountCustomerData([]);
      }
    }
  }, [isCustomerSuccess, customerData]);
  const handleCustomerSelect = async (e) => {
    setCustomerName(e.target.value);
    const data = getObjectByCustomerId(sellReportData.data.data, e.target.value);
    setSellReportDatas(data);
  };
  const handleProductSelect = async (e) => {
    setProductName(e.target.value);
    setReportData({ ...reportData, productName: e.target.value });
  };
  const handleItemSelect = async (e) => {
    setItemName(e.target.value);
    setReportData({ ...reportData, itemName: e.target.value });
  };

  const handleStartDateChange = (newValue) => {
    if (newValue.isBefore(reportData.toDate)) {
      setReportData({ ...reportData, fromDate: dayjs(newValue) });
      setDataError({ ...dataError, startDateError: false });
    } else {
      setDataError({ ...dataError, startDateError: true });
    }
  };

  const handleEndDateChange = (newValue) => {
    if (reportData.fromDate.isBefore(newValue)) {
      setReportData({ ...reportData, toDate: dayjs(newValue) });
      setDataError({ ...dataError, endDateError: false });
    } else {
      setDataError({ ...dataError, endDateError: true });
    }
  };

  return (
    <React.Fragment>
      <Card elevation={3} sx={{ pt: '20px', mb: 3 }}>
        <CardHeader>
          <Grid container justifyContent='space-between'>
            <Grid item sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Title>Sell-Report Table</Title>
            </Grid>

            <Grid item>
              <Grid container sx={{ flexWrap: 'nowrap', alignItems: 'end' }}>
                <Grid item sx={{ width: '40%' }}>
                  <InputLabel sx={{ fontSize: '10px' }} id='customerName'>
                    Customer Name
                  </InputLabel>
                  <Select
                    size='small'
                    sx={{ width: '90%', my: 1 }}
                    variant='standard'
                    labelId='customerName'
                    value={customerName}
                    onChange={handleCustomerSelect}
                  >
                    <MenuItem defaultValue value={'all'} key='all' id='all'>
                      All Customer
                    </MenuItem>
                    {accountCustomerData.map((data, index) => (
                      <MenuItem value={data.account_name} key={index} id={index}>
                        {data.account_name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item sx={{ width: '40%' }}>
                  <InputLabel sx={{ fontSize: '10px' }} id='itemName'>
                    Item Name
                  </InputLabel>
                  <Select
                    size='small'
                    sx={{ width: '90%', my: 1 }}
                    variant='standard'
                    labelId='itemName'
                    value={itemName}
                    onChange={handleItemSelect}
                  >
                    <MenuItem defaultValue value={'all'} key='all' id='all'>
                      Select Items
                    </MenuItem>
                    {orderItemsData.map((data, index) => (
                      <MenuItem value={data.item_name} key={index} id={index}>
                        {data.item_name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item sx={{ width: '40%' }}>
                  <InputLabel sx={{ fontSize: '10px' }} id='customerName'>
                    Product Name
                  </InputLabel>
                  <Select
                    size='small'
                    sx={{ width: '90%', my: 1 }}
                    variant='standard'
                    labelId='productName'
                    value={productName}
                    onChange={handleProductSelect}
                  >
                    <MenuItem defaultValue value={'all'} key='all' id='all'>
                      Products
                    </MenuItem>
                    {orderProductsData.map((data, index) => (
                      <MenuItem value={data.product_name} key={index} id={index}>
                        {data.product_name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item sx={{ textAlign: 'end' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: '40%', my: 1, mr: 2 }}
                      label='From Date'
                      format='DD/MM/YYYY'
                      value={reportData.fromDate}
                      onChange={handleStartDateChange}
                      slotProps={{
                        textField: {
                          variant: 'standard',
                          error: dataError.startDateError,
                          helperText: dataError.startDateError ? 'Please Select Start Date' : null
                        }
                      }}
                    />

                    <DatePicker
                      sx={{ width: '40%', my: 1, mr: 2 }}
                      label='To Date'
                      format='DD/MM/YYYY'
                      value={reportData.toDate}
                      onChange={handleEndDateChange}
                      slotProps={{
                        textField: {
                          variant: 'standard',
                          error: dataError.endDateError,
                          helperText: dataError.endDateError ? 'Please Select End Date' : null
                        }
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item>
                  <IconButton
                    color='primary'
                    sx={{ ml: 2 }}
                    onClick={() => {
                      dispatch(
                        genrateSellReportPdf({
                          fromDate: reportData.fromDate.format('MM-DD-YYYY'),
                          toDate: reportData.toDate.format('MM-DD-YYYY'),
                          customerName: customerName === 'all' ? '' : customerName
                        })
                      );
                    }}
                  >
                    <Icon>print</Icon>
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardHeader>
        <TableContainer component={Paper}>
          <Table aria-label='collapsible table' sx={{ minWidth: '1600px', overflowX: 'auto' }}>
            <TableHead sx={{ backgroundColor: 'rgb(34,41,67)' }}>
              <TableRow>
                <TableCell colSpan={2} sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Bill No.
                </TableCell>
                <TableCell colSpan={2} sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Book
                </TableCell>
                <TableCell colSpan={2} sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Date
                </TableCell>
                <TableCell colSpan={4} sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Customer Name
                </TableCell>
                <TableCell colSpan={2} sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Pcs
                </TableCell>
                <TableCell colSpan={3} sx={{ px: 2, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Labour/Pcs
                </TableCell>
                <TableCell colSpan={3} sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Labour-Charge
                </TableCell>
                <TableCell colSpan={3} sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Quark-Weight
                </TableCell>
                <TableCell colSpan={3} sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Studd-Weight
                </TableCell>
                <TableCell colSpan={3} sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Product-Price
                </TableCell>
                <TableCell colSpan={3} sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Extra-Cost
                </TableCell>
                <TableCell colSpan={3} sx={{ px: 2, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Quantity
                </TableCell>
                <TableCell colSpan={2} sx={{ px: 2, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Total
                </TableCell>
                <TableCell sx={{ width: '5%' }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {sellReportDatas.length > 0 ? (
                sellReportDatas.map((invoice) => (
                  <SellTableRowData key={invoice.id} row={invoice} />
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={35}
                    align='center'
                    sx={{ color: 'rgb(34,41,67)', fontWeight: 'bold' }}
                  >
                    No Stock Data Found
                  </TableCell>
                </TableRow>
              )}
              <TableRow sx={{ backgroundColor: 'rgb(34,41,67)' }}>
                <TableCell
                  colSpan={10}
                  align='center'
                  sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}
                >
                  Total
                </TableCell>
                <TableCell colSpan={4} sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}>
                  {totalValue.studd_pieces ? totalValue.studd_pieces : 0}
                </TableCell>

                <TableCell
                  colSpan={4}
                  sx={{ px: 0, textAlign: 'center', fontWeight: 'bold', color: '#FFFFFF' }}
                >
                  {totalValue.labour_charge ? totalValue.labour_charge : 0}
                </TableCell>
                <TableCell colSpan={3} sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}>
                  {totalValue.self_weight ? totalValue.self_weight : 0}
                </TableCell>
                <TableCell colSpan={3} sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}>
                  {totalValue.studd_weight ? totalValue.studd_weight : 0}
                </TableCell>
                <TableCell colSpan={3} sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}>
                  {totalValue.product_price ? totalValue.product_price : 0}
                </TableCell>
                <TableCell colSpan={3} sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}>
                  {totalValue.extra_cost ? totalValue.extra_cost : 0}
                </TableCell>
                <TableCell colSpan={3} sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}>
                  {totalValue.quantity ? totalValue.quantity : 0}
                </TableCell>
                <TableCell colSpan={2} sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  {totalValue.total ? totalValue.total : 0}
                </TableCell>
                <TableCell sx={{ width: '5%' }} />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </React.Fragment>
  );
};

export default CollapsibleSellTable;
