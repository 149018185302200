import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Card,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  styled,
  TextField
} from '@mui/material';
import { addAccountData, updateAccountType } from 'app/contexts/reducers/account/accountSlice';
import { getaccountgroup } from 'app/contexts/reducers/accountgroup/accountgroupSlice';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { convertAccountId } from 'app/utils/convertId';
import Loading from 'app/components/MatxLoading';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' }
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main }
}));

const CustomerCard = ({ handleApiCall }) => {
  const dispatch = useDispatch();
  const { accountgroupData } = useSelector((state) => state.accountGroup);
  const { isAccountGroupSuccess } = useSelector((state) => state.accountGroup);
  const intialData = {
    accountGroupId: '',
    customerType: 'RETAILER',
    accountName: '',
    accountCode: '',
    openingBalance: '',
    reference: '',
    specialNote: '',
    firstName: '',
    lastName: '',
    firmName: '',
    address: '',
    mobileNumber: '',
    email: ''
  };
  const [accountgroup, setAccountGroup] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [accountGroupName, setAccountGroupName] = useState();
  const [accountGroupId, setAccountGroupId] = useState();
  const [customerData, setCustomerData] = useState(intialData);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [accountNameError, setAccountNameError] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState();

  useEffect(() => {
    dispatch(getaccountgroup());
  }, [dispatch]);
  useEffect(() => {
    if (isAccountGroupSuccess) {
      setIsLoading(true);
      setAccountGroup(accountgroupData.data.data);

      convertAccountId(accountgroupData.data.data, 'customer').then((data) => {
        setAccountGroupName(data.account_group_name);
        setCustomerData({ ...customerData, accountGroupId: data.id });
        setAccountGroupId(data.id);
      });

      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [isAccountGroupSuccess, accountgroupData]);
  function handleClickError() {
    setSuccessOpen(false);
    setErrorOpen(true);
  }
  function handleClickSuccess(message) {
    setErrorOpen(false);
    setSuccessMessage(message);
    setSuccessOpen(true);
  }
  function handleSnakeBarClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
    setErrorOpen(false);
  }
  const handleAccountGroupSelect = async (event) => {
    // const customerId = await convertAccountId(accountgroup, event.target.value.toLowerCase());

    setCustomerData({ ...customerData, accountGroupId: accountGroupId });
    setAccountGroupName(event.target.value.toLowerCase());
    dispatch(updateAccountType(event.target.value.toLowerCase()));
  };
  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (accountGroupName.toLowerCase() === 'customer') {
        if (customerData.accountName.length > 0 && customerData.accountCode.length > 0) {
          const updatedCustomerData = { ...customerData };
          Object.keys(updatedCustomerData).forEach((key) => {
            if (updatedCustomerData[key] === '') {
              delete updatedCustomerData[key];
            }
          });
          const responseValue = await dispatch(addAccountData(updatedCustomerData));
          if (!responseValue.hasOwnProperty('error')) {
            handleClickSuccess('Customer added successfully');
            setCustomerData({ ...intialData, accountGroupId: accountGroupId });
            handleApiCall();
            setLoading(false);
          } else {
            throw responseValue;
          }
        } else {
          setAccountNameError(true);
          handleClickError();
          setError({ payload: 'Account-Name Is Required!' });
          setLoading(false);
        }
      }
    } catch (error) {
      handleClickError();
      setError(error);
      setLoading(false);
    }
  };
  const filteredAccountGroup = accountgroup.filter((accountGroupData) => {
    const groupName = accountGroupData.account_group_name.toLowerCase();
    return groupName === 'customer' || groupName === 'employee' || groupName === 'vendor';
  });
  const menuItems = filteredAccountGroup.map((accountGroupData, index) => (
    <MenuItem value={accountGroupData.account_group_name.toLowerCase()} id={index} key={index}>
      {accountGroupData.account_group_name}
    </MenuItem>
  ));
  return (
    <React.Fragment>
      {' '}
      <Snackbar open={successOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='error'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {error?.payload}
        </Alert>
      </Snackbar>
      <Grid container spacing={3} sx={{ mb: '24px' }}>
        <Grid item xs={12} md={12}>
          <StyledCard elevation={6}>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <InputLabel id='accountGroupName'>Account Group</InputLabel>

                <Select
                  size='small'
                  sx={{ width: '50%', my: 1 }}
                  variant='standard'
                  labelId='accountGroupName'
                  defaultValue={'customer'}
                  onChange={handleAccountGroupSelect}
                >
                  {!isLoading ? menuItems : <Loading />}
                </Select>

                <InputLabel id='customerType'>Customer Type</InputLabel>
                <Select
                  size='small'
                  sx={{ width: '50%', my: 1 }}
                  labelId='customerType'
                  variant='standard'
                  onChange={(e) => {
                    setCustomerData({ ...customerData, customerType: e.target.value });
                  }}
                  value={customerData.customerType}
                >
                  <MenuItem value='FIRM' id={1}>
                    FIRM
                  </MenuItem>
                  <MenuItem value='RETAILER' id={2}>
                    RETAILER
                  </MenuItem>
                </Select>
                <ContentBox>
                  <TextField
                    error={accountNameError}
                    id='accountName'
                    type='text'
                    size='small'
                    margin='dense'
                    label='Account Name'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    required
                    helperText={accountNameError ? 'Please Enter Account Name' : ''}
                    value={customerData.accountName}
                    onChange={(e) => {
                      setAccountNameError(false);
                      setCustomerData({ ...customerData, accountName: e.target.value });
                    }}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    error={accountNameError}
                    id='accountCode'
                    type='text'
                    size='small'
                    margin='dense'
                    label='Account Code'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    required
                    helperText={accountNameError ? 'Please Enter Account Name' : ''}
                    value={customerData.accountCode}
                    onChange={(e) => {
                      setAccountNameError(false);
                      setCustomerData({ ...customerData, accountCode: e.target.value });
                    }}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    id='openingBalance'
                    type='number'
                    size='small'
                    margin='dense'
                    label='Opening Balance'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={customerData.openingBalance}
                    onChange={(e) => {
                      setCustomerData({ ...customerData, openingBalance: e.target.value });
                    }}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    id='reference'
                    type='text'
                    margin='dense'
                    size='small'
                    label='Reference'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={customerData.reference}
                    onChange={(e) => {
                      setCustomerData({ ...customerData, reference: e.target.value });
                    }}
                  />
                </ContentBox>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ContentBox>
                  <TextField
                    id='firmName'
                    type='text'
                    margin='dense'
                    size='small'
                    label='Firm Name'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={customerData.firmName}
                    onChange={(e) => {
                      setCustomerData({ ...customerData, firmName: e.target.value });
                    }}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    id='firstName'
                    type='text'
                    margin='dense'
                    size='small'
                    label='First Name'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={customerData.firstName}
                    onChange={(e) => {
                      setCustomerData({ ...customerData, firstName: e.target.value });
                    }}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    id='lastName'
                    type='text'
                    margin='dense'
                    size='small'
                    label='Last Name'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={customerData.lastName}
                    onChange={(e) => {
                      setCustomerData({ ...customerData, lastName: e.target.value });
                    }}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    id='address'
                    type='text'
                    margin='dense'
                    size='small'
                    label='Address'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={customerData.address}
                    onChange={(e) => {
                      setCustomerData({ ...customerData, address: e.target.value });
                    }}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    id='contactNumber'
                    type='text'
                    margin='dense'
                    size='small'
                    label='Contact Number'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={customerData.mobileNumber}
                    onChange={(e) => {
                      setCustomerData({ ...customerData, mobileNumber: e.target.value });
                    }}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    id='email'
                    type='email'
                    margin='dense'
                    size='small'
                    label='Email'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={customerData.email}
                    onChange={(e) => {
                      setCustomerData({ ...customerData, email: e.target.value });
                    }}
                  />
                </ContentBox>
              </Grid>

              <TextField
                multiline
                maxRows={4}
                id='specialNote'
                type='text'
                margin='dense'
                size='small'
                label='Special Note'
                InputLabelProps={{ shrink: true }}
                sx={{ my: 1, width: '75%' }}
                variant='standard'
                value={customerData.specialNote}
                onChange={(e) => {
                  setCustomerData({ ...customerData, specialNote: e.target.value });
                }}
              />
            </Grid>
            <LoadingButton
              onClick={handleSubmit}
              color='primary'
              loading={loading}
              variant='contained'
              sx={{ my: 2, textTransform: 'capitalize' }}
            >
              Add {accountGroupName}
            </LoadingButton>
          </StyledCard>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CustomerCard;
