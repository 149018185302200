import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders } from 'app/utils/handleHeader';
import axios from 'axios';
const initialState = {
  accountgroupData: null,
  isInitialised: false,
  isAccountGroupSuccess: false,
  isLoading: false,
  isError: false
};

export const getaccountgroup = createAsyncThunk(
  '/accountgroup',
  async (object, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/accountgroup/getaccountgroup`,
        { headers: getHeaders() }
      );
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
const accountgroupSlice = createSlice({
  name: 'accountgroup',
  initialState,
  reducers: {},
  extraReducers: {
    [getaccountgroup.pending]: (state, action) => {
      state.isLoading = true;
      state.isAccountGroupSuccess = false;
      state.isError = false;
    },
    [getaccountgroup.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isAccountGroupSuccess = true;
      state.accountgroupData = action.payload;
      state.isError = false;
    },
    [getaccountgroup.rejected]: (state, action) => {
      state.isLoading = false;
      state.isAccountGroupSuccess = false;
      state.isError = true;
    }
  }
});
export default accountgroupSlice.reducer;
