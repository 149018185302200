import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './auth/AuthGuard';

import Loadable from './components/Loadable';
import MatxLayout from './components/MatxLayout/MatxLayout';
import materialRoutes from 'app/views/material-kit/MaterialRoutes';
import ItemMaster from './views/Master-Account/Item-master/itemMaster';
import ProductMaster from './views/Master-Account/Product-Master/productMaster';
import AccountGroupMaster from './views/Master-Account/Account-Group-Master/accountGroupMaster';
import AccountMaster from './views/Master-Account/Account-Master/accountMaster';
import BookMaster from './views/Master-Account/Book-Master/BookMaster';
import Order from './views/Transaction/Order/Order';
import SingleOrder from './views/Transaction/Order/SingleOrder/SingleOrder';
import StockMaster from './views/Master-Account/Stock-Master/StockMaster';
import SpecificStock from './views/Master-Account/Stock-Master/SingleStock/SpecificStock';
import BulkStockMaster from './views/Master-Account/Bulk-Stock-Master/BulkStockMaster';
import SpecificBulkStock from './views/Master-Account/Bulk-Stock-Master/SingleBulkStock/SpecificBulkStock';
import Sell from './views/Transaction/Sell/Sell';
import SingleInvoice from './views/Transaction/Sell/shared/SingleInvoice/SingleInvoice';
import CustomerReceipt from './views/Transaction/CustomerReceipt/CustomerReceipt';
import VendorReceipt from './views/Transaction/VendorReceipt/VendorReceipt';
import IncomeEntry from './views/Transaction/IncomeEntry/IncomeEntry';
import ExpenseEntry from './views/Transaction/ExpenseEntry/ExpenseEntry';
import IncomeType from './views/Master-Account/IncomeType/IncomeType';
import ExpenseType from './views/Master-Account/ExpenseType /ExpenseType';
import CustomerReport from './views/Report/Customer-Report/customerReport';
import VendorReport from './views/Report/Vendor-Report/vendorReport';
import SellReport from './views/Report/Sell-Report/sellReport';
import IEReport from './views/Report/Income-Expense-Report/Income-Expense';

// session pages
const NotFound = Loadable(lazy(() => import('app/views/sessions/NotFound')));
const JwtLogin = Loadable(lazy(() => import('app/views/sessions/JwtLogin')));

// echart page
const AppEchart = Loadable(lazy(() => import('app/views/charts/echarts/AppEchart')));

// dashboard page
const Analytics = Loadable(lazy(() => import('app/views/dashboard/Analytics')));

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...materialRoutes,
      // dashboard route
      {
        path: '/dashboard',
        element: <Analytics />
      },
      {
        path: '/itemmaster',
        element: <ItemMaster />
      },
      {
        path: '/productmaster',
        element: <ProductMaster />
      },
      {
        path: '/incometype',
        element: <IncomeType />
      },
      {
        path: '/expensetype',
        element: <ExpenseType />
      },
      {
        path: '/accountmaster',
        element: <AccountMaster />
      },
      {
        path: '/accountgroup',
        element: <AccountGroupMaster />
      },
      {
        path: '/bookmaster',
        element: <BookMaster />
      },
      {
        path: '/stockmaster',
        element: <StockMaster />
      },
      {
        path: '/bulkstockmaster',
        element: <BulkStockMaster />
      },
      {
        path: '/specificstock',
        element: <SpecificStock />
      },
      {
        path: '/specificbulkstock',
        element: <SpecificBulkStock />
      },
      // Transaction route
      {
        path: '/orders',
        element: <Order />
      },
      {
        path: '/orders/:orderId',
        element: <SingleOrder />
      },
      {
        path: '/invoice',
        element: <Sell />
      },
      {
        path: '/invoice/:invoiceId',
        element: <SingleInvoice />
      },
      {
        path: '/customerreceipt',
        element: <CustomerReceipt />
      },
      {
        path: '/vendorreceipt',
        element: <VendorReceipt />
      },
      {
        path: '/incomeentry',
        element: <IncomeEntry />
      },
      {
        path: '/expenseentry',
        element: <ExpenseEntry />
      },
      // Reports
      {
        path: '/customerreport',
        element: <CustomerReport />
      },
      {
        path: '/vendorreport',
        element: <VendorReport />
      },
      {
        path: '/sellreport',
        element: <SellReport />
      },
      {
        path: '/iereport',
        element: <IEReport />
      },
      // e-chart rooute

      {
        path: '/charts/echarts',
        element: <AppEchart />
      }
    ]
  },

  // session pages route
  { path: '/404', element: <NotFound /> },
  { path: '/signin', element: <JwtLogin /> },

  { path: '/', element: <Navigate to='/dashboard' /> },
  { path: '*', element: <NotFound /> }
];

export default routes;
