import accountSlice from './reducers/account/accountSlice';
import accountgroupSlice from './reducers/accountgroup/accountgroupSlice';
import authSlice from './reducers/auth/authSlice';
import bookMasterSlice from './reducers/bookMaster/bookMasterSlice';
import itemSlice from './reducers/item/itemSlice';
import productSlice from './reducers/product/productSlice';
import stockMasterSlice from './reducers/stockMaster/stockMasterSlice';
import orderSlice from './reducers/transaction/oderSlice';
import diamondInwardSlice from './reducers/transaction/diamondInwardSlice';
import diamondOutwardSlice from './reducers/transaction/diamondOutwardSlice';
import bulkStockMasterSlice from './reducers/bulkStockMaster/bulkStockMasterSlice';
import invoiceSlice from './reducers/transaction/invoiceSlice';
import customerReceiptSlice from './reducers/transaction/customerReceiptSlice';
import vendorReceiptSlice from './reducers/transaction/vendorReceiptSlice';
import incomeEntrySlice from './reducers/transaction/incomeEntrySlice';
import expenseEntrySlice from './reducers/transaction/expenseEntrySlice';
import incomeTypeSlice from './reducers/incomeType/incomeTypeSlice';
import expenseTypeSlice from './reducers/expenseType/expenseTypeSlice';
import customerReportSlice from './reducers/report/customerReportSlice';
import vendorReportSlice from './reducers/report/vendorReportSlice';
import billImageSlice from './reducers/imageUpload/imageUploadSlice';
import sellReportSlice from './reducers/report/sellReportSlice';
import IEReportSlice from './reducers/report/IEReportSlice';
import dashboardSlice from './reducers/dashboard/dashboardSlice';
const { configureStore } = require('@reduxjs/toolkit');

const store = configureStore({
  reducer: {
    user: authSlice,
    item: itemSlice,
    product: productSlice,
    accountGroup: accountgroupSlice,
    account: accountSlice,
    bookMaster: bookMasterSlice,
    order: orderSlice,
    stock: stockMasterSlice,
    bulkStock: bulkStockMasterSlice,
    inwardDetails: diamondInwardSlice,
    outwardDetails: diamondOutwardSlice,
    invoice: invoiceSlice,
    customerReceipt: customerReceiptSlice,
    vendorReceipt: vendorReceiptSlice,
    incomeEntry: incomeEntrySlice,
    expenseEntry: expenseEntrySlice,
    incomeType: incomeTypeSlice,
    expenseType: expenseTypeSlice,
    customerReport: customerReportSlice,
    vendorReport: vendorReportSlice,
    sellReport: sellReportSlice,
    ieReport: IEReportSlice,
    billImage: billImageSlice,
    dashboard: dashboardSlice,

  }
});

export default store;
