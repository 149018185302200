import { Grid, styled } from '@mui/material';
import { Fragment } from 'react';
import AddStock from './shared/AddStock';
import StockTable from './shared/StockTable';
import { useState } from 'react';
// import AllStockDetailsCard from './shared/AllStockDetailsCard';
// import RealStockDetailsCard from './shared/RealStockDetailsCard';
// import CvdStockDetailsCard from './shared/CvdStockDetailsCard';
import OverAllStockTable from './shared/OverAllStockTable';

const ContentBox = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' }
}));

const StockMaster = () => {
  const [apiCall, setApiCall] = useState(false);
  function handleApiCall() {
    setApiCall((prev) => !prev);
  }
  return (
    <Fragment>
      <ContentBox className='analytics'>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <AddStock handleApiCall={handleApiCall} />
            <StockTable apiCall={apiCall} setApiCall={setApiCall} />
            <OverAllStockTable apiCall={apiCall} />
            {/* <AllStockDetailsCard apiCall={apiCall} />
            <RealStockDetailsCard apiCall={apiCall} />
            <CvdStockDetailsCard apiCall={apiCall} /> */}
          </Grid>
        </Grid>
      </ContentBox>
    </Fragment>
  );
};

export default StockMaster;
