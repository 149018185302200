import {
  Alert,
  Box,
  Card,
  Grid,
  Icon,
  IconButton,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { H5 } from 'app/components/Typography';
import {
  getAccountData,
  getEmployeeAccountData,
  getVendorAccountData
} from 'app/contexts/reducers/account/accountSlice';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchAccount from './searchAccount';
import AccountTypeSelect from './headerGroupSelect';
import React from 'react';
import UpdateAccountDialog from './UpdateAccountDialog';
import DeleteAccountDialog from './DeleteAccountDialog';
import UpdateVendorAccountDialog from './UpdateVendorAccountDialog';
import EmployeeTable from './EmployeeTable';
import UpdateEmployeeDialog from './UpdateEmployeeDialog';
import Loading from 'app/components/MatxLoading';

const CardHeader = styled(Box)(() => ({
  display: 'flex',
  paddingLeft: '24px',
  paddingRight: '24px',
  marginBottom: '12px',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  textTransform: 'capitalize'
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)'
  },
  '& td': { borderBottom: '1px solid lightgray' },
  '& td:first-of-type': { paddingLeft: '16px !important' }
}));

const AccountTable = ({ apiCall, setApiCall }) => {
  const {
    isCustomerSuccess,
    customerData,
    isVendorSuccess,
    vendorData,
    isEmployeeSuccess,
    employeeData,
    accountType
  } = useSelector((state) => state.account);
  const [accountCustomerData, setAccountCustomerData] = useState([]);
  const [updateOpen, setUpdateOpen] = useState(false);

  const [updateVendorOpen, setUpdateVendorOpen] = useState(false);
  const [updateEmployeeOpen, setUpdateEmployeeOpen] = useState(false);
  const [updateAccountId, setUpdateAccountId] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteAccountId, setDeleteAccountId] = useState(null);
  const [errorOpen, setErrorOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [error, setError] = useState();

  const dispatch = useDispatch();
  useEffect(() => {
    if (accountType.toLowerCase() === 'customer') {
      dispatch(getAccountData());
    } else if (accountType.toLowerCase() === 'vendor') {
      dispatch(getVendorAccountData());
    } else {
      dispatch(getEmployeeAccountData());
    }
  }, [dispatch, apiCall, accountType]);
  useEffect(() => {
    if (accountType.toLowerCase() === 'customer') {
      if (isCustomerSuccess) {
        setAccountCustomerData(customerData.data.data);
      }
    } else if (accountType.toLowerCase() === 'vendor') {
      if (isVendorSuccess) {
        setAccountCustomerData(vendorData.data.data);
      }
    } else {
      if (isEmployeeSuccess) {
        setAccountCustomerData(employeeData.data.data);
      }
    }
  }, [
    isCustomerSuccess,
    customerData,
    isVendorSuccess,
    vendorData,
    isEmployeeSuccess,
    employeeData,
    accountType
  ]);

  function handleUpdateClose() {
    setUpdateOpen(false);
  }
  function handleVendorUpdateClose() {
    setUpdateVendorOpen(false);
  }
  function handleEmployeeUpdateClose() {
    setUpdateEmployeeOpen(false);
  }
  function handleDeleteClose() {
    setDeleteOpen(false);
  }
  function handleClickSuccess(message) {
    setErrorOpen(false);
    setSuccessMessage(message);
    setSuccessOpen(true);
  }
  function handleClickError() {
    setSuccessOpen(false);
    setErrorOpen(true);
  }
  function handleSnakeBarClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
    setErrorOpen(false);
  }
  return (
    <React.Fragment>
      <Card elevation={3} sx={{ pt: '20px', mb: 3 }}>
        <CardHeader>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <Title>Account Table</Title>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <SearchAccount
                    setAccountCustomerData={setAccountCustomerData}
                    accountType={accountType}
                  />
                </Grid>
                {accountType.toLowerCase() !== 'employee' && (
                  <Grid item>
                    <AccountTypeSelect setAccountCustomerData={setAccountCustomerData} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </CardHeader>

        <Box overflow='auto'>
          {accountType.toLowerCase() !== 'employee' ? (
            <ProductTable>
              <TableHead sx={{ backgroundColor: 'rgb(34,41,67)' }}>
                <TableRow>
                  <TableCell align='center' sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}>
                    Index
                  </TableCell>
                  <TableCell align='center' sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                    Group Name
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                    colSpan={2}
                  >
                    Customer Name
                  </TableCell>
                  {accountType.toLowerCase() === 'customer' && (
                    <TableCell align='center' sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                      Customer Code
                    </TableCell>
                  )}
                  <TableCell align='center' sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                    Customer Type
                  </TableCell>
                  <TableCell align='center' sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                    Opening Balance
                  </TableCell>
                  {/* <TableCell align='center' sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                    Cr. /Dr.
                  </TableCell> */}
                  <TableCell
                    align='left'
                    sx={{ px: 2, fontWeight: 'bold', color: '#FFFFFF' }}
                    colSpan={2}
                  >
                    Reference
                  </TableCell>
                  <TableCell
                    align='left'
                    sx={{ px: 2, fontWeight: 'bold', color: '#FFFFFF' }}
                    colSpan={2}
                  >
                    Special Note
                  </TableCell>
                  <TableCell align='center' sx={{ fontWeight: 'bold', color: '#FFFFFF', px: 2 }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {accountCustomerData && accountCustomerData.length > 0 ? (
                  accountCustomerData.map((account, index) => (
                    <TableRow key={index} hover>
                      <TableCell align='center' sx={{ px: 3, textTransform: 'capitalize' }}>
                        <H5 sx={{ px: 3 }}>{index + 1}</H5>
                      </TableCell>
                      <TableCell align='center' sx={{ px: 0, textTransform: 'capitalize' }}>
                        <H5>{account.account_group_name}</H5>
                      </TableCell>
                      <TableCell
                        align='center'
                        colSpan={2}
                        sx={{ px: 0, textTransform: 'capitalize' }}
                      >
                        <H5>{account.account_name}</H5>
                      </TableCell>
                      {accountType.toLowerCase() === 'customer' && (
                        <TableCell align='center' sx={{ px: 0, textTransform: 'capitalize' }}>
                          <H5>{account.account_code}</H5>
                        </TableCell>
                      )}

                      <TableCell sx={{ px: 0 }} align='center'>
                        <H5>
                          {accountType.toLowerCase() === 'customer'
                            ? account.customer_type
                            : account.vendor_type}
                        </H5>
                      </TableCell>

                      <TableCell sx={{ px: 0 }} align='center'>
                        <H5 sx={{ px: 5 }}>{account.opening_balance}</H5>
                      </TableCell>
                      {/* <TableCell align='center' sx={{ px: 0 }}>
                          <H5 sx={{ px: 2 }}>Cr.</H5>
                        </TableCell> */}
                      <TableCell align='left' sx={{ px: 2 }} colSpan={2}>
                        <H5>{account.reference ? account.reference : '-'}</H5>
                      </TableCell>
                      <TableCell align='left' sx={{ px: 2 }} colSpan={2}>
                        <H5>{account.special_note ? account.special_note : '-'}</H5>
                      </TableCell>
                      <TableCell align='center'>
                        <IconButton
                          sx={{ ml: 1 }}
                          onClick={() => {
                            setUpdateAccountId(account.id);
                            if (accountType.toLowerCase() === 'customer') {
                              setUpdateOpen(true);
                            } else if (accountType.toLowerCase() === 'vendor') {
                              setUpdateVendorOpen(true);
                            } else {
                              console.log('Employe Update');
                            }
                          }}
                        >
                          <Icon color='primary'>edit</Icon>
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setDeleteOpen(true);
                            setDeleteAccountId(account.id);
                          }}
                        >
                          <Icon color='error'>close</Icon>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={10} align='center'>
                      <Loading />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </ProductTable>
          ) : (
            <EmployeeTable
              accountCustomerData={accountCustomerData}
              setUpdateAccountId={setUpdateAccountId}
              setUpdateEmployeeOpen={setUpdateEmployeeOpen}
              setDeleteAccountId={setDeleteAccountId}
              setDeleteOpen={setDeleteOpen}
            />
          )}
        </Box>
      </Card>
      {/* Update-Customer-Dialog */}
      <UpdateAccountDialog
        open={updateOpen}
        onClose={handleUpdateClose}
        updateAccountId={updateAccountId}
        setApiCall={setApiCall}
        handleClickSuccess={handleClickSuccess}
        setError={setError}
        handleClickError={handleClickError}
      />
      {/* Update-Vendor-Dialog */}
      <UpdateVendorAccountDialog
        open={updateVendorOpen}
        onClose={handleVendorUpdateClose}
        updateAccountId={updateAccountId}
        setApiCall={setApiCall}
        handleClickSuccess={handleClickSuccess}
        setError={setError}
        handleClickError={handleClickError}
      />
      {/* Update-Employee-Dialog */}
      <UpdateEmployeeDialog
        open={updateEmployeeOpen}
        onClose={handleEmployeeUpdateClose}
        updateAccountId={updateAccountId}
        setApiCall={setApiCall}
        handleClickSuccess={handleClickSuccess}
        setError={setError}
        handleClickError={handleClickError}
      />
      {/* Start-Delete-Account */}
      <DeleteAccountDialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        deleteAccountId={deleteAccountId}
        setApiCall={setApiCall}
        handleClickSuccess={handleClickSuccess}
        handleClickError={handleClickError}
        setError={setError}
        accountType={accountType}
      />
      {/* End-Delete-Account */}
      <Snackbar open={successOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='error'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {error?.payload}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default AccountTable;
