import {
  Alert,
  Avatar,
  Box,
  Card,
  Grid,
  Icon,
  IconButton,
  Snackbar,
  styled,
  Tooltip
} from '@mui/material';
import { Small } from 'app/components/Typography';

import { formatDate } from '../helper/formateDate';
import { Fragment } from 'react';
import { useState } from 'react';
import AddDiamondInwardType from './Inward/AddDiamondInwardType';
import { useParams } from 'react-router-dom';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' }
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main }
}));

const Heading = styled('h6')(({ theme }) => ({
  margin: 0,
  marginTop: '4px',
  fontSize: '14px',
  fontWeight: '500',
  color: theme.palette.primary.main
}));

const OrderDetailCards = ({ singleOrder, handleApiCall }) => {
  const { orderId } = useParams();
  const [orderTypeOpen, setOrderTypeOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [errorOpen, setErrorOpen] = useState(false);
  const [error, setError] = useState();

  function handleClickSuccess(message) {
    setErrorOpen(false);
    setSuccessMessage(message);
    setSuccessOpen(true);
  }
  function handleClickError() {
    setSuccessOpen(false);
    setErrorOpen(true);
  }
  function handleSnakeBarClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
    setErrorOpen(false);
  }

  function handleOrderTypeClose() {
    setOrderTypeOpen(false);
  }

  return (
    <Fragment>
      <Grid container spacing={3} sx={{ mb: '24px' }}>
        {/* Account Card */}
        <Grid item xs={12} md={6} key={2}>
          <StyledCard elevation={6}>
            <ContentBox>
              <Icon className='icon'>watch_icon</Icon>
              <Box ml='12px'>
                <Heading>Order Id</Heading>
                <Small>{singleOrder.id}</Small>
              </Box>
            </ContentBox>
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={6} key={1}>
          <StyledCard elevation={6}>
            <ContentBox>
              <Icon className='icon'>group</Icon>
              <Box ml='12px'>
                <Heading>Account Name</Heading>
                <Small>{singleOrder.account_name}</Small>
              </Box>
            </ContentBox>

            <Tooltip title='View Details' placement='top'>
              <IconButton>
                <Icon>keyboard_arrow_right</Icon>
              </IconButton>
            </Tooltip>
          </StyledCard>
        </Grid>
        {/* Account Card */}
        {/* Expected Price Card */}

        {/* Expected Price Card */}
        {/* Daimond Inward Entry */}
        <Grid item xs={12} md={6} key={3}>
          <StyledCard elevation={6}>
            <ContentBox>
              <Icon className='icon'>add_circle_outlined</Icon>
              <Box ml='12px'>
                <Heading>Diamond Inwards</Heading>
                <Small>{singleOrder.order_inward_type}</Small>
              </Box>
            </ContentBox>

            <Tooltip title='Update Inward-Type' placement='top'>
              <IconButton
                onClick={() => {
                  setOrderTypeOpen(true);
                }}
              >
                <Icon>keyboard_arrow_right</Icon>
              </IconButton>
            </Tooltip>
          </StyledCard>
        </Grid>
        {/* Daimond Inward Entry */}
        {/* Daimond Outward Entry */}
        <Grid item xs={12} md={6} key={4}>
          <StyledCard elevation={6}>
            <ContentBox>
              <Icon className='icon'>access_time</Icon>
              <Box ml='12px'>
                <Heading>Ordered Date</Heading>
                <Small>{formatDate(singleOrder.date)}</Small>
              </Box>
            </ContentBox>
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={12} key={5}>
          <StyledCard elevation={6}>
            <ContentBox>
              <Avatar src={singleOrder?.productimage} alt={singleOrder.product_name} />

              <Box ml='12px'>
                <Heading>Product Name</Heading>
                <Small> {singleOrder.product_name} </Small>
              </Box>
            </ContentBox>
          </StyledCard>
        </Grid>
        {/* Daimond Outward Entry */}
      </Grid>
      <AddDiamondInwardType
        open={orderTypeOpen}
        orderId={orderId}
        onClose={handleOrderTypeClose}
        inwardType={singleOrder.order_inward_type}
        handleClickSuccess={handleClickSuccess}
        handleClickError={handleClickError}
        setError={setError}
        handleApiCall={handleApiCall}
      />
      {/* Alert */}
      <Snackbar open={successOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='error'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {error?.payload}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default OrderDetailCards;
