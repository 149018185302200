export function calculateTotals(dataArray) {
    // Initialize variables to store the sum of each property
    let totalStuddPieces = 0;
    let totalLabourChargePerPcs = 0;
    let totalLabourCharge = 0;
    let totalStuddWeight = 0;
    let totalQuarkWeight = 0;
    let totalProductPrice = 0;
    let totalExtraCost = 0;
    let totalQuantity = 0;
    let grandTotal = 0;

    // Iterate over the array and calculate the sum for each property
    dataArray.forEach(obj => {
        totalStuddPieces += parseInt(obj.studd_pieces, 10) || 0;
        totalLabourChargePerPcs += parseInt(obj.labour_charge_per_pcs, 10) || 0;
        totalLabourCharge += parseInt(obj.labour_charge, 10) || 0;
        totalStuddWeight = parseFloat(parseFloat(parseFloat(parseFloat(obj.studd_weight).toFixed(3)) + parseFloat(parseFloat(totalStuddWeight).toFixed(3))).toFixed(3)) || 0;
        totalQuarkWeight = parseFloat(parseFloat(parseFloat(parseFloat(obj.self_weight).toFixed(3)) + parseFloat(parseFloat(totalQuarkWeight).toFixed(3))).toFixed(3)) || 0;
        totalProductPrice += parseInt(obj.product_price, 10) || 0;
        totalExtraCost += parseInt(obj.extra_cost, 10) || 0;
        totalQuantity += parseInt(obj.quantity, 10) || 0;
        grandTotal += parseInt(obj.total, 10) || 0;
    });

    // Create and return an object with the calculated totals
    const totalsObject = {
        totalStuddPieces,
        totalLabourChargePerPcs,
        totalLabourCharge,
        totalStuddWeight,
        totalQuarkWeight,
        totalProductPrice,
        totalExtraCost,
        totalQuantity,
        grandTotal,
    };

    return totalsObject;
}

export function calculateSums(arr) {
    let sums = arr.reduce((sums, obj) => {
        sums.studd_pieces += parseInt(obj.studd_pieces);
        sums.labour_charge += parseInt(obj.labour_charge);
        sums.self_weight += parseFloat(obj.self_weight);
        sums.studd_weight += parseFloat(obj.studd_weight);
        sums.product_price += parseInt(obj.product_price);
        sums.extra_cost += parseInt(obj.extra_cost);
        sums.quantity += parseInt(obj.quantity);
        sums.total += parseInt(obj.total);
        return sums;
    }, {
        studd_pieces: 0,
        labour_charge: 0,
        self_weight: 0,
        studd_weight: 0,
        product_price: 0,
        extra_cost: 0,
        quantity: 0,
        total: 0
    });

    // Format self_weight and studd_weight to 3 decimal places
    sums.self_weight = parseFloat(sums.self_weight.toFixed(3));
    sums.studd_weight = parseFloat(sums.studd_weight.toFixed(3));

    return sums;
}