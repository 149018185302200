import {
  Box,
  Card,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme
} from '@mui/material';
import { H5 } from 'app/components/Typography';
import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { convertVendorId } from 'app/utils/convertId';
import { getVendorAccountData } from 'app/contexts/reducers/account/accountSlice';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {
  genrateVendorReportPdf,
  getVendorReportData
} from 'app/contexts/reducers/report/vendorReportSlice';

const CardHeader = styled(Box)(() => ({
  display: 'flex',
  paddingLeft: '24px',
  paddingRight: '24px',
  marginBottom: '12px',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  textTransform: 'capitalize'
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)'
  },
  '& td': { borderBottom: '1px solid lightgray' },
  '& td:first-of-type': { paddingLeft: '16px !important' }
}));
const Small = styled('small')(({ bgcolor }) => ({
  width: 50,
  height: 15,
  color: '#fff',
  padding: '2px 8px',
  borderRadius: '4px',
  overflow: 'hidden',
  background: bgcolor,
  boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)'
}));
const VendorReportTable = () => {
  const { palette } = useTheme();
  const bgSuccess = palette.success.main;
  const bgError = palette.error.main;

  const dispatch = useDispatch();
  const { isVendorReportSuccess, vendorReportData } = useSelector((state) => state.vendorReport);
  const { isVendorSuccess, vendorData } = useSelector((state) => state.account);

  const [reportData, setReportData] = useState({
    fromDate: dayjs().startOf('month'),
    toDate: dayjs(),
    vendorId: ''
  });
  const [vendorReportDatas, setVendorReportDatas] = useState([]);
  const [accountVendorData, setAccountVendorData] = useState([]);
  const [grandTotalValue, setGrandTotalValue] = useState({
    totalCredit: 0,
    totalDebit: 0
  });
  const [vendorName, setVendorName] = useState();
  const [dataError, setDataError] = useState({
    startDateError: false,
    endDateError: false
  });

  useEffect(() => {
    dispatch(getVendorAccountData());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getVendorReportData({
        fromDate: reportData.fromDate.format('MM-DD-YYYY'),
        toDate: reportData.toDate.format('MM-DD-YYYY'),
        vendorId: reportData.vendorId
      })
    );
  }, [dispatch, reportData]);

  useEffect(() => {
    if (isVendorReportSuccess) {
      if (Number(vendorReportData.data.code) === 200) {
        setVendorReportDatas(vendorReportData.data.data.finalObject.finalData);
        setGrandTotalValue({
          totalCredit: vendorReportData.data.data.finalObject.grandTotalValue.totalCredit,
          totalDebit: vendorReportData.data.data.finalObject.grandTotalValue.totalDebit
        });
      } else {
        setVendorReportDatas([]);
      }
    }
  }, [isVendorReportSuccess, vendorReportData]);
  useEffect(() => {
    if (isVendorSuccess) {
      if (Number(vendorData.data.code) === 200) {
        setAccountVendorData(vendorData.data.data);
      } else {
        setAccountVendorData([]);
      }
    }
  }, [isVendorSuccess, vendorData]);

  const handleVendorSelect = async (e) => {
    const vendorId = await convertVendorId(accountVendorData, e.target.value);
    setVendorName(e.target.value);
    setReportData({ ...reportData, vendorId: vendorId.id });
  };
  const handleStartDateChange = (newValue) => {
    if (newValue.isBefore(reportData.toDate)) {
      setReportData({ ...reportData, fromDate: dayjs(newValue) });
      setDataError({ ...dataError, startDateError: false });
    } else {
      setDataError({ ...dataError, startDateError: true });
    }
  };

  const handleEndDateChange = (newValue) => {
    if (reportData.fromDate.isBefore(newValue)) {
      setReportData({ ...reportData, toDate: dayjs(newValue) });
      setDataError({ ...dataError, endDateError: false });
    } else {
      setDataError({ ...dataError, endDateError: true });
    }
  };

  return (
    <React.Fragment>
      <Card elevation={3} sx={{ pt: '20px', mb: 3 }}>
        <CardHeader>
          <Grid container justifyContent='space-between'>
            <Grid item display={'flex'} flexDirection={'column'} justifyContent={'space-around'}>
              <Title>Vendor-Report</Title>
            </Grid>
            <Grid item>
              <Grid container sx={{ flexWrap: 'nowrap', alignItems: 'end' }}>
                <Grid item sx={{ width: '40%' }}>
                  <InputLabel sx={{ fontSize: '10px' }} id='vendorName'>
                    Vendor Name
                  </InputLabel>
                  <Select
                    size='small'
                    sx={{ width: '100%', my: 1 }}
                    variant='standard'
                    labelId='vendorName'
                    value={vendorName}
                    onChange={handleVendorSelect}
                  >
                    {accountVendorData.map((data, index) => (
                      <MenuItem value={data.account_name} key={index} id={index}>
                        {data.account_name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item sx={{ textAlign: 'end' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: '40%', my: 1, mr: 2 }}
                      label='From Date'
                      format='DD/MM/YYYY'
                      value={reportData.fromDate}
                      onChange={handleStartDateChange}
                      slotProps={{
                        textField: {
                          variant: 'standard',
                          error: dataError.startDateError,
                          helperText: dataError.startDateError ? 'Please Select Start Date' : null
                        }
                      }}
                    />

                    <DatePicker
                      sx={{ width: '40%', my: 1, mr: 2 }}
                      label='To Date'
                      format='DD/MM/YYYY'
                      value={reportData.toDate}
                      onChange={handleEndDateChange}
                      slotProps={{
                        textField: {
                          variant: 'standard',
                          error: dataError.endDateError,
                          helperText: dataError.endDateError ? 'Please Select End Date' : null
                        }
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item>
                  <IconButton
                    color='primary'
                    disabled={reportData.vendorId ? false : true}
                    sx={{ ml: 2 }}
                    onClick={() => {
                      dispatch(
                        genrateVendorReportPdf({
                          fromDate: reportData.fromDate.format('MM-DD-YYYY'),
                          toDate: reportData.toDate.format('MM-DD-YYYY'),
                          vendorId: reportData.vendorId
                        })
                      );
                    }}
                  >
                    <Icon>print</Icon>
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardHeader>
        <Box overflow='auto'>
          <ProductTable>
            <TableHead sx={{ backgroundColor: 'rgb(34,41,67)' }}>
              <TableRow>
                <TableCell sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}>In</TableCell>
                <TableCell colSpan={2} sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Date
                </TableCell>
                <TableCell colSpan={2} sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Desc.
                </TableCell>
                <TableCell colSpan={2} sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Type
                </TableCell>
                <TableCell colSpan={2} sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Pcs
                </TableCell>
                <TableCell colSpan={2} sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Weight
                </TableCell>
                <TableCell colSpan={2} sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Rate/Carat
                </TableCell>
                <TableCell colSpan={2} sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Debit
                </TableCell>
                <TableCell colSpan={2} sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Credit
                </TableCell>
                <TableCell colSpan={3} sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Remark
                </TableCell>
                <TableCell colSpan={2} sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Balance
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vendorReportDatas && vendorReportDatas.length > 0 ? (
                vendorReportDatas.map((data, index) => (
                  <TableRow key={index} hover>
                    <TableCell align='left' sx={{ px: 1, textTransform: 'capitalize' }}>
                      <H5 sx={{ px: 0 }}>{index + 1}</H5>
                    </TableCell>
                    <TableCell colSpan={2} align='left' sx={{ px: 0, textTransform: 'capitalize' }}>
                      {data.date}
                    </TableCell>
                    <TableCell colSpan={2} align='left' sx={{ px: 3, textTransform: 'capitalize' }}>
                      {data.description}
                    </TableCell>
                    <TableCell align='left' colSpan={2} sx={{ px: 3, textTransform: 'capitalize' }}>
                      {data.type === 'credit' && <Small bgcolor={bgSuccess}>Credit</Small>}
                      {data.type === 'debit' && <Small bgcolor={bgError}>Debit</Small>}
                    </TableCell>
                    <TableCell colSpan={2} align='left' sx={{ px: 3, textTransform: 'capitalize' }}>
                      {data.pcs ? data.pcs : 0}
                    </TableCell>
                    <TableCell colSpan={2} align='left' sx={{ px: 3, textTransform: 'capitalize' }}>
                      {data.current_weight ? data.current_weight : 0}
                    </TableCell>
                    <TableCell colSpan={2} align='left' sx={{ px: 3, textTransform: 'capitalize' }}>
                      {data.rate_per_carat ? data.rate_per_carat : 0}
                    </TableCell>
                    <TableCell
                      colSpan={2}
                      align='left'
                      sx={{
                        px: 4,
                        fontWeight: 'bold',
                        color: data.type === 'debit' && data.number > 0 ? 'red' : 'black',
                        textTransform: 'capitalize'
                      }}
                    >
                      {data.type === 'debit' ? data.number : 0}
                    </TableCell>
                    <TableCell
                      colSpan={2}
                      align='left'
                      sx={{
                        px: 3,
                        fontWeight: 'bold',
                        color: data.type === 'credit' && data.number > 0 ? 'green' : 'black',
                        textTransform: 'capitalize'
                      }}
                    >
                      {data.type === 'credit' ? data.number : 0}
                    </TableCell>
                    <TableCell colSpan={3} align='left' sx={{ px: 0, textTransform: 'capitalize' }}>
                      {data.remark ? data.remark : '-'}
                    </TableCell>
                    <TableCell
                      colSpan={2}
                      align='left'
                      sx={{
                        px: 3,
                        color: data.balance < 0 ? 'red' : 'green',
                        textTransform: 'capitalize',
                        fontWeight: 'bold'
                      }}
                    >
                      {data.balance}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={22} sx={{ textAlign: 'center' }}>
                    No data found
                  </TableCell>
                </TableRow>
              )}
              <TableRow sx={{ backgroundColor: 'rgb(34,41,67)' }}>
                <TableCell
                  colSpan={3}
                  sx={{
                    textAlign: 'center',
                    color: 'white',
                    fontWeight: 'bold',
                    borderRight: '1px solid white'
                  }}
                >
                  Grand Total
                </TableCell>
                <TableCell
                  colSpan={12}
                  sx={{
                    textAlign: 'right',
                    color: 'white',
                    fontWeight: 'bold',
                    pr: 3,
                    borderRight: '1px solid white'
                  }}
                >
                  {grandTotalValue.totalDebit}
                </TableCell>
                <TableCell
                  colSpan={5}
                  sx={{
                    textAlign: 'left',
                    px: 3,
                    color: 'white',
                    fontWeight: 'bold',
                    borderRight: '1px solid white'
                  }}
                >
                  {grandTotalValue.totalCredit}
                </TableCell>
                <TableCell
                  colSpan={2}
                  sx={{
                    textAlign: 'left',
                    px: 3,
                    color:
                      grandTotalValue.totalDebit - grandTotalValue.totalCredit > 0
                        ? 'red'
                        : 'green',
                    fontWeight: 'bold'
                  }}
                >
                  {grandTotalValue.totalDebit - grandTotalValue.totalCredit}
                </TableCell>
              </TableRow>
            </TableBody>
          </ProductTable>
        </Box>
      </Card>
    </React.Fragment>
  );
};

export default VendorReportTable;
