import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  DialogContent,
  DialogContentText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  styled
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import DialogTitle from '@mui/material/DialogTitle';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleExpenseEntry } from '../helper/getSingleExpenseEntry';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { updateExpenseEntry } from 'app/contexts/reducers/transaction/expenseEntrySlice';
import { getExpenseTypeData } from 'app/contexts/reducers/expenseType/expenseTypeSlice';
const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' }
}));
const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main }
}));
export default function UpdateExpenseEntryDialog({
  open,
  onClose,
  updateEntryId,
  setApiCall,
  handleClickSuccess,
  setError,
  handleClickError
}) {
  const dispatch = useDispatch();
  const { isAllExpenseEntriesSuccess, allExpenseEntriesData } = useSelector(
    (state) => state.expenseEntry
  );
  const { isExpenseTypeSuccess, expenseTypeData } = useSelector((state) => state.expenseType);

  const [updateValue, setUpdateValue] = useState({
    expenseType: '',
    amount: '',
    paymentType: '',
    bookNumber: '',
    date: '',
    remark: ''
  });
  const [expenseType, setExpenseType] = useState([]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    dispatch(getExpenseTypeData());
  }, [dispatch]);
  useEffect(() => {
    if (isExpenseTypeSuccess) {
      if (Number(expenseTypeData.data.code) === 200) {
        setExpenseType(expenseTypeData.data.data);
      } else {
        setExpenseType([]);
      }
    }
  }, [isExpenseTypeSuccess, expenseTypeData]);
  useEffect(() => {
    if (allExpenseEntriesData) {
      getSingleExpenseEntry(allExpenseEntriesData.data.data, updateEntryId).then(
        (expenseEntryDetails) => {
          expenseEntryDetails &&
            setUpdateValue({
              paymentType: expenseEntryDetails.payment_type,
              expenseType: expenseEntryDetails.expense_type,
              bookNumber: expenseEntryDetails.book_number,
              date: dayjs(expenseEntryDetails.date),
              amount: expenseEntryDetails.amount,
              remark: expenseEntryDetails.remark
            });
        }
      );
    }
    // eslint-disable-next-line
  }, [updateEntryId, isAllExpenseEntriesSuccess, allExpenseEntriesData, open]);

  async function handleUpdate() {
    try {
      if (
        updateValue.expenseType &&
        updateValue.expenseType.length > 0 &&
        updateValue.amount &&
        Number(updateValue.amount) > 0 &&
        updateValue.paymentType &&
        updateValue.paymentType.length > 0 &&
        updateValue.bookNumber &&
        Number(updateValue.bookNumber) > 0
      ) {
        const expenseEntryDetails = { ...updateValue };
        Object.keys(expenseEntryDetails).forEach((key) => {
          if (expenseEntryDetails[key] === '') {
            delete expenseEntryDetails[key];
          }
          // if (key === 'date') {
          //   expenseEntryDetails[key] = dayjs(expenseEntryDetails[key]).format('YYYY-MM-DD');
          // }
        });

        const responseValue = await dispatch(
          updateExpenseEntry({ entryId: updateEntryId, expenseEntryDetails })
        );
        if (!responseValue.hasOwnProperty('error')) {
          onClose();
          handleClickSuccess('Expense-Entry Updated successfully!!');
          setApiCall((prev) => !prev);
          setLoading(false);
        } else {
          setApiCall((prev) => !prev);
          throw responseValue;
        }
      } else {
        handleClickError();
        setError({ payload: 'Please Fill Mandatory Data!' });
        setLoading(false);
      }
    } catch (e) {
      handleClickError();
      setError(e);
      setLoading(false);
    }
  }

  const handleExpenseTypeSelect = async (e) => {
    setUpdateValue({ ...updateValue, expenseType: e.target.value });
  };
  const handleBookNameSelect = async (e) => {
    setUpdateValue({ ...updateValue, bookNumber: e.target.value });
  };
  const getFiveYearsBack = () => {
    const currentYear = new Date().getFullYear();
    const fiveYearsBackArray = [];

    for (let i = 0; i < 3; i++) {
      const year = currentYear - i;
      fiveYearsBackArray.push(String(year));
    }

    return fiveYearsBackArray;
  };
  const handlePaymentTypeSelect = async (e) => {
    setUpdateValue({ ...updateValue, paymentType: e.target.value });
  };

  const PaymentType = [
    {
      name: 'CASH'
    },
    {
      name: 'BANK'
    }
  ];

  return (
    <Box>
      <Dialog open={open} onClose={onClose} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>Update Expense-Entry</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: '10px' }}>
            You can Update Your Expense Entry Details From Here!{' '}
          </DialogContentText>

          <Grid container spacing={3} sx={{ mb: '24px' }}>
            <Grid item xs={12} md={12}>
              <StyledCard elevation={6}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <InputLabel sx={{ fontSize: '10px' }} id='expenseType'>
                      Expense Type
                    </InputLabel>
                    <Select
                      size='small'
                      sx={{ my: 1, width: '80%' }}
                      variant='standard'
                      labelId='expenseType'
                      value={updateValue.expenseType}
                      onChange={handleExpenseTypeSelect}
                    >
                      {expenseType &&
                        expenseType.map((data, index) => (
                          <MenuItem value={data.expense_type} key={index} id={index}>
                            {data.expense_type}
                          </MenuItem>
                        ))}
                    </Select>
                    <ContentBox>
                      <TextField
                        id='amount'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Amount'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '80%' }}
                        variant='standard'
                        value={updateValue.amount == null ? 'null' : updateValue.amount}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, amount: e.target.value });
                        }}
                      />
                    </ContentBox>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{ width: '80%', my: 1 }}
                        label='Date'
                        format='DD/MM/YYYY'
                        value={updateValue.date}
                        onChange={(newValue) => {
                          setUpdateValue({ ...updateValue, date: newValue });
                        }}
                        slotProps={{
                          textField: {
                            variant: 'standard'
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <InputLabel sx={{ fontSize: '10px' }} required id='bookNumber'>
                      Book Number
                    </InputLabel>

                    <Select
                      size='small'
                      sx={{ width: '80%', my: 1 }}
                      variant='standard'
                      labelId='customerReceiptBookNumber'
                      value={updateValue.bookNumber}
                      onChange={handleBookNameSelect}
                    >
                      {getFiveYearsBack().map((data, index) => (
                        <MenuItem value={data} key={index} id={index}>
                          {data}
                        </MenuItem>
                      ))}
                    </Select>
                    <InputLabel sx={{ fontSize: '10px' }} id='paymentType'>
                      Payment Type
                    </InputLabel>
                    <Select
                      size='small'
                      sx={{ my: 1, width: '80%' }}
                      variant='standard'
                      labelId='diamondType'
                      value={updateValue.paymentType}
                      disabled={updateValue.paymentType.toLowerCase() === 'bank'}
                      onChange={handlePaymentTypeSelect}
                    >
                      {PaymentType.map((data, index) => (
                        <MenuItem value={data.name} key={index} id={index}>
                          {data.name}
                        </MenuItem>
                      ))}
                    </Select>

                    <ContentBox>
                      <TextField
                        multiline
                        maxRows={4}
                        id='remark'
                        type='text'
                        margin='dense'
                        size='small'
                        label='Remark'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '80%' }}
                        variant='standard'
                        value={updateValue.remark}
                        onChange={(e) => {
                          setUpdateValue({ ...updateValue, remark: e.target.value });
                        }}
                      />
                    </ContentBox>
                  </Grid>
                </Grid>
              </StyledCard>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='standard' color='secondary' onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleUpdate}
            color='primary'
            loading={loading}
            variant='contained'
            sx={{ my: 2 }}
          >
            Update Expense Entry
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
