import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders } from 'app/utils/handleHeader';
const initialState = {
    stockEntryData: null,
    bulkStockData: null,
    singleBulkStockData: null,
    singleBulkStockEntryData: null,
    isInitialised: false,
    isBulkStockMasterEntrySuccess: false,
    isBulkStockMasterSuccess: false,
    isSingleBulkStockDataSuccess: false,
    isSingleBulkStockEntrySuccess: false,
    isBulkStockStatusUpdateSuccess: false,
    isupdateBulkStockStatus: false,
    isCreateInOutPdf: false,
    isError: false,
    isLoading: false
};

//--------------Asyncthunk for Add Book Master

export const addBulkStockEntry = createAsyncThunk(
    '/addBulkStock',
    async (object, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/stock/addbulkstockentry`,
                object,
                { headers: getHeaders() }
            );
            return response;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);
//--------------Asyncthunk for Get Stock Data--------------------------------

export const getBulkStockData = createAsyncThunk('getBulkStockData', async (object, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/stock/getbulkstock`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Get Specific Stock Data Based On Vendor--------------------------------

export const getCustomerStockData = createAsyncThunk('getCustomerStockData', async ({ bulkStockId }, { rejectWithValue }) => {
    try {
        // const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/stock/getsinglebulkstock/?customerId=${customerId}&diamondType=${diamondType}`, {
        //     headers: getHeaders()
        // });
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/stock/getsinglebulkstock/${bulkStockId}`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

//--------------Asyncthunk for Get Specific Stock-Entry Data Based On Vendor And Diamond Type--------------------------------

export const getSpecificBulkStockEntry = createAsyncThunk('getSpecificBulkStockEntry', async ({ stockId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/stock/getspecificbulkstockentry/${stockId}`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Delete Stock Data--------------------------------
export const deleteBulkStock = createAsyncThunk(
    '/deleteBulkStock',
    async ({ deleteStockId }, { rejectWithValue }) => {
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_BASE_URL}/stock/deletebulkstock/${deleteStockId}`,
                { headers: getHeaders() }
            );
            return response;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);
//--------------Asyncthunk for Delete Stock-Entry Data--------------------------------
export const deleteBulkStockEntry = createAsyncThunk(
    '/deleteBulkStockEntry',
    async ({ deleteStockEntryId }, { rejectWithValue }) => {
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_BASE_URL}/stock/deletebulkstockentry/${deleteStockEntryId}`,
                { headers: getHeaders() }
            );
            return response;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);
//--------------Asyncthunk for Update Stock-Entry Data--------------------------------
export const updateBulkStockEntryData = createAsyncThunk(
    '/updateBulkStockEntryData',
    async ({ updateStockEntryId, updateStockDetails }, { rejectWithValue }) => {

        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/stock/updatebulkstockentry/${updateStockEntryId}`, updateStockDetails,
                { headers: getHeaders() }
            );
            return response;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);
//--------------Asyncthunk for Update Bulk-Stock Status--------------------------------
export const updateBulkStockStatus = createAsyncThunk(
    '/updateBulkStockStatus',
    async ({ stockId, status }, { rejectWithValue }) => {

        try {
            const response = await axios.patch(
                `${process.env.REACT_APP_BASE_URL}/stock/updatebulkstockstatus/${stockId}/${status}`, {},
                { headers: getHeaders() }
            );
            return response;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);
// ----------------------------------------Genrate Bulk PDF --------------------------------
export const genrateBulkInOutPdf = createAsyncThunk('genrateBulkInOutPdf', async ({ bulkStockId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/stock/genratestockentrypdf/${bulkStockId}`,

            {
                responseType: 'arraybuffer',
                headers: { 'Accept': 'application/pdf', ...getHeaders() }
            }
        );
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Bulk-In-Out_${bulkStockId}.pdf`; // Set the desired file name
        a.click();
        window.URL.revokeObjectURL(url);
        // return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

const bulkStockMasterSlice = createSlice({
    name: 'BulkStock',
    initialState,
    reducers: {},
    extraReducers: {
        //--------------Slice action for the Add Stock entry --------------------------------
        [addBulkStockEntry.pending]: (state, action) => {
            state.pending = true;
            state.isBulkStockMasterEntrySuccess = false;
            state.isError = false;
        },
        [addBulkStockEntry.fulfilled]: (state, action) => {
            state.pending = false;
            state.isBulkStockMasterEntrySuccess = true;
            state.isError = false;
        },
        [addBulkStockEntry.rejected]: (state, action) => {
            state.pending = false;
            state.isBulkStockMasterEntrySuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Stock Data --------------------------------
        [getBulkStockData.pending]: (state, action) => {
            state.pending = false;
            state.isBulkStockMasterSuccess = false;
            state.isError = false;
        },
        [getBulkStockData.fulfilled]: (state, action) => {
            state.pending = false;
            state.isBulkStockMasterSuccess = true;
            state.bulkStockData = action.payload;
            state.isError = false;
        },
        [getBulkStockData.rejected]: (state, action) => {
            state.pending = false;
            state.isBulkStockMasterSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Vendor Stock Data --------------------------------
        [getCustomerStockData.pending]: (state, action) => {
            state.pending = false;
            state.isSingleBulkStockDataSuccess = false;
            state.isError = false;
        },
        [getCustomerStockData.fulfilled]: (state, action) => {
            state.pending = false;
            state.isSingleBulkStockDataSuccess = true;
            state.singleBulkStockData = action.payload;
            state.isError = false;
        },
        [getCustomerStockData.rejected]: (state, action) => {
            state.pending = false;
            state.isSingleBulkStockDataSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Stock Entry Data Based On VendorId And Diamond Type---------------------
        [getSpecificBulkStockEntry.pending]: (state, action) => {
            state.pending = false;
            state.isSingleBulkStockEntrySuccess = false;
            state.isError = false;
        },
        [getSpecificBulkStockEntry.fulfilled]: (state, action) => {
            state.pending = false;
            state.isSingleBulkStockEntrySuccess = true;
            state.singleBulkStockEntryData = action.payload;
            state.isError = false;
        },
        [getSpecificBulkStockEntry.rejected]: (state, action) => {
            state.pending = false;
            state.isSingleBulkStockEntrySuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Delete Stock Data
        [deleteBulkStock.pending]: (state, action) => {
            state.pending = true;
            state.isBulkStockMasterSuccess = false;
            state.isError = false;
        },
        [deleteBulkStock.fulfilled]: (state, action) => {
            state.pending = false;
            state.isBulkStockMasterSuccess = true;
            state.isError = false;
        },
        [deleteBulkStock.rejected]: (state, action) => {
            state.pending = false;
            state.isBulkStockMasterSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Delete Stock-Entry Data
        [deleteBulkStockEntry.pending]: (state, action) => {
            state.pending = true;
            state.isSingleBulkStockEntrySuccess = false;
            state.isError = false;
        },
        [deleteBulkStockEntry.fulfilled]: (state, action) => {
            state.pending = false;
            state.isSingleBulkStockEntrySuccess = true;
            state.isError = false;
        },
        [deleteBulkStockEntry.rejected]: (state, action) => {
            state.pending = false;
            state.isSingleBulkStockEntrySuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Update Stock-Entry Data
        [updateBulkStockEntryData.pending]: (state, action) => {
            state.pending = true;
            state.isSingleBulkStockEntrySuccess = false;
            state.isError = false;
        },
        [updateBulkStockEntryData.fulfilled]: (state, action) => {
            state.pending = false;
            state.isSingleBulkStockEntrySuccess = true;
            state.isError = false;
        },
        [updateBulkStockEntryData.rejected]: (state, action) => {
            state.pending = false;
            state.isSingleBulkStockEntrySuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Update Bulk Stock status--------------
        [updateBulkStockStatus.pending]: (state, action) => {
            state.pending = true;
            state.isupdateBulkStockStatus = false;
            state.isError = false;
        },
        [updateBulkStockStatus.fulfilled]: (state, action) => {
            state.pending = false;
            state.isupdateBulkStockStatus = true;
            state.isError = false;
        },
        [updateBulkStockStatus.rejected]: (state, action) => {
            state.pending = false;
            state.isupdateBulkStockStatus = false;
            state.isError = true;
        },
        //--------------Slice action for the Create In-Out PDF
        [genrateBulkInOutPdf.pending]: (state, action) => {
            state.isLoading = true;
            state.isCreateInOutPdf = false;
            state.isError = false;
        },
        [genrateBulkInOutPdf.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isCreateInOutPdf = true;
            state.isError = false;
        },
        [genrateBulkInOutPdf.rejected]: (state, action) => {
            state.isLoading = false;
            state.isCreateInOutPdf = false;
            state.isError = true;
        }
    }
})
export default bulkStockMasterSlice.reducer;
