import {
  Alert,
  Box,
  Card,
  Grid,
  Icon,
  IconButton,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  useTheme
} from '@mui/material';
import { H6 } from 'app/components/Typography';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchAccount from './SearchIncomeType';
import FilterOrderByMonth from './FilterIncomeEntryByMonth';
import FilterStatus from './FilterStatus';
import { getAllIncomeEntriesData } from 'app/contexts/reducers/transaction/incomeEntrySlice';
import UpdateIncomeEntryDialog from './UpdateIncomeEntryDialog';
import DeleteIncomeEntry from './DeleteIncomeEntry';

const CardHeader = styled(Box)(() => ({
  display: 'flex',
  paddingLeft: '24px',
  paddingRight: '24px',
  marginBottom: '12px',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  textTransform: 'capitalize'
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)'
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' }
}));

const Small = styled('small')(({ bgcolor }) => ({
  width: 50,
  height: 15,
  color: '#fff',
  padding: '2px 8px',
  borderRadius: '4px',
  overflow: 'hidden',
  background: bgcolor,
  boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)'
}));

const IncomeEntryTable = ({ apiCall, setApiCall }) => {
  const { palette } = useTheme();
  const bgSuccess = palette.success.main;
  const bgSecondary = palette.secondary.main;
  const { isAllIncomeEntriesSuccess, allIncomeEntriesData } = useSelector(
    (state) => state.incomeEntry
  );
  const [incomeEntriesData, setIncomeEntriesData] = useState([]);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [updateEntryId, setUpdateEntryId] = useState(null);
  const [deleteEntryId, setDeleteEntryId] = useState(null);
  const [errorOpen, setErrorOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [error, setError] = useState();
  const [deleteOpen, setDeleteOpen] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllIncomeEntriesData());
  }, [dispatch, apiCall]);
  useEffect(() => {
    if (isAllIncomeEntriesSuccess) {
      if (Number(allIncomeEntriesData.data.code) === 200) {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const incomeWithCurrentMonth = allIncomeEntriesData.data.data.filter((income) => {
          const incomeDate = new Date(income.date);
          const incomeMonth = incomeDate.getMonth() + 1; // Adding 1 since getMonth() returns a zero-based index
          return incomeMonth === currentMonth;
        });
        setIncomeEntriesData(incomeWithCurrentMonth);
      } else {
        setIncomeEntriesData([]);
      }
    }
  }, [isAllIncomeEntriesSuccess, allIncomeEntriesData]);
  const convertDate = (isoDateString) => {
    const date = new Date(isoDateString);

    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    };

    const formattedDate = date.toLocaleString('en-GB', options).replace(/\//g, '-');
    return formattedDate;
  };
  function handleUpdateClose() {
    setUpdateOpen(false);
  }
  function handleDeleteClose() {
    setDeleteOpen(false);
  }
  function handleClickSuccess(message) {
    setErrorOpen(false);
    setSuccessMessage(message);
    setSuccessOpen(true);
  }
  function handleClickError() {
    setSuccessOpen(false);
    setErrorOpen(true);
  }
  function handleSnakeBarClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
    setErrorOpen(false);
  }
  function calculateTotalAndDiscount(allIncomeEntriesData) {
    let amount = 0;
    for (let i = 0; i < allIncomeEntriesData.length; i++) {
      const receipt = allIncomeEntriesData[i];

      // Add the amount to the total
      amount += parseInt(receipt.amount);
    }

    return { amount };
  }
  return (
    <React.Fragment>
      <Card elevation={3} sx={{ pt: '20px', mb: 3 }}>
        <CardHeader>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <Title>IncomeEntry Table</Title>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <SearchAccount setIncomeEntriesData={setIncomeEntriesData} />
                </Grid>
                <Grid item>
                  <FilterOrderByMonth setIncomeEntriesData={setIncomeEntriesData} />
                </Grid>
                <Grid item>
                  <FilterStatus setIncomeEntriesData={setIncomeEntriesData} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardHeader>

        <Box overflow='auto'>
          <ProductTable>
            <TableHead sx={{ backgroundColor: 'rgb(34,41,67)' }}>
              <TableRow>
                <TableCell align='center' sx={{ px: 2, fontWeight: 'bold', color: '#FFFFFF' }}>
                  Index
                </TableCell>
                <TableCell
                  colSpan={3}
                  align='center'
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                >
                  Income-Type
                </TableCell>
                <TableCell
                  colSpan={2}
                  align='center'
                  sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}
                >
                  Amount
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 1, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  Payment-Type
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  Book-Number
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  Date
                </TableCell>

                <TableCell
                  align='left'
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={3}
                >
                  Remark
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 5, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={2}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {incomeEntriesData && incomeEntriesData.length > 0 ? (
                incomeEntriesData.map((incomeEntryDetails, index) => (
                  <TableRow key={index} hover>
                    <TableCell align='center' sx={{ px: 0, textTransform: 'capitalize' }}>
                      <H6 sx={{ px: 2 }}>{index + 1}</H6>
                    </TableCell>
                    <TableCell
                      colSpan={3}
                      align='center'
                      sx={{ px: 0, textTransform: 'capitalize' }}
                    >
                      {incomeEntryDetails.hasOwnProperty('customer_name')
                        ? incomeEntryDetails.customer_name
                        : incomeEntryDetails.income_type}
                    </TableCell>
                    <TableCell
                      align='center'
                      colSpan={2}
                      sx={{ px: 2, textTransform: 'capitalize' }}
                    >
                      {incomeEntryDetails.amount}
                    </TableCell>
                    <TableCell align='center' sx={{ px: 2 }} colSpan={2}>
                      {incomeEntryDetails.payment_type === 'CASH' && (
                        <Small bgcolor={bgSecondary}>CASH</Small>
                      )}
                      {incomeEntryDetails.payment_type === 'BANK' && (
                        <Small bgcolor={bgSuccess}>BANK</Small>
                      )}
                    </TableCell>
                    <TableCell align='center' sx={{ px: 2 }} colSpan={2}>
                      {incomeEntryDetails.book_number}
                    </TableCell>

                    <TableCell align='center' sx={{ px: 2 }} colSpan={2}>
                      {convertDate(incomeEntryDetails.date)}
                    </TableCell>

                    <TableCell align='left' sx={{ px: 0, fontWeight: 'bold' }} colSpan={3}>
                      {incomeEntryDetails.remark ? incomeEntryDetails.remark : '-'}
                    </TableCell>
                    <TableCell align='center' sx={{ px: 2 }} colSpan={2}>
                      <Tooltip title='Edit'>
                        <IconButton
                          disabled={
                            incomeEntryDetails.income_type === 'CUSTOMER_RECEIPT' ? true : false
                          }
                          color='primary'
                          onClick={() => {
                            setUpdateEntryId(incomeEntryDetails.id);
                            setUpdateOpen(true);
                          }}
                        >
                          <Icon>edit</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Delete'>
                        <IconButton
                          disabled={
                            incomeEntryDetails.income_type === 'CUSTOMER_RECEIPT' ? true : false
                          }
                          color='error'
                          onClick={() => {
                            setDeleteEntryId(incomeEntryDetails.id);
                            setDeleteOpen(true);
                          }}
                        >
                          <Icon>close</Icon>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={12}
                    align='center'
                    sx={{ color: 'rgb(34,41,67)', fontWeight: 'bold' }}
                  >
                    No Stock Data Found
                  </TableCell>
                </TableRow>
              )}
              <TableRow sx={{ backgroundColor: 'rgb(34,41,67)' }}>
                <TableCell align='center' sx={{ px: 0, textTransform: 'capitalize' }}></TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 0, textTransform: 'capitalize', fontWeight: 'bold' }}
                  colSpan={3}
                ></TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 2, color: 'white', textTransform: 'capitalize', fontWeight: 'bold' }}
                  colSpan={2}
                >
                  {calculateTotalAndDiscount(incomeEntriesData).amount}
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 0, textTransform: 'capitalize', fontWeight: 'bold' }}
                  colSpan={2}
                ></TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 0, textTransform: 'capitalize', fontWeight: 'bold' }}
                  colSpan={2}
                ></TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 0, textTransform: 'capitalize', fontWeight: 'bold' }}
                  colSpan={2}
                ></TableCell>
                <TableCell
                  align='left'
                  sx={{ px: 0, textTransform: 'capitalize', fontWeight: 'bold' }}
                  colSpan={3}
                ></TableCell>
                <TableCell
                  align='center'
                  sx={{ px: 0, textTransform: 'capitalize', fontWeight: 'bold' }}
                  colSpan={2}
                ></TableCell>
              </TableRow>
            </TableBody>
          </ProductTable>
        </Box>
      </Card>
      {/* Alert */}
      <Snackbar open={successOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='error'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {error?.payload}
        </Alert>
      </Snackbar>
      {/* Update-Customer-Dialog */}
      <UpdateIncomeEntryDialog
        open={updateOpen}
        onClose={handleUpdateClose}
        updateEntryId={updateEntryId}
        setApiCall={setApiCall}
        handleClickSuccess={handleClickSuccess}
        setError={setError}
        handleClickError={handleClickError}
      />
      {/* Start-Delete-Account */}
      <DeleteIncomeEntry
        open={deleteOpen}
        onClose={handleDeleteClose}
        deleteEntryId={deleteEntryId}
        setApiCall={setApiCall}
        handleClickSuccess={handleClickSuccess}
        handleClickError={handleClickError}
        setError={setError}
      />
    </React.Fragment>
  );
};

export default IncomeEntryTable;
