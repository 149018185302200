import { Navigate, useLocation } from 'react-router-dom';
import { getCookieValue } from 'app/utils/handleCookie';

const AuthGuard = ({ children }) => {
  const { authToken } = getCookieValue();
  const { pathname } = useLocation();

  if (authToken) return <>{children}</>;

  return <Navigate replace to='/signin' state={{ from: pathname }} />;
};

export default AuthGuard;
