import { getHeaders } from 'app/utils/handleHeader';
import axios from 'axios';
const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');
const initialState = {
    isInitialised: true,
    isInvoiceSuccess: false,
    isAllInvoiceSuccess: false,
    isSpecificInvoiceSuccess: false,
    isCreateInvoicePdf: false,
    isLoading: false,
    isError: false,
    invoiceData: null,
    specificInvoiceData: null,
};
// --------------------------------------Create Invoice --------------------------------
export const genrateInvoice = createAsyncThunk('genrateInvoice', async (invoiceDetails, { rejectWithValue }) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/transaction/createinvoice`,
            invoiceDetails,
            {
                headers: getHeaders()
            }
        );
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Get Invoices --------------------------------

export const getInvoices = createAsyncThunk('getInvoices', async (object, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transaction/getinvoice`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Get Specific Invoice --------------------------------

export const getSpecificInvoice = createAsyncThunk('getSpecificInvoice', async ({ invoiceId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transaction/getspecificinvoice/${invoiceId}`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
// ---------------------------------------Update Invoice Details --------------------------------
export const updateInvoice = createAsyncThunk('updateInvoice', async ({ invoiceId, updateInvoiceDetails }, { rejectWithValue }) => {
    try {
        const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/transaction/updatecustomerinvoice/${invoiceId}`, updateInvoiceDetails, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
// ---------------------------------------Delete Invoice Details --------------------------------
export const deleteInvoice = createAsyncThunk(
    '/deleteInvoice',
    async ({ invoiceId }, { rejectWithValue }) => {
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_BASE_URL}/transaction/deletecustomerinvoice/${invoiceId}`,
                { headers: getHeaders() }
            );
            return response;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);
// ----------------------------------------Genrate Invoice PDF --------------------------------
export const genrateInvoicePdf = createAsyncThunk('genrateInvoicePdf', async ({ invoiceId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/transaction/genrateinvoicepdf/${invoiceId}`,

            {
                responseType: 'arraybuffer',
                headers: { 'Accept': 'application/pdf', ...getHeaders() }
            }
        );
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Invoice_${invoiceId}.pdf`; // Set the desired file name
        a.click();
        window.URL.revokeObjectURL(url);
        // return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
const invoiceSlice = createSlice({
    name: 'invoice',
    initialState,
    reducers: {},
    extraReducers: {
        //--------------Slice action for the Create Order
        [genrateInvoice.pending]: (state, action) => {
            state.isLoading = true;
            state.isInvoiceSuccess = false;
            state.isError = false;
        },
        [genrateInvoice.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isInvoiceSuccess = true;
            state.isError = false;
        },
        [genrateInvoice.rejected]: (state, action) => {
            state.isLoading = false;
            state.isInvoiceSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Invoice
        [getInvoices.pending]: (state, action) => {
            state.isLoading = true;
            state.isAllInvoiceSuccess = false;
            state.isError = false;
        },
        [getInvoices.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isAllInvoiceSuccess = true;
            state.invoiceData = action.payload;
            state.isError = false;
        },
        [getInvoices.rejected]: (state, action) => {
            state.isLoading = false;
            state.isAllInvoiceSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Single Invoice
        [getSpecificInvoice.pending]: (state, action) => {
            state.isLoading = true;
            state.isSpecificInvoiceSuccess = false;
            state.isError = false;
        },
        [getSpecificInvoice.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isSpecificInvoiceSuccess = true;
            state.specificInvoiceData = action.payload;
            state.isError = false;
        },
        [getSpecificInvoice.rejected]: (state, action) => {
            state.isLoading = false;
            state.isSpecificInvoiceSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Update Customer Invoice
        [updateInvoice.pending]: (state, action) => {
            state.isLoading = true;
            state.isInvoiceSuccess = false;
            state.isError = false;
        },
        [updateInvoice.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isInvoiceSuccess = true;
            state.isError = false;
        },
        [updateInvoice.rejected]: (state, action) => {
            state.isLoading = false;
            state.isInvoiceSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Delete Customer Invoice
        [deleteInvoice.pending]: (state, action) => {
            state.isLoading = true;
            state.isInvoiceSuccess = false;
            state.isError = false;
        },
        [deleteInvoice.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isInvoiceSuccess = true;
            state.isError = false;
        },
        [deleteInvoice.rejected]: (state, action) => {
            state.isLoading = false;
            state.isInvoiceSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Create Invoice action--------------
        [genrateInvoicePdf.pending]: (state, action) => {
            state.isLoading = true;
            state.isCreateInvoicePdf = false;
            state.isError = false;
        },
        [genrateInvoicePdf.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isCreateInvoicePdf = true;
            state.isError = false;
        },
        [genrateInvoicePdf.rejected]: (state, action) => {
            state.isLoading = false;
            state.isCreateInvoicePdf = false;
            state.isError = true;
        }
    }
});
export default invoiceSlice.reducer;
