import { Grid, styled } from '@mui/material';
import { Fragment } from 'react';

import { H4 } from 'app/components/Typography';
import ItemTable from './shared/ItemTable';

const ContentBox = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' }
}));

const ItemMaster = () => {
  return (
    <Fragment>
      <ContentBox className='analytics'>
        <H4 sx={{ my: 2 }}>Item Master</H4>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ItemTable />
          </Grid>
        </Grid>
      </ContentBox>
    </Fragment>
  );
};

export default ItemMaster;
