import { Box, Card, Chip, Grid, InputLabel, Select, TextField, styled } from '@mui/material';
import { H4 } from 'app/components/Typography';
// import { convertHexToRGB } from 'app/utils/utils';

// const CardRoot = styled(Card)(({ theme }) => ({
//   marginBottom: '24px',
//   padding: '12px !important',
//   [theme.breakpoints.down('sm')]: { paddingLeft: '16px !important' }
// }));

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' }
}));
const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main }
}));

const BasicDetails = ({ singleOrder, connectedEmployee }) => {
  return (
    // <CardRoot>
    <Grid container spacing={3} sx={{ mb: '24px' }}>
      <Grid item xs={12} md={12}>
        <StyledCard elevation={6}>
          <H4 sx={{ mb: 2 }}>Basic Details</H4>
          <Grid container>
            <TextField
              id='itemName'
              type='text'
              margin='dense'
              size='small'
              label='Item Name'
              InputLabelProps={{ shrink: true }}
              sx={{ my: 1, width: '100%' }}
              variant='standard'
              value={singleOrder.item_name}
            />
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ContentBox>
                <TextField
                  id='productName'
                  type='text'
                  margin='dense'
                  size='small'
                  label='Product Name'
                  InputLabelProps={{ shrink: true }}
                  sx={{ my: 1, width: '80%' }}
                  variant='standard'
                  value={singleOrder.product_name}
                />
              </ContentBox>
              <ContentBox>
                <TextField
                  id='email'
                  type='text'
                  margin='dense'
                  size='small'
                  label='Email'
                  InputLabelProps={{ shrink: true }}
                  sx={{ my: 1, width: '80%' }}
                  variant='standard'
                  value={singleOrder.email_id}
                />
              </ContentBox>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ContentBox>
                <TextField
                  id='productPrice'
                  type='text'
                  margin='dense'
                  size='small'
                  label='Product Price'
                  InputLabelProps={{ shrink: true }}
                  sx={{ my: 1, width: '80%' }}
                  variant='standard'
                  value={singleOrder.product_price}
                />
              </ContentBox>
              <ContentBox>
                <TextField
                  id='phoneNumber'
                  type='text'
                  margin='dense'
                  size='small'
                  label='Contact Number'
                  InputLabelProps={{ shrink: true }}
                  sx={{ my: 1, width: '80%' }}
                  variant='standard'
                  value={singleOrder.phone_number}
                />
              </ContentBox>
            </Grid>

            <TextField
              id='extraCost'
              type='text'
              margin='dense'
              size='small'
              label='Extra Cost'
              InputLabelProps={{ shrink: true }}
              sx={{ my: 1, width: '80%' }}
              variant='standard'
              value={singleOrder.extra_cost}
            />

            <TextField
              id='remark'
              multiline
              maxRows={5}
              type='text'
              margin='dense'
              size='small'
              label='Remark'
              InputLabelProps={{ shrink: true }}
              sx={{ my: 1, width: '100%' }}
              variant='standard'
              value={singleOrder.remark}
            />
            <TextField
              id='address'
              type='text'
              multiline
              maxRows={5}
              margin='dense'
              size='small'
              label='Address'
              InputLabelProps={{ shrink: true }}
              sx={{ my: 1, width: '100%' }}
              variant='standard'
              value={singleOrder.address}
            />
            <InputLabel sx={{ fontSize: '10px' }} id='demo-multiple-employee-label'>
              Employee
            </InputLabel>

            <Select
              labelId='demo-multiple-employee-label'
              id='demo-multiple-employee'
              multiple
              value={connectedEmployee}
              variant='standard'
              InputLabelProps={{ shrink: true }}
              sx={{ my: 1, width: '90%' }}
              inputProps={{
                readOnly: true // Making the input read-only
              }}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            ></Select>
          </Grid>
        </StyledCard>
      </Grid>
    </Grid>
    // </CardRoot>
  );
};

export default BasicDetails;
