import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Card,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  styled,
  TextField
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React from 'react';
import { H4 } from 'app/components/Typography';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { convertCustomerAmountDataId } from 'app/utils/convertId';
import dayjs from 'dayjs';
import {
  createCustomerPaymentReceipt,
  getAllCustomerAmountData,
  getCustomerAmountData
} from 'app/contexts/reducers/transaction/customerReceiptSlice';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' }
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main }
}));

const CreateCustomerReceipt = ({ handleApiCall }) => {
  const dispatch = useDispatch();

  const {
    isCustomerAmountFoundedSuccess,
    isAllCustomerAmountFoundedSuccess,
    customerAmountData,
    allCustomerAmountData
  } = useSelector((state) => state.customerReceipt);

  const intialData = {
    customerName: '',
    customerId: null,
    amount: null,
    customerRemainingAmount: null,
    customerDiscountAmount: null,
    customerPaidAmount: null,
    discountAmount: null,
    paymentType: '',
    bookNumber: dayjs().year(),
    date: dayjs(),
    remark: ''
  };
  const [createCustomerReceipt, setCreateCustomerReceipt] = useState(intialData);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [accountCustomerData, setAccountCustomerData] = useState([]);
  const [dataError, setDataError] = useState({
    customerNameError: false,
    customerIdError: false,
    amountError: false,
    paymentTypeError: false,
    bookNumberError: false
  });
  useEffect(() => {
    dispatch(getAllCustomerAmountData({ bookNumber: createCustomerReceipt.bookNumber }));
  }, [dispatch, createCustomerReceipt.bookNumber]);

  // ---------------------Get the Customer Amount Data
  useEffect(() => {
    Number(createCustomerReceipt.customerId) > 0 &&
      Number(createCustomerReceipt.bookNumber) > 0 &&
      dispatch(
        getCustomerAmountData({
          customerId: createCustomerReceipt.customerId,
          bookNumber: createCustomerReceipt.bookNumber
        })
      );
  }, [dispatch, createCustomerReceipt.customerId, createCustomerReceipt.bookNumber]);

  useEffect(() => {
    if (isAllCustomerAmountFoundedSuccess) {
      if (Number(allCustomerAmountData.data.code) === 200) {
        setAccountCustomerData(allCustomerAmountData.data.data);
      } else {
        setAccountCustomerData([]);
      }
    }
  }, [isAllCustomerAmountFoundedSuccess, allCustomerAmountData]);

  // ---------------------Get the Customer Amount Data And Setit into State
  useEffect(() => {
    if (isCustomerAmountFoundedSuccess) {
      if (customerAmountData.data.code === 200) {
        setCreateCustomerReceipt((prevState) => ({
          ...prevState,
          customerRemainingAmount: customerAmountData.data.data.remaining_amount,
          customerDiscountAmount: customerAmountData.data.data.discount_amount,
          customerPaidAmount: customerAmountData.data.data.paid_amount
        }));
      } else {
        setCreateCustomerReceipt((prevState) => ({
          ...prevState,
          customerRemainingAmount: 0,
          customerDiscountAmount: 0,
          customerPaidAmount: 0
        }));
      }
    }
  }, [isCustomerAmountFoundedSuccess, customerAmountData]);

  function handleClickError() {
    setSuccessOpen(false);
    setErrorOpen(true);
  }
  function handleClickSuccess(message) {
    setErrorOpen(false);
    setSuccessMessage(message);
    setSuccessOpen(true);
  }
  function handleSnakeBarClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
    setErrorOpen(false);
  }
  const handleCustomerSelect = async (e) => {
    const customerId = await convertCustomerAmountDataId(accountCustomerData, e.target.value);
    setCreateCustomerReceipt({
      ...createCustomerReceipt,
      customerId: customerId.customer_id,
      customerName: e.target.value
    });
    setDataError({ ...dataError, customerNameError: false, customerIdError: false });
  };

  const handleBookNameSelect = async (e) => {
    setCreateCustomerReceipt({ ...createCustomerReceipt, bookNumber: e.target.value });
    setDataError({ ...dataError, bookNameError: false });
  };
  const getFiveYearsBack = () => {
    const currentYear = new Date().getFullYear();
    const fiveYearsBackArray = [];

    for (let i = 0; i < 3; i++) {
      const year = currentYear - i;
      fiveYearsBackArray.push(String(year));
    }

    return fiveYearsBackArray;
  };
  const handlePaymentTypeSelect = async (e) => {
    setCreateCustomerReceipt({ ...createCustomerReceipt, paymentType: e.target.value });
    setDataError({ ...dataError, paymentTypeError: false });
  };
  const handleSubmit = async () => {
    try {
      if (
        createCustomerReceipt.customerName &&
        createCustomerReceipt.customerName.length > 0 &&
        createCustomerReceipt.customerId &&
        Number(createCustomerReceipt.customerId) > 0 &&
        createCustomerReceipt.paymentType &&
        createCustomerReceipt.paymentType.length > 0 &&
        createCustomerReceipt.amount &&
        Number(createCustomerReceipt.amount) > 0 &&
        createCustomerReceipt.bookNumber &&
        Number(createCustomerReceipt.bookNumber) > 0
      ) {
        const customerReceiptDetails = { ...createCustomerReceipt };
        const propertiesToConvert = ['amount', 'discountAmount', 'customerId', 'bookNumber'];
        propertiesToConvert.forEach((property) => {
          customerReceiptDetails[property] = parseInt(customerReceiptDetails[property]);
        });
        Object.keys(customerReceiptDetails).forEach((key) => {
          if (customerReceiptDetails[key] === '' || customerReceiptDetails[key] === null) {
            delete customerReceiptDetails[key];
          }
        });

        const responseValue = await dispatch(createCustomerPaymentReceipt(customerReceiptDetails));
        if (!responseValue.hasOwnProperty('error')) {
          handleClickSuccess('Customer Receipt Created successfully!!');
          setCreateCustomerReceipt(intialData);
          handleApiCall();
          setLoading(false);
        } else {
          throw responseValue;
        }
      } else {
        const validationRules = [
          {
            property: 'customerName',
            condition: createCustomerReceipt.customerName.length <= 0,
            errorKey: 'customerNameError'
          },
          {
            property: 'customerId',
            condition: Number(createCustomerReceipt.customerId) <= 0,
            errorKey: 'customerIdError'
          },
          {
            property: 'amount',
            condition: Number(createCustomerReceipt.amount) <= 0,
            errorKey: 'amountError'
          },
          {
            property: 'bookNumber',
            condition: Number(createCustomerReceipt.bookNumber) <= 0,
            errorKey: 'bookNumberError'
          },
          {
            property: 'paymentType',
            condition: createCustomerReceipt.paymentType.length <= 0,
            errorKey: 'paymentTypeError'
          }
        ];

        const updatedErrors = validationRules.reduce(
          (errors, rule) => {
            if (rule.condition) {
              errors[rule.errorKey] = true;
            }
            return errors;
          },
          { ...dataError }
        );

        setDataError(updatedErrors);

        handleClickError();
        setError({ payload: 'Please Fill Mandatory Data!' });
        setLoading(false);
      }
    } catch (error) {
      handleClickError();
      setError(error);
      setLoading(false);
    }
  };

  const PaymentType = [
    {
      name: 'CASH'
    },
    {
      name: 'BANK'
    }
  ];
  return (
    <React.Fragment>
      {' '}
      <Snackbar open={successOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='error'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {error?.payload}
        </Alert>
      </Snackbar>
      <Grid container spacing={3} sx={{ mb: '24px' }}>
        <Grid item xs={12} md={12}>
          <StyledCard elevation={6}>
            <H4 sx={{ mb: 2 }}>CREATE CUSTOMER RECEIPT</H4>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <InputLabel
                  sx={{ fontSize: '10px' }}
                  error={dataError.customerNameError}
                  id='customerName'
                >
                  Customer Name
                </InputLabel>

                <Select
                  size='small'
                  sx={{ width: '50%', my: 1 }}
                  variant='standard'
                  labelId='customerName'
                  value={createCustomerReceipt.customerName}
                  onChange={handleCustomerSelect}
                  error={dataError.customerNameError}
                >
                  {accountCustomerData.map((data, index) => (
                    <MenuItem value={data.customer_name} key={index} id={index}>
                      {data.customer_name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ContentBox>
                  <TextField
                    id='customerId'
                    type='text'
                    margin='dense'
                    size='small'
                    disabled
                    label='Customer Id'
                    InputLabelProps={{ shrink: true }}
                    error={dataError.customerIdError}
                    helperText={dataError.customerIdError ? 'Please Select Customer' : null}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={
                      createCustomerReceipt.customerId == null
                        ? 'Please Select Customer'
                        : createCustomerReceipt.customerId
                    }
                  />
                </ContentBox>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ContentBox>
                  <TextField
                    id='amount'
                    type='number'
                    margin='dense'
                    size='small'
                    label='Amount'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={createCustomerReceipt.amount == null ? 0 : createCustomerReceipt.amount}
                    error={dataError.amountError}
                    helperText={dataError.amountError ? 'Pleae Enter Amount' : null}
                    onChange={(e) => {
                      setCreateCustomerReceipt({
                        ...createCustomerReceipt,
                        amount: e.target.value
                      });
                      setDataError({ ...dataError, amountError: false });
                    }}
                  />
                </ContentBox>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ContentBox>
                  <TextField
                    id='discountAmount'
                    type='number'
                    margin='dense'
                    size='small'
                    label='Discount Amount'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={
                      createCustomerReceipt.discountAmount == null
                        ? 0
                        : createCustomerReceipt.discountAmount
                    }
                    onChange={(e) => {
                      setCreateCustomerReceipt({
                        ...createCustomerReceipt,
                        discountAmount: e.target.value
                      });
                    }}
                  />
                </ContentBox>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: '50%', my: 1 }}
                    label='Date'
                    format='DD/MM/YYYY'
                    value={createCustomerReceipt.date}
                    onChange={(newValue) =>
                      setCreateCustomerReceipt({ ...createCustomerReceipt, date: newValue })
                    }
                    slotProps={{ textField: { variant: 'standard' } }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <InputLabel sx={{ fontSize: '10px' }} required id='bookNumber'>
                  Book Number
                </InputLabel>

                <Select
                  size='small'
                  sx={{ width: '50%', my: 1 }}
                  variant='standard'
                  labelId='customerReceiptBookNumber'
                  value={createCustomerReceipt.bookNumber}
                  onChange={handleBookNameSelect}
                >
                  {getFiveYearsBack().map((data, index) => (
                    <MenuItem value={data} key={index} id={index}>
                      {data}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <InputLabel
                  sx={{ fontSize: '10px' }}
                  error={dataError.paymentTypeError}
                  id='paymentType'
                >
                  Payment Type
                </InputLabel>
                <Select
                  size='small'
                  sx={{ my: 1, width: '50%' }}
                  variant='standard'
                  labelId='diamondType'
                  value={createCustomerReceipt.paymentType}
                  onChange={handlePaymentTypeSelect}
                  error={dataError.paymentTypeError}
                >
                  {PaymentType.map((data, index) => (
                    <MenuItem value={data.name} key={index} id={index}>
                      {data.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ContentBox>
                  <TextField
                    id='remark'
                    type='text'
                    multiline
                    maxRows={3}
                    margin='dense'
                    size='small'
                    label='Remak'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={createCustomerReceipt.remark == null ? '' : createCustomerReceipt.remark}
                    onChange={(e) => {
                      setCreateCustomerReceipt({
                        ...createCustomerReceipt,
                        remark: e.target.value
                      });
                    }}
                  />
                </ContentBox>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <ContentBox>
                  <TextField
                    id='customerRemainingAmount'
                    type='number'
                    margin='dense'
                    size='small'
                    label='Remaining Amount'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='filled'
                    value={
                      createCustomerReceipt.customerRemainingAmount == null
                        ? 0
                        : createCustomerReceipt.customerRemainingAmount
                    }
                  />
                </ContentBox>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <ContentBox>
                  <TextField
                    id='customerDiscountAmount'
                    type='number'
                    margin='dense'
                    size='small'
                    label='Discount Amount'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='filled'
                    value={
                      createCustomerReceipt.customerDiscountAmount == null
                        ? 0
                        : createCustomerReceipt.customerDiscountAmount
                    }
                  />
                </ContentBox>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <ContentBox>
                  <TextField
                    id='customerPaidAmount'
                    type='number'
                    margin='dense'
                    size='small'
                    label='Paid Amount'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='filled'
                    value={
                      createCustomerReceipt.customerPaidAmount == null
                        ? 0
                        : createCustomerReceipt.customerPaidAmount
                    }
                  />
                </ContentBox>
              </Grid>
            </Grid>
            <LoadingButton
              onClick={handleSubmit}
              color='primary'
              loading={loading}
              variant='contained'
              sx={{ my: 2, textTransform: 'capitalize' }}
            >
              Create Receipt
            </LoadingButton>
          </StyledCard>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CreateCustomerReceipt;
