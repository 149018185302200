import { getHeaders } from 'app/utils/handleHeader';
import axios from 'axios';
const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');
const initialState = {
  isInitialised: true,
  isOrderSuccess: false,
  isRunningOrderSuccess: false,
  isCompletedOrderSuccess: false,
  isParticularOrderSuccess: false,
  isCreateJobBook: false,
  isSingleCustomerOrderSuccess: false,
  isSingleCustomerRunningOrderSuccess: false,
  isuserInvoiceOrderSuccess: false,
  isOrderStatusUpdated: false,
  isOrderEmployees: false,
  isOrderProducts: false,
  isOrderItems: false,
  isLoading: false,
  isError: false,
  ordersData: null,
  runningOrderData: null,
  completedOrderData: null,
  particularOrder: null,
  singleCustomerOrder: null,
  userInvoiceOrder: null,
  orderEmployeeData: null,
  orderProducts: null,
  orderItems: null

};

// --------------------------------------Create Order --------------------------------
export const addOrder = createAsyncThunk('addOrder', async (item, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/transaction/createorder`,
      item,
      {
        headers: getHeaders()
      }
    );
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
//--------------Asyncthunk for Get Order

export const getOrders = createAsyncThunk('getOrders', async (object, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transaction/getorders`, {
      headers: getHeaders()
    });
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
//--------------Asyncthunk for Get Order's Emplyoee

export const getOrderEmplyoeeData = createAsyncThunk('getOrdersEmplyoeeData', async ({ orderId }, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transaction/getorderemployee/${orderId}`, {
      headers: getHeaders()
    });
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
//--------------Asyncthunk for Get Completed Order

export const getCompletedOrders = createAsyncThunk('getCompletedOrders', async (object, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transaction/getcompletedorders`, {
      headers: getHeaders()
    });
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
//--------------Asyncthunk for Get Running Order

export const getRunningOrders = createAsyncThunk('getRunningOrders', async (object, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transaction/getrunningorders`, {
      headers: getHeaders()
    });
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
//--------------Asyncthunk for Get Single Order

export const getSingleOrder = createAsyncThunk('getSingleOrder', async ({ orderId }, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transaction/getorder/${orderId}`, {
      headers: getHeaders()
    });
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
//--------------Asyncthunk for Get Single Customer's Orders

export const getSingleCustomerOrder = createAsyncThunk('getSingleCustomerOrders', async ({ CustomerId }, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transaction/getuserorders/${CustomerId}`, {
      headers: getHeaders()
    });
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
//--------------Asyncthunk for Get Single Customer's Running Orders

export const getSingleCustomerRunningOrder = createAsyncThunk('getSingleCustomerRunningOrder', async ({ customerId }, { rejectWithValue }) => {
  try {

    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transaction/getuserrunningorders/${customerId}`, {
      headers: getHeaders()
    });
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
//--------------Asyncthunk for Get Customer's Completed Orders

export const getUserInvoiceOrder = createAsyncThunk('getUserInvoiceOrder', async ({ invoiceId }, { rejectWithValue }) => {
  try {

    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transaction/getuserinvoiceorder/${invoiceId}`, {
      headers: getHeaders()
    });
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
//--------------Asyncthunk for Order Products----------------

export const getOrderProducts = createAsyncThunk('getOrderProducts', async ({ itemName }, { rejectWithValue }) => {
  try {

    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transaction/getorderproducts/${itemName}`, {
      headers: getHeaders()
    });
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
//--------------Asyncthunk for Order Item----------------

export const getOrderItems = createAsyncThunk('getOrderItems', async (object, { rejectWithValue }) => {
  try {

    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transaction/getorderitems`, {
      headers: getHeaders()
    });
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
//--------------Asyncthunk for Update Order

export const updateOrders = createAsyncThunk('updateOrders', async ({ updateOrderId, updateOrderDetails }, { rejectWithValue }) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/transaction/updateorder/${updateOrderId}`, updateOrderDetails, {
      headers: getHeaders()
    });
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
//--------------Asyncthunk for Update Inward Type
export const updateInwardType = createAsyncThunk('updateInwardType', async ({ orderId, updateOrderDetails }, { rejectWithValue }) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/transaction/updateorderinward/${orderId}`, updateOrderDetails, {
      headers: getHeaders()
    });
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
//--------------Asyncthunk for Delete Order
export const deleteOrder = createAsyncThunk(
  '/deleteOrder',
  async ({ deleteOrderId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/transaction/deleteorder/${deleteOrderId}`,
        { headers: getHeaders() }
      );
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
// --------------------------------------Create Job Book --------------------------------
export const createJobBook = createAsyncThunk('createJobBook', async ({ orderId }, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/transaction/createorderbook/${orderId}`, {},

      {
        responseType: 'arraybuffer',
        headers: { 'Accept': 'application/pdf', ...getHeaders() }
      }
    );
    const blob = new Blob([response.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'order.pdf'; // Set the desired file name
    a.click();
    window.URL.revokeObjectURL(url);
    // return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
//--------------Asyncthunk for Update Order Status --------------------------------
export const updateOrderStatus = createAsyncThunk('updateOrderStatus', async ({ orderId, statusId }, { rejectWithValue }) => {
  try {
    const response = await axios.patch(`${process.env.REACT_APP_BASE_URL}/transaction/updateorderstatus/${orderId}`, { statusId }, {
      headers: getHeaders()
    });
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {},
  extraReducers: {
    //--------------Slice action for the Create Order
    [addOrder.pending]: (state, action) => {
      state.isLoading = true;
      state.isOrderSuccess = false;
      state.isError = false;
    },
    [addOrder.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isOrderSuccess = true;
      state.isError = false;
    },
    [addOrder.rejected]: (state, action) => {
      state.isLoading = false;
      state.isOrderSuccess = false;
      state.isError = true;
    },
    //--------------Slice action for the Get Orders
    [getOrders.pending]: (state, action) => {
      state.isLoading = true;
      state.isOrderSuccess = false;
      state.isError = false;
    },
    [getOrders.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isOrderSuccess = true;
      state.ordersData = action.payload;
      state.isError = false;
    },
    [getOrders.rejected]: (state, action) => {
      state.isLoading = false;
      state.isOrderSuccess = false;
      state.isError = true;
    },
    //--------------Slice action for the Get Order's Emplyoee
    [getOrderEmplyoeeData.pending]: (state, action) => {
      state.isLoading = true;
      state.isOrderEmployees = false;
      state.isError = false;
    },
    [getOrderEmplyoeeData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isOrderEmployees = true;
      state.orderEmployeeData = action.payload;
      state.isError = false;
    },
    [getOrderEmplyoeeData.rejected]: (state, action) => {
      state.isLoading = false;
      state.isOrderEmployees = false;
      state.isError = true;
    },
    //--------------Slice action for the Get Order's Items
    [getOrderItems.pending]: (state, action) => {
      state.isLoading = true;
      state.isOrderItems = false;
      state.isError = false;
    },
    [getOrderItems.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isOrderItems = true;
      state.orderItems = action.payload;
      state.isError = false;
    },
    [getOrderItems.rejected]: (state, action) => {
      state.isLoading = false;
      state.isOrderItems = false;
      state.isError = true;
    },
    //--------------Slice action for the Get Completed-Orders
    [getCompletedOrders.pending]: (state, action) => {
      state.isLoading = true;
      state.isCompletedOrderSuccess = false;
      state.isError = false;
    },
    [getCompletedOrders.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isCompletedOrderSuccess = true;
      state.completedOrderData = action.payload;
      state.isError = false;
    },
    [getCompletedOrders.rejected]: (state, action) => {
      state.isLoading = false;
      state.isCompletedOrderSuccess = false;
      state.isError = true;
    },
    //--------------Slice action for the Get Running Orders
    [getRunningOrders.pending]: (state, action) => {
      state.isLoading = true;
      state.isRunningOrderSuccess = false;
      state.isError = false;
    },
    [getRunningOrders.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isRunningOrderSuccess = true;
      state.runningOrderData = action.payload;
      state.isError = false;
    },
    [getRunningOrders.rejected]: (state, action) => {
      state.isLoading = false;
      state.isRunningOrderSuccess = false;
      state.isError = true;
    },
    //--------------Slice action for the Get Single Orders
    [getSingleOrder.pending]: (state, action) => {
      state.isLoading = true;
      state.isParticularOrderSuccess = false;
      state.isError = false;
    },
    [getSingleOrder.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isParticularOrderSuccess = true;
      state.particularOrder = action.payload;
      state.isError = false;
    },
    [getSingleOrder.rejected]: (state, action) => {
      state.isLoading = false;
      state.isParticularOrderSuccess = false;
      state.isError = true;
    },
    //--------------Slice action for the Get User's Orders
    [getSingleCustomerOrder.pending]: (state, action) => {
      state.isLoading = true;
      state.isSingleCustomerOrderSuccess = false;
      state.isError = false;
    },
    [getSingleCustomerOrder.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSingleCustomerOrderSuccess = true;
      state.singleCustomerOrder = action.payload;
      state.isError = false;
    },
    [getSingleCustomerOrder.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSingleCustomerOrderSuccess = false;
      state.isError = true;
    },
    //--------------Slice action for the Get User's Running Orders
    [getSingleCustomerRunningOrder.pending]: (state, action) => {
      state.isLoading = true;
      state.isSingleCustomerRunningOrderSuccess = false;
      state.isError = false;
    },
    [getSingleCustomerRunningOrder.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSingleCustomerRunningOrderSuccess = true;
      state.singleCustomerRunningOrder = action.payload;
      state.isError = false;
    },
    [getSingleCustomerRunningOrder.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSingleCustomerRunningOrderSuccess = false;
      state.isError = true;
    },
    //--------------Slice action for the Get User's Completed Orders
    [getUserInvoiceOrder.pending]: (state, action) => {
      state.isLoading = true;
      state.isuserInvoiceOrderSuccess = false;
      state.isError = false;
    },
    [getUserInvoiceOrder.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isuserInvoiceOrderSuccess = true;
      state.userInvoiceOrder = action.payload;
      state.isError = false;
    },
    [getUserInvoiceOrder.rejected]: (state, action) => {
      state.isLoading = false;
      state.isuserInvoiceOrderSuccess = false;
      state.isError = true;
    },
    //--------------Slice action for the Get Order's Products
    [getOrderProducts.pending]: (state, action) => {
      state.isLoading = true;
      state.isOrderProducts = false;
      state.isError = false;
    },
    [getOrderProducts.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isOrderProducts = true;
      state.orderProducts = action.payload;
      state.isError = false;
    },
    [getOrderProducts.rejected]: (state, action) => {
      state.isLoading = false;
      state.isOrderProducts = false;
      state.isError = true;
    },
    //--------------Slice action for the Update Order Details
    [updateOrders.pending]: (state, action) => {
      state.isLoading = true;
      state.isOrderSuccess = false;
      state.isError = false;
    },
    [updateOrders.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isOrderSuccess = true;
      state.isError = false;
    },
    [updateOrders.rejected]: (state, action) => {
      state.isLoading = false;
      state.isOrderSuccess = false;
      state.isError = true;
    },
    //--------------Slice action for the Update Order Details
    [updateInwardType.pending]: (state, action) => {
      state.isLoading = true;
      state.isOrderSuccess = false;
      state.isError = false;
    },
    [updateInwardType.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isOrderSuccess = true;
      state.isError = false;
    },
    [updateInwardType.rejected]: (state, action) => {
      state.isLoading = false;
      state.isOrderSuccess = false;
      state.isError = true;
    },
    //--------------Slice action for the Delete Order
    [deleteOrder.pending]: (state, action) => {
      state.isLoading = true;
      state.isOrderSuccess = false;
      state.isError = false;
    },
    [deleteOrder.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isOrderSuccess = true;
      state.isError = false;
    },
    [deleteOrder.rejected]: (state, action) => {
      state.isLoading = false;
      state.isOrderSuccess = false;
      state.isError = true;
    },
    //--------------Slice action for the Create Job Book
    [createJobBook.pending]: (state, action) => {
      state.isLoading = true;
      state.isCreateJobBook = false;
      state.isError = false;
    },
    [createJobBook.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isCreateJobBook = true;
      state.isError = false;
    },
    [createJobBook.rejected]: (state, action) => {
      state.isLoading = false;
      state.isCreateJobBook = false;
      state.isError = true;
    },
    //--------------Slice action for the Update Order Details
    [updateOrderStatus.pending]: (state, action) => {
      state.isLoading = true;
      state.isOrderStatusUpdated = false;
      state.isError = false;
    },
    [updateOrderStatus.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isOrderStatusUpdated = true;
      state.isError = false;
    },
    [updateOrderStatus.rejected]: (state, action) => {
      state.isLoading = false;
      state.isOrderStatusUpdated = false;
      state.isError = true;
    }
  }
});
export default orderSlice.reducer;
