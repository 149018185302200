import { MenuItem, Select } from '@mui/material';

import { useSelector } from 'react-redux';
import { useState } from 'react';
import { selectDiamondType } from '../helper/filterStock';

const FilterDiamondType = ({ setVendorStockData }) => {
  const { stockData } = useSelector((state) => state.stock);
  const [defaultSelect, setDefaultSelect] = useState('all');

  const handleSelect = async (event) => {
    setDefaultSelect(event.target.value);
    const seletctedData = await selectDiamondType(event.target.value, stockData.data.data);
    setVendorStockData(seletctedData);
  };
  return (
    <Select size='small' value={defaultSelect} onChange={handleSelect}>
      <MenuItem value='all' id='all'>
        Select Diamond Type
      </MenuItem>

      <MenuItem value='REAL' id={1}>
        REAL
      </MenuItem>

      <MenuItem value='CVD' id={2}>
        CVD
      </MenuItem>
    </Select>
  );
};

export default FilterDiamondType;
