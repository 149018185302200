import { MenuItem, Select } from '@mui/material';

import { useSelector } from 'react-redux';
import { useState } from 'react';
import { selectStatus } from '../helper/filterReceipt';

const FilterStatus = ({ setAllVendorReceiptsData }) => {
  const { allVendorReceiptData } = useSelector((state) => state.vendorReceipt);
  const [defaultSelect, setDefaultSelect] = useState('all');

  const handleSelect = async (event) => {
    setDefaultSelect(event.target.value);
    const seletctedData = await selectStatus(event.target.value, allVendorReceiptData.data.data);
    setAllVendorReceiptsData(seletctedData);
  };
  return (
    <Select size='small' value={defaultSelect} onChange={handleSelect}>
      <MenuItem value='all' id='all'>
        Payment Type
      </MenuItem>

      <MenuItem value='BANK' id={1}>
        BANK
      </MenuItem>

      <MenuItem value='CASH' id={2}>
        CASH
      </MenuItem>
    </Select>
  );
};

export default FilterStatus;
