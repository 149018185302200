import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Card,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  styled,
  TextField
} from '@mui/material';
import React from 'react';
import { H4 } from 'app/components/Typography';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { addIncomeEntry } from 'app/contexts/reducers/transaction/incomeEntrySlice';
import { getIncomeTypeData } from 'app/contexts/reducers/incomeType/incomeTypeSlice';
import { useEffect } from 'react';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' }
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main }
}));

const CreateIncomeEntry = ({ handleApiCall }) => {
  const dispatch = useDispatch();
  const { isIncomeTypeSuccess, incomeTypeData } = useSelector((state) => state.incomeType);

  const intialData = {
    incomeType: '',
    amount: '',
    paymentType: '',
    bookNumber: dayjs().year(),
    date: dayjs(),
    remark: ''
  };
  const [incomeType, setIncomeType] = useState([]);
  const [createIncomeEntry, setCreateIncomeEntry] = useState(intialData);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [dataError, setDataError] = useState({
    incomeTypeError: false,
    amountError: false,
    paymentTypeError: false,
    bookNumberError: false,
    dateError: false
  });
  useEffect(() => {
    dispatch(getIncomeTypeData());
  }, [dispatch]);
  useEffect(() => {
    if (isIncomeTypeSuccess) {
      if (Number(incomeTypeData.data.code) === 200) {
        setIncomeType(incomeTypeData.data.data);
      } else {
        setIncomeType([]);
      }
    }
  }, [isIncomeTypeSuccess, incomeTypeData]);
  function handleClickError() {
    setSuccessOpen(false);
    setErrorOpen(true);
  }
  function handleClickSuccess(message) {
    setErrorOpen(false);
    setSuccessMessage(message);
    setSuccessOpen(true);
  }
  function handleSnakeBarClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
    setErrorOpen(false);
  }

  const handleIncomeTypeSelect = async (e) => {
    setCreateIncomeEntry({ ...createIncomeEntry, incomeType: e.target.value });
    setDataError({ ...dataError, incomeTypeError: false });
  };
  const handleBookNameSelect = async (e) => {
    setCreateIncomeEntry({ ...createIncomeEntry, bookNumber: e.target.value });
    setDataError({ ...dataError, bookNameError: false });
  };
  const getFiveYearsBack = () => {
    const currentYear = new Date().getFullYear();
    const fiveYearsBackArray = [];

    for (let i = 0; i < 3; i++) {
      const year = currentYear - i;
      fiveYearsBackArray.push(String(year));
    }

    return fiveYearsBackArray;
  };
  const handlePaymentTypeSelect = async (e) => {
    setCreateIncomeEntry({ ...createIncomeEntry, paymentType: e.target.value });
    setDataError({ ...dataError, paymentTypeError: false });
  };
  const handleSubmit = async () => {
    try {
      if (
        createIncomeEntry.incomeType &&
        createIncomeEntry.incomeType.length > 0 &&
        createIncomeEntry.amount &&
        Number(createIncomeEntry.amount) > 0 &&
        createIncomeEntry.paymentType &&
        createIncomeEntry.paymentType.length > 0 &&
        createIncomeEntry.bookNumber &&
        Number(createIncomeEntry.bookNumber) > 0 &&
        createIncomeEntry.date
      ) {
        const incomeEntryDetails = { ...createIncomeEntry };
        Object.keys(incomeEntryDetails).forEach((key) => {
          if (incomeEntryDetails[key] === '') {
            delete incomeEntryDetails[key];
          }
          // if (key === 'date') {
          //   createIncomeEntry[key] = dayjs(createIncomeEntry[key]).format('YYYY-MM-DD');
          // }
        });

        const responseValue = await dispatch(addIncomeEntry(incomeEntryDetails));
        if (!responseValue.hasOwnProperty('error')) {
          handleClickSuccess('Income-Entry Created successfully!!');
          handleApiCall();
          setCreateIncomeEntry(intialData);
          setLoading(false);
        } else {
          throw responseValue;
        }
      } else {
        const validationRules = [
          {
            property: 'incomeType',
            condition: createIncomeEntry.incomeType.length <= 0,
            errorKey: 'incomeTypeError'
          },
          {
            property: 'amount',
            condition: Number(createIncomeEntry.amount) <= 0,
            errorKey: 'amountError'
          },
          {
            property: 'bookNumber',
            condition: Number(createIncomeEntry.bookNumber) <= 0,
            errorKey: 'bookNumberError'
          },
          {
            property: 'paymentType',
            condition: createIncomeEntry.paymentType.length <= 0,
            errorKey: 'paymentTypeError'
          }
        ];

        const updatedErrors = validationRules.reduce(
          (errors, rule) => {
            if (rule.condition) {
              errors[rule.errorKey] = true;
            }
            return errors;
          },
          { ...dataError }
        );
        setDataError(updatedErrors);
        handleClickError();
        setError({ payload: 'Please Fill Mandatory Data!' });
        setLoading(false);
      }
    } catch (error) {
      handleClickError();
      setError(error);
      setLoading(false);
    }
  };

  const PaymentType = [
    {
      name: 'CASH'
    }
    // {
    //   name: 'BANK'
    // }
  ];
  return (
    <React.Fragment>
      {' '}
      <Snackbar open={successOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='error'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {error?.payload}
        </Alert>
      </Snackbar>
      <Grid container spacing={3} sx={{ mb: '24px' }}>
        <Grid item xs={12} md={12}>
          <StyledCard elevation={6}>
            <H4 sx={{ mb: 2 }}>CREATE INCOME ENTRY</H4>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <InputLabel
                  sx={{ fontSize: '10px' }}
                  error={dataError.incomeTypeError}
                  id='incomeType'
                >
                  Income Type
                </InputLabel>
                <Select
                  size='small'
                  sx={{ my: 1, width: '50%' }}
                  variant='standard'
                  labelId='incomeType'
                  value={createIncomeEntry.incomeType}
                  onChange={handleIncomeTypeSelect}
                  error={dataError.incomeTypeError}
                >
                  {incomeType &&
                    incomeType.map((data, index) => (
                      <MenuItem value={data.income_type} key={index} id={index}>
                        {data.income_type}
                      </MenuItem>
                    ))}
                </Select>
                <ContentBox>
                  <TextField
                    id='amount'
                    type='number'
                    margin='dense'
                    size='small'
                    label='Amount'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={createIncomeEntry.amount == null ? 'null' : createIncomeEntry.amount}
                    onChange={(e) => {
                      setCreateIncomeEntry({ ...createIncomeEntry, amount: e.target.value });
                      setDataError({ ...dataError, amountError: false });
                    }}
                    error={dataError.amountError}
                    helperText={dataError.amountError ? 'Please Enter Amount' : null}
                  />
                </ContentBox>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: '50%', my: 1 }}
                    label='Date'
                    format='DD/MM/YYYY'
                    value={createIncomeEntry.date}
                    onChange={(newValue) => {
                      setCreateIncomeEntry({ ...createIncomeEntry, date: newValue });
                      setDataError({ ...dataError, dateError: false });
                    }}
                    slotProps={{
                      textField: {
                        variant: 'standard',
                        error: dataError.dateError,
                        helperText: dataError.dateError ? 'Please Select Date' : null
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <InputLabel
                  sx={{ fontSize: '10px' }}
                  error={dataError.bookNumberError}
                  required
                  id='bookNumber'
                >
                  Book Number
                </InputLabel>

                <Select
                  size='small'
                  sx={{ width: '50%', my: 1 }}
                  variant='standard'
                  labelId='customerReceiptBookNumber'
                  value={createIncomeEntry.bookNumber}
                  onChange={handleBookNameSelect}
                >
                  {getFiveYearsBack().map((data, index) => (
                    <MenuItem value={data} key={index} id={index}>
                      {data}
                    </MenuItem>
                  ))}
                </Select>
                <InputLabel
                  sx={{ fontSize: '10px' }}
                  error={dataError.paymentTypeError}
                  id='paymentType'
                >
                  Payment Type
                </InputLabel>
                <Select
                  size='small'
                  sx={{ my: 1, width: '50%' }}
                  variant='standard'
                  labelId='diamondType'
                  value={createIncomeEntry.paymentType}
                  onChange={handlePaymentTypeSelect}
                  error={dataError.paymentTypeError}
                >
                  {PaymentType.map((data, index) => (
                    <MenuItem value={data.name} key={index} id={index}>
                      {data.name}
                    </MenuItem>
                  ))}
                </Select>

                <ContentBox>
                  <TextField
                    multiline
                    maxRows={4}
                    id='remark'
                    type='text'
                    margin='dense'
                    size='small'
                    label='Remark'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={createIncomeEntry.remark}
                    onChange={(e) => {
                      setCreateIncomeEntry({ ...createIncomeEntry, remark: e.target.value });
                    }}
                  />
                </ContentBox>
              </Grid>
            </Grid>
            <LoadingButton
              onClick={handleSubmit}
              color='primary'
              loading={loading}
              variant='contained'
              sx={{ my: 2, textTransform: 'capitalize' }}
            >
              Create Income Entry
            </LoadingButton>
          </StyledCard>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CreateIncomeEntry;
