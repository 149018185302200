import { LoadingButton } from '@mui/lab';
import { Box, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateIncomeType } from 'app/contexts/reducers/incomeType/incomeTypeSlice';
import { useEffect } from 'react';
export default function UpdateIncomeType({
  open,
  onClose,
  currentIncomeType,
  incomeTypeId,
  handleApiCall,
  handleClickSuccess,
  setError,
  handleClickError
}) {
  const dispatch = useDispatch();
  const [incomeEntryDetails, setIncomeEntryDetails] = useState(currentIncomeType);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setIncomeEntryDetails(currentIncomeType);
  }, [currentIncomeType]);
  async function handleUpdate() {
    try {
      setLoading(true);
      const response = await dispatch(updateIncomeType({ incomeTypeId, incomeEntryDetails }));
      if (!response.hasOwnProperty('error')) {
        onClose();
        handleApiCall();
        handleClickSuccess('Income-type Update successfully');
        setLoading(false);
      } else {
        throw response;
      }
    } catch (e) {
      handleClickError();
      setError(e);
      setLoading(false);
    }
  }
  return (
    <Box>
      <Dialog open={open} onClose={onClose} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>Update Income-Type</DialogTitle>

        <DialogContent>
          <DialogContentText>Please update the name Income-Type</DialogContentText>

          <TextField
            fullWidth
            autoFocus
            id='incomeType'
            type='text'
            margin='dense'
            label='Income Type'
            value={incomeEntryDetails}
            sx={{ my: 2 }}
            onChange={(e) => {
              setIncomeEntryDetails(e.target.value);
            }}
          />
        </DialogContent>

        <DialogActions>
          <Button variant='outlined' color='secondary' onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleUpdate}
            color='primary'
            loading={loading}
            disabled={incomeEntryDetails && incomeEntryDetails.length > 0 ? false : true}
            variant='contained'
            sx={{ my: 2 }}
          >
            Update Income-Type
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
