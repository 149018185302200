// CollapsibleTable.js
import React from 'react';
import {
  Box,
  Card,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { getOrders } from 'app/contexts/reducers/transaction/oderSlice';
import TableRowData from './TableRowData';
import SearchAccount from './SearchAccount';
import styled from '@emotion/styled';
import FilterOrderByMonth from './FilterOrderByMonth';
import FilterStatus from './FilterStatus';
const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  textTransform: 'capitalize'
}));
const CardHeader = styled(Box)(() => ({
  display: 'flex',
  paddingLeft: '24px',
  paddingRight: '24px',
  marginBottom: '12px',
  alignItems: 'center',
  justifyContent: 'space-between'
}));
const CollapsibleTable = ({ apiCall, setApiCall }) => {
  const { isOrderSuccess, ordersData } = useSelector((state) => state.order);
  const [orderData, setOrderData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrders());
  }, [dispatch, apiCall]);
  useEffect(() => {
    if (isOrderSuccess) {
      const ordersWithStatusZero = ordersData.data.data.filter(
        (order) => Number(order.status) === 0
      );
      setOrderData(ordersWithStatusZero);
    }
  }, [isOrderSuccess, ordersData]);

  // Grouping orders based on account_id
  const groupedOrders = orderData.reduce((acc, order) => {
    const existingGroup = acc.find((group) => group.accountId === order.account_id);

    if (existingGroup) {
      existingGroup.orders.push(order);
    } else {
      acc.push(createData(order.account_id, [order], order.account_name));
    }

    return acc;
  }, []);

  return (
    <React.Fragment>
      <Card elevation={3} sx={{ pt: '20px', mb: 3 }}>
        <CardHeader>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <Title>OrderTable</Title>
            </Grid>

            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <SearchAccount setOrderData={setOrderData} />
                </Grid>
                <Grid item>
                  <FilterOrderByMonth setOrderdata={setOrderData} />
                </Grid>
                <Grid item>
                  <FilterStatus setOrderdata={setOrderData} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardHeader>
        <TableContainer component={Paper}>
          <Table aria-label='collapsible table'>
            <TableHead sx={{ backgroundColor: 'rgb(34,41,67)' }}>
              <TableRow>
                <TableCell
                  align='center'
                  sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                  colSpan={6}
                >
                  Account Name
                </TableCell>
                <TableCell sx={{ width: '5%' }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {groupedOrders.map((group) => (
                <TableRowData key={group.accountId} row={group} setApiCall={setApiCall} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </React.Fragment>
  );
};

function createData(accountId, orders, accountName) {
  return {
    accountId,
    orders,
    accountName
  };
}

export default CollapsibleTable;
