import { getHeaders } from 'app/utils/handleHeader';
import axios from 'axios';

export async function fetchOutwardData(orders, dispatch) {
    let piecesSum = 0;
    let weightSum = 0;
    let totalWeight = 0;
    let totalProductPrice = 0;
    let totalExtraCost = 0;

    for (const order of orders) {
        if (order.id) {
            try {
                const orderId = order.id;
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transaction/getdiamondoutward/${orderId}`, {
                    headers: getHeaders()
                });
                const productPrice = Number(order.product_price) || 0;
                const extraCost = Number(order.extra_cost) || 0;
                totalProductPrice += productPrice;
                totalExtraCost += extraCost;
                const outwardData = response.data.data;
                for (const outwardDetails of outwardData) {
                    const pieces = Number(outwardDetails.studd_pieces) || 0;
                    const totalWeightPiece = parseFloat(parseFloat(outwardDetails.studd_weight
                    ).toFixed(3)) || 0;
                    piecesSum += pieces;
                    totalWeight = parseFloat(parseFloat(parseFloat(parseFloat(totalWeight).toFixed(3)) + totalWeightPiece).toFixed(3));
                    if (outwardDetails.order_diamond_type === 'SELF') {
                        const weight = parseFloat(parseFloat(outwardDetails.studd_weight
                        ).toFixed(3)) || 0;
                        weightSum = parseFloat(parseFloat(weight + parseFloat(parseFloat(weightSum).toFixed(3))).toFixed(3));;
                    }
                }

            } catch (error) {
                console.error('Error fetching outward data for order ID:', order.orderId);
            }
        }
        else {
            piecesSum = 0;
            weightSum = 0;
            totalWeight = 0;
            totalExtraCost = 0;
        }
    }

    return { piecesSum, weightSum, totalWeight, totalProductPrice, totalExtraCost };
}
