import { getHeaders } from 'app/utils/handleHeader';
import axios from 'axios';
const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');
const initialState = {
    isInitialised: true,
    isLoading: false,
    isError: false,
    isIncomeEntryGenrateSuccess: false,
    isIncomeEntryDeleteSuccess: false,
    isIncomeEntryUpdateSuccess: false,
    isAllIncomeEntriesSuccess: false,
    allIncomeEntriesData: null,
};
// --------------------------------------Create Income Entry --------------------------------
export const addIncomeEntry = createAsyncThunk('addIncomeEntry', async (incomeEntryDetails, { rejectWithValue }) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/transaction/createincomeentry`,
            incomeEntryDetails,
            {
                headers: getHeaders()
            }
        );
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Get All Income Entries--------------------------------
export const getAllIncomeEntriesData = createAsyncThunk('getAllIncomeEntriesData', async (object, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transaction/getincomeentries`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Delete Income Entry--------------------------------
export const deleteIncomeEntry = createAsyncThunk('deleteIncomeEntry', async ({ entryId }, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/transaction/deleteincomeentry/${entryId}`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Update Income Entry--------------------------------
export const updateIncomeEntry = createAsyncThunk('updateIncomeEntry', async ({ entryId, incomeEntryDetails }, { rejectWithValue }) => {
    try {
        const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/transaction/updateincomeentry/${entryId}`, incomeEntryDetails, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
const incomeEntrySlice = createSlice({
    name: 'incomeEntrySlice',
    initialState,
    reducers: {},
    extraReducers: {
        //--------------Slice action for the Create Customer Receipt
        [addIncomeEntry.pending]: (state, action) => {
            state.isLoading = true;
            state.isIncomeEntryGenrateSuccess = false;
            state.isError = false;
        },
        [addIncomeEntry.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isIncomeEntryGenrateSuccess = true;
            state.isError = false;
        },
        [addIncomeEntry.rejected]: (state, action) => {
            state.isLoading = false;
            state.isIncomeEntryGenrateSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Customer Receipt's Data --------------------------------
        [getAllIncomeEntriesData.pending]: (state, action) => {
            state.pending = false;
            state.isAllIncomeEntriesSuccess = false;
            state.isError = false;
        },
        [getAllIncomeEntriesData.fulfilled]: (state, action) => {
            state.pending = false;
            state.isAllIncomeEntriesSuccess = true;
            state.allIncomeEntriesData = action.payload;
            state.isError = false;
        },
        [getAllIncomeEntriesData.rejected]: (state, action) => {
            state.pending = false;
            state.isAllIncomeEntriesSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Customer's Receipt Data --------------------------------
        [deleteIncomeEntry.pending]: (state, action) => {
            state.pending = false;
            state.isIncomeEntryDeleteSuccess = false;
            state.isError = false;
        },
        [deleteIncomeEntry.fulfilled]: (state, action) => {
            state.pending = false;
            state.isIncomeEntryDeleteSuccess = true;
            state.isError = false;
        },
        [deleteIncomeEntry.rejected]: (state, action) => {
            state.pending = false;
            state.isIncomeEntryDeleteSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Specific Receipt Data --------------------------------
        [updateIncomeEntry.pending]: (state, action) => {
            state.pending = false;
            state.isIncomeEntryUpdateSuccess = false;
            state.isError = false;
        },
        [updateIncomeEntry.fulfilled]: (state, action) => {
            state.pending = false;
            state.isIncomeEntryUpdateSuccess = true;
            state.isError = false;
        },
        [updateIncomeEntry.rejected]: (state, action) => {
            state.pending = false;
            state.isIncomeEntryUpdateSuccess = false;
            state.isError = true;
        }
    }
});
export default incomeEntrySlice.reducer;
