import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders } from 'app/utils/handleHeader';
const initialState = {
    customerVendorNumberData: null,
    sellRelatedData: null,
    totalIncomeExpenseData: null,
    monthWiseIncomeAmountData: null,
    monthWiseAmountData: null,
    customerRemainingAmountData: null,
    vendorRemainingAmountData: null,
    cashOnBankData: null,
    cashOnHandData: null,
    isCustomerVendorNumberSuccess: false,
    isSellRelatedSuccess: false,
    istotalIncomeExpenseSuccess: false,
    ismonthWiseIncomeAmountSuccess: false,
    ismonthWiseAmountSuccess: false,
    iscustomerRemainingAmountSuccess: false,
    isvendorRemainingAmountSuccess: false,
    isCashOnBankSuccess: false,
    isCashOnHandSuccess: false,
    isError: false,
    isLoading: false
};
//--------------Asyncthunk for Get Customer VendorNumber Data --------------------------------
export const getCustomerVendorNumberData = createAsyncThunk('getCustomerVendorNumberData', async (object, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/dashboard/getcustomervendornumber`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Get Sell Related Data --------------------------------
export const getSellRelatedData = createAsyncThunk('getSellRelatedData', async (object, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/dashboard/getselldata`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Get Total Income-Expense Data --------------------------------
export const getTotalIncomeExpenseData = createAsyncThunk('getTotalIncomeExpenseData', async (object, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/dashboard/gettotalincomeexpenseAmount`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Get Month Wise Income Data --------------------------------
export const getMonthWiseIncomeData = createAsyncThunk('getMonthWiseIncomeData', async (object, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/dashboard/getmonthwiseincomedata`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Get Month Wise Expense Data --------------------------------
export const getMonthWiseData = createAsyncThunk('getMonthWiseData', async (object, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/dashboard/getmonthwisedata`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Get Customer Remaining Data --------------------------------
export const getCustomerRemainingData = createAsyncThunk('getCustomerRemainingData', async (object, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/dashboard/getcustomerremainingdata`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Get Vendor Remaining Data --------------------------------
export const getVendorRemainingData = createAsyncThunk('getVendorRemainingData', async (object, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/dashboard/getvendorremainingdata`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Get Cash On Bank Data --------------------------------
export const getCashOnBank = createAsyncThunk('getCashOnBank', async (object, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/dashboard/getcashonbank`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//--------------Asyncthunk for Get Cash On Hand Data --------------------------------
export const getCashOnHand = createAsyncThunk('getCashOnHand', async (object, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/dashboard/getcashonhand`, {
            headers: getHeaders()
        });
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
const dashboardSlice = createSlice({
    name: 'dashboardSlice',
    initialState,
    reducers: {},
    extraReducers: {
        //--------------Slice action for the Get Customer VendorNumber Data --------------------------------
        [getCustomerVendorNumberData.pending]: (state, action) => {
            state.isLoading = false;
            state.isCustomerVendorNumberSuccess = false;
            state.isError = false;
        },
        [getCustomerVendorNumberData.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isCustomerVendorNumberSuccess = true;
            state.customerVendorNumberData = action.payload;
            state.isError = false;
        },
        [getCustomerVendorNumberData.rejected]: (state, action) => {
            state.isLoading = false;
            state.isCustomerVendorNumberSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Sell Related Data --------------------------------
        [getSellRelatedData.pending]: (state, action) => {
            state.isLoading = false;
            state.isSellRelatedSuccess = false;
            state.isError = false;
        },
        [getSellRelatedData.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isSellRelatedSuccess = true;
            state.sellRelatedData = action.payload;
            state.isError = false;
        },
        [getSellRelatedData.rejected]: (state, action) => {
            state.isLoading = false;
            state.isSellRelatedSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Total Income-Expense Data --------------------------------
        [getTotalIncomeExpenseData.pending]: (state, action) => {
            state.isLoading = false;
            state.istotalIncomeExpenseSuccess = false;
            state.isError = false;
        },
        [getTotalIncomeExpenseData.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.istotalIncomeExpenseSuccess = true;
            state.totalIncomeExpenseData = action.payload;
            state.isError = false;
        },
        [getTotalIncomeExpenseData.rejected]: (state, action) => {
            state.isLoading = false;
            state.istotalIncomeExpenseSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Month Wise Expense Data --------------------------------
        [getMonthWiseData.pending]: (state, action) => {
            state.isLoading = false;
            state.ismonthWiseAmountSuccess = false;
            state.isError = false;
        },
        [getMonthWiseData.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.ismonthWiseAmountSuccess = true;
            state.monthWiseAmountData = action.payload;
            state.isError = false;
        },
        [getMonthWiseData.rejected]: (state, action) => {
            state.isLoading = false;
            state.ismonthWiseAmountSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Customer Remaining Data --------------------------------
        [getCustomerRemainingData.pending]: (state, action) => {
            state.isLoading = false;
            state.iscustomerRemainingAmountSuccess = false;
            state.isError = false;
        },
        [getCustomerRemainingData.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.iscustomerRemainingAmountSuccess = true;
            state.customerRemainingAmountData = action.payload;
            state.isError = false;
        },
        [getCustomerRemainingData.rejected]: (state, action) => {
            state.isLoading = false;
            state.iscustomerRemainingAmountSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Vendor Remaining Data --------------------------------
        [getVendorRemainingData.pending]: (state, action) => {
            state.isLoading = false;
            state.isvendorRemainingAmountSuccess = false;
            state.isError = false;
        },
        [getVendorRemainingData.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isvendorRemainingAmountSuccess = true;
            state.vendorRemainingAmountData = action.payload;
            state.isError = false;
        },
        [getVendorRemainingData.rejected]: (state, action) => {
            state.isLoading = false;
            state.isvendorRemainingAmountSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Cash On Hand Data --------------------------------
        [getCashOnHand.pending]: (state, action) => {
            state.isLoading = false;
            state.isCashOnHandSuccess = false;
            state.isError = false;
        },
        [getCashOnHand.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isCashOnHandSuccess = true;
            state.cashOnHandData = action.payload;
            state.isError = false;
        },
        [getCashOnHand.rejected]: (state, action) => {
            state.isLoading = false;
            state.isCashOnHandSuccess = false;
            state.isError = true;
        },
        //--------------Slice action for the Get Cash On Bank Data --------------------------------
        [getCashOnBank.pending]: (state, action) => {
            state.isLoading = false;
            state.isCashOnBankSuccess = false;
            state.isError = false;
        },
        [getCashOnBank.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isCashOnBankSuccess = true;
            state.cashOnBankData = action.payload;
            state.isError = false;
        },
        [getCashOnBank.rejected]: (state, action) => {
            state.isLoading = false;
            state.isCashOnBankSuccess = false;
            state.isError = true;
        },
    }
})
export default dashboardSlice.reducer;
