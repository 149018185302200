export const selectStatus = async (selectedData, dataHouse) => {

    if (typeof selectedData !== 'number') {

        if (selectedData.trim() === 'all') {
            // If the search term is all, return the whole array
            return dataHouse;
        }
    }
    else {
        const filteredMonthData = [];
        dataHouse.forEach((data) => {
            if (Number(data.status) === Number(selectedData)) {
                filteredMonthData.push(data);
            }
        });

        return filteredMonthData;
    }
};
