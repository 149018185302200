import { MenuItem, Select } from '@mui/material';

import { useSelector } from 'react-redux';
import { useState } from 'react';
import { selectStatus } from '../helper/filterOrder';

const FilterStatus = ({ setOrderdata }) => {
  const { ordersData } = useSelector((state) => state.order);
  const [defaultSelect, setDefaultSelect] = useState(0);

  const handleSelect = async (event) => {
    setDefaultSelect(event.target.value);
    const seletctedData = await selectStatus(event.target.value, ordersData.data.data);
    setOrderdata(seletctedData);
  };
  return (
    <Select size='small' value={defaultSelect} onChange={handleSelect}>
      <MenuItem value='all' id='all'>
        Select Status
      </MenuItem>

      <MenuItem value={0} id={1}>
        Pending
      </MenuItem>
      <MenuItem value={1} id={1}>
        Running
      </MenuItem>

      <MenuItem value={2} id={2}>
        Completed
      </MenuItem>
      <MenuItem value={3} id={3}>
        Sold
      </MenuItem>
    </Select>
  );
};

export default FilterStatus;
