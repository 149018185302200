import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
  styled
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { addDiamondOutward } from 'app/contexts/reducers/transaction/diamondOutwardSlice';
const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' }
}));
const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main }
}));

export default function AddDiamondOutward({
  open,
  onClose,
  vendorId,
  customerId,
  orderId,
  outwardDiamondType,
  outwardTotalPcs,
  outwardTotalWeight,
  outwardVendorName,
  inwardDiamondType,
  inwardOrderType,
  handleClickSuccess,
  setError,
  handleClickError,
  handleIsEntryCall
}) {
  const dispatch = useDispatch();
  const intialData = {
    orderId: null,
    vendorName: null,
    vendorId: null,
    customerId: null,
    diamondType: null,
    orderDiamondType: null,
    orderType: null,
    totalPcs: '',
    returnPcs: '',
    brokenPcs: '',
    missingPcs: '',
    studdPcs: '',
    totalWeight: '',
    returnWeight: '',
    brokenWeight: '',
    missingWeight: '',
    studdWeight: '',
    outwardDate: dayjs(),
    remarks: ''
  };
  const [addOutwardEntry, setAddOutwardEntry] = useState(intialData);

  const [loading, setLoading] = useState(false);
  const [outwardDisable, setOutwardDisable] = useState(false);
  useEffect(() => {
    setAddOutwardEntry({
      ...intialData,
      vendorName: outwardVendorName,
      diamondType: outwardDiamondType,
      totalPcs: outwardTotalPcs,
      totalWeight: outwardTotalWeight,
      orderDiamondType: inwardDiamondType,
      orderType: inwardOrderType,
      orderId: orderId,
      vendorId: vendorId,
      customerId: customerId
    });

    // eslint-disable-next-line
  }, [outwardDiamondType, outwardTotalPcs, outwardTotalWeight, outwardVendorName, orderId]);
  async function handleOutward() {
    try {
      if (
        addOutwardEntry.orderId &&
        Number(addOutwardEntry.orderId) > 0 &&
        addOutwardEntry.orderDiamondType &&
        addOutwardEntry.orderDiamondType.length > 0 &&
        addOutwardEntry.orderType &&
        addOutwardEntry.orderType.length > 0 &&
        addOutwardEntry.diamondType &&
        addOutwardEntry.diamondType.length > 0 &&
        addOutwardEntry.totalWeight &&
        addOutwardEntry.outwardDate &&
        parseFloat(parseFloat(addOutwardEntry.totalWeight).toFixed(3)) > 0 &&
        addOutwardEntry.totalPcs &&
        Number(addOutwardEntry.totalPcs) > 0
      ) {
        const addOutwardDetails = { ...addOutwardEntry };
        const propertiesToConvert = [
          'totalWeight',
          'returnWeight',
          'brokenWeight',
          'missingWeight',
          'studdWeight',
          'totalPcs',
          'returnPcs',
          'brokenPcs',
          'missingPcs',
          'studdPcs'
        ];
        propertiesToConvert.forEach((property) => {
          if (addOutwardEntry.hasOwnProperty(property)) {
            addOutwardEntry[property] = [
              'totalPcs',
              'returnPcs',
              'brokenPcs',
              'missingPcs',
              'studdPcs'
            ].includes(property)
              ? parseInt(addOutwardEntry[property])
              : parseFloat(parseFloat(addOutwardEntry[property]).toFixed(3));
          }
        });
        Object.keys(addOutwardDetails).forEach((key) => {
          if (addOutwardDetails[key] === '' || addOutwardDetails[key] === null) {
            delete addOutwardDetails[key];
          }
        });
        if (addOutwardDetails.orderDiamondType.toLowerCase() === 'self') {
          // Make sure vendorid has a value when inwardtype is 'self'
          if (!vendorId) {
            setError({ payload: "Vendor ID is required for inwardtype 'self'." });
          }
          customerId = null;
        } else if (addOutwardDetails.orderDiamondType.toLowerCase() === 'customer') {
          // When inwardtype is 'customer', no need for vendorid
          vendorId = null;
        } else if (addOutwardDetails.orderDiamondType.toLowerCase() === 'bulk') {
          // When inwardtype is 'customer', no need for vendorid
          vendorId = null;
        } else {
          setError({ payload: "Invalid inwardtype. It should be 'self' or 'customer'." });
        }
        const responseValue = await dispatch(addDiamondOutward({ ...addOutwardDetails, vendorId }));
        if (!responseValue.hasOwnProperty('error')) {
          onClose();
          handleClickSuccess('Diamond Outward successfully!!');
          handleIsEntryCall();
          setLoading(false);
        } else {
          throw responseValue;
        }
      } else {
        handleClickError();
        setError({ payload: 'Please Fill Mandatory Data!' });
        setLoading(false);
      }
    } catch (error) {
      handleClickError();
      setError(error);
      setLoading(false);
    }
  }
  useEffect(() => {
    const parsedBrokenWeight = parseFloat(parseFloat(addOutwardEntry.brokenWeight).toFixed(3)) || 0;
    const parsedMissingWeight =
      parseFloat(parseFloat(addOutwardEntry.missingWeight).toFixed(3)) || 0;
    const parsedReturnWeight = parseFloat(parseFloat(addOutwardEntry.returnWeight).toFixed(3)) || 0;
    const parsedStuddWeight = parseFloat(
      (
        parseFloat(outwardTotalWeight).toFixed(3) -
        parsedReturnWeight -
        parsedBrokenWeight -
        parsedMissingWeight
      ).toFixed(3)
    );
    const studdPieces =
      Number(outwardTotalPcs) -
      Number(addOutwardEntry.returnPcs) -
      Number(addOutwardEntry.brokenPcs) -
      Number(addOutwardEntry.missingPcs);

    setAddOutwardEntry((prevAddOutwardEntry) => ({
      ...prevAddOutwardEntry,
      studdWeight: parsedStuddWeight,
      studdPcs: studdPieces
    }));

    const totalWeight = parseFloat(
      parseFloat(
        parseFloat(parsedBrokenWeight.toFixed(3)) +
          parseFloat(parsedMissingWeight.toFixed(3)) +
          parseFloat(parsedReturnWeight.toFixed(3)) +
          parseFloat(parsedStuddWeight.toFixed(3))
      ).toFixed(3)
    );
    const parsedOutwardTotalWeight = parseFloat(outwardTotalWeight).toFixed(3);
    if (
      Number(addOutwardEntry.brokenPcs) +
        Number(addOutwardEntry.missingPcs) +
        Number(addOutwardEntry.returnPcs) +
        Number(addOutwardEntry.studdPcs) >
      outwardTotalPcs
    ) {
      setOutwardDisable(true);
    } else if (totalWeight > parseFloat(parsedOutwardTotalWeight)) {
      setOutwardDisable(true);
    } else {
      setOutwardDisable(false);
    }
  }, [
    addOutwardEntry.brokenPcs,
    addOutwardEntry.missingPcs,
    addOutwardEntry.brokenWeight,
    addOutwardEntry.missingWeight,
    addOutwardEntry.returnPcs,
    addOutwardEntry.returnWeight,
    addOutwardEntry.studdPcs,
    addOutwardEntry.studdWeight,
    outwardTotalPcs,
    outwardTotalWeight
  ]);
  return (
    <Box>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth='md'
        fullWidth
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>Add Outward-Diamond Data</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: '10px' }}>
            You can Add Your Outward Entry Details From Here!{' '}
          </DialogContentText>
          <Grid container spacing={3} sx={{ mb: '24px' }}>
            <Grid item xs={12} md={12}>
              <StyledCard elevation={6}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ContentBox>
                      <TextField
                        id='vendor_name'
                        type='text'
                        margin='dense'
                        size='small'
                        label='Vendor Name'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '70%' }}
                        variant='standard'
                        value={addOutwardEntry.vendorName}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='orderDiamondType'
                        type='text'
                        margin='dense'
                        size='small'
                        label='Inward Type'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '70%' }}
                        variant='standard'
                        value={addOutwardEntry.orderDiamondType}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='totalPieces'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Total Pieces'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '70%' }}
                        variant='standard'
                        value={addOutwardEntry.totalPcs}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='returnPieces'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Return Pieces'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '70%' }}
                        variant='standard'
                        value={addOutwardEntry.returnPcs}
                        onChange={(e) => {
                          setAddOutwardEntry({ ...addOutwardEntry, returnPcs: e.target.value });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='brokenPieces'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Broken Pieces'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '70%' }}
                        variant='standard'
                        value={addOutwardEntry.brokenPcs}
                        onChange={(e) => {
                          setAddOutwardEntry({ ...addOutwardEntry, brokenPcs: e.target.value });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='missingPieces'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Missing Pieces'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '70%' }}
                        variant='standard'
                        value={addOutwardEntry.missingPcs}
                        onChange={(e) => {
                          setAddOutwardEntry({ ...addOutwardEntry, missingPcs: e.target.value });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='studdPieces'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Studd Pieces'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '70%' }}
                        variant='standard'
                        value={addOutwardEntry.studdPcs}
                        // onChange={(e) => {
                        //   setAddOutwardEntry({ ...addOutwardEntry, studdPcs: e.target.value });
                        // }}
                      />
                    </ContentBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ContentBox>
                      <TextField
                        id='diamondType'
                        type='text'
                        margin='dense'
                        size='small'
                        label='Diamond Type'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '70%' }}
                        variant='standard'
                        value={addOutwardEntry.diamondType}
                      />
                    </ContentBox>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{ width: '70%', my: 1 }}
                        label='Date'
                        format='DD/MM/YYYY'
                        value={addOutwardEntry.outwardDate}
                        onChange={(newValue) =>
                          setAddOutwardEntry({ ...addOutwardEntry, outwardDate: newValue })
                        }
                        slotProps={{ textField: { variant: 'standard' } }}
                      />
                    </LocalizationProvider>
                    <ContentBox>
                      <TextField
                        id='totalWeight'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Total Weight'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '70%' }}
                        variant='standard'
                        value={addOutwardEntry.totalWeight}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='returnWeight'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Return Weight'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '70%' }}
                        variant='standard'
                        value={addOutwardEntry.returnWeight}
                        onChange={(e) => {
                          setAddOutwardEntry({ ...addOutwardEntry, returnWeight: e.target.value });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='brokenWeight'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Broken Weight'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '70%' }}
                        variant='standard'
                        value={addOutwardEntry.brokenWeight}
                        onChange={(e) => {
                          setAddOutwardEntry({ ...addOutwardEntry, brokenWeight: e.target.value });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='missingWeight'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Missing Weight'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '70%' }}
                        variant='standard'
                        value={addOutwardEntry.missingWeight}
                        onChange={(e) => {
                          setAddOutwardEntry({ ...addOutwardEntry, missingWeight: e.target.value });
                        }}
                      />
                    </ContentBox>
                    <ContentBox>
                      <TextField
                        id='studdWeight'
                        type='number'
                        margin='dense'
                        size='small'
                        label='Studd Weight'
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 1, width: '70%' }}
                        variant='standard'
                        value={addOutwardEntry.studdWeight}
                        // onChange={(e) => {
                        //   setAddOutwardEntry({ ...addOutwardEntry, studdWeight: e.target.value });
                        // }}
                      />
                    </ContentBox>
                  </Grid>
                </Grid>

                <TextField
                  id='remark'
                  type='text'
                  multiline
                  maxRows={4}
                  margin='dense'
                  size='small'
                  label='Remark'
                  InputLabelProps={{ shrink: true }}
                  sx={{ my: 1, width: '85%' }}
                  variant='standard'
                  value={addOutwardEntry.remarks}
                  onChange={(e) => {
                    setAddOutwardEntry({ ...addOutwardEntry, remarks: e.target.value });
                  }}
                />
              </StyledCard>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='standard' color='secondary' onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleOutward}
            disabled={outwardDisable}
            color='primary'
            loading={loading}
            variant='contained'
            sx={{ my: 2 }}
          >
            Add Outward Entry
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
