import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function DeleteDialog({ open, onClose, handleDelete, loading }) {
  return (
    <Box>
      <Dialog
        open={open}
        onClose={onclose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Item Delete Confirmation Dialog</DialogTitle>

        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to delete this Item? This action cannot be undone.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} color='primary'>
            Disagree
          </Button>

          <LoadingButton
            onClick={handleDelete}
            color='primary'
            loading={loading}
            variant='contained'
            sx={{ my: 2 }}
          >
            Delete Item
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
