import { MenuItem, Select } from '@mui/material';

import { useSelector } from 'react-redux';
import { useState } from 'react';
import { selectStatus } from '../helper/filterStatus';

const FilterStatus = ({ setVendorStockData }) => {
  const { stockData } = useSelector((state) => state.stock);
  const [defaultSelect, setDefaultSelect] = useState(0);

  const handleSelect = async (event) => {
    setDefaultSelect(event.target.value);
    const seletctedData = await selectStatus(event.target.value, stockData.data.data);
    setVendorStockData(seletctedData);
  };
  return (
    <Select size='small' value={defaultSelect} onChange={handleSelect}>
      <MenuItem value='all' id='all'>
        Select Status
      </MenuItem>
      <MenuItem value={0} id={1}>
        Running
      </MenuItem>
      <MenuItem value={1} id={2}>
        completed
      </MenuItem>
    </Select>
  );
};

export default FilterStatus;
