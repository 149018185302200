import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  styled,
  TextField
} from '@mui/material';
import React from 'react';
import { H4 } from 'app/components/Typography';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getVendorAccountData } from 'app/contexts/reducers/account/accountSlice';
import { convertVendorId } from 'app/utils/convertId';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { addStockEntry } from 'app/contexts/reducers/stockMaster/stockMasterSlice';
import {
  removeStockBillImage,
  stockBillImage
} from 'app/contexts/reducers/imageUpload/imageUploadSlice';
const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' }
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main }
}));
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});
const FlexBox = styled(Box)(() => ({ display: 'flex', alignItems: 'center' }));
const JustifyBox = styled(FlexBox)(() => ({ justifyContent: 'center' }));
const AddStock = ({ handleApiCall }) => {
  const dispatch = useDispatch();

  const { isVendorSuccess, vendorData } = useSelector((state) => state.account);
  const intialData = {
    billImage: '',
    vendorName: '',
    vendorId: null,
    diamondType: '',
    currentWeight: null,
    ratePerCarat: null,
    price: null,
    pcs: null,
    remark: '',
    date: dayjs()
  };
  const [addStock, setAddStock] = useState(intialData);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [stockVendorData, setStockVendorData] = useState([]);
  const [vendorNameError, setVendorNameError] = useState(false);
  const [stockWeightError, setStockWeightError] = useState(false);
  const [diamondTypeError, setDiamondTypeError] = useState(false);
  const [previweImage, setPreviweImage] = useState();
  const [imageName, setImageName] = useState();

  useEffect(() => {
    dispatch(getVendorAccountData());
  }, [dispatch]);
  useEffect(() => {
    if (isVendorSuccess) {
      setStockVendorData(vendorData.data.data);
    }
  }, [isVendorSuccess, vendorData]);

  useEffect(() => {
    // Calculate the price when there's a change in currentWeight or ratePerCarat
    if (addStock.currentWeight !== null && addStock.ratePerCarat !== null) {
      const calculatedPrice = Math.round(addStock.currentWeight * addStock.ratePerCarat);
      setAddStock((prevAddStock) => ({
        ...prevAddStock,
        price: calculatedPrice
      }));
    } else {
      // If currentWeight or ratePerCarat is null, set price to null
      setAddStock((prevAddStock) => ({
        ...prevAddStock,
        price: null
      }));
    }
  }, [addStock.currentWeight, addStock.ratePerCarat]);

  function handleClickError() {
    setSuccessOpen(false);
    setErrorOpen(true);
  }
  function handleClickSuccess(message) {
    setErrorOpen(false);
    setSuccessMessage(message);
    setSuccessOpen(true);
  }
  function handleSnakeBarClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
    setErrorOpen(false);
  }
  const handleVendorSelect = async (e) => {
    const vendorId = await convertVendorId(stockVendorData, e.target.value);
    setAddStock({ ...addStock, vendorId: vendorId.id, vendorName: e.target.value });
    setVendorNameError(false);
  };
  const handleDiamondTypeSelect = async (e) => {
    setAddStock({ ...addStock, diamondType: e.target.value });
    setDiamondTypeError(false);
  };
  async function handleImageUpdate(event) {
    if (imageName !== undefined) {
      await dispatch(removeStockBillImage({ fileName: imageName, folderName: 'Stock-Bill-Image' }));
      const data = new FormData();
      data.append('stockBillImage', event.target.files[0]);
      const response = await dispatch(stockBillImage(data));

      setAddStock({ ...addStock, billImage: response.payload.data.data });
      await setImageName(event.target.files[0].name);
    } else {
      const data = new FormData();
      data.append('stockBillImage', event.target.files[0]);
      const response = await dispatch(stockBillImage(data));
      setAddStock({ ...addStock, billImage: response.payload.data.data });
      await setImageName(event.target.files[0].name);
    }
    const reader = new FileReader();
    reader.onload = () => {
      setPreviweImage(reader.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  }
  async function handleRemoveImage() {
    const fileName = imageName;
    const folderName = 'Stock-Bill-Image';
    await dispatch(removeStockBillImage({ fileName, folderName }));
    setPreviweImage('');
    setImageName(undefined);
  }
  const handleSubmit = async () => {
    try {
      if (
        addStock.vendorName &&
        addStock.vendorName.length > 0 &&
        addStock.diamondType &&
        addStock.diamondType.length > 0 &&
        addStock.currentWeight &&
        addStock.currentWeight !== null &&
        addStock.vendorId &&
        addStock.vendorId !== null
      ) {
        const stockDetails = { ...addStock };

        const propertiesToConvert = ['currentWeight', 'ratePerCarat', 'price', 'pcs'];
        propertiesToConvert.forEach((property) => {
          if (stockDetails.hasOwnProperty(property)) {
            if (property !== 'currentWeight') {
              stockDetails[property] = parseInt(stockDetails[property]);
            } else {
              stockDetails['currentWeight'] = parseFloat(
                parseFloat(stockDetails['currentWeight']).toFixed(3)
              );
            }
          }
        });
        Object.keys(stockDetails).forEach((key) => {
          if (stockDetails[key] === '') {
            delete stockDetails[key];
          }
        });

        const responseValue = await dispatch(addStockEntry(stockDetails));
        if (!responseValue.hasOwnProperty('error')) {
          handleClickSuccess('Stock Addes successfully!!');
          setAddStock(intialData);
          setPreviweImage('');
          setImageName('');
          handleApiCall();
          setLoading(false);
        } else {
          throw responseValue;
        }
      } else {
        addStock.vendorName.length <= 0 && setVendorNameError(true);
        addStock.diamondType.length <= 0 && setDiamondTypeError(true);
        addStock.currentWeight <= 0 && setStockWeightError(true);
        handleClickError();
        setError({ payload: 'Please Fill Mandatory Data!' });
        setLoading(false);
      }
    } catch (error) {
      console.error(error, 'Hello');
      handleClickError();
      setError(error);
      setLoading(false);
    }
  };
  const DiamondType = [
    {
      name: 'REAL'
    },
    {
      name: 'CVD'
    }
  ];
  return (
    <React.Fragment>
      {' '}
      <Snackbar open={successOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='error'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {error?.hasOwnProperty('payload') ? error.payload : 'Something went wrong!!'}
        </Alert>
      </Snackbar>
      <Grid container spacing={3} sx={{ mb: '24px' }}>
        <Grid item xs={12} md={12}>
          <StyledCard elevation={6}>
            <H4 sx={{ mb: 2 }}>ADD STOCK</H4>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <InputLabel sx={{ fontSize: '10px' }} id='vendorName'>
                  Vendor Name
                </InputLabel>

                <Select
                  size='small'
                  sx={{ width: '50%', my: 1 }}
                  variant='standard'
                  labelId='Vendor Name'
                  value={addStock.vendorName}
                  onChange={handleVendorSelect}
                  error={vendorNameError}
                  helperText={vendorNameError ? 'Please Select Customer' : ''}
                >
                  {stockVendorData.map((data, index) => (
                    <MenuItem value={data.account_name} key={index} id={index}>
                      {data.account_name}
                    </MenuItem>
                  ))}
                </Select>

                <InputLabel sx={{ fontSize: '10px' }} id='diamondType'>
                  Diamond Type
                </InputLabel>
                <Select
                  size='small'
                  sx={{ width: '50%', my: 1 }}
                  variant='standard'
                  labelId='diamondType'
                  value={addStock.diamondType}
                  onChange={handleDiamondTypeSelect}
                  error={diamondTypeError}
                  helperText={diamondTypeError ? 'Please Select Item' : ''}
                >
                  {DiamondType.map((data, index) => (
                    <MenuItem value={data.name} key={index} id={index}>
                      {data.name}
                    </MenuItem>
                  ))}
                </Select>

                <ContentBox>
                  <TextField
                    id='stockPcs'
                    type='number'
                    margin='dense'
                    size='small'
                    label='Diamond Count'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={addStock.pcs == null ? '' : addStock.pcs}
                    onChange={(e) => {
                      setAddStock({ ...addStock, pcs: e.target.value });
                    }}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    id='stockWeight'
                    type='number'
                    margin='dense'
                    size='small'
                    label='Stock Weight'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    error={stockWeightError}
                    helperText={stockWeightError ? 'Please Add Stock Weight' : ''}
                    value={addStock.currentWeight == null ? '' : addStock.currentWeight}
                    required
                    onChange={(e) => {
                      setStockWeightError(false);
                      setAddStock({ ...addStock, currentWeight: e.target.value });
                    }}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    id='caratPrice'
                    type='number'
                    margin='dense'
                    size='small'
                    label='Carat Price'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={addStock.ratePerCarat == null ? '' : addStock.ratePerCarat}
                    onChange={(e) => {
                      setAddStock({ ...addStock, ratePerCarat: e.target.value });
                    }}
                  />
                </ContentBox>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ContentBox>
                  <TextField
                    id='vendorId'
                    type='text'
                    margin='dense'
                    size='small'
                    label='Vendor Code'
                    disabled
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={addStock.vendorId === null ? '' : addStock.vendorId}
                  />
                </ContentBox>

                <ContentBox>
                  <TextField
                    id='stockPrice'
                    type='number'
                    margin='dense'
                    size='small'
                    label='Stock Price'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={addStock.price == null ? '' : addStock.price}
                    onChange={(e) => {
                      setAddStock({ ...addStock, price: e.target.value });
                    }}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    multiline
                    maxRows={4}
                    id='specialNote'
                    type='text'
                    margin='dense'
                    size='small'
                    label='Special Note'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={addStock.remark}
                    onChange={(e) => {
                      setAddStock({ ...addStock, remark: e.target.value });
                    }}
                  />
                </ContentBox>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: '50%', my: 1 }}
                    format='DD/MM/YYYY'
                    label='Date'
                    value={addStock.date}
                    onChange={(newValue) => setAddStock({ ...addStock, date: newValue })}
                    slotProps={{ textField: { variant: 'standard' } }}
                  />
                </LocalizationProvider>
                <ContentBox>
                  <Button
                    component='label'
                    variant='outlined'
                    onChange={handleImageUpdate}
                    sx={{ my: 2 }}
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload file
                    <VisuallyHiddenInput type='file' />
                  </Button>
                </ContentBox>

                <ContentBox sx={{ mt: 2 }}>
                  {previweImage && (
                    <React.Fragment>
                      <JustifyBox p={1} height='100%' sx={{ width: '50%' }}>
                        <img src={previweImage} width='100%' alt={'Bill'} />
                      </JustifyBox>
                      <IconButton onClick={handleRemoveImage}>
                        <Icon color='error'>delete</Icon>
                      </IconButton>
                    </React.Fragment>
                  )}
                </ContentBox>
              </Grid>
            </Grid>
            <LoadingButton
              onClick={handleSubmit}
              color='primary'
              loading={loading}
              variant='contained'
              sx={{ my: 2, textTransform: 'capitalize' }}
            >
              Add Stock
            </LoadingButton>
          </StyledCard>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AddStock;
