import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Card,
  Chip,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  styled,
  TextField,
  useTheme
} from '@mui/material';
import React from 'react';
import { H4 } from 'app/components/Typography';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getItems } from 'app/contexts/reducers/item/itemSlice';
import { getProducts } from 'app/contexts/reducers/product/productSlice';
import { useEffect } from 'react';
import { getAccountData, getEmployeeAccountData } from 'app/contexts/reducers/account/accountSlice';
import {
  convertCustomerId,
  convertItemId,
  convertProductId,
  convertYearId
} from 'app/utils/convertId';
import { addOrder } from 'app/contexts/reducers/transaction/oderSlice';
import { getBookMaster } from 'app/contexts/reducers/bookMaster/bookMasterSlice';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' }
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
const CreateOrder = ({ handleApiCall }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const { isCustomerSuccess, customerData, employeeData, isEmployeeSuccess } = useSelector(
    (state) => state.account
  );
  const { isItemSuccess, itemData } = useSelector((state) => state.item);
  const { isProductSuccess, productData } = useSelector((state) => state.product);
  const { bookYear, isBookMasterSuccess, bookMasterData } = useSelector(
    (state) => state.bookMaster
  );
  const intialData = {
    bookId: null,
    accountName: '',
    accountId: null,
    phoneNumber: null,
    address: '',
    email: '',
    remark: '',
    itemName: '',
    itemId: null,
    productName: '',
    productId: null,
    productPrice: null,
    extraCost: null,
    inwardOrderType: '',
    employeeName: []
  };
  const [createOrder, setCreateOrder] = useState(intialData);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [accountCustomerData, setAccountCustomerData] = useState([]);
  const [accountEmployeeData, setAccountEmployeeData] = useState([]);
  const [itemsData, setItemsData] = useState([]);
  const [productsData, setProductsData] = useState([]);
  // const [date, setDate] = useState(new Date());
  const [customerNameError, setCustomerNameError] = useState(false);
  const [itemNameError, setItemNameError] = useState(false);
  const [productNameError, setProductNameError] = useState(false);
  const [inwardOrderTypeError, setInwardOrderTypeError] = useState(false);
  const [callYear, setCallYear] = useState(false);
  useEffect(() => {
    dispatch(getAccountData());
    dispatch(getItems());
    dispatch(getProducts());
    dispatch(getBookMaster());
    dispatch(getEmployeeAccountData());
  }, [dispatch]);
  useEffect(() => {
    isCustomerSuccess && setAccountCustomerData(customerData.data.data);
    isEmployeeSuccess && setAccountEmployeeData(employeeData.data.data);
    isItemSuccess && setItemsData(itemData.data.data);
    isProductSuccess && setProductsData(productData.data.data);
    isBookMasterSuccess && getBookId();
    // eslint-disable-next-line
  }, [
    isCustomerSuccess,
    isItemSuccess,
    isProductSuccess,
    isBookMasterSuccess,
    customerData,
    itemData,
    productData,
    bookMasterData,
    isEmployeeSuccess,
    employeeData
  ]);
  useEffect(() => {
    getBookId();
    // eslint-disable-next-line
  }, [callYear]);
  // const handleDateChange = (date) => setDate(date);
  const getBookId = async () => {
    const yearId = await convertYearId(bookMasterData?.data?.data, bookYear);
    yearId && setCreateOrder({ ...createOrder, bookId: yearId.id });
  };
  function handleClickError() {
    setSuccessOpen(false);
    setErrorOpen(true);
  }
  function handleClickSuccess(message) {
    setErrorOpen(false);
    setSuccessMessage(message);
    setSuccessOpen(true);
  }
  function handleSnakeBarClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
    setErrorOpen(false);
  }
  const handleCustomerSelect = async (e) => {
    const customerId = await convertCustomerId(accountCustomerData, e.target.value);
    setCreateOrder({
      ...createOrder,
      accountId: customerId.id,
      accountName: e.target.value,
      email: customerId.email,
      phoneNumber: customerId.mobile_number,
      address: customerId.address
    });
    setCustomerNameError(false);
  };
  const handleItemSelect = async (e) => {
    const itemId = await convertItemId(itemsData, e.target.value);
    setCreateOrder({ ...createOrder, itemId: itemId.id, itemName: e.target.value });
    setItemNameError(false);
    const filterProduct = await productData.data.data.filter(
      (product) => product.itemname.toLowerCase() === e.target.value.toLowerCase()
    );
    setProductsData(filterProduct);
  };
  const handleProductSelect = async (e) => {
    const productId = await convertProductId(productData.data.data, e.target.value);
    setCreateOrder({ ...createOrder, productId: productId.id, productName: e.target.value });
    setProductNameError(false);
  };

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    setCreateOrder({
      ...createOrder,
      employeeName: typeof value === 'string' ? value.split(',') : value
    });
  };
  function getStyles(name, employeeName, theme) {
    return {
      fontWeight:
        employeeName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium
    };
  }
  const handleDiamondTypeSelect = async (e) => {
    setCreateOrder({ ...createOrder, inwardOrderType: e.target.value });
    setInwardOrderTypeError(false);
  };
  const handleSubmit = async () => {
    try {
      if (
        createOrder.accountName &&
        createOrder.accountName.length > 0 &&
        createOrder.itemName &&
        createOrder.itemName.length > 0 &&
        createOrder.productName &&
        createOrder.productName.length > 0 &&
        createOrder.itemId !== null &&
        createOrder.productId !== null &&
        createOrder.inwardOrderType &&
        createOrder.inwardOrderType.length > 0
      ) {
        const orderDetails = { ...createOrder };
        Object.keys(orderDetails).forEach((key) => {
          if (orderDetails[key] === '') {
            delete orderDetails[key];
          }
          if (key === 'employeeName') {
            orderDetails[key] = orderDetails[key].join(', ');
          }
        });

        const responseValue = await dispatch(addOrder(orderDetails));
        if (!responseValue.hasOwnProperty('error')) {
          handleClickSuccess('Order Created successfully!!');
          setCreateOrder(intialData);
          handleApiCall();
          setLoading(false);
          setCallYear((prev) => !prev);
        } else {
          throw responseValue;
        }
      } else {
        createOrder.accountName <= 0 && setCustomerNameError(true);
        createOrder.itemName <= 0 && setItemNameError(true);
        createOrder.productName <= 0 && setProductNameError(true);
        createOrder.inwardOrderType <= 0 && setInwardOrderTypeError(true);

        handleClickError();
        setError({ payload: 'Please Fill Mandatory Data!' });
        setLoading(false);
      }
    } catch (error) {
      handleClickError();
      setError(error);
      setLoading(false);
    }
  };

  const InwardType = [
    {
      name: 'SELF'
    },
    {
      name: 'CUSTOMER_SELF'
    },
    {
      name: 'BULK_SELF'
    }
  ];
  return (
    <React.Fragment>
      {' '}
      <Snackbar open={successOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='error'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {error?.payload}
        </Alert>
      </Snackbar>
      <Grid container spacing={3} sx={{ mb: '24px' }}>
        <Grid item xs={12} md={12}>
          <StyledCard elevation={6}>
            <H4 sx={{ mb: 2 }}>CREATE ORDER</H4>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <InputLabel
                  sx={{ fontSize: '10px' }}
                  error={customerNameError}
                  id='accountGroupName'
                >
                  Account Name
                </InputLabel>

                <Select
                  size='small'
                  sx={{ width: '50%', my: 1 }}
                  variant='standard'
                  labelId='accountGroupName'
                  value={createOrder.accountName}
                  onChange={handleCustomerSelect}
                  error={customerNameError}
                >
                  {accountCustomerData.map((data, index) => (
                    <MenuItem value={data.account_name} key={index} id={index}>
                      {data.account_name}
                    </MenuItem>
                  ))}
                </Select>

                <InputLabel sx={{ fontSize: '10px' }} error={itemNameError} id='itemName'>
                  Item Name
                </InputLabel>

                <Select
                  size='small'
                  sx={{ width: '50%', my: 1 }}
                  variant='standard'
                  labelId='itemName'
                  value={createOrder.itemName}
                  onChange={handleItemSelect}
                  error={itemNameError}
                >
                  {itemsData.map((data, index) => (
                    <MenuItem value={data.itemname} key={index} id={index}>
                      {data.itemname}
                    </MenuItem>
                  ))}
                </Select>
                <InputLabel sx={{ fontSize: '10px' }} error={productNameError} id='productName'>
                  Product Name
                </InputLabel>

                <Select
                  size='small'
                  sx={{ width: '50%', my: 1 }}
                  variant='standard'
                  labelId='productName'
                  value={createOrder.productName}
                  onChange={handleProductSelect}
                  error={productNameError}
                >
                  {productsData.map((data, index) => (
                    <MenuItem value={data.productname} key={index} id={index}>
                      {data.productname}
                    </MenuItem>
                  ))}
                </Select>
                <InputLabel sx={{ fontSize: '10px' }} error={inwardOrderTypeError} id='diamondType'>
                  Inward Diamond Type
                </InputLabel>
                <Select
                  size='small'
                  sx={{ my: 1, width: '50%' }}
                  variant='standard'
                  labelId='diamondType'
                  value={createOrder.inwardOrderType}
                  onChange={handleDiamondTypeSelect}
                  error={inwardOrderTypeError}
                >
                  {InwardType.map((data, index) => (
                    <MenuItem value={data.name} key={index} id={index}>
                      {data.name}
                    </MenuItem>
                  ))}
                </Select>
                <ContentBox>
                  <TextField
                    id='productPrice'
                    type='number'
                    margin='dense'
                    size='small'
                    label='Product Price'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={createOrder.productPrice == null ? 'null' : createOrder.productPrice}
                    onChange={(e) => {
                      setCreateOrder({ ...createOrder, productPrice: e.target.value });
                    }}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    id='extraCost'
                    type='number'
                    margin='dense'
                    size='small'
                    label='Extra Cost'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={createOrder.extraCost == null ? 'null' : createOrder.extraCost}
                    onChange={(e) => {
                      setCreateOrder({ ...createOrder, extraCost: e.target.value });
                    }}
                  />
                </ContentBox>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ContentBox>
                  <TextField
                    id='bookMaster'
                    type='text'
                    margin='dense'
                    size='small'
                    label='Year'
                    disabled
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={bookYear}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    id='email'
                    type='email'
                    margin='dense'
                    size='small'
                    label='Email'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={createOrder.email}
                    onChange={(e) => {
                      setCreateOrder({ ...createOrder, email: e.target.value });
                    }}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    id='phoneNumber'
                    type='text'
                    margin='dense'
                    size='small'
                    label='Contact Number'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={createOrder.phoneNumber == null ? '' : createOrder.phoneNumber}
                    onChange={(e) => {
                      setCreateOrder({ ...createOrder, phoneNumber: e.target.value });
                    }}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    id='address'
                    type='text'
                    margin='dense'
                    size='small'
                    label='Address'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={createOrder.address}
                    onChange={(e) => {
                      setCreateOrder({ ...createOrder, address: e.target.value });
                    }}
                  />
                </ContentBox>
                <InputLabel sx={{ fontSize: '10px' }} id='demo-multiple-employee-label'>
                  Employee
                </InputLabel>

                <Select
                  labelId='demo-multiple-employee-label'
                  id='demo-multiple-employee'
                  multiple
                  value={createOrder.employeeName}
                  variant='standard'
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                  sx={{ my: 1, width: '50%' }}
                  // input={
                  //   <OutlinedInput id='select-multiple-chip'  label='Chip' sx={{ width: '50%' }} />
                  // }
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {accountEmployeeData.map((employee, index) => (
                    <MenuItem
                      value={employee.name}
                      style={getStyles(employee.name, createOrder.employeeName, theme)}
                      key={index}
                      id={index}
                    >
                      {employee.name}
                    </MenuItem>
                  ))}
                </Select>
                <ContentBox>
                  <TextField
                    multiline
                    maxRows={4}
                    id='specialNote'
                    type='text'
                    margin='dense'
                    size='small'
                    label='Special Note'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '50%' }}
                    variant='standard'
                    value={createOrder.remark}
                    onChange={(e) => {
                      setCreateOrder({ ...createOrder, remark: e.target.value });
                    }}
                  />
                </ContentBox>
              </Grid>
            </Grid>
            <LoadingButton
              onClick={handleSubmit}
              color='primary'
              loading={loading}
              variant='contained'
              sx={{ my: 2, textTransform: 'capitalize' }}
            >
              Create Order
            </LoadingButton>
          </StyledCard>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CreateOrder;
