// TableRowData.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import { H6, Paragraph } from 'app/components/Typography';
import { Alert, Avatar, Icon, Snackbar } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { createJobBook } from 'app/contexts/reducers/transaction/oderSlice';
import { useNavigate } from 'react-router-dom';
import UpdateOrderDialog from './UpdateOrderDialog';
import DeleteOrder from './DeleteOrder';
import styled from '@emotion/styled';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
const Small = styled('small')(({ bgcolor }) => ({
  width: 50,
  height: 15,
  color: '#fff',
  padding: '2px 8px',
  borderRadius: '4px',
  overflow: 'hidden',
  background: bgcolor,
  boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)'
}));
const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)'
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' }
}));
function TableRowData(props) {
  const dispatch = useDispatch();
  const { row } = props;
  const [open, setOpen] = useState(false);
  const { palette } = useTheme();
  const bgPrimary = palette.primary.main;
  const bgSuccess = palette.success.main;
  const bgSecondary = palette.secondary.main;
  const bgError = palette.error.main;
  const navigate = useNavigate();

  const [updateOpen, setUpdateOpen] = useState(false);
  const [updateOrderId, setUpdateOrderId] = useState(null);
  const [deleteOrderId, setDeleteOrderId] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [error, setError] = useState();

  const convertDate = (isoDateString) => {
    const date = new Date(isoDateString);

    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    };
    const formattedDate = date.toLocaleString('en-GB', options).replace(/\//g, '-');
    return formattedDate;
  };

  function handleUpdateClose() {
    setUpdateOpen(false);
  }

  function handleDeleteClose() {
    setDeleteOpen(false);
  }

  function handleClickSuccess(message) {
    setErrorOpen(false);
    setSuccessMessage(message);
    setSuccessOpen(true);
  }

  function handleClickError() {
    setSuccessOpen(false);
    setErrorOpen(true);
  }

  function handleSnakeBarClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
    setErrorOpen(false);
  }

  return (
    <React.Fragment>
      <Snackbar open={successOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='error'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {error?.payload}
        </Alert>
      </Snackbar>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} onClick={() => setOpen(!open)}>
        <TableCell
          component='th'
          scope='row'
          sx={{ fontWeight: 'bold' }}
          align='center'
          colSpan={6}
        >
          {row.accountName}
        </TableCell>
        <TableCell>
          <IconButton aria-label='expand row' size='small'>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <ProductTable>
                <TableHead sx={{ backgroundColor: 'rgb(34,41,67)' }}>
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      align='center'
                      sx={{ px: 2, fontWeight: 'bold', color: '#FFFFFF' }}
                    >
                      Order-Id
                    </TableCell>
                    <TableCell
                      colSpan={2}
                      align='center'
                      sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                    >
                      Item-Name
                    </TableCell>
                    <TableCell
                      align='left'
                      sx={{ px: 3, fontWeight: 'bold', color: '#FFFFFF' }}
                      colSpan={3}
                    >
                      Product-Name
                    </TableCell>

                    <TableCell
                      align='center'
                      sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                      colSpan={2}
                    >
                      Order Status
                    </TableCell>
                    <TableCell
                      align='center'
                      sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                      colSpan={2}
                    >
                      Inward Type
                    </TableCell>

                    <TableCell
                      colSpan={2}
                      align='center'
                      sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      colSpan={2}
                      align='center'
                      sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                    >
                      Print-Label
                    </TableCell>

                    <TableCell
                      align='center'
                      sx={{ px: 5, fontWeight: 'bold', color: '#FFFFFF' }}
                      colSpan={3}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {row.orders && row.orders.length > 0 ? (
                    row.orders.map((orderDetails, index) => (
                      <TableRow key={index} hover>
                        <TableCell
                          colSpan={2}
                          align='left'
                          sx={{ px: 0, textTransform: 'capitalize' }}
                        >
                          <H6 sx={{ px: 1 }}>Q-{orderDetails.id}</H6>
                        </TableCell>
                        <TableCell
                          colSpan={2}
                          align='center'
                          sx={{ px: 0, textTransform: 'capitalize' }}
                        >
                          {orderDetails.item_name}
                        </TableCell>
                        <TableCell
                          align='left'
                          colSpan={3}
                          sx={{ px: 2, textTransform: 'capitalize' }}
                        >
                          <Box display='flex' alignItems='center'>
                            <Avatar src={orderDetails.productimage} />
                            <Paragraph sx={{ m: 0, ml: 1 }}>{orderDetails.product_name}</Paragraph>
                          </Box>
                        </TableCell>

                        <TableCell align='center' sx={{ px: 2 }} colSpan={2}>
                          {orderDetails.status === 0 && (
                            <Small bgcolor={bgSecondary}>Pending</Small>
                          )}
                          {orderDetails.status === 1 && <Small bgcolor={bgPrimary}>Running</Small>}
                          {orderDetails.status === 2 && (
                            <Small bgcolor={bgSuccess}>Completed</Small>
                          )}
                          {orderDetails.status === 3 && <Small bgcolor={bgError}>Sold</Small>}
                        </TableCell>
                        <TableCell align='center' sx={{ px: 2 }} colSpan={2}>
                          {orderDetails.order_inward_type}
                        </TableCell>

                        <TableCell colSpan={2} align='center' sx={{ px: 0 }}>
                          {convertDate(orderDetails.date)}
                        </TableCell>
                        <TableCell
                          align='center'
                          sx={{ px: 0, fontWeight: 'bold', color: '#FFFFFF' }}
                          colSpan={2}
                        >
                          <Tooltip title='Print'>
                            <IconButton
                              onClick={() => {
                                const orderId = orderDetails.id;
                                dispatch(createJobBook({ orderId }));
                              }}
                            >
                              <Icon color='primary'>print</Icon>
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell align='center' sx={{ px: 2 }} colSpan={3}>
                          <Tooltip title='Edit'>
                            <IconButton
                              onClick={() => {
                                setUpdateOrderId(orderDetails.id);
                                setUpdateOpen(true);
                              }}
                            >
                              <Icon color='primary'>edit</Icon>
                            </IconButton>
                          </Tooltip>
                          <Tooltip title='Delete'>
                            <IconButton
                              onClick={() => {
                                setDeleteOrderId(orderDetails.id);
                                setDeleteOpen(true);
                              }}
                            >
                              <Icon color='error'>close</Icon>
                            </IconButton>
                          </Tooltip>
                          <Tooltip title='View'>
                            <IconButton
                              onClick={() => {
                                navigate(`/orders/${orderDetails.id}`);
                              }}
                            >
                              <Icon color='primary'>keyboard_arrow_right</Icon>
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={12}
                        align='center'
                        sx={{ color: 'rgb(34,41,67)', fontWeight: 'bold' }}
                      >
                        No Stock Data Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </ProductTable>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <UpdateOrderDialog
        open={updateOpen}
        onClose={handleUpdateClose}
        updateOrderId={updateOrderId}
        setApiCall={props.setApiCall}
        handleClickSuccess={handleClickSuccess}
        setError={setError}
        handleClickError={handleClickError}
      />
      {/* Start-Delete-Account */}
      <DeleteOrder
        open={deleteOpen}
        onClose={handleDeleteClose}
        deleteOrderId={deleteOrderId}
        setApiCall={props.setApiCall}
        handleClickSuccess={handleClickSuccess}
        handleClickError={handleClickError}
        setError={setError}
      />
    </React.Fragment>
  );
}

TableRowData.propTypes = {
  row: PropTypes.shape({
    accountId: PropTypes.number.isRequired,
    orders: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        item_name: PropTypes.string.isRequired,
        productimage: PropTypes.string.isRequired,
        product_name: PropTypes.string.isRequired,
        status: PropTypes.number.isRequired,
        order_inward_type: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired
};

export default TableRowData;
