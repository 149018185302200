const selectHeadGroupValue = async (selectedData, dataHouse) => {
  if (selectedData.trim() === 'all') {
    // If the search term is empty, return the whole array
    return dataHouse;
  }
  const searchData = await dataHouse.filter((data) => {
    return data.head_group_name.toLowerCase().includes(selectedData);
  });
  return searchData;
};
const selectAccountCustomerValue = async (selectedData, dataHouse) => {
  if (selectedData.trim() === 'all') {
    // If the search term is all, return the whole array
    return dataHouse;
  }
  const selectedCustomerData = await dataHouse.filter((data) => {
    return data.customer_type.toLowerCase().includes(selectedData);
  });
  return selectedCustomerData;
};
const selectAccountVendorValue = async (selectedData, dataHouse) => {
  if (selectedData.trim() === 'all') {
    // If the search term is all, return the whole array
    return dataHouse;
  }
  const selectedVendorData = await dataHouse.filter((data) => {
    return data.vendor_type.toLowerCase().includes(selectedData);
  });
  return selectedVendorData;
};
export { selectHeadGroupValue, selectAccountCustomerValue, selectAccountVendorValue };
